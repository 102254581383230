import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { Button, Grid, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TableBody } from '@mui/material'
import { UUID } from 'utils/types'
import { QuestionPurpose, QuestionType } from 'components/questions/constants'
import { deleteConfirmation } from 'components/layouts/Modal/contentModal'
import Modal from 'components/layouts/Modal/Modal'
import QuestionLine from 'components/questions/questions-list/QuestionLine'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useAddQuestion from 'hooks/questions/useAddQuestion'
import useUnpublishQuestion from 'hooks/questions/useUnpublishQuestion'
import usePublishQuestion from 'hooks/questions/usePublishQuestion'
import useOfficialNationalSimulationQuiz from 'hooks/officialNationalSimulation/useOfficialNationalSimulationQuiz'
import useAddOfficialNationalQuizQuestion from 'hooks/officialNationalSimulation/useAddOfficialNationalQuizQuestion'
import usePublishOfficialNationalQuiz from 'hooks/officialNationalSimulation/usePublishOfficialNationalQuiz'
import useUnpublishOfficialNationalQuiz from 'hooks/officialNationalSimulation/useUnpublishOfficialNationalQuiz'
import useDeleteQuizQuestion from 'hooks/questions/useDeleteQuizQuestion'


interface MonthlySimulationsQuizPageParams {
  quizId: UUID
}

const OfficialNationalQuizPage = () => {
  const queryClient = useQueryClient()
  const { quizId } = useParams<MonthlySimulationsQuizPageParams>()
  const quiz = useOfficialNationalSimulationQuiz(quizId).data

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [questionIdToDelete, setQuestionIdToDelete] = useState<string>('')

  const addQuestionMutation = useAddQuestion()
  const addOfficialNationalQuizMutation = useAddOfficialNationalQuizQuestion(quizId)
  const publishQuizMutation = usePublishOfficialNationalQuiz()
  const unpublishQuizMutation = useUnpublishOfficialNationalQuiz()
  const publishQuestionMutation = usePublishQuestion()
  const unpublishQuestionMutation = useUnpublishQuestion()
  const deleteQuizQuestionMutation = useDeleteQuizQuestion(quizId)

  const handleDeleteQuestionDialogOpen = (questionId: string) => {
    setDeleteConfirmDialogOpen(true)
    setQuestionIdToDelete(questionId)
  }

  const handleAddQuestion = () =>
    addQuestionMutation.mutate({
      type: QuestionType.MULTIPLE_CHOICE,
      purpose: QuestionPurpose.OFFICIAL_NATIONAL,
    }, {
      async onSuccess({id}) {
        addOfficialNationalQuizMutation.mutate({
          questionId: id,
          number: (quiz?.questions?.length || 0) + 1,
        })
      }
    })
  
  const handleDeleteQuestion = () => deleteQuizQuestionMutation.mutateAsync(questionIdToDelete)
    .then(_ => setDeleteConfirmDialogOpen(false))
    .then(toggleRefetch)
  
  const toggleQuizPublish = (published: boolean) => {
    const mutation = published ? unpublishQuizMutation : publishQuizMutation
    mutation.mutate(quizId)
  }

  const toggleQuestionPublish = (questionId: string, published: boolean) => {
    const mutation = published ? unpublishQuestionMutation : publishQuestionMutation
    mutation.mutateAsync(questionId).then(toggleRefetch)
  }
  
  const toggleRefetch = () => queryClient.invalidateQueries([ReactQueryKeys.OFFICIAL_NATIONAL_QUIZ])

  return (
    <>
      {quiz && (
        <div>
          <Grid sx={{mb: '30px'}}>
            <Button
              variant="contained"
              disabled={quiz.published}
              onClick={handleAddQuestion}
            >
              Add question
            </Button>
            <Button
              variant="contained"
              color={quiz.published ? 'secondary' : 'primary'}
              sx={{ml: 3, width: 'auto'}}
              onClick={() => toggleQuizPublish(quiz.published)}
            >
              {quiz.published ? 'Unpublish' : 'Publish'}
            </Button>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">№</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Answer Explanation</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Statistic Topic</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quiz.questions && quiz.questions.map(({ question, number }) => (
                  <TableRow key={question.id} hover style={{cursor: "pointer"}}>
                    <QuestionLine
                      question={question}
                      number={number}
                      link={`/quizzes/official-national-simulations/${quizId}/questions/${question.id}`}
                      onChangePublish={toggleQuestionPublish}
                      onOpenDeleteConfirmDialog={handleDeleteQuestionDialogOpen}
                      showPublish={false}
                      canDelete={!quiz.published}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            confirmOpen={deleteConfirmDialogOpen}
            onAction={handleDeleteQuestion}
            onClose={() => setDeleteConfirmDialogOpen(false)}
            content={deleteConfirmation}
          />
        </div>)}
    </>
  )
}

export default OfficialNationalQuizPage
