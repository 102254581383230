import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { getLessonTags } from 'services/lesson-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useLessonTags = (options?: UseMutationOptions<Array<string>, AxiosError, string>)
  : UseMutationResult<Array<string>, AxiosError, string> => useMutationWithSnackbar(
    getLessonTags,
    options,
  )

export default useLessonTags
