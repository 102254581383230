import { useHistory } from 'react-router-dom'
import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { addCourse } from 'services/courses-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateCourse = (options?: UseMutationOptions<CourseView, AxiosError, CourseUpdateRequest>)
  : UseMutationResult<CourseView, AxiosError, CourseUpdateRequest> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    addCourse,
    {
      async onSuccess() {
        await history.push(`/courses`)
      },
      ...options,
    },
  )
}

export default useCreateCourse
