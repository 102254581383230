import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { UUID } from 'utils/types'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { CreatePackResponse } from 'types/responses/CreatePackResponse'
import { getPackSimulation } from 'services/pack-simulation-quiz-service'

const usePackSimulation = (packSimulationId: UUID, options?: UseQueryOptions<CreatePackResponse, AxiosError>)
  : UseQueryResult<CreatePackResponse> => useQueryWithSnackbar<CreatePackResponse>(
    [ReactQueryKeys.PACK_SIMULATION, packSimulationId],
    () => getPackSimulation(packSimulationId), {
      enabled: !!packSimulationId,
      ...options,
    },
  )

export default usePackSimulation
