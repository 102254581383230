import { useHistory } from 'react-router-dom'
import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createLessonQuiz } from 'services/lesson-quiz-service'
import { UUID } from 'utils/types'
import { Quiz } from 'components/quizzes/quizzes'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateLessonQuiz = (courseId: UUID, lessonId: UUID, options?: UseMutationOptions<Quiz, AxiosError>)
  : UseMutationResult<Quiz, AxiosError, void> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    () => createLessonQuiz(courseId, lessonId),
    {
      async onSuccess(lessonQuiz) {
        await history.push(`/courses/${courseId}/lessons/${lessonId}/quizzes/${lessonQuiz.id}`)
      },
      ...options,
    },
  )
}

export default useCreateLessonQuiz
