import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getLessonArea } from 'services/area-service'
import { isWeTest } from 'utils/constants'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useLessonAreas = (courseId: string, options?: UseQueryOptions<AreaResponseView[], AxiosError>): UseQueryResult<AreaResponseView[]> =>
  useQueryWithSnackbar<AreaResponseView[]>(
    ReactQueryKeys.LESSON_AREA,
    () => getLessonArea(courseId),
    {
      enabled: isWeTest() && !!courseId,
      ...options,
    },
  )

export default useLessonAreas
