import { useState } from 'react'

type SearchProps = {
  text?: string,
  secondaryText?: string
}

interface usePageableResult {
  pageNumber: number
  rowsPerPage: number
  textFilter?: string | null
  secondaryFilter?: string | null
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  handleChangeBack: (event: React.ChangeEvent<unknown>, newPage: number) => void
  handleChangeRowsPerPage: ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  clearFilters: () => void
  handleSearch: (data: SearchProps) => void
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const usePageable = (): usePageableResult => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [textFilter, setTextFilter] = useState<string | null>()
  const [secondaryFilter, setSecondaryFilter] = useState<string | null>()

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPageNumber(newPage)

  const handleChangeBack = (event: React.ChangeEvent<unknown>, newPage: number) => setPageNumber(newPage - 1)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleSearch = ({text, secondaryText }: SearchProps) => {
    setTextFilter(text || null)
    setSecondaryFilter(secondaryText || null)
    setPageNumber(0)
  }

  const clearFilters = () => {
    setTextFilter(null)
    setSecondaryFilter(null)
    setPageNumber(0)
  }
  
  return {
    rowsPerPage,
    pageNumber,
    textFilter,
    secondaryFilter,
    handleChangePage,
    handleChangeBack,
    handleChangeRowsPerPage,
    setPageNumber,
    clearFilters,
    handleSearch,
  }
}

export default usePageable
