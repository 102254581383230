import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { saveQuestion } from 'services/question-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useSaveQuestion = (
  link: string,
  questionId: string,
  options?: UseMutationOptions<QuestionResponse, AxiosError, QuestionUpsertModelRequest>
): UseMutationResult<QuestionResponse, AxiosError, QuestionUpsertModelRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data) => saveQuestion(questionId, data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.QUESTION)
        await history.push(link)
      },
    ...options,
    },
)}

export default useSaveQuestion
