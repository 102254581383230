import useSubjects from "hooks/subjects/useSubjects";
import useQuizRule from "hooks/useQuizRule";
import useUpdateQuizRulesList from "hooks/useUpdateQuizRulesList";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GetRuleData,  SectionData} from "types/responses/GetRuleData";
import QuizRulesForm from "./forms/QuizRulesForm";
import { RuleInterface } from "./types/quizRule";

const QuizEditRulesPage = (props: RouteComponentProps<{ ruleId: string }>) => {
  const ruleId: string = props.match.params.ruleId;
  const subjects = useSubjects().data || []

  const quizRule = useQuizRule(ruleId).data;
  const updateRuleMutation = useUpdateQuizRulesList(ruleId);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(-1)

  const handleSectionSelection = (section: any, index: number) => {
    setSelectedSectionIndex(index)
  }

  const formatData = (sectionsForm: SectionData[]) => {
    let sections = sectionsForm.map(
      ({ id, name, questionsAmount, timeLimit, subjects }, index) => ({
        id,
        name,
        questionsAmount: Number(questionsAmount),
        timeLimit: timeLimit !== null ? Number(timeLimit) : timeLimit,
        sortOrder: index,
        subjects: subjects
          .map((subject) => ({
            subjectId: subject.subject.id,
            tagProbabilities: subject.tagProbabilities.map(
              ({ probability, simulationTag }) => ({
                probability,
                tagId: simulationTag.id,
              })
            ),
          }))
          .flat(),
      })
    );

    return sections;
  };

  const handleSubmit = (rule: RuleInterface) => {
    updateRuleMutation.mutate({
      name: rule.name,
      availableFrom: rule.availableFrom,
      correctAnswerPoints: rule.correctAnswerPoints * 100,
      incorrectAnswerPoints: rule.incorrectAnswerPoints * 100,
      noAnswerPoints: rule.noAnswerPoints * 100,
      sections: formatData(rule.sections),
      timeLimit: rule.timeLimit
    });
  };

  const getInitialState = (rule: GetRuleData) => {
    return {
      name: rule.name,
      availableFrom: rule.availableFrom,
      ranking: rule.ranking,
      correctAnswerPoints: rule.correctAnswerPoints / 100,
      incorrectAnswerPoints: rule.incorrectAnswerPoints / 100,
      noAnswerPoints: rule.noAnswerPoints / 100,
      sections: rule.sections,
      divided: rule.dividedBySections,
      timeLimit: rule.timeLimit,
      source: rule.source,
      type: rule.type,
      questionAmount: rule.questionAmount,
      subjectId: rule.subject?.id
    };
  };

  return (
    <>
      {quizRule && (
        <QuizRulesForm
          quiz={getInitialState(quizRule)}
          onSubmit={handleSubmit}
          selectedSectionIndex={selectedSectionIndex}
          handleSectionSelection={handleSectionSelection}
          subjects={subjects}
        />
      )}
    </>
  );
};

export default QuizEditRulesPage;
