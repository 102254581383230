import { Box, TextField } from "@mui/material";
import { StyledLabel } from "components/layouts/dashboard/StyledInput";

type RulePointsInteface = {
    correctAnswerPoints: number,
    incorrectAnswerPoints: number,
    noAnswerPoints: number,
    onChange: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const RulePoints = ({correctAnswerPoints, incorrectAnswerPoints, noAnswerPoints, onChange}: RulePointsInteface ) => {
    return(
        <Box mt={5} className="u-flex-container" sx={{ alignItems: "flex-start", flexDirection: "column" }}>
        <StyledLabel sx={{marginBottom: '10px'}}>Point rules:</StyledLabel>
        <Box
          sx={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: 'space-between' }}
        >
          <Box sx={{display: 'flex', flexDirection: "column" }}>
            <StyledLabel>Correct</StyledLabel>
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              value={correctAnswerPoints}
              inputProps={{ min: 0, maxLength: 3,
                step: "0.1"}}
              sx={{width: '180px'}}
              onChange={(e) =>
                onChange("correctAnswerPoints", +e.target.value)
              }
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: "column" }}>
            <StyledLabel>Incorrect</StyledLabel>
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              inputProps={{ max: 0, maxLength: 3, step: "0.1" }}
              value={incorrectAnswerPoints}
              sx={{width: '180px'}}
              onChange={(e) =>
                onChange("incorrectAnswerPoints", +e.target.value)
              }
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: "column" }}>
            <StyledLabel>No answer</StyledLabel>
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              value={noAnswerPoints}
              inputProps={{ maxLength: 3, step: "0.1" }}
              sx={{width: '180px'}}
              onChange={(e) =>
                onChange("noAnswerPoints", +e.target.value)
              }
            />
          </Box>
        </Box>
      </Box>
    )

};

export default RulePoints
