import { object, string, number } from "yup";

const PackSimulationFormSchema = object().shape({
  title: string().required(),
  code: string().required(),
  purchaseLink: string().required(),
  availableFrom: number().required(),
});

export default PackSimulationFormSchema;
