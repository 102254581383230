import { clientDataService } from './client-data-service'
import { UUID } from '../utils/types'
import { QuizType } from '../components/quizzes/constants'
import { QuizQuestion } from '../components/quizzes/quizzes'
import jsonToFormData from 'utils/jsonToFormData'

export type PackQuizDetails = {
  name: string
  packSimulationId: UUID
  packSimulationTitle: string
}

export type PackQuizzes = {
    id: UUID
    quizType: QuizType
    createdAt: number
    modifiedAt: number
    published: boolean
    quizDetails: PackQuizDetails
}

export type PackQuiz = {
  quizType: QuizType
  createdAt: number
  ruleSetId: UUID
  modifiedAt: number
  publishedAt: number
  published: boolean,
  id: UUID
  questions: QuizQuestion[]
  quizDetails: PackQuizDetails
}

export type CreatePackQuiz = {
  title: string,
  questionsData?: File
}

export type PublishPackQuizRequest = {
  quizId: UUID,
  packSimulationId: UUID

}

export type EditPackQuizRequest = {
  title: string
}


const baseUrl = "/admin/pack-simulations";

export const getPackQuizzes = (packSimulationId: UUID): Promise<PackQuizzes[]> =>
  clientDataService<PackQuizzes[]>({
    method: 'GET',
    url: `${baseUrl}/${packSimulationId}/quizzes`,
})

export const getPackQuiz = (packSimulationId: UUID, quizId: UUID): Promise<PackQuiz> =>
  clientDataService<PackQuiz>({
    method: 'GET',
    url: `${baseUrl}/${packSimulationId}/quizzes/${quizId}`
})

export const createPackQuiz = async (packSimulationId: UUID, data: CreatePackQuiz): Promise<void> =>
  await clientDataService<void>({
    method: 'POST',
    url: `${baseUrl}/${packSimulationId}/quizzes`,
    data: jsonToFormData(data)
})

export const editPackQuiz = async (packSimulationId: UUID, quizId: UUID, data: EditPackQuizRequest ): Promise<PackQuizzes> =>
  await clientDataService<PackQuizzes>({
    method: 'PATCH',
    url: `${baseUrl}/${packSimulationId}/quizzes/${quizId}`,
    data
})

export const publishPackQuiz = async (data: PublishPackQuizRequest): Promise<void> =>
  await clientDataService<void>({
    method: 'PUT',
    url: `${baseUrl}/${data.packSimulationId}/quizzes/${data.quizId}/publish`,
})

export const unpublishPackQuiz = async (data: PublishPackQuizRequest): Promise<void> =>
  await clientDataService<void>({
    method: 'PUT',
    url: `${baseUrl}/${data.packSimulationId}/quizzes/${data.quizId}/unpublish`,
})

