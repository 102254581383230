import React, { FC } from 'react'
import { Link } from "react-router-dom"
import MathJax from 'react-mathjax-preview'
import { parse } from 'node-html-parser'
import { Box, Button, TableCell } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { QuestionPurpose } from '../constants'

type QuestionLineProps = {
  question: QuestionResponse
  number?: number
  link: string
  onOpenDeleteConfirmDialog: (questionId: string) => void
  onChangePublish: (questionId: string, published: boolean) => void
  canDelete?: boolean
  showPublish?: boolean
}

const QuestionLine: FC<QuestionLineProps> = ({
                                              question,
                                              number,
                                              link,
                                              onOpenDeleteConfirmDialog,
                                              onChangePublish,
                                              showPublish=true,
                                              canDelete=true
}) => {
  const { id, text, answerExplanation, published, difficulty, purpose, subject, topic, simulationTag } = question

  return (
  <>
    {number &&<TableCell component="th" scope="row" align="right">{number}</TableCell>}
    <TableCell component="th" scope="row">
      {parse(text).childNodes.map((child, index) => (
        <MathJax key={index} math={child.toString()}/>
      ))}
    </TableCell>
    <TableCell component="th" scope="row">
      {parse(answerExplanation).childNodes.map((child, index) => (
        <MathJax key={index} math={child.toString()}/>
      ))}
    </TableCell>
    {subject && <TableCell component="th" scope="row">
      {subject?.name}
    </TableCell>}
    {topic && <TableCell component="th" scope="row">
      {topic?.name}
    </TableCell>}
    {simulationTag && <TableCell component="th" scope="row">
      {simulationTag?.name}
    </TableCell>}
    { question.purpose !== QuestionPurpose.STATIC_PRACTICE && difficulty &&
      <TableCell component="th" scope="row">
        {difficulty}
      </TableCell>
    }
    <TableCell component="th" scope="row" sx={{ wordBreak: "break-word" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Link to={link}>
          <Button color="primary" component="span">
            <EditIcon />
          </Button>
        </Link>
        {showPublish &&
          <Button
            variant="contained"
            color={published ? 'secondary' : 'primary'}
            sx={{ml: 3, width: 'auto', minWidth: '120px'}}
            onClick={() => onChangePublish(id, published)}
            disabled={!canDelete}
          >
            {published ? 'Unpublish' : 'Publish'}
          </Button>}
        <Button type="button" onClick={() => onOpenDeleteConfirmDialog(id)} disabled={!canDelete}>
          <DeleteIcon/>
        </Button>
      </Box>
    </TableCell>
  </>
  )}

export default QuestionLine
