import {
  Badge,
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { FormEvent, useState } from 'react'
import { useQueryClient } from 'react-query'
import { questionPurposes, QuestionPurpose } from 'components/questions/constants'
import { ImportTask } from 'components/management/import/questions/ImportTask'
import FileUpload from '../../../shared/file-upload/FileUpload'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useTasksForImport from 'hooks/useTasksForImport'
import useDeleteTasks from 'hooks/useDeleteTasks'
import useImportQuestions from 'hooks/useImportQuestions'
import { ErrorTable } from './ErrorTable'


export const ImportQuestionsPage = () => {
  const queryClient = useQueryClient()
  const [selectedType, setSelectedType] = useState<string>(QuestionPurpose.QUESTIONS_POOL)

  const tasks = useTasksForImport().data || []

  const deleteTasksMutation = useDeleteTasks()
  const importQuestionMutation = useImportQuestions()

  const refreshTask = () => queryClient.invalidateQueries(ReactQueryKeys.IMPORT_TASKS)

  const getImportConfig = () => {
    switch (selectedType) {
      case QuestionPurpose.QUESTIONS_POOL:
        return {url: 'import-questions-pool', dataName: 'questionPoolData'}
      case QuestionPurpose.LESSON:
        return {url: 'import-lesson-quizzes', dataName: 'quizData'}
      default: 
        return {url: 'import-questions-pool', dataName: 'questionPoolData'}
    }
  }

  const handleDeleteTasks = () => deleteTasksMutation.mutate()


  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    file: File | undefined,
    setUploadPercentage: (value: number) => void,
    setUploadComplete: (value: boolean) => void,
  ) => {
    e.preventDefault()
    const formData = new FormData()
    if (file) {
      formData.append(getImportConfig().dataName, file)
    }

    await importQuestionMutation.mutateAsync({
      data: formData,
      link: getImportConfig().url,
      config: {
        onUploadProgress: (progressEvent: any) => {
          setUploadPercentage(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
          )
        }
      }
    }).then(_ => setUploadComplete(true)).finally(refreshTask)
  }

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: 'flex-end', mb: '5px' }}>
        <div style={{width:'500px'}}>
          <FileUpload onSubmit={handleSubmit} fileType="file" />
        </div>
        <Box sx={{ml: '20px', mr: 'auto'}}>
          <Typography sx={{textTransform: 'uppercase', mb: '15px'}}>Select Type</Typography>
          <Select name="questionType" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
            {questionPurposes.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
          </Select>
        </Box>
        <Button variant="contained" onClick={() => handleDeleteTasks()}>CLEAR TABLE</Button>
      </Box>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Created at
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Completed
                </TableCell>
                <TableCell>
                  Total questions
                </TableCell>
                <TableCell>
                  Questions imported
                </TableCell>
                <TableCell>
                  Errors
                </TableCell>
              </TableRow>
            </TableHead>
            <ErrorTable
              tasks={tasks}
            />
          </Table>
        </TableContainer>
      </div>
    </div>
  );

};
