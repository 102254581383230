import { clientDataService } from './client-data-service'
import { UUID } from 'utils/types'
import { Quiz, QuizQuestion } from 'components/quizzes/quizzes'

export const getLessonQuiz = (courseId: UUID, lessonId: UUID, quizId: UUID): Promise<Quiz> =>
  clientDataService<Quiz>({
    method: 'GET',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/quizzes/${quizId}`,
  })

export const createLessonQuiz = (courseId: UUID, lessonId: UUID): Promise<Quiz> =>
  clientDataService<Quiz>({
    method: 'POST',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/quizzes`,
  })

export const deleteLessonQuiz = (courseId: UUID, lessonId: UUID): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/quizzes`,
  })

export type LessonQuizQuestionUpsertModel = {
  number: number
  questionId: UUID
}

export const addLessonQuizQuestion = (courseId: UUID, lessonId: UUID, quizId: UUID, data: LessonQuizQuestionUpsertModel): Promise<QuizQuestion> =>
  clientDataService<QuizQuestion>({
    method: 'POST',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/quizzes/${quizId}/questions`,
    data
  })
