import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { parse } from 'node-html-parser'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import MathJax from 'react-mathjax-preview'
import useMonthlySimulationQuiz from 'hooks/monthlySimulation/useMonthlySimulationQuiz'

const MonthlySimulationsQuizPage = (props: RouteComponentProps<{ quizId: string }>) => {
  const quizId = props.match.params.quizId || ''
  const history = useHistory()

  const quiz = useMonthlySimulationQuiz(quizId).data

  function selectedRow(questionId: String) {
    history.push(`/quizzes/monthly-simulations/${quizId}/questions/${questionId}`)
  }

  function tableContent() {
    return (
      <TableBody>
        {quiz?.questions?.map(({number, question}) =>
          
          <TableRow
            key={question.id}
            hover
            onClick={_ => selectedRow(question.id)}
            style={{cursor: "pointer"}}
          >
            <TableCell component="th" scope="row" align="right">
              {number}
            </TableCell>
            <TableCell component="th" scope="row">
              {parse(question.text).childNodes.map(child => (
                <MathJax math={child.toString()}/>
              ))}
            </TableCell>
            <TableCell component="th" scope="row">
              {parse(question.answerExplanation).childNodes.map(child => (
                <MathJax math={child.toString()}/>
              ))}
            </TableCell>
            <TableCell component="th" scope="row">
              {question.subject.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {question.topic.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {question.difficulty}
            </TableCell>
          </TableRow>
          
        )}
      </TableBody>
    )
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">№</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Answer Explanation</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Difficulty</TableCell>
            </TableRow>
          </TableHead>
          {quiz && tableContent()}
        </Table>
      </TableContainer>
    </div>
  )
}

export default MonthlySimulationsQuizPage
