import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { createArea } from 'services/area-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateArea = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar((data: NewAreaRequest) => createArea(data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.AREAS)
        await history.push(`/areas`)
      },
    },
)}

export default useCreateArea
