import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { addTag } from 'services/simulationtags-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useCreateTag = ( options?: UseMutationOptions<CreateResponse, AxiosError, CreateSimulationTagRequest>)
  : UseMutationResult<CreateResponse, AxiosError, CreateSimulationTagRequest> => {
  
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: CreateSimulationTagRequest) => addTag(data), {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.TAGS)
      },
      ...options,
    },
  )
}

export default useCreateTag
