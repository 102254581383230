import { object, string, mixed } from 'yup'

const QuestionFormSchema = object().shape({
  text: string().required(),
  answerExplanation: string().required(),
  options: mixed()
    .test(
      'allQuestion',
      'Fill all question options',
      value => !value || value && value.filter(o => !!o.text).length === value.length,
    )
    .test(
      'correctOption',
      'Choose one correct option',
      value => !value || value && value.filter(o => o.correct).length === 1,
    ),   
})

export default QuestionFormSchema
