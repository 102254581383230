import { UseMutationOptions, UseMutationResult } from "react-query";
import { AxiosError } from "axios";
import { getStatisticsReport } from "services/quiz-generate-rules-service";
import useMutationWithSnackbar from "./useMutationWithSnackbar";

const useStatisticsReport = (
  options?: UseMutationOptions<any, AxiosError, GetReportData>
): UseMutationResult<any, AxiosError, GetReportData> =>
  useMutationWithSnackbar((data: GetReportData) => getStatisticsReport(data),
  {
    ...options
  });

export default useStatisticsReport;
