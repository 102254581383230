import { UUID } from 'utils/types'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { editPackQuiz, EditPackQuizRequest, PackQuizzes } from 'services/pack-quizzes-service'

const useEditPackQuiz = (packSimulationId: UUID, quizId: UUID, options?: UseMutationOptions<PackQuizzes, AxiosError, EditPackQuizRequest>)
  : UseMutationResult<PackQuizzes, AxiosError, EditPackQuizRequest> => {
  const queryClient = useQueryClient()
  return useMutationWithSnackbar(
    (data: EditPackQuizRequest) => editPackQuiz(packSimulationId, quizId, data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.PACK_QUIZ)
      },
      ...options,
    },
  )
  }
  
export default useEditPackQuiz
