import serverApi from './server-api-service'
import { clientDataService } from './client-data-service'
import { ApiPageableResponse, Page } from '../utils/api-responses'
import { ReportType, ReportStatus } from '../components/reports/constants'

  export type GetReportsQueryParams = {
    size: number
    page: number
    reportText?: string | null
    reportState?: ReportStatus | string | null
    reportType?: ReportType | string | null
  }

export const getReportsByParams = (params: GetReportsQueryParams): Promise<ApiPageableResponse<ReportView>> =>
  serverApi.get(`/admin/user-reports?`, { params })

export const completeReport = async ({reportId, reportState}: ReportRequest): Promise<void> =>
  await clientDataService<void>({
          method: 'PUT',
          url: `/admin/user-reports/${reportId}`,
          data: {reportState}
        })
