import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteTopic } from '../services/topics-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from '../types/ReactQueryKeys'

const useDeleteTopic = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteTopic, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.TOPICS)
      },
      ...options,
    }
  )
}

export default useDeleteTopic
