import React, { FC, FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { AxiosRequestConfig } from 'axios'
import { isEmpty } from 'lodash'
import { Box, Button } from '@mui/material'

import { ApiResponse } from 'utils/api-responses'
import FileUpload from '../../shared/file-upload/FileUpload'
import VideoPlayer from '../../shared/video-player/VideoPlayer'
import config from 'utils/config'

type CourseVideoProps = {
  videoUri?: string
  onSubmit: (formData: FormData, config: AxiosRequestConfig) => Promise<ApiResponse<UploadVideoResponse>>
  addChaptersLink?: string
}

const VideoUpload: FC<CourseVideoProps> = ({videoUri, onSubmit, addChaptersLink}) => {

  const [uploadedVideoUri, setUploadedVideoUri] = useState<string>()

  const currentVideoUri = uploadedVideoUri ? uploadedVideoUri : videoUri

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    file: File | undefined,
    setUploadPercentage: (value: number) => void,
    setUploadComplete: (value: boolean) => void,
  ) => {
    e.preventDefault()
    const formData = new FormData()
    if (file) {
      formData.append('video', file)
    }

    onSubmit(formData, {
      onUploadProgress: (progressEvent: any) => {
        setUploadPercentage(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        )
      },
    }).then(({payload}) => {
      setUploadComplete(true)
      setUploadedVideoUri(payload.videoUri)
    })
  }

  return (
    <Box>
      <Box sx={{textTransform: 'uppercase'}}>Video</Box>
      <Box
        sx={{
          background: '#1F2021',
          borderRadius: '3px',
          mt: '16px',
          p: '28px',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {!isEmpty(currentVideoUri) && (
            <Box sx={{mb: '16px'}}>
              <VideoPlayer
                videoFileUri={`${config.cdnBaseUri}${currentVideoUri}`}
                previewMode={true}
              />
            </Box>
          )}
          <FileUpload onSubmit={handleSubmit} fileType="video"/>
          {addChaptersLink && (
            <Link to={addChaptersLink} style={{textDecoration: 'none', width: '100%'}}>
              <Button
                variant="contained"
                sx={{
                  mt: '19px',
                  textTransform: 'none',
                  width: '100%',
                }}
              >
                Add chapters
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default VideoUpload
