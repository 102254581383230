import useSubscription from "hooks/subscriptions/useSubscription";
import useUpdateSubscription from "hooks/subscriptions/useUpdateSubscription";
import useClasses from "hooks/useClasses";
import { RouteComponentProps } from "react-router-dom";
import { SubscriptionView } from "types/SubscriptionView";
import { initialValuesInterface } from "./SubscriptionCreate";
import SubscriptionForm from "./SubscriptionForm";

const SubscriptionEdit = (
  props: RouteComponentProps<{ subscriptionId: string }>
) => {
  const subscriptionId: string = props.match.params.subscriptionId;
  const updateMutation = useUpdateSubscription(subscriptionId);
  const subscription = useSubscription(subscriptionId).data;
  const allClasses = useClasses({
    page: 0,
    size: 100,
    name: "",
  }).data?.content;

  const handleSubmit = (initialValues: initialValuesInterface) => {
    console.log(initialValues)
    updateMutation.mutate({
      name: initialValues.name,
      code: initialValues.code,
      paid: initialValues.paid,
      hasClasses: initialValues.hasClasses,
      hasCourses: initialValues.hasCourses,
      randomQuizAccess: initialValues.randomQuizAccess,
      classIds: initialValues.hasClasses ? initialValues.classId : [],
      published: false,
      availableOnAndroid: initialValues.availableOnAndroid,
      availableOnIos: initialValues.availableOnIos
    });
  };

  const getInitialState = (subscription: SubscriptionView) => {
    return {
      name: subscription.name,
      code: subscription.code,
      paid: subscription.paid,
      hasClasses: subscription.hasClasses,
      hasCourses: subscription.hasCourses,
      randomQuizAccess: subscription.randomQuizAccess,
      classId: subscription.classIds,
      availableOnAndroid: subscription.availableOnAndroid,
      availableOnIos: subscription.availableOnIos
    };
  };

  return (
    <>
      {subscription && (
        <SubscriptionForm
          initialValues={getInitialState(subscription)}
          onSubmit={handleSubmit}
          allClasses={allClasses}
        ></SubscriptionForm>
      )}
    </>
  );
};

export default SubscriptionEdit;
