import React, { FC, useEffect, useState } from 'react'
import { Form, Formik, ErrorMessage } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Box, Button } from '@mui/material'

import FormEditor from 'components/common/ui/form/form-editor/form-editor'
import s from 'components/courses/course-form/CourseForm.module.css'
import FormInput from 'components/common/ui/form/form-input/form-input'
import BlogFormSchema from './BlogValidationSchema'
import config from 'utils/config'
import { IMAGE_SUPPORTED_FORMATS } from 'utils/constants'

type BlogFormProps = {
  blog: BlogType
  onSubmit: (data: BlogUpdateRequest) => void
};

const BlogForm: FC<BlogFormProps> = ({
                                       blog,
                                       onSubmit,
                                     }) => {
  const { id, title, previewImageUri, previewText, text, areas } = blog

  const [imgPreview, setImgPreview] = useState<any>(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (previewImageUri) {
      setImgPreview(`${config.cdnBaseUri}${previewImageUri}`)
    } else {
      setImgPreview(null)
    }
  }, [previewImageUri])


  const handleImageChange = (
    e: any,
    setFieldValue: FormikHelpers<BlogUpdateRequest>['setFieldValue'],
  ) => {
    setError(false)
    const selected = e.target.files[0]
    if (selected && IMAGE_SUPPORTED_FORMATS.includes(selected.type)) {
      let reader = new FileReader()
      reader.onloadend = () => {
        setImgPreview(reader.result)
      }
      reader.readAsDataURL(selected)
      setFieldValue('previewImage', selected)
    } else {
      setError(true)
    }
  }

  return (
    <Formik
      initialValues={{
        id,
        title,
        previewText,
        text,
        areasIds: areas.toString(),
        deletePreviewImage: false,
      }}
      validationSchema={BlogFormSchema}
      onSubmit={onSubmit}
    >{({ setFieldValue }) => (
      <Form>
        <Box
          className="u-m-0 u-bg-black-secondary"
          sx={{
            p: { xs: '20px', md: '40px' },
          }}
        >
          <FormInput name="title" label="Title"/>
          <Box sx={{ mt: '20px' }}>
            <label>Preview Image</label>

            <Box
              sx={{
                mt: '10px',
                p: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              > 
                <Box
                  sx={{
                    width: '100%',
                    height: '460px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    background: imgPreview
                      ? `url("${imgPreview}") no-repeat center/cover`
                      : 'grey',
                  }}
                >
                  {imgPreview
                    ? (
                      <button
                        className={s.removeButton}
                        type="button"
                        onClick={() => {
                          setImgPreview(null)
                          setFieldValue('deletePreviewImage', true)
                        }}>
                        Remove image
                      </button>
                    )
                    : (
                      <>
                        <p>Add an image</p>
                        <label htmlFor="fileUpload" className={s.customFileUpload}>
                          Choose file
                        </label>
                        <input
                          type="file"
                          id="fileUpload"
                          hidden
                          onChange={(e) => handleImageChange(e, setFieldValue)}
                        />
                        <span>(jpeg or png)</span>
                      </>
                      )}
                  </Box>
                  <Box sx={{color: 'red', margin: '3px 0'}}>
                    {error && <p>File is not supported</p>}
                    <ErrorMessage name="previewImage"/>
                  </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: '20px' }}>
            <FormInput
              name="previewText"
              label="Preview text"
              type="text"
              rows={3}
              multiline={true}
            />
          </Box>
          <Box sx={{ mt: '20px' }}>
            <FormEditor name="text" label="Text"/>
          </Box>
        </Box>
        <Button type="submit" variant="contained" sx={{ mt: '20px' }}>Save</Button>
      </Form>
    )}
    </Formik>
  )
}

export default BlogForm
