import React, { FC } from 'react'
import { Link } from "react-router-dom"
import MathJax from 'react-mathjax-preview'
import { parse } from 'node-html-parser'
import { Box, Button, TableCell } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { isWeTest } from 'utils/constants'
import { QuestionSlimListView } from 'types/QuestionSlimListView'

type QuestionLinePageProps = {
  question: QuestionSlimListView
  link: string
  onOpenDeleteConfirmDialog: (questionId: string) => void
  onChangePublish: (questionId: string, published: boolean) => void
  canDelete?: boolean
}

const QuestionLinePage: FC<QuestionLinePageProps> = ({
                                              question,
                                              link,
                                              onOpenDeleteConfirmDialog,
                                              onChangePublish,
                                              canDelete=true
}) => {
  const { id, text, published, difficulty, purpose, subject, topic, simulationTag } = question

  return (
  <>
    <TableCell component="th" scope="row">
      {parse(text).childNodes.map((child, index) => (
        <MathJax key={index} math={child.toString()}/>
      ))}
    </TableCell>
    <TableCell component="th" scope="row">
      {subject}
    </TableCell>
    {!isWeTest() && <>
      <TableCell component="th" scope="row">
        {topic}
      </TableCell>
      <TableCell component="th" scope="row">
        {simulationTag}
      </TableCell>
      <TableCell component="th" scope="row">
        {difficulty}
      </TableCell>
    </>}
    <TableCell component="th" scope="row">
      {purpose}
    </TableCell>
    <TableCell component="th" scope="row" sx={{ wordBreak: "break-word" }}>
      <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'end' }}>
        <Link to={link}>
          <Button color="primary" component="span">
            <EditIcon />
          </Button>
        </Link>
        <Button
          variant="contained"
          color={published ? 'secondary' : 'primary'}
          sx={{ml: 3, width: 'auto', minWidth: '120px'}}
          onClick={() => onChangePublish(id, published)}
          disabled={!canDelete}
        >
          {published ? 'Unpublish' : 'Publish'}
        </Button>
        <Button type="button" onClick={() => onOpenDeleteConfirmDialog(id)} disabled={!canDelete}>
          <DeleteIcon/>
        </Button>
      </Box>
    </TableCell>
  </>
  )}

export default QuestionLinePage
