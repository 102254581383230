import React, { FC } from 'react'
import { AxiosRequestConfig } from 'axios'

import { uploadVideoToCourse } from 'services/courses-service'
import VideoUpload from '../../shared/video-upload/VideoUpload'

type CourseVideoProps = {
  course: CourseView
}

const CourseVideo: FC<CourseVideoProps> = ({course}) => {
  const handleSubmit = (formData: FormData, config: AxiosRequestConfig) =>
    uploadVideoToCourse(course.id, formData, config)

  return (
    <VideoUpload
      videoUri={course.welcomeVideoFileUri}
      onSubmit={handleSubmit}
    />
  )
}

export default CourseVideo
