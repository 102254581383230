import React from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Page } from 'utils/api-responses'
import EditIcon from '@mui/icons-material/Edit'
import config from 'utils/config'

import SaveButton from "../../layouts/dashboard/SaveButton"
import DeleteButton from 'components/shared/delete-button/DeleteButton'
import PageTitle from "../../layouts/dashboard/PageTitle"
import usePageable from 'hooks/usePageable'
import useCourse from 'hooks/course/useCourse'
import useLessons from 'hooks/lesson/useLessons'
import useCreateLesson from 'hooks/lesson/useCreateLesson'
import usePublishLesson from 'hooks/lesson/usePublishLesson'
import useUnpublishLesson from 'hooks/lesson/useUnpublishLesson'
import useDeleteLesson from 'hooks/lesson/useDeleteLesson'


export const LessonsListPage = (props: any) => {
  const history = useHistory()

  const courseId = props.match.params.courseId
  const courseQuery = useCourse(courseId)
  const courseName = courseQuery?.data?.name

  const {
    rowsPerPage,
    pageNumber,
    handleChangePage,
    handleChangeBack,
    handleChangeRowsPerPage,
  } = usePageable()

  const lessonsQuery = useLessons({courseId, size: rowsPerPage, page: pageNumber})
  const lessons = lessonsQuery.data || {} as Page<LessonType>

  const newLessonMutation = useCreateLesson(courseId)
  const publishLessonMutation = usePublishLesson()
  const unpublishLessonMutation = useUnpublishLesson()
  const deleteLessonMutation = useDeleteLesson()

  const handleCreateLesson = () => newLessonMutation.mutate({
    name: '',
    lecturer: '',
    transcrypt: '',
    lessonText: '',
    number: 0,
  } as LessonUpdateRequest)

  const toggleLessonPublish = (lesson: LessonType) => {
    const mutation = lesson.published ? unpublishLessonMutation : publishLessonMutation
    mutation.mutateAsync({courseId, lessonId: lesson.id})
  }

  const handleDeleteQuestion = (lessonId: string) => deleteLessonMutation.mutateAsync({courseId, lessonId})

  const selectRow = (courseId: String, lessonId: string) =>
    history.push(`/courses/${courseId}/lessons/${lessonId}`)

  function tableContent() {
    return (
      <TableBody>
        {lessons.content.map((lesson) =>
          <TableRow key={lesson.id}
                    hover
                    style={{cursor: "pointer"}}
          >
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              {lesson.number}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              {lesson.name}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              {lesson.lecturer}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              <Box sx={{width: '100%', height: '50px'}}>
                {lesson.previewImageUri && <img
                  src={`${config.cdnBaseUri}${lesson.previewImageUri}`}
                  alt="Preview"
                  style={{width: '100%', height: '100%', objectFit: 'cover'}}
                />}
              </Box>
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'end'}}>
                <Button onClick={_ => selectRow(courseId, lesson.id)}><EditIcon/></Button>
                <Button
                  variant="contained"
                  color={lesson.published ? 'secondary' : 'primary'}
                  sx={{minWidth: '120px'}}
                  onClick={() => toggleLessonPublish(lesson)}
                >
                  {lesson.published ? 'Unpublish' : 'Publish'}
                </Button>
                <DeleteButton onDelete={() => handleDeleteQuestion(lesson.id)}/>
              </Box>
            </TableCell>
          </TableRow>,
        )}
      </TableBody>
    )
  }

  return (
    <div>
      <div className="u-flex-container u-mb-30 u-w-100">
        {courseName && <PageTitle>Course: {courseName}</PageTitle>}
        <SaveButton onClick={handleCreateLesson}>
          New Lesson
        </SaveButton>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lesson Number</TableCell>
                <TableCell>Lesson Title</TableCell>
                <TableCell>Lecturer</TableCell>
                <TableCell>Preview image</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {!isEmpty(lessons) && tableContent()}
          </Table>
          <Grid container spacing={3}>
            <Grid item xs={6} display="flex" alignItems="center">
              <Pagination
                size={"medium"}
                count={lessons.totalPages}
                onChange={handleChangeBack}
                page={pageNumber + 1}
                boundaryCount={2}/>
            </Grid>
            <Grid item xs={6}>
              <TablePagination
                component="div"
                count={lessons.totalElements}
                page={pageNumber}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </TableContainer>
      </div>
    </div>
  )
}
