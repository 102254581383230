import React, { FC } from 'react'
import { Box } from '@mui/material'

type FixedBottomModalProps = {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

const FixedBottomModal: FC<FixedBottomModalProps> = ({
                                                       open,
                                                       onClose,
                                                       children,
                                                     }) => {
  return (
    <Box sx={{
      display: open ? 'block' : 'none',
      position: "fixed",
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: '#1F2021',
      border: "2px solid #000",
      boxShadow: '3px 4px 12px 9px rgba(0, 0, 0, 0.0339543)',
      px: '20px',
      pt: '20px',
      pb: '50px',
      zIndex: 1300,
    }}
    >
      {children}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: '30px',
        }}
        onClick={onClose}
      >
        <img
          src="/images/icons/close-menu.svg"
          className="u-pr-15"
          alt="Cancel"
          role="presentation"
        />
        <span className="u-fs-14 u-lh-17">Close</span>
      </Box>
    </Box>
  )
}

export default FixedBottomModal
