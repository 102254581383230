import axios from 'axios'
import { clientDataService } from 'services/client-data-service'
import { QuizRulesListType } from 'types/QuizRulesList'
import { CreateQuizRuleRequest } from 'types/requests/CreateQuizRuleRequest'
import { UpdateRulesListRequest } from 'types/requests/UpdateRulesRequest'
import { GetRuleData } from 'types/responses/GetRuleData'
import { UUID } from 'utils/types'

export const getQuizRulesList = async (): Promise<QuizRulesListType[]> => {
  return await clientDataService<QuizRulesListType[]>({
    method: 'GET',
    url: `/admin/quiz-rules`
  })
}

export const deleteQuizRule = async (id: UUID): Promise<void> => {
  return await clientDataService<void>({
    method: 'DELETE',
    url: `/admin/quiz-rules/${id}`
  })
}


export const updateProbabilityTag = ({ruleId, ...data}: AddRuleSimulationTagsRequest): Promise<void> =>
  clientDataService<void>({
    method: 'POST',
    url: `/admin/${ruleId}/simulation-tags`,
    data
  })


  export const createQuizRule = (data: CreateQuizRuleRequest): Promise<CreateQuizRuleResponse> =>
  clientDataService<CreateQuizRuleResponse>({
    method: 'POST',
    url: '/admin/quiz-rules',
    data
  })

  export const getQuizRule = (quizRuleId: string): Promise<GetRuleData> => 
    clientDataService<GetRuleData>({
      method: 'GET',
      url: `/admin/quiz-rules/${quizRuleId}`
  })

  export const updateQuizRulesList = (quizRuleId: UUID, data: UpdateRulesListRequest): Promise<GetRuleData> =>
    clientDataService<GetRuleData>({
      method: 'PATCH',
      url: `/admin/quiz-rules/${quizRuleId}`,
      data
  })

  export const getStatisticsReport = (data: GetReportData): Promise<any> => 
  clientDataService({
    method: 'GET',
    url: `/admin/simulation-tags/statistics/xlsx`,
    params: {
     ruleId: data.ruleId,
     from: data.from,
     to: data.to
    }
})


  
