import React, { FC } from 'react'
import { Box, TextField } from '@mui/material'

import { hhMMssToSeconds, secondsToFullTimeFormat } from 'utils/duration'

type VideoChapterProps = {
  number: number
  id: string
  startSecond: number
  name: string
  onTimeChange: (seconds: number) => void
  onNameChange: (value: string) => void
  onRemove: () => void
}

const inputStyles = {
  '& .MuiOutlinedInput-root': {
    background: '#1F2021',
    padding: 0,
  },
  '& .MuiOutlinedInput-input': {
    color: '#FFFFFF !important',
    padding: '10px 21px',
    borderRadius: '4px',
    fontSize: '19px',
    lineHeight: '32px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    pr: '21px',
  },
} as const

const VideoChapter: FC<VideoChapterProps> = ({
                                               number,
                                               id,
                                               startSecond,
                                               name,
                                               onTimeChange,
                                               onNameChange,
                                               onRemove,
                                             }) => {
  return (
    <Box
      key={id}
      className="VideoChapter"
    >
      <Box sx={{display: 'flex', mt: '20px'}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{
            ml: '47px',
            mb: '14px',
            fontSize: '11px',
            lineHeight: '13px',
            letterSpacing: '0.45px',
            color: '#B2B5BD',
          }}>
            START CHAPTER
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '30px', textAlign: 'end'}}>{number}.</Box>
            <Box sx={{ml: '15px'}}>
              <TextField
                id={`tp-${id}`}
                defaultValue={secondsToFullTimeFormat(startSecond)}
                onChange={(e) => {
                  const seconds = hhMMssToSeconds(e.target.value)
                  seconds >= 0 && onTimeChange(seconds)
                }}
                sx={{
                  width: '125px',
                  ...inputStyles,
                  '& .MuiOutlinedInput-root': {
                    ...inputStyles['& .MuiOutlinedInput-root'],
                    height: '52px',
                  },
                  '& .MuiOutlinedInput-input': {
                    ...inputStyles['& .MuiOutlinedInput-input'],
                    pr: 0,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ml: '25px', display: 'flex', flexDirection: 'column', flexGrow: 1}}>
          <Box sx={{mb: '14px', fontSize: '11px', lineHeight: '13px', letterSpacing: '0.45px', color: '#B2B5BD'}}>
            CHAPTER NAME
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <TextField
              variant="outlined"
              type="text"
              multiline={true}
              defaultValue={name}
              fullWidth={true}
              sx={inputStyles}
              onChange={(e) => onNameChange(e.target.value)}
            />
            <img src="/images/icons/delete-chapter.svg" className="u-pl-15 u-cursor-pointer" onClick={onRemove}/>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VideoChapter
