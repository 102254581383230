import { Form, Formik } from "formik";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { StyledLabel } from "components/layouts/dashboard/StyledInput";
import dayjs, { Dayjs } from "dayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import useQuizRulesList from "hooks/useQuizRulesList";
import PageTitle from "components/layouts/dashboard/PageTitle";
import useStatisticsReport from "hooks/useStatisricsReport";
import config from "utils/config";

const QuizRulesReportsPage = () => {
  const ruleId = useQuizRulesList().data?.find(
    (value) => value.name === "Rule set V2 (50 questions)"
  )?.id;
  const getReport = useStatisticsReport();
  const handleSubmit = ({from, to, ruleId}) => getReport.mutateAsync({from, to, ruleId});
  const reportFileUri = (data) =>
    `${config.baseApiURI}/admin/simulation-tags/statistics/xlsx?ruleId=${data.ruleId}&from=${data.from}&to=${data.to}`;

  return (
    <>
   {ruleId && <div className="u-wpx-900 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary">
      <PageTitle>Get report</PageTitle>
      <p>
        The "Rule set V2 (50 questions)" is active rule for generate reports{" "}
      </p>
      <Formik
        initialValues={{ from: Date.now(), to: Date.now(), ruleId }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Box style={{ width: "800px" }}>
              <div className="u-flex-container u-pt-30">
                <StyledLabel>From:</StyledLabel>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    value={props.values.from}
                    maxDate={dayjs(Date.now())}
                    onChange={(newValue: Dayjs | null) => {
                      props.setFieldValue("from", newValue?.valueOf());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                          },
                        }}
                        helperText={null}
                      />
                    )}
                  />
                </LocalizationProvider>
                {props.errors.from && props.touched.from ? (
                  <div style={{ color: "red" }}>{props.errors.from}</div>
                ) : null}
              </div>
              <div className="u-flex-container u-pt-30">
                <StyledLabel>To:</StyledLabel>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    value={props.values.to}
                    minDate={dayjs(props.values.from)}
                    onChange={(newValue: Dayjs | null) => {
                      props.setFieldValue("to", newValue?.valueOf());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                          },
                        }}
                        helperText={null}
                      />
                    )}
                  />
                </LocalizationProvider>
                {props.errors.to && props.touched.to ? (
                  <div style={{ color: "red" }}>{props.errors.to}</div>
                ) : null}
              </div>
            </Box>
            <br />
            <Button
              type="submit"
              variant="contained"
              href={reportFileUri(props.values)}
              target="_blank"
            >
              Get Statistics report
            </Button>
          </Form>
        )}
      </Formik>
    </div>}
    </>
  );
};

export default QuizRulesReportsPage;
