import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Button, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow } from '@mui/material'
import { UUID } from 'utils/types'
import { QuestionPurpose, QuestionType } from '../../../questions/constants'
import { deleteConfirmation } from 'components/layouts/Modal/contentModal'
import Modal from 'components/layouts/Modal/Modal'
import QuestionLine from 'components/questions/questions-list/QuestionLine'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useAddQuestion from 'hooks/questions/useAddQuestion'
import useLessonQuiz from 'hooks/lesson/useLessonQuiz'
import useAddLessonQuizQuestion from 'hooks/lesson/useAddLessonQuizQuestion'
import useUnpublishQuestion from 'hooks/questions/useUnpublishQuestion'
import usePublishQuestion from 'hooks/questions/usePublishQuestion'
import useDeleteQuizQuestion from 'hooks/questions/useDeleteQuizQuestion'

interface MonthlySimulationsQuizPageParams {
  courseId: UUID
  lessonId: UUID
  quizId: UUID
}

const LessonQuizEditPage = () => {
  const queryClient = useQueryClient()
  const {courseId, lessonId, quizId} = useParams<MonthlySimulationsQuizPageParams>()

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [questionIdToDelete, setQuestionIdToDelete] = useState<string>('')

  const quiz = useLessonQuiz(courseId, lessonId, quizId).data

  const addQuestionMutation = useAddQuestion()
  const publishQuestionMutation = usePublishQuestion()
  const unpublishQuestionMutation = useUnpublishQuestion()
  const addLessonQuizQuestionMutation = useAddLessonQuizQuestion(courseId, lessonId, quizId)
  const deleteQuizQuestionMutation = useDeleteQuizQuestion(quizId)

  const handleDeleteQuestionDialogOpen = (questionId: string) => {
    setDeleteConfirmDialogOpen(true)
    setQuestionIdToDelete(questionId)
  }

  const handleAddQuestion = () => addQuestionMutation.mutate({
    type: QuestionType.MULTIPLE_CHOICE,
    purpose: QuestionPurpose.LESSON,
  }, {
    async onSuccess(data) {
      await addLessonQuizQuestionMutation.mutate({
        questionId: data.id,
        number: (quiz?.questions?.length || 0) + 1,
      })
    }
  })

  const toggleRefetch = () => queryClient.invalidateQueries([ReactQueryKeys.LESSON_QUIZ])

  const handleDeleteQuestion = () => deleteQuizQuestionMutation.mutateAsync(questionIdToDelete)
    .then(_ => setDeleteConfirmDialogOpen(false))
      .then(toggleRefetch)

  const toggleQuestionPublish = (questionId: string, published: boolean) => {
    const mutation = published ? unpublishQuestionMutation : publishQuestionMutation
    mutation.mutateAsync(questionId).then(toggleRefetch)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Button onClick={handleAddQuestion}>Add question</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">№</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Answer Explanation</TableCell>
              <TableCell>Difficulty</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {quiz?.questions && quiz.questions.map(({ question, number }) => (
              <TableRow key={question.id} hover style={{cursor: "pointer"}}>
                <QuestionLine
                  question={question}
                  number={number}
                  link={`/courses/${courseId}/lessons/${lessonId}/quizzes/${quizId}/questions/${question.id}`}
                  onOpenDeleteConfirmDialog={handleDeleteQuestionDialogOpen}
                  onChangePublish={toggleQuestionPublish}
                  showPublish={false}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        confirmOpen={deleteConfirmDialogOpen}
        onAction={handleDeleteQuestion}
        onClose={() => setDeleteConfirmDialogOpen(false)}
        content={deleteConfirmation}
      />
    </div>
  )
}

export default LessonQuizEditPage
