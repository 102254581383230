import { Form, Formik } from 'formik'
import { Box, Button } from '@mui/material'
import React from 'react'
import { StyledInput } from '../layouts/dashboard/StyledInput'


type SimulationTagFormProps = {
  tag: UpdateTagView
  handleSubmit:(tag: UpdateTagView) => void
}

export const SimulationTagForm = (props: SimulationTagFormProps) => {
  const {tag, handleSubmit} = props

  return (
    <Formik
      initialValues={{name: tag.name, subjectId: tag.subjectId, id: tag.id}}
      onSubmit={handleSubmit}>
      {(props) => (
        <Form>
          <Box style={{width: '800px'}}>
            <StyledInput id='name' name='name' value={props.values.name} onChange={props.handleChange}/>
          </Box>
          <br/>
          <Button type='submit' variant='contained'>Save</Button>
        </Form>
      )}
    </Formik>
  )
}
