import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

import { importParticipantsToClass } from 'services/class-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useImportParticipantsToClass = (options?: UseMutationOptions<void, AxiosError, ClassImportParticipantsRequest>)
  : UseMutationResult<void, AxiosError, ClassImportParticipantsRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    importParticipantsToClass,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.CLASSES)
        await queryClient.invalidateQueries(ReactQueryKeys.CLASS)
        await history.push('/classes')
      },
      ...options,
    },
    'Users were imported to class successfully',
  )
}

export default useImportParticipantsToClass
