import {useEffect, useState} from 'react'
import {TagDataRequest} from "../types/requests/TagDataRequest";

interface UseEditSimulationTagsResult {
  isTagOpen: (id: string) => boolean
  onTagClose: () => void
  onTagSelect: (tag: TagDataRequest) => void
}

interface UseEditSimulationTagsOptions {
  ruleId: string
}

const useEditSimulationTags = ({ruleId}: UseEditSimulationTagsOptions): UseEditSimulationTagsResult => {

  const [selectedTag, setSelectedTag] = useState<TagDataRequest>({} as TagDataRequest)
  const [editingOpen, setEditingOpen] = useState<boolean>(false)

  useEffect(() => {
    setSelectedTag({} as TagDataRequest)
  }, [ruleId])

  const isTagOpen = (actionId: string) => actionId === selectedTag.simulationTagId ? editingOpen : false

  const onTagSelect = (tag: TagDataRequest) => {
    setSelectedTag(tag)
    setEditingOpen(!isTagOpen(tag.simulationTagId))
  }

  const onTagClose = () => setEditingOpen(false)

  return {
    isTagOpen,
    onTagClose,
    onTagSelect,
  }
}

export default useEditSimulationTags
