import { Formik } from 'formik'
import { Box } from '@mui/material'
import { AreaUpdateSchema } from './AreaUpdateSchema'
import SaveButton from 'components/layouts/dashboard/SaveButton'
import FormInput from 'components/common/ui/form/form-input/form-input'
import useUpdateArea from 'hooks/useUpdateArea'

type AreaEditProps = {
  areaId: string
  defaultValue: string
  onClose: () => void
}

const AreaEdit = ({areaId, defaultValue, onClose}: AreaEditProps) => {

  const updateAreaMutation = useUpdateArea()
  const handleSubmit = (data: UpdateAreaRequest) => {
    const formattedName = data.name.trim()
    updateAreaMutation.mutateAsync({...data, name: formattedName } as UpdateAreaRequest).then(_ => onClose())
  }

  return (
      <Formik
        validateOnBlur
        validateOnChange={false}
        validationSchema={AreaUpdateSchema}
        initialValues={{id: areaId, name: defaultValue}}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{m: 0, display: 'flex', alignItems: 'center'}}>
              <FormInput type="name" name="name" variant="standard"/>
              <SaveButton type="submit" sx={{ml: '20px'}}>Save</SaveButton>
              <SaveButton
                type="button"
                sx={{backgroundColor: '#535353', ml: '20px'}}
                onClick={onClose}
              >
                Cancel
              </SaveButton>
            </Box>
          </form>
        )}
      </Formik>
  )
}

export default AreaEdit
