import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { unpublishPackSimulation } from 'services/pack-simulation-quiz-service'

const useUnpublishPackSimulation = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    unpublishPackSimulation, {
      async onSuccess() { 
        await queryClient.invalidateQueries([ReactQueryKeys.PACK_SIMULATIONS])
      },
    ...options,
  }
)}

export default useUnpublishPackSimulation