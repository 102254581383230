import React from 'react'
import {
  Box,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  TablePagination,
} from '@mui/material'
import { isEmpty } from 'lodash'
import EditIcon from '@mui/icons-material/Edit'
import { Link, useHistory } from 'react-router-dom'
import { Page } from 'utils/api-responses'
import PageTitle from '../../layouts/dashboard/PageTitle'
import SaveButton from '../../layouts/dashboard/SaveButton'
import DeleteButton from '../../shared/delete-button/DeleteButton'
import useCourses from 'hooks/course/useCourses'
import useUnpublishCourse from 'hooks/course/useUnpublishCourse'
import usePublishCourse from 'hooks/course/usePublishCourse'
import usePageable from 'hooks/usePageable'
import useDeleteCourse from 'hooks/course/useDeleteCourse'

export const CoursesPage = () => {
  const history = useHistory()

  const {
    rowsPerPage,
    pageNumber,
    handleChangePage,
    handleChangeBack,
    handleChangeRowsPerPage,
  } = usePageable()

  const coursesQuery = useCourses({size: rowsPerPage, page: pageNumber}).data || {} as Page<CourseView>
  const courses = coursesQuery?.content

  const publishCourse = usePublishCourse()
  const unpublishCourse = useUnpublishCourse()
  const deleteCourseMutation = useDeleteCourse()

  const handleDeleteCourse = (courseId: string) => deleteCourseMutation.mutateAsync(courseId)

  const redirectToLessons = (id: string) => history.push(`/courses/${id}/lessons`)

  const toggleQuizPublish = (id: string, published: boolean) => {
    const mutation = published ? unpublishCourse : publishCourse
    mutation.mutate(id)
  }

  const tableContent = () => (
    <TableBody>
      {courses.map(({id, number, name, published}: CourseView) => (
        <TableRow key={id} hover>
          <TableCell scope="row">
            {number}
          </TableCell>
          <TableCell scope="row"
            sx={{cursor: 'pointer'}}
            onClick={() => redirectToLessons(id)}
          >
            <span className='u-m-0'>{name}</span>
          </TableCell>
          <TableCell>
            <div className="u-flex-end ">
              <Link to={`/courses/${id}/edit`}>
                <Button><EditIcon/></Button>
              </Link>
              <DeleteButton onDelete={() => handleDeleteCourse(id)}/>
              <Button
                variant="contained"
                color={published ? 'secondary' : 'primary'}
                sx={{ml: 3, width: '120px'}}
                onClick={() => toggleQuizPublish(id, published)}
              >
                {published ? 'Unpublish' : 'Publish'}
              </Button>
            </div>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

  return (
    <>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Courses</PageTitle>
        <Link to="/courses/new">
          <SaveButton>Add course</SaveButton>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 160}}>
                Course number
              </TableCell>
              <TableCell>
                <Box component={"span"}>
                  Course title
                </Box>
              </TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          {!isEmpty(courses) && tableContent()}
        </Table>
        <Grid container spacing={3}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Pagination
              size={"medium"}
              count={coursesQuery.totalPages}
              onChange={handleChangeBack}
              page={pageNumber + 1}
              boundaryCount={2}/>
          </Grid>
          <Grid item xs={6}>
            <TablePagination
              component="div"
              count={coursesQuery.totalElements}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </TableContainer>
    </>

  )
}
