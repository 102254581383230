const config = {
  baseApiURI: process.env.REACT_APP_API_BASE_URI,
  tinyMCEKey: process.env.REACT_APP_TINY_MCE_KEY,
  cdnBaseUri: process.env.REACT_APP_CDN_BASE_URI,
  webBaseUri: process.env.REACT_APP_WEB_BASE_URI,
  platform: process.env.REACT_APP_PLATFORM,
  image: {
    maxFileSizeMb: Number(process.env.REACT_APP_PUBLIC_MAX_FILE_SIZE_MB),
  },
}

export default config
