import { AxiosError } from "axios";
import {
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { addStudentPackSimulation } from "services/pack-simulation-quiz-service";
import ReactQueryKeys from "types/ReactQueryKeys";
import useMutationWithSnackbar from "../useMutationWithSnackbar";
import { AddStudentPackRequest } from "types/requests/AddStudentPackRequest";

const useAddStudentPackSimulation = (
  options?: UseMutationOptions<void, AxiosError, AddStudentPackRequest>
): UseMutationResult<void, AxiosError, AddStudentPackRequest> => {
  const queryClient = useQueryClient();
  return useMutationWithSnackbar(
    (data: AddStudentPackRequest) => addStudentPackSimulation(data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(
          ReactQueryKeys.ADDITION_STUDENT_PACK_SIMULATIONS
        );
        await queryClient.invalidateQueries(
          ReactQueryKeys.STUDENT_PACK_SIMULATIONS
        );
      },
      ...options,
    }
  );
};

export default useAddStudentPackSimulation;
