import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { unpublishBlog } from 'services/blog-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useUnpublishBlog = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> =>
  useMutationWithSnackbar(
    unpublishBlog,
    options,
  )

export default useUnpublishBlog
