import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createQuestionImage } from 'services/question-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateQuestionImage = (options?: UseMutationOptions<CreateImageResponse, AxiosError, CreateQuestionImageData>)
  : UseMutationResult<CreateImageResponse, AxiosError, CreateQuestionImageData> =>
  useMutationWithSnackbar(
    createQuestionImage,
    options,
  )

export default useCreateQuestionImage
