import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { UUID } from "utils/types";
import { UserRole, userRoles } from "./constants";
import { UserProfileAdminView } from "types/UserProfileAdminView";
import { resetPasswortStudent } from "../layouts/Modal/contentModal";
import { StyledInput, StyledLabel } from "../layouts/dashboard/StyledInput";
import Modal from "../layouts/Modal/Modal";
import PageTitle from "../layouts/dashboard/PageTitle";
import SaveButton from "../layouts/dashboard/SaveButton";
import EditUserSchema from "./EditUserSchema";
import useResetPassword from "hooks/users/useResetPassword";
import useUser from "hooks/users/useUser";
import useUpdateRole from "hooks/users/useUpdateRole";
import useUpdateUser from "hooks/users/useUpdateUser";
import useSubscriptions from "../../hooks/subscriptions/useSubscriptions";
import useRestoreUser from "hooks/users/useRestoreUser";
import styles from "./User.module.scss";
import useGetUserClasses from "hooks/users/useGetUserClasses";
import DeleteButton from "components/shared/delete-button/DeleteButton";
import EditUserPacksModal from "./EditUserPacksModal";
import useSelectItem from "hooks/useSelectItem";
import useStudentPackSimulations from "hooks/packSimulation/useStudentPackSimulations";
import useAdditionStudentPackSimulations from "hooks/packSimulation/useAdditionStudentPackSimulations";
import useAddStudentPackSimulation from "hooks/packSimulation/useAddStudentPackSimulation";
import useDeleteStudentPackSimulation from "hooks/packSimulation/useDeleteStudentPackSimulation";

interface EditUserPageParams {
  studentId: UUID;
}

export const EditUserPage = () => {
  const { studentId } = useParams<EditUserPageParams>();
  const user = useUser(studentId).data;
  const additionPacks = useAdditionStudentPackSimulations({
    studentId: studentId,
    availableForPurchase: true,
  }).data;
  const userPacks = useStudentPackSimulations({
    studentId: studentId,
    availableForPurchase: false,
  }).data;
  const addPackMutation = useAddStudentPackSimulation();
  const deletePackMutation = useDeleteStudentPackSimulation();

  const {
    handleItemChecked,
    handleAllChecked,
    selectedItemIds,
    handleClear,
  } = useSelectItem({ allItems: additionPacks });
  const subscriptions = useSubscriptions().data;

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const restoreUserMutation = useRestoreUser();
  const resetPasswortMutation = useResetPassword();
  const updateRoleMutation = useUpdateRole(studentId);
  const updateUserMutation = useUpdateUser(studentId);
  const userClasses = useGetUserClasses(studentId).data;

  const handleResetPassword = () =>
    resetPasswortMutation
      .mutateAsync(studentId)
      .then((_) => setConfirmDialogOpen(false));

  const handleUpdateRole = (newRole: UserRole) =>
    updateRoleMutation.mutate(newRole);

  const handleSubmit = (data: UserProfileAdminView) =>
    updateUserMutation.mutate(data);

  const handleRestore = () => restoreUserMutation.mutateAsync(studentId);

  const handleAddPackSimulation = () => {
    setIsOpen(false);
    handleClear();
    addPackMutation.mutate({ studentId: studentId, packIds: selectedItemIds });
  };

  const handleDeletePackSimulation = (packSimulationId: UUID) => {
    deletePackMutation.mutateAsync({studentId: studentId, packSimulationId: packSimulationId})
  }

  return (
    <>
      {user && (
        <>
          <Grid
            container
            spacing={3}
            className="u-m-0"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="u-flex-container u-mb-30 u-w-100">
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "600px !important",
                }}
              >
                <PageTitle>Edit User</PageTitle>
                <Select
                  id="role"
                  name="role"
                  size="small"
                  value={user.mainRole}
                  onChange={(e) => handleUpdateRole(e.target.value as UserRole)}
                >
                  {userRoles.map((role) => (
                    <MenuItem value={role}>{role}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {user.deleted && (
                  <SaveButton
                    onClick={handleRestore}
                    className="u-bg-sunglo u-br-n u-mr-10"
                  >
                    Restore User
                  </SaveButton>
                )}
                <SaveButton
                  onClick={() => setConfirmDialogOpen(true)}
                  className="u-bg-sunglo u-br-n"
                >
                  Reset password
                </SaveButton>
              </Grid>
            </div>
            <div>
              <Formik
                initialValues={user}
                validationSchema={EditUserSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form>
                    <Grid
                      container
                      spacing={3}
                      className="u-wpx-600 u-m-0 u-bg-black-secondary"
                    >
                      <Grid item xs={12} className="u-pl-48">
                        <StyledLabel>Account email</StyledLabel>
                        <StyledInput
                          type="email"
                          name="username"
                          placeholder="Fill username"
                          value={props.values.email}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="u-pl-48">
                        <StyledLabel htmlFor="firstName">
                          First name
                        </StyledLabel>
                        <StyledInput
                          id="firstName"
                          type="text"
                          name="firstName"
                          placeholder="Fill first name"
                          value={props.values.firstName}
                          onChange={props.handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} className="u-pl-48">
                        <StyledLabel htmlFor="lastName" className="u-pt-48">
                          Last name
                        </StyledLabel>
                        <StyledInput
                          id="lastName"
                          type="text"
                          name="lastName"
                          placeholder="Fill last name"
                          value={props.values.lastName}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="u-pl-48">
                        <StyledLabel htmlFor="nickname" className="u-pt-48">
                          Nickname
                        </StyledLabel>
                        <StyledInput
                          id="nickname"
                          type="text"
                          name="nickname"
                          placeholder="Fill nickname"
                          value={props.values.nickname}
                          onChange={props.handleChange}
                        />
                      </Grid>
                      {user.mainRole === "STUDENT" && subscriptions && (
                        <Grid item xs={12} className="u-pl-48 u-pt-30">
                          <PageTitle className="u-pb-30 u-weight-400 u-fs-24">
                            Subscription plan
                          </PageTitle>
                          <Select
                            id="subscriptionPlan"
                            name="subscriptionPlan"
                            defaultValue={user.subscriptionPlan}
                            onChange={props.handleChange}
                          >
                            {subscriptions.map(({ name, id, code }) => (
                              <MenuItem key={id} value={code}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      )}
                      <Grid item xs={12} className="u-pl-48 u-pb-30">
                        <Button type="submit" variant="contained">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
            <Grid item xs={6}>
              <h2>Student's details (shown available classes)</h2>
              <h3>Student's classes</h3>
              <Grid
                container
                spacing={3}
                className={`${styles.classesBlock} u-mt-10 u-ml-5`}
              >
                <Box sx={{ marginLeft: 2, fontWeight: "700" }}>
                  {userClasses && userClasses.length !== 0 ? (
                    userClasses.map((item) => {
                      return <Link style={{color: 'white'}} to={`/classes/${item.id}/edit`} key={item.id}><p>{item.name}</p></Link>;
                    })
                  ) : (
                    <p>User has no classes</p>
                  )}
                </Box>
              </Grid>
              <Grid sx={{ maxWidth: "500px", marginTop: "20px" }}>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>Student's Packs</h3>
                  <SaveButton
                    sx={{ height: "40px" }}
                    className="u-br-n"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Pack
                  </SaveButton>
                </Grid>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pack Name</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userPacks &&
                        userPacks.map((item) => {
                          return (
                            <TableRow
                              hover
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                              }}
                            >
                              <TableCell>{item.title}</TableCell>

                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <DeleteButton onDelete={async() => await handleDeletePackSimulation(item.id)} />
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Modal
            confirmOpen={confirmDialogOpen}
            onAction={handleResetPassword}
            onClose={() => setConfirmDialogOpen(false)}
            content={resetPasswortStudent}
          />
          <EditUserPacksModal
            isOpen={isOpen}
            additionPacks={additionPacks}
            selectedPacksIds={selectedItemIds}
            handleAllChecked={handleAllChecked}
            handlePackChecked={handleItemChecked}
            handleClear={handleClear}
            onClose={() => setIsOpen(false)}
            handleAddPackSimulation={handleAddPackSimulation}
          />
        </>
      )}
    </>
  );
};
