import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { updateTag } from 'services/simulationtags-service'

const useUpdateTag = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar((data: UpdateTagView) => updateTag(data),
    {
      async onSuccess(data) {
        await queryClient.invalidateQueries(ReactQueryKeys.TAGS)
        await history.push(`/subjects/${data.subjectId}/tags`)
      },
    },
)}

export default useUpdateTag