import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getMonthlySimulationQuiz } from 'services/monthly-simulation-quiz-service'
import { Quiz } from 'components/quizzes/quizzes'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useMonthlySimulationQuiz = (quizId: string, options?: UseQueryOptions<Quiz, AxiosError>): UseQueryResult<Quiz> =>
  useQueryWithSnackbar<Quiz>(
  [ReactQueryKeys.MONTHLY_SIMULATION_QUIZ],
  () => getMonthlySimulationQuiz(quizId), {
    enabled: !!quizId,
    ...options,
  },
)

export default useMonthlySimulationQuiz
