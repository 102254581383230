import React, { useState } from 'react'
import {Button} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Modal from '../../layouts/Modal/Modal'
import { markDeletedConfirmation } from '../../layouts/Modal/contentModal'

interface DeleteButtonInterface {
  onDelete: () => Promise<void>
}

const DeleteButton = ({onDelete}: DeleteButtonInterface) => {

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)

  const handleDeleteDialogOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setDeleteConfirmDialogOpen(true)
  }

  const handleDelete = () => onDelete().then(_ => setDeleteConfirmDialogOpen(false))

  return (
    <>
      <Button onClick={handleDeleteDialogOpen} sx={{ml: 3}}>
        <DeleteIcon/>
      </Button>
      <Modal
        confirmOpen={deleteConfirmDialogOpen}
        onAction={handleDelete}
        onClose={() => setDeleteConfirmDialogOpen(false)}
        content={markDeletedConfirmation}/>
    </>
  );
};

export default DeleteButton;
