import React, { FC, useState } from 'react'
import { Field, FieldProps } from 'formik'
import { isEmpty, isArray } from 'lodash'
import { FormikHelpers } from 'formik/dist/types'
import { TextField, Autocomplete } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { FilterOptionsState } from '@mui/base/AutocompleteUnstyled/useAutocomplete'

type FormSelectProps = {
  name: string
  multiple?: boolean
  idField?: string
  options: any[]
  selectedOptions?: any
  renderOption: (option: any, selectedId: string, props: any) => React.ReactNode
  label: { text: (option: any) => string }
  sx?: SxProps<Theme>
}

const FormSelect: FC<FormSelectProps> = ({
                                           name,
                                           multiple = false,
                                           idField = 'id',
                                           options,
                                           selectedOptions = [],
                                           renderOption,
                                           label,
                                           sx,
                                         }) => {
  const [selected, setSelected] = useState<any>({})

  const defaultProps = {
    defaultValue: selectedOptions,
    options,
    getOptionLabel: label.text,
  }

  const handleChange = (
    value: any,
    setFieldValue: FormikHelpers<unknown>['setFieldValue'],
  ) => {
    const newValue = isArray(value)
      ? value.map(_ => _[idField]).toString()
      : (isEmpty(value) ? value[idField] : '')
    setFieldValue(name, newValue)
    setSelected(value)
  }

  return (
    <Field>
      {({ form: { setFieldValue } }: FieldProps) => (
        <Autocomplete
          {...defaultProps}
          disableClearable={true}
          multiple={multiple}
          onChange={(e, value) => handleChange(value, setFieldValue)}
          renderOption={(props, option) => renderOption(option, selected[idField], props)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              inputProps={{ style: { minHeight: '45px' }, ...params.inputProps }}
              sx={sx}
            />
          )}
        />
      )}
    </Field>
  )
}

export default FormSelect
