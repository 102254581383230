import React, { FC, useState } from 'react'
import { Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AreaEdit from '../area-edit'

type AreasListProps = {
  allAreas: AreaResponseView[]
  onDelete: (areaId: string) => void
}

const AreasList: FC<AreasListProps> = ({allAreas, onDelete}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [selectAreaId, setSelectAreaId] = useState<string>('')

  const isOpen = (id: string) => selectAreaId === id ? openEdit : false

  const tableContent = () => {
    return (
      <TableBody>
        {allAreas.map(({ id, name }) => (
          <TableRow key={id} hover style={{cursor: "pointer"}}>
            <TableCell component="th" scope="row">
              {!isOpen(id) && name}
              <Collapse in={isOpen(id)}>
                <AreaEdit areaId={id} defaultValue={name} onClose={() => setOpenEdit(false)}/>
              </Collapse> 
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              {!isOpen(id) &&
                <Button
                  onClick={() => {
                  setOpenEdit(!openEdit)
                  setSelectAreaId(id)
                  }}>
                <EditIcon/>
              </Button>
              }
              <Button onClick={() => onDelete(id)}><DeleteIcon/></Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {tableContent()}
            </Table>
          </TableContainer>
  )
}

export default AreasList
