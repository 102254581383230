import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { addLessonVideoChapters } from '../services/lesson-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { AxiosError } from 'axios'

const useAddLessonVideoChapters = (options?: UseMutationOptions<void, AxiosError, AddLessonVideoChaptersRequest>)
  : UseMutationResult<void, AxiosError, AddLessonVideoChaptersRequest> => useMutationWithSnackbar(
    addLessonVideoChapters,
    options,
    'Video chapters were added successfully'
  )

export default useAddLessonVideoChapters
