import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { resetPlanConfirmation } from 'components/layouts/Modal/contentModal'
import Modal from 'components/layouts/Modal/Modal'
import PageTitle from 'components/layouts/dashboard/PageTitle'
import SaveButton from 'components/layouts/dashboard/SaveButton'
import useDowngradePlan from 'hooks/useDowngradePlan'


const ResetPlanPage = () => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  
  const handleDialogOpen = () => setConfirmDialogOpen(true)
  const handleDialogClose = () => setConfirmDialogOpen(false)

  const downgradePlanMutation = useDowngradePlan()
  const handleDownGradePlan = () => downgradePlanMutation.mutateAsync().then(handleDialogClose)

  return (
    <>
      <Grid container>
        <div className="u-w-100">
          <PageTitle>Change Plan</PageTitle>
          <SaveButton 
            onClick={handleDialogOpen}
            className="u-bg-sunglo u-br-n u-mt-20">
              Reset Plan
          </SaveButton>
        </div>
      </Grid>  
      <Modal
        confirmOpen={confirmDialogOpen}
        onAction={handleDownGradePlan}
        onClose={handleDialogClose}
        content={resetPlanConfirmation}
      />
  </>
  )
}

export default ResetPlanPage
