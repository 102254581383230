import { UseMutationOptions, useQueryClient } from "react-query";
import { UseMutationResult } from "react-query/types/react/types";
import { AxiosError } from "axios";
import { deleteStudentPackSimulation } from "services/pack-simulation-quiz-service";
import useMutationWithSnackbar from "../useMutationWithSnackbar";
import ReactQueryKeys from "types/ReactQueryKeys";
import { DeleteStudentPackRequest } from "types/requests/DeleteStudentPackRequest";

const useDeleteStudentPackSimulation = (
  options?: UseMutationOptions<void, AxiosError, DeleteStudentPackRequest>
): UseMutationResult<void, AxiosError, DeleteStudentPackRequest> => {
  const queryClient = useQueryClient();

  return useMutationWithSnackbar(
    (data: DeleteStudentPackRequest) => deleteStudentPackSimulation(data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(
          ReactQueryKeys.ADDITION_STUDENT_PACK_SIMULATIONS
        );
        await queryClient.invalidateQueries(
          ReactQueryKeys.STUDENT_PACK_SIMULATIONS
        );
      },
      ...options,
    }
  );
};

export default useDeleteStudentPackSimulation;
