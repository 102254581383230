import { clientDataService } from './client-data-service'
import { UUID } from 'utils/types'
import { Quiz } from 'components/quizzes/quizzes'

export const generateMonthlySimulationQuiz = async (): Promise<Quiz> => {
  return await clientDataService<Quiz>({
    method: 'post',
    url: '/admin/quizzes/monthly-simulations/generate'
  })
}

export const findMonthlySimulationQuizzes = async (): Promise<QuizShortPageableType> => {
  return await clientDataService<QuizShortPageableType>({
    method: 'get',
    url: '/admin/quizzes/monthly-simulations?sort=createdAt,desc'
  })
}

export const getMonthlySimulationQuiz = async (quizId: UUID): Promise<Quiz> => {
  return await clientDataService<Quiz>({
    method: 'get',
    url: `/admin/quizzes/monthly-simulations/${quizId}`
  })
}

export const getPlannedDate = async (): Promise<number> => {
  return await clientDataService<number>({
    method: 'get',
    url: `/admin/quizzes/monthly-simulations/generate-planned-date`
  })
}

export const setGeneratePlannedDate = async (date: number): Promise<void> => {
  return await clientDataService<void>({
    method: 'post',
    url: `/admin/quizzes/monthly-simulations/generate-planned-date`,
    data: {date}
  })
}
