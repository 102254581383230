import { AxiosError } from 'axios'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { addQuestion, QuestionCreateModel  } from 'services/question-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useAddQuestion = (
  options?: UseMutationOptions<QuestionResponse, AxiosError, QuestionCreateModel>
): UseMutationResult<QuestionResponse, AxiosError, QuestionCreateModel> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (question: QuestionCreateModel) => addQuestion(question),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.QUESTION)
      },
    ...options,
    },
)}

export default useAddQuestion
