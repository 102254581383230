import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { CircularProgress, Grid, Box, Button } from '@mui/material'
import { isEmpty, debounce } from 'lodash'
import { isWeTest } from 'utils/constants'
import { deleteConfirmation } from 'components/layouts/Modal/contentModal'

import LessonForm from '../lesson-form/LessonForm'
import LessonVideo from '../lesson-video/LessonVideo'
import LessonTags from '../lesson-tags/LessonTags'
import Modal from 'components/layouts/Modal/Modal'

import useLesson from 'hooks/lesson/useLesson'
import useCreateLessonQuiz from 'hooks/lesson/useCreateLessonQuiz'
import useDeleteLessonQuiz from 'hooks/lesson/useDeleteLessonQuiz'
import useUpdateLesson from 'hooks/lesson/useUpdateLesson'
import useLessonAreas from 'hooks/lesson/useLessonAreas'
import useLessonTags from 'hooks/lesson/useLessonTags'
import Selector from 'components/shared/selector/Selector'
import useSelectItem from 'hooks/useSelectItem'


const LessonEditPage = () => {
  
  const {courseId, lessonId}: any = useParams()
  const lessonQuery = useLesson(courseId, lessonId)
  const lesson = lessonQuery.data || {} as LessonType
  const lessonAreaQuery = useLessonAreas(courseId)
  const lessonArea = lessonAreaQuery.data || []  

  const [lessonTags, setSelectedTag] = useState<string[]>(lesson?.tags || [])
  const [tagsOption, setTagsOption] = useState<string[]>([])
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)

  const lessonUpdateMutation = useUpdateLesson(courseId, lessonId)
  const lessonTagMutation = useLessonTags()
  const newQuizMutation = useCreateLessonQuiz(courseId, lessonId)
  const deleteQuizMutation = useDeleteLessonQuiz(courseId, lessonId)
 
  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({ allItems: lessonArea, initialSelectedItemsIds: lesson.areas })
  
  const handleCreateQuiz = () => newQuizMutation.mutate()
  const handleDeleteQuiz = () => deleteQuizMutation.mutateAsync().then(_ => setDeleteConfirmDialogOpen(false))
  
  const handleSubmit = (data: LessonUpdateRequest) => {
    const formattedAray = (tag: string[]) => [...tag].toString()
    const formattedData = isWeTest() ?
      {
        ...data,
        lessonTags: formattedAray(lessonTags),
        areas: formattedAray(selectedItemIds)
      }
      : {
        ...data,
        lessonTags: formattedAray(lessonTags)
      }
    
    return lessonUpdateMutation.mutate(formattedData)
  }

  const handleSearchTag = debounce((e: any) => {
    const { value } = e.target
    const trimValue = value.trim()
    
    !isEmpty(trimValue) && lessonTagMutation.mutateAsync(trimValue)
        .then(res =>
          !isEmpty(res)
          ? setTagsOption(res)
          : !tagsOption.includes(value) && setTagsOption(prevState => [...prevState, trimValue])
        )
  }, 500)

  const handleSelectTag = (value: string) => !isEmpty(value) && !lessonTags.includes(value) && setSelectedTag(prevState => [...prevState, value])
  const handleDeleteTag = (tagName: string) => setSelectedTag(lessonTags.filter(tag => tag !== tagName))

  return (
    <>
      {isEmpty(lesson) && <CircularProgress/>}

      {!isEmpty(lesson) && (
        <Grid container>
          <Grid item xs={12} md={8}>
            <LessonForm
              onSubmit={handleSubmit}
              lesson={lesson}
              withUploader
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LessonVideo lesson={lesson}/>
            <LessonTags
              options={tagsOption}
              selectedTags={lessonTags}
              onSearch={handleSearchTag}
              onChange={handleSelectTag}
              onDeleteTag={handleDeleteTag}
            />
            {!isEmpty(lessonArea) &&
              <Selector
                allItems={lessonArea}
                selectedItem={selectedItemIds}
                onItemChecked={handleItemChecked}
                onAllChecked={handleAllChecked}
              />
            }
            <Box sx={{mt: '20px'}}>
              {
                !!lesson.quizId
                ? <>
                    <Link
                      to={`/courses/${courseId}/lessons/${lessonId}/quizzes/${lesson.quizId}`}
                      style={{textDecoration: 'none', minWidth: '120px'}}
                    >
                      <Button variant="contained">Edit quiz</Button>
                    </Link>
                    <Button variant="contained" sx={{ ml: '20px' }} onClick={()=> setDeleteConfirmDialogOpen(true)}>
                      DELETE QUIZ
                    </Button>
                  </>
                : <Button variant="contained" onClick={handleCreateQuiz} sx={{minWidth: '120px'}}>
                    Create quiz
                  </Button>
              }
            </Box>
          </Grid>
        </Grid>
      )}
      <Modal
        confirmOpen={deleteConfirmDialogOpen}
        onAction={handleDeleteQuiz}
        onClose={() => setDeleteConfirmDialogOpen(false)}
        content={deleteConfirmation}
      />
    </>
  )
}

export default LessonEditPage
