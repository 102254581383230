import * as yup from 'yup'  

const EditUserSchema = yup.object({
    email: yup.string().required(),
    firstName: yup.string(),
    lastName: yup.string(),
    nickname: yup.string(),
    subscriptionPlan: yup.string(),
    areaId: yup.string(),
})
  
export default EditUserSchema
