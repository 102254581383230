import React, { FC, useState, useRef } from 'react'
import { isEmpty, debounce } from 'lodash'
import dayjs from 'dayjs'
import { FULL_DATE_FORMAT, DEBOUNCE_TYPING_MILLI } from 'utils/constants'
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  NativeSelect,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { ReportStatus } from '../constants'
import { Page } from 'utils/api-responses'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SaveButton from '../../layouts/dashboard/SaveButton'
import config from 'utils/config'
import usePageable from 'hooks/usePageable'
import useReports from 'hooks/useReports'
import useCompleteReport from 'hooks/useCompleteReport'

const progressColor = (reportState: string) => {
  switch (reportState) {
    case 'FIXED':
      return 'u-color-jungle-green'
    case 'IN_PROCESS':
      return 'u-color-sunglow'
    case 'NEW':
      return 'white'
  }
}

const stateVariants = ['ALL', 'NEW', 'IN_PROCESS', 'FIXED']
const typeVariants = ['ALL', 'QUESTION', 'POST', 'LESSON', 'POST_COMMENT']

const ReportsList: FC = () => {
  const [reportText, setReportText] = useState<string>('')
  const [reportType, setReportType] = useState<string>('')
  const [reportState, setReportState] = useState<string>('')
  const textRef = useRef<any>('')

   const {
    rowsPerPage,
    pageNumber,
    setPageNumber,
    handleChangePage,
    handleChangeBack,
    handleChangeRowsPerPage,
  } = usePageable()
  
  const reportsQuery = useReports({
    size: rowsPerPage,
    page: pageNumber,
    reportText,
    reportType,
    reportState
  })
  const reportsPage = reportsQuery.data?.payload || {} as Page<ReportView>

  const completeReportMutation = useCompleteReport()
    
  const handleFindText = debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setReportText(e.target.value), DEBOUNCE_TYPING_MILLI)
  
  const handleFindByType = (
    e: React.ChangeEvent<HTMLSelectElement>,
    action: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const requiredValue = e.target.value === 'ALL' ? '' : e.target.value
    action(requiredValue)
  }

  const handleChangeStatus = ( reportId: string, newStatus: ReportStatus ) =>
    completeReportMutation.mutate({reportId, reportState: newStatus})
  
  const clearFilters = () => {
    setReportText('')
    setReportType('')
    setReportState('')
    setPageNumber(0)
    textRef.current.value = ''
  }

  const tableContent = () => (
    <TableBody>
      {reportsPage.content.map(({ id, reportType, reportState, reportText, createdAt, userReportDetails }, index) => {
              
        const reportEditLink = (reportType: string) => {
          switch (reportType) {
            case 'QUESTION':
              return `/questions/${userReportDetails.questionId}`
            case 'LESSON':
              return `/courses/${userReportDetails.courseId}/lessons/${userReportDetails.lessonId}`
            case 'POST':
              return config.webBaseUri + `/classes/${userReportDetails.classId}/posts/${userReportDetails.postId}?postMode=edit`
            case 'POST_COMMENT':
              return config.webBaseUri + `/classes/${userReportDetails.classId}/posts/${userReportDetails.postId}/comments/${userReportDetails.postCommentId}`
          }
        }

        return (
          <TableRow key={id} hover style={{cursor: "pointer"}}>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              {index+1}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}}>
              {reportType}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word", maxWidth: '600px'}}>
              {reportText}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word", width: '180px'}}>
              {dayjs(createdAt).format(FULL_DATE_FORMAT)}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word", maxWidth: '600px'}} className={`${progressColor(reportState)}`}>
              {reportState}
            </TableCell>
            <TableCell component="th" scope="row" sx={{wordBreak: "break-word"}} size="small">
              <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'flex-end'}}>
                <a href={`${reportEditLink(reportType)}`} style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer">
                  <Button variant="contained" color="primary" sx={{ ml: 3, width: 'auto' }}>
                    OPEN
                  </Button>
                </a>
                <Button
                  title="IN PROCESS"
                  variant="contained"
                  disabled={reportState !== "NEW"}
                  sx={{ml: 3, width: 'auto'}}
                  onClick={() => handleChangeStatus(id, "IN_PROCESS" as ReportStatus)}
                >
                  <AccessTimeIcon/>
                </Button>
                <Button
                  variant="contained"
                  disabled={reportState === "FIXED"}
                  sx={{ml: 3, width: 'auto'}}
                  onClick={() => handleChangeStatus(id, "FIXED" as ReportStatus)}
                >
                  Complete
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        )})}
    </TableBody>
  )

  return (
    <div>
      <Box sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}>
        <Box className='u-flex-container'>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
              <span style={{paddingTop: '28px', display: 'inline-block', paddingRight: '20px'}}>Filters:</span>
              <FormControl sx={{marginRight: '20px'}}>
                <InputLabel variant="standard" shrink={true}>
                  Report type
                </InputLabel>
                <NativeSelect
                  defaultValue={'ALL'}
                  value={reportType}
                  onChange={(e) => handleFindByType(e, setReportType)}
                >
                 {typeVariants.map((type, index) => (
                    <option value={type} key={type+index} className="u-text-transform-uppercase">{type}</option>
                  ))}
                </NativeSelect>
              </FormControl>
              
              <TextField
                inputRef={textRef}
                name="reportText"
                label="Text"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={handleFindText}
              />     
              <FormControl sx={{marginRight: '20px'}}>
                <InputLabel variant="standard" shrink={true}>
                  Report state
                </InputLabel>
                <NativeSelect
                  defaultValue={'ALL'}
                  value={reportState}
                  onChange={(e) => handleFindByType(e, setReportState)}
                >
                  {stateVariants.map((variant, index) => (
                    <option value={variant} key={variant+index} className="u-text-transform-uppercase">{variant}</option>
                  ))}
                </NativeSelect>
              </FormControl>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <SaveButton type='button' onClick={() => clearFilters()} sx={{width: '165px'}}>
              Clear filters
            </SaveButton>
          </Box>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Text</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {!isEmpty(reportsPage) && tableContent()}
        </Table>
        <Grid container spacing={3}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Pagination
              size={"medium"}
              count={reportsPage.totalPages}
              onChange={handleChangeBack}
              page={pageNumber + 1}
              boundaryCount={2}
            />
          </Grid>
          <Grid item xs={6}>
            <TablePagination
              component="div"
              count={reportsPage.totalElements}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </TableContainer>
    </div>
  )
}

export default ReportsList
