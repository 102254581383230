import React, { FC, RefObject, useRef, useState } from 'react'
import { Box, Grid, Typography, IconButton, Slider, Select, MenuItem } from '@mui/material'
import { KeyboardArrowDownRounded, Settings } from '@mui/icons-material'

import FixedBottomNestedMenu from '../fixed-bottom-nested-menu/FixedBottomNestedMenu'
import SliderValueTooltip from './SliderValueTooltip'

const playbackSpeed = [1, 1.25, 1.5, 1.75, 2]

const style = {
  slider: {
    '& .MuiSlider-track': {
      bgcolor:
        '#F17576',
      borderColor: '#F17576',
      height: {xs: '1px', md: '4px'},
    },
    '& .MuiSlider-rail': {
      height: {xs: '1px', md: '4px'},
    },
    '& .MuiSlider-thumb': {
      color: '#F17576',
      borderColor: '#F17576',
      width: '15px',
      height: '15px',
      ':hover': {boxShadow: '0px 0px 0px 8px rgb(241 117 118 / 16%)'},
    },
    '& .MuiSlider-valueLabel': {
      fontSize: '16px',
      lineHeight: '32px',
      background: 'unset',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#EC4647',
      height: 'inherit',
      width: '5px',
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
    '&': {
      color: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 0,
      p: {
        md: 0.5,
      },
    },
  },
  select: {
    width: 85,
    height: 24,
    textAlign: 'right',
    borderRadius: 0,
    '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
      borderRadius: '0',
    },
    '& .MuiSelect-select': {
      padding: '10px',
      margin: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

} as const

const iconStyles = {
  width: {xs: '30px', md: '50px'},
  height: {xs: '30px', md: '50px'},
} as const

type VideoPlayerControls = {
  title?: string
  titleDescription?: string
  previewMode?: boolean
  qualityScale?: string
  qualityScales?: string[]
  videoChapters?: AddVideoChapterData[]
  playing: boolean
  muted: boolean
  fullscreen: boolean
  played: number
  durationSeconds: number
  playbackRate: number
  totalDurationFormatted: string
  elapsedTimeFormatted: string
  seeking: boolean
  innerRef: RefObject<any>
  onPlayPause: () => void
  onRewind: () => void
  onFastforward: () => void
  onMute: () => void
  onPlaybackRateChange: (value: number) => void
  onToggleFullScreen: () => void
  onSeek: (evt: any, newValue: number | number[]) => void
  onSeekMouseUp: (evt: any, newValue: number | number[]) => void
  onSeekMouseDown: () => void
  onQualityScaleChange?: (scale: string) => void
}

const VideoPlayerControls: FC<VideoPlayerControls> = ({
                                                        title,
                                                        titleDescription,
                                                        previewMode = false,
                                                        qualityScale,
                                                        qualityScales = [],
                                                        videoChapters = [],
                                                        playing,
                                                        muted,
                                                        fullscreen,
                                                        played,
                                                        durationSeconds,
                                                        playbackRate,
                                                        totalDurationFormatted,
                                                        elapsedTimeFormatted,
                                                        innerRef,
                                                        onPlayPause,
                                                        onRewind,
                                                        onFastforward,
                                                        onMute,
                                                        onPlaybackRateChange,
                                                        onToggleFullScreen,
                                                        onSeek,
                                                        onSeekMouseUp,
                                                        onSeekMouseDown,
                                                        onQualityScaleChange,
                                                      }) => {
  const playbackRateRef = useRef(null)
  const qualityScaleRef = useRef(null)
  const [playbackRateAnchorEl, setPlaybackRateAnchorEl] = useState(null)
  const [qualityScaleAnchorEl, setQualityScaleAnchorEl] = useState(null)
  const [openRestMenu, setOpenRestMenu] = useState(false)

  const restMenuItems = [
    qualityScales?.length > 0 && ({
      icon: '/images/icons/player-quality-scale.svg',
      title: 'Video quality',
      nestedItems: qualityScales.map((scale) => (
        {
          title: scale,
          selected: scale === qualityScale,
          onClick: () => onQualityScaleChange && onQualityScaleChange(scale),
        } as MenuItem
      )),
    } as MenuItem),
    {
      icon: '/images/icons/player-play-speed.svg',
      title: 'Play speed',
      nestedItems: playbackSpeed.map((speed) => (
        {
          title: `${speed}x`,
          selected: speed === playbackRate,
          onClick: () => onPlaybackRateChange(speed),
        } as MenuItem
      )),
    } as MenuItem,
  ]

  const valueLabelFormat = (percents: number) =>
    <SliderValueTooltip percents={percents} durationSeconds={durationSeconds} videoChapters={videoChapters}/>

  React.useEffect(() => {
    if (playbackRateRef.current) {
      setPlaybackRateAnchorEl(playbackRateRef.current)
    }
  }, [playbackRateRef])

  React.useEffect(() => {
    if (qualityScaleRef.current) {
      setQualityScaleAnchorEl(qualityScaleRef.current)
    }
  }, [qualityScaleRef])

  return (
    <Box
      {...{ref: innerRef}}
      className="VideoPlayer-controls u-position-absolute u-w-100"
      sx={{
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        pl: {xs: '20px', sm: '25px'},
        pr: {xs: '20px', sm: '25px'},
        overflow: 'visible',
      }}
    >
      <Box sx={{display: {xs: 'none', md: 'block'}}}>
        <Typography sx={{
          fontSize: '30px',
          lineHeight: '38px',
          fontWeight: '800',
        }}>{title}</Typography>
        <Typography
          sx={{fontSize: '14px', lineHeight: '17px'}}>{titleDescription}</Typography>
      </Box>
      <Box sx={{order: {xs: 3, md: 'initial'}}}>
        <Slider
          onChange={onSeek}
          onChangeCommitted={onSeekMouseUp}
          onMouseDown={onSeekMouseDown}
          value={played * 100}
          min={0}
          max={100}
          aria-label="Current time"
          sx={{...style.slider}}
          marks={videoChapters.map(({startSecond, name}) => ({value: startSecond / durationSeconds * 100}))}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay={videoChapters?.length ? 'auto' : 'off'}
        />
      </Box>
      {!previewMode && (
        <Grid container justifyContent="space-between"
              sx={{width: 'auto'}}
        >
          <Grid item sx={{width: {md: 'auto'}, flexGrow: {xs: 3}}}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: {xs: 'space-between', md: 'flex-start'},
            }}>
              <IconButton
                onClick={onPlayPause}
                aria-label="play"
                size="medium"
                sx={{
                  ...iconStyles,
                  mr: '20px',
                  display: {xs: 'none', md: 'flex'},
                }}
              >
                {playing ? <img
                  src="/images/icons/player-pause-icon.svg"
                  alt="Pause the video"
                /> : <img
                  src="/images/icons/player-play-icon.svg"
                  alt="Play the video"
                />}
              </IconButton>
              <IconButton
                onClick={onMute}
                aria-label="volume"
                size="medium"
                sx={{
                  ...iconStyles,
                  mr: '20px',
                  display: {xs: 'none', md: 'flex'},
                }}
              >
                {muted ?
                  <img src="/images/icons/player-mute-icon.svg" className="u-w-100" alt=""/>
                  : <img src="/images/icons/player-volume-icon.svg" className="u-w-100" alt=""/>
                }
              </IconButton>
              <Box sx={{marginRight: '15px'}}>
                <Typography
                  sx={{
                    fontSize: {xs: '10px', md: '16px'},
                    lineHeight: {xs: '12px', md: '19px'},
                  }}
                >
                  {elapsedTimeFormatted} / {totalDurationFormatted}
                </Typography>
              </Box>
              <Box sx={{marginRight: '20px', display: {xs: 'none', md: 'block'}}}>
                <Select
                  ref={playbackRateRef}
                  sx={{...style.select}}
                  inputProps={{'aria-label': 'Speed'}}
                  defaultValue={1}
                  IconComponent={KeyboardArrowDownRounded}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    container: playbackRateAnchorEl,
                  }}
                >
                  {playbackSpeed.map((speed) => {
                    return (
                      <MenuItem onClick={() => onPlaybackRateChange(speed)} className="VideoPlayer-select" key={speed}
                                value={speed}>{`${speed}x`}</MenuItem>
                    )
                  })}
                </Select>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <IconButton onClick={onRewind} aria-label="prev" size="medium" sx={{...iconStyles}}>
                  <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                    <img src="/images/icons/player-prev-icon.svg" alt=""/>
                  </Box>
                  <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                    <img src="/images/icons/player-prev-icon-mobile.svg" alt=""/>
                  </Box>
                </IconButton>
                <Typography
                  sx={{
                    mx: {xs: 0, md: '10px'},
                    fontSize: {xs: '10px', md: '16px'},
                    lineHeight: {xs: '12px', md: '19px'},
                  }}
                >
                  15s
                </Typography>
                <IconButton onClick={onFastforward} aria-label="next" size="medium" sx={{...iconStyles}}>
                  <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                    <img src="/images/icons/player-next-icon.svg" alt=""/>
                  </Box>
                  <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                    <img src="/images/icons/player-next-icon-mobile.svg" alt=""/>
                  </Box>
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item sx={{display: 'flex'}}>
            {qualityScales?.length > 0 && (
              <IconButton aria-label="quality" size="medium" sx={{
                ...iconStyles,
                '& .MuiOutlinedInput-root': {overflow: 'unset !important'},
              }}>
                <Select
                  ref={qualityScaleRef}
                  sx={{
                    ...style.select,
                    width: '38px',
                    '& .MuiSelect-select': {color: 'transparent'},
                    display: {xs: 'none', md: 'block'},
                  }}
                  inputProps={{'aria-label': 'Video quality'}}
                  IconComponent={Settings}
                  defaultValue={qualityScale}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    container: qualityScaleAnchorEl,
                  }}
                >
                  {qualityScales.map((scale) => {
                    return (
                      <MenuItem
                        className="VideoPlayer-select"
                        key={scale}
                        value={scale}
                        onClick={() => onQualityScaleChange && onQualityScaleChange(scale)}
                      >
                        {scale}
                      </MenuItem>
                    )
                  })}
                </Select>
              </IconButton>
            )}
            <IconButton sx={{...iconStyles, pt: 0, pb: 0}} onClick={onToggleFullScreen} aria-label="next" size="medium">
              <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                <img
                  src={fullscreen ? '/images/icons/player-quit-full-screen-icon.svg' : '/images/icons/player-full-screen-icon.svg'}
                  alt=""
                />
              </Box>
              <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                <img
                  src={fullscreen ? '/images/icons/player-quit-full-screen-icon-mobile.svg' : '/images/icons/player-full-screen-icon-mobile.svg'}
                  alt=""
                />
              </Box>
            </IconButton>
          </Grid>
          <Grid item sx={{display: {xs: 'flex', md: 'none'}}}>
            <IconButton sx={{...iconStyles, pr: 0}} onClick={() => setOpenRestMenu(!openRestMenu)} aria-label="next"
                        size="medium">
              <img src='/images/icons/three-horizontal-dots.svg' alt="Rest menu"/>
            </IconButton>
          </Grid>
        </Grid>
      )}
      <FixedBottomNestedMenu
        open={openRestMenu}
        onClose={() => setOpenRestMenu(false)}
        menuItems={restMenuItems}
      />
    </Box>
  )
}

export default VideoPlayerControls
