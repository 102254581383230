import React from "react";
import { Form, Formik } from "formik";
import { DatePicker } from "@mui/lab";
import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import {
  StyledLabel,
  StyledInput,
} from "components/layouts/dashboard/StyledInput";
import Selector from "components/shared/selector/Selector";
import PackSimulationFormSchema from "./PackSimulationFormSchema";
import { SubscriptionResponse } from "types/responses/SubscriptionResponse";
import { QuizRulesListType } from "types/QuizRulesList";

type PackSimulationFormProps = {
  group: any;
  onSubmit(groupInfo: any): void;
  selectedSubscriptionIds: string[];
  handleSubscriptionChecked: (classId: string) => void;
  handleAllChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  allSubscriptions: SubscriptionResponse[] | undefined;
  allRules?: QuizRulesListType[] | undefined;
};

const PackSimulationForm = ({
  group,
  selectedSubscriptionIds,
  onSubmit,
  allSubscriptions,
  handleSubscriptionChecked,
  handleAllChecked,
  allRules,
}: PackSimulationFormProps) => (
  <Formik
    initialValues={{ ...group }}
    validationSchema={PackSimulationFormSchema}
    onSubmit={onSubmit}
  >
    {(props) => (
      <Form>
        <Box style={{ width: "800px" }}>
          <div className="u-flex-container">
            <StyledLabel>Name</StyledLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <StyledInput
                type="text"
                name="title"
                placeholder="Fill name"
                value={props.values.title}
                onChange={props.handleChange}
              />
              {props.errors.title && props.touched.title ? (
                <div style={{ color: "red" }}>{props.errors.title}</div>
              ) : null}
            </Box>
          </div>
          <div className="u-flex-container">
            <StyledLabel>Code</StyledLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <StyledInput
                type="text"
                name="code"
                placeholder="Fill code"
                value={props.values.code}
                onChange={props.handleChange}
              />
              {props.errors.code && props.touched.code ? (
                <div style={{ color: "red" }}>{props.errors.code}</div>
              ) : null}
            </Box>
          </div>

          <div className="u-flex-container">
            <StyledLabel>Purchase Link</StyledLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <StyledInput
                type="text"
                name="purchaseLink"
                placeholder="Fill link"
                value={props.values.purchaseLink}
                onChange={props.handleChange}
              />
              {props.errors.purchaseLink && props.touched.purchaseLink ? (
                <div style={{ color: "red" }}>{props.errors.purchaseLink}</div>
              ) : null}
            </Box>
          </div>
          <div className="u-flex-container u-pt-30">
            <StyledLabel>Available from</StyledLabel>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                value={props.values.availableFrom}
                minDate={dayjs(Date.now())}
                onChange={(newValue: Dayjs | null) => {
                  props.setFieldValue("availableFrom", newValue?.valueOf());
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "white",
                      },
                    }}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
            {props.errors.availableFrom && props.touched.availableFrom ? (
              <div style={{ color: "red" }}>{props.errors.availableFrom}</div>
            ) : null}
          </div>
          {allRules && (
            <Box mb={2} mt={5} className="u-flex-container">
              <StyledLabel>Rules</StyledLabel>
              <Select
                value={props.values.quizRuleId}
                sx={{ width: 300 }}
                size="small"
                onChange={(e) =>
                  props.setFieldValue("quizRuleId", e.target.value)
                }
              >
                {allRules.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {allSubscriptions && (
            <Grid item xs={12} className="u-pl-0 u-pb-30">
              <Selector
                allItems={allSubscriptions}
                selectedItem={selectedSubscriptionIds}
                onItemChecked={handleSubscriptionChecked}
                onAllChecked={handleAllChecked}
                title={'Optional: Admin can include this Pack in selected Subscription'}
              />
            </Grid>
          )}
        </Box>
        <br />
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Form>
    )}
  </Formik>
);

export default PackSimulationForm;
