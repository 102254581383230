enum ReactQueryKeys {
  AREAS = 'areas',
  ADDITION_STUDENT_PACK_SIMULATIONS = 'additionStudentPackSimulations',
  LESSON_AREA = 'lessonArea',
  SUBJECTS = 'subjects',
  SUBJECT = 'subject',
  SUBJECT_TOPICS = 'subjectTopics',
  SUBJECT_AREAS = 'subjectAreas',
  STATIC_PRACTICE_GROUPS ='staticPracticeGroups',
  STATIC_PRACTICE_GROUP ='staticPracticeGroup',
  COURSES = 'courses',
  COURSE = 'course',
  LESSONS = 'lessons',
  LESSON = 'lesson',
  LESSON_QUIZ = 'lessonQuiz',
  QUESTIONS = 'questions',
  QUESTION = 'question',
  BLOGS = 'blogs',
  BLOG = 'blog',
  CLASSES = 'classes',
  CLASS = 'class',
  USERS = 'users',
  USER = 'user',
  USER_CLASSES = 'userClasses',
  PLANNED_DATE = 'plannedDate',
  QUIZ_RULES = 'quizRules',
  QUIZ_RULE ='quizRule',
  MONTHLY_SIMULATION_QUIZZES = 'monthlySimulationQuizzes',
  MONTHLY_SIMULATION_QUIZ = 'monthlySimulationQuiz',
  OFFICIAL_NATIONAL_QUIZZES = 'officialNationalQuiz',
  OFFICIAL_NATIONAL_QUIZ = 'officialNationalQuiz',
  STATIC_PRACTICE_QUIZZES = 'staticPracticeQuizzes',
  STATIC_PRACTICE_QUIZ = 'staticPracticeQuiz',
  PACK_SIMULATIONS = 'packSimulations',
  PACK_SIMULATION = 'packSimulation',
  PACK_QUIZZES = 'packQuizzes',
  PACK_QUIZ = 'packQuiz',
  REPORTS = 'reports',
  SIMULATION_TAG = 'simulationTags',
  TOPICS = 'topics',
  TAGS = 'tags',
  TAG = 'tag',
  SCALE_TASKS = 'scaleTasks',
  IMPORT_TASKS = 'importTasks',
  STUDENT_PACK_SIMULATIONS = 'studentPackSimulations',
  SUBSCRIPTIONS = 'subscriptions',
  SUBSCRIPTION = 'subscription',
}

export default ReactQueryKeys
