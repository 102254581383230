import {
  Box,
  Button,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty, debounce } from 'lodash'
import { Page } from 'utils/api-responses'
import { isWeTest, DEBOUNCE_TYPING_MILLI } from 'utils/constants'
import { UserRole } from './constants'
import EditIcon from '@mui/icons-material/Edit'
import PageTitle from '../layouts/dashboard/PageTitle'
import SaveButton from '../layouts/dashboard/SaveButton'
import DeleteButton from 'components/shared/delete-button/DeleteButton'
import useDeleteUser from 'hooks/users/useDeleteUser'
import useAreas from 'hooks/useAreas'
import usePageable from 'hooks/usePageable'
import useUsers from 'hooks/users/useUsers'

const roleVariants = ['ALL', 'STUDENT', 'TEACHER', 'ADMIN']


export const UsersListPage = () => {
  const [email, setEmail] = useState<string | null>(null)
  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)
  const [nickname, setNickname] = useState<string | null>(null)
  const [area, setArea] = useState<string | null>('')
  const [mainRole, setMainRole] = useState<string | null>('')
  
  const nicknameRef = useRef<any>('')
  const firstNameRef = useRef<any>('')
  const lastNamRef = useRef<any>('')
  const emailRef = useRef<any>('')

  const {
    rowsPerPage,
    pageNumber,
    handleChangePage,
    handleChangeBack,
    handleChangeRowsPerPage,
    setPageNumber
  } = usePageable()

  const areas = useAreas().data || []
  const usersQuery = useUsers({
    size: rowsPerPage,
    page: pageNumber,
    firstName,
    lastName,
    nickname,
    username: email,
    mainRole,
    areaName: isWeTest() ? area : null
  }).data || {} as Page<UserSlimView>
  const users = usersQuery.content

  const deleteUserMutation = useDeleteUser()
  const handleDeleteUser = (userId: string) => deleteUserMutation.mutateAsync(userId)

  const handleFindByText = debounce((
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    action: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    pageNumber !==0 && setPageNumber(0)
    action(e.target.value)}, DEBOUNCE_TYPING_MILLI)


  const handleFindByType = (
    e: React.ChangeEvent<HTMLSelectElement>,
    action: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    pageNumber !==0 && setPageNumber(0)
    const requiredValue = e.target.value === 'ALL' ? '' : e.target.value
    action(requiredValue)
  }

  const clearFilters = () => {
    setEmail(null)
    setFirstName(null)
    setLastName(null)
    setFirstName(null)
    setNickname(null)
    setArea('')
    setMainRole('')
    setPageNumber(0)
    
    firstNameRef.current.value = ''
    lastNamRef.current.value = ''
    nicknameRef.current.value = ''
    emailRef.current.value = ''
  }

  const tableContent = () => (
    <TableBody>
      {users.map(({id, username, firstName, lastName, nickname, mainRole, deleted, areaName}) =>
        <TableRow key={id} hover style={{cursor: "pointer"}}>
          <TableCell component="th" scope="row">{username}</TableCell>
          <TableCell component="th" scope="row" sx={{wordBreak: 'break-word'}}>{firstName}</TableCell>
          <TableCell component="th" scope="row" sx={{wordBreak: 'break-word'}}>{lastName}</TableCell>
          <TableCell component="th" scope="row" sx={{wordBreak: 'break-word'}}>{nickname}</TableCell>
          <TableCell component="th" scope="row">{mainRole}</TableCell>
          {isWeTest() && <TableCell component="th" scope="row">{areaName}</TableCell>}
          <TableCell component="th" scope="row" align="right">
            <Link to={`/users/${id}`}>
              <Button component="span" color="primary" aria-label="upload picture">
                <EditIcon/>
              </Button>
            </Link>
            {mainRole === UserRole.STUDENT && (
              deleted
              ? <div style={{color: 'red', marginLeft: '10px'}}>DELETED</div>
              : <DeleteButton onDelete={() => handleDeleteUser(id)}/>)
            }
          </TableCell>
        </TableRow>
      )}
    </TableBody>
    )

  return (
    <>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Users</PageTitle>
        <Link to="/users/user/new">
          <SaveButton>New user</SaveButton>
        </Link>
      </div>
      <Box sx={{display: 'flex', mb: '30px', '& .MuiTextField-root': {m: 1}}}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <TextField
            name="email"
            inputRef={emailRef}
            placeholder="email"
            variant="standard"
            onChange={(e) => handleFindByText(e, setEmail)}
          />
          <TextField
            name="firstName"
            inputRef={firstNameRef}
            placeholder="First name"
            variant="standard"
            onChange={(e) => handleFindByText(e, setFirstName)}
          />
          <TextField
            name="lastName"
            inputRef={lastNamRef}
            placeholder="Last name"
            variant="standard"
            onChange={(e) => handleFindByText(e, setLastName)}
          />
          <TextField
            name="nickname"
            inputRef={nicknameRef}
            placeholder="Nickname"
            variant="standard"
            onChange={(e) => handleFindByText(e, setNickname)}
          />
          <NativeSelect
            defaultValue={'ALL'}
            name="role"
            size="small"
            value={mainRole}
            onChange={(e) => handleFindByType(e, setMainRole)}
            sx={{minWidth: '100px', mr: '10px'}}
          >
            {roleVariants.map((value, index) => (
              <option value={value} key={value+index} className="u-text-transform-uppercase">{value}</option>
            ))}
          </NativeSelect>
          {isWeTest() && !isEmpty(areas) && (
            <NativeSelect id="area"
              defaultValue={'ALL'}
              name="area"
              size="small"
              value={area}
              onChange={(e) => handleFindByType(e, setArea)}
              sx={{ minWidth: '125px' }}
            >
              <option value="ALL" key="ALL">ALL</option>
              {areas.map(({name}, index) => (<option value={name} key={name+index}>{name}</option>))}
            </NativeSelect>
          )}
        </Box>
        <Box>
          <SaveButton type="reset" className="u-wpx-165 u-ml-20" onClick={clearFilters}>
            Clear filters
          </SaveButton>
        </Box>
      </Box> 
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email/username</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Nickname</TableCell>
                <TableCell>Role</TableCell>
                {isWeTest() && <TableCell>Area</TableCell>}
                <TableCell />
              </TableRow>
            </TableHead>
            {!isEmpty(users) && tableContent()}
          </Table>
          {!isEmpty(usersQuery) && <Grid container spacing={3}>
            <Grid item xs={6} display="flex" alignItems="center">
              <Pagination
                size={"medium"}
                count={usersQuery.totalPages}
                onChange={handleChangeBack}
                page={pageNumber + 1}
                boundaryCount={2}/>
            </Grid>
            <Grid item xs={6}>
              <TablePagination
                component="div"
                count={usersQuery.totalElements}
                page={pageNumber}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>}
        </TableContainer>
      </div>
    </>
  )
}
