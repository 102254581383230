import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import { UserClassesResponse } from 'types/responses/UserClassesResponse'
import { getUserClassesList } from 'services/users-service'

const useGetUserClasses = (userId: string, options?: UseQueryOptions<UserClassesResponse[], AxiosError>): UseQueryResult<UserClassesResponse[]> =>
  useQueryWithSnackbar<UserClassesResponse[]>(
    [ReactQueryKeys.USER_CLASSES, userId],
    () => getUserClassesList(userId),
    {
        enabled: !!userId,
      ...options,
    }
  )

export default useGetUserClasses
