import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { updateTopic } from '../services/topics-service'

const useUpdateTopic = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar((data: TopicSearchView) => updateTopic(data),
    {
      async onSuccess(data) {
        await queryClient.invalidateQueries(ReactQueryKeys.TOPICS)
        await history.push(`/subjects/${data.subjectId}/topics`)
      },
    },
)}

export default useUpdateTopic
