import { object, string } from 'yup'

const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)

const ClassImportParticipantsFormSchema = object().shape({
  usernames: string()
    .transform((value) => Array.from(new Set(value.split(','))).join(','))
    .required()
    .test(
      'emails',
      'Invalid email address',
      (value) => value ? value.split(',').every(isEmail) : false
    ),
})

export default ClassImportParticipantsFormSchema
