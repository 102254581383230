import { UUID } from 'utils/types'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { editPackSimulation } from 'services/pack-simulation-quiz-service'
import { CreatePackResponse } from 'types/responses/CreatePackResponse'
import { useHistory } from 'react-router-dom'

const useEditPackSimulation = (packSimulationId: UUID, options?: UseMutationOptions<CreatePackResponse, AxiosError, EditPackRequest>)
  : UseMutationResult<CreatePackResponse, AxiosError, EditPackRequest> => {
  const queryClient = useQueryClient()
  const history = useHistory()
  return useMutationWithSnackbar(
    (data: EditPackRequest) => editPackSimulation(packSimulationId, data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.PACK_SIMULATION)
        history.push("/quizzes/pack-simulations")
      },
      ...options,
    },
  )
  }
  
export default useEditPackSimulation
