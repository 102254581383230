import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { generateMonthlySimulationQuiz } from 'services/monthly-simulation-quiz-service'
import { Quiz } from 'components/quizzes/quizzes'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useGenerateMonthlySimulationQuiz = (options?: UseMutationOptions<Quiz, AxiosError>)
  : UseMutationResult<Quiz, AxiosError, void> => {
    const queryClient = useQueryClient()
  
    return useMutationWithSnackbar(
      generateMonthlySimulationQuiz, {
        async onSuccess() {
          await queryClient.invalidateQueries(ReactQueryKeys.MONTHLY_SIMULATION_QUIZZES)
        },
        ...options,
      }
    )
  }
    
export default useGenerateMonthlySimulationQuiz
