import { UseMutationOptions, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { addLessonQuizQuestion, LessonQuizQuestionUpsertModel } from 'services/lesson-quiz-service'
import { UUID } from 'utils/types'
import { QuizQuestion } from 'components/quizzes/quizzes'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useAddLessonQuizQuestion = (
  courseId: UUID,
  lessonId: UUID,
  quizId: UUID, 
  options?: UseMutationOptions<QuizQuestion, AxiosError, LessonQuizQuestionUpsertModel>
 ): UseMutationResult<QuizQuestion, AxiosError, LessonQuizQuestionUpsertModel> => {
  const history = useHistory()
  const queryClient = useQueryClient()
  
    return useMutationWithSnackbar(
      (data: LessonQuizQuestionUpsertModel) => addLessonQuizQuestion(courseId, lessonId, quizId, data), {
        async onSuccess({ question }) {
          await queryClient.invalidateQueries(ReactQueryKeys.LESSON_QUIZ)
          await history.push(`/courses/${courseId}/lessons/${lessonId}/quizzes/${quizId}/questions/${question.id}`)
      },
        ...options,
      }
    )
} 

export default useAddLessonQuizQuestion
