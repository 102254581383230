import { AxiosError } from "axios"
import { UseMutationOptions, UseMutationResult, useQueryClient } from "react-query"
import { deleteQuizRule } from "services/quiz-generate-rules-service"
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from "types/ReactQueryKeys"

const useDeleteStaticPracticeGroup = (options?: UseMutationOptions<void, AxiosError, string>)
: UseMutationResult<void, AxiosError, string> => {
const queryClient = useQueryClient()

return useMutationWithSnackbar(
    deleteQuizRule, {
    async onSuccess() {
      await queryClient.invalidateQueries(ReactQueryKeys.QUIZ_RULES)
    },
    ...options, 
  }
)
}

export default useDeleteStaticPracticeGroup
