import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getClasses } from 'services/class-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useClasses = (data: ClassesSearchRequest, options?: UseQueryOptions<ClassesPageableType, AxiosError>): UseQueryResult<ClassesPageableType> =>
  useQueryWithSnackbar<ClassesPageableType>(
    [ReactQueryKeys.CLASSES, data.page, data.size, data.name],
    () => getClasses(data),
    options,
  )

export default useClasses
