import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CircularProgress, Box, Typography, Button } from '@mui/material'
import { v4 } from 'uuid'
import { useSnackbar } from 'notistack'

import VideoPlayer from '../../shared/video-player/VideoPlayer'
import config from 'utils/config'
import VideoChapter from './VideoChapter'
import useAddLessonVideoChapters from 'hooks/useAddLessonVideoChapters'
import useCourse from 'hooks/course/useCourse'
import useLesson from 'hooks/lesson/useLesson'

const MIN_START_SECOND_ODD = 10

const LessonVideoChaptersPage = () => {
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const {courseId, lessonId}: any = useParams()
  const [chapters, setChapters] = useState<AddVideoChapterData[]>([])
 
  const course = useCourse(courseId).data
  const lessonQuery = useLesson(courseId, lessonId, {
    onSuccess(data) {
      const gotChapters = data?.videoChapters || []
      gotChapters.length > 0 ?
        setChapters(gotChapters.map((chapter, index) =>
          ({...chapter, id: v4(), number: index + 1} as AddVideoChapterData),
        ))
      : handleAddChapter()
    }
  })
  const lesson = lessonQuery.data

  const newChapter = () => ({
    id: v4(),
    startSecond: chapters.length > 0 ? chapters[chapters.length - 1].startSecond + MIN_START_SECOND_ODD : 0,
    name: '',
  } as AddVideoChapterData)

  const handleAddChapter = () => setChapters([...chapters, newChapter()])
  const handleRemoveChapter = (chapterId: string) => setChapters(chapters.filter(({id}) => id !== chapterId))

  const handleUpdateChapter = (chapterId: string, fieldName: string, newValue: number | string) =>
    setChapters(chapters.map((chapter) => (
      chapter.id === chapterId ? { ...chapter, [fieldName]: newValue } : chapter
    )))

  const addLessonVideoChaptersMutation = useAddLessonVideoChapters()

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const chapterWithoutName = chapters.find((chapter) => !chapter.name)
    if (chapterWithoutName) {
      enqueueSnackbar(
        'All the chapters must have some name',
        {variant: 'error'},
      )
    } else {
      addLessonVideoChaptersMutation.mutate({
        courseId,
        lessonId,
        videoChaptersData: chapters,
      } as AddLessonVideoChaptersRequest)
    }
  }

  useEffect(() => {
    if (lesson && !lesson.videoUri) {
      history.push(`/courses/${courseId}/lessons/${lessonId}`)
      enqueueSnackbar(
        'There is no video',
        {variant: 'error'},
      )
    }
  }, [lesson])

  return (
    <>
      {(!course || !lesson) && <CircularProgress/>}

      {course && lesson && (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography
            sx={{
              fontSize: '30px',
              lineHeight: '38px',
              fontWeight: 'bold',
            }}
          >
            {course.name}
          </Typography>
          <Typography
            sx={{
              mt: '11px',
              fontSize: '18px',
              lineHeight: '22px',
            }}
          >
            {lesson.name}
          </Typography>

          <Box>
            {lesson.videoUri && <VideoPlayer videoFileUri={`${config.cdnBaseUri}${lesson.videoUri}`} videoChapters={chapters}/>}
          </Box>

          <form onSubmit={handleSubmit}>
            <Box sx={{display: 'flex', flexDirection: 'column', mt: '50px'}}>
              {chapters.map(({startSecond, name, id}, index) => {
                  const chapterId = id || v4()
                  return <VideoChapter
                    number={index + 1}
                    id={chapterId}
                    name={name}
                    startSecond={startSecond}
                    onTimeChange={(newValue: number) => handleUpdateChapter(chapterId, 'startSecond', newValue)}
                    onNameChange={(newValue: string) => handleUpdateChapter(chapterId, 'name', newValue)}
                    onRemove={() => handleRemoveChapter(chapterId)}
                  />
                },
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: '12px',
                pl: '41px',
                height: '60px',
                cursor: 'pointer',
              }}
              onClick={handleAddChapter}
            >
              <img src="/images/icons/plus-in-circle.svg" alt="Add chapter" className="u-cursor-pointer"/>
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '28px',
                  pl: '8px',
                }}
              >
                Add chapter
              </Typography>
            </Box>

            <Box
              sx={{
                mt: '25px',
                pl: '45px',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: 'none',
                  width: '137px',
                  height: '48px',
                  borderRadius: '3px',
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  )
}

export default LessonVideoChaptersPage
