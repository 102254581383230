import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getClass } from 'services/class-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useClass = (classId: string, options?: UseQueryOptions<ClassType, AxiosError>): UseQueryResult<ClassType> =>
  useQueryWithSnackbar<ClassType>(
    [ReactQueryKeys.CLASS, classId],
    () => getClass(classId), {
      enabled: !!classId,
      ...options,
    },
  )

export default useClass
