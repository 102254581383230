import { UseQueryResult } from "react-query";
import useQueryWithSnackbar from "../useQueryWithSnackbar";
import { getStudentPackSimulationList } from "services/pack-simulation-quiz-service";
import ReactQueryKeys from "types/ReactQueryKeys";
import { StudentPackResponse } from "types/responses/StudentPackResponse";
import { StudentPackRequest } from "types/requests/StudentPackRequest";

const useStudentPackSimulations = (
  data: StudentPackRequest
): UseQueryResult<StudentPackResponse[]> =>
  useQueryWithSnackbar<StudentPackResponse[]>(
    [ReactQueryKeys.STUDENT_PACK_SIMULATIONS],
    () => getStudentPackSimulationList(data)
  );

export default useStudentPackSimulations;
