import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { ApiPageableResponse } from 'utils/api-responses'
import { findOfficialNationalSimulationQuizzes } from 'services/official-national-quiz-service'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useOfficialNationalSimulationQuizzes = (options?: UseQueryOptions<ApiPageableResponse<OfficialNationalQuizShort>, AxiosError>)
  : UseQueryResult<ApiPageableResponse<OfficialNationalQuizShort>> =>
  useQueryWithSnackbar<ApiPageableResponse<OfficialNationalQuizShort>>(
    [ReactQueryKeys.OFFICIAL_NATIONAL_QUIZZES],
    () => findOfficialNationalSimulationQuizzes(),
    options,
  )

export default useOfficialNationalSimulationQuizzes
