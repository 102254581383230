import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getQuizRule } from 'services/quiz-generate-rules-service'
import { GetRuleData } from 'types/responses/GetRuleData'

const useQuizRule = (quizRuleId: string, options?: UseQueryOptions<GetRuleData, AxiosError>): UseQueryResult<GetRuleData> =>
  useQueryWithSnackbar<GetRuleData>(
    [ReactQueryKeys.QUIZ_RULE, quizRuleId],
    () => getQuizRule(quizRuleId), {
      enabled: !!quizRuleId,
      ...options,
    },
  )

export default useQuizRule
