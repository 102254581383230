import React from 'react'
import { OfficialNationalSimulationQuizUpsertModel } from 'services/official-national-quiz-service'
import OfficialNationalQuizForm from '../quiz-form/OfficialNationalQuizForm'
import PageTitle from '../../../layouts/dashboard/PageTitle'
import useCreateOfficialNational from 'hooks/useCreateOfficialNational'

export const OfficialNationalNew = () => {

  const newOfficialNationalMutation = useCreateOfficialNational()

  const handleSubmit = (quiz: OfficialNationalSimulationQuizUpsertModel) => newOfficialNationalMutation.mutate(quiz)

  const getInitialState = (): OfficialNationalSimulationQuizUpsertModel => {
    return {
      specialty: '',
      name: '',
      year: new Date().getFullYear()
    }
  }

  return (
    <div>
      <PageTitle>New Question</PageTitle>
      <OfficialNationalQuizForm
        quiz={getInitialState()}
        onSubmit={handleSubmit} />
    </div>
  )
}
