import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'

import PageTitle from 'components/layouts/dashboard/PageTitle'
import ClassForm from '../class-form/ClassForm'
import ClassImportParticipantsForm from '../class-form/ClassImportParticipantsForm'
import useClass from 'hooks/useClass'
import usePatchClass from 'hooks/usePatchClass'
import useUsers from 'hooks/users/useUsers'
import useImportParticipantsToClass from 'hooks/useImportParticipantsToClass'

export const ClassEditPage = () => {
  const { classId }: any = useParams()
  const history = useHistory()

  const classQuery = useClass(classId)
  const usersQuery = useUsers({page: 0, size: 10000})

  const claz = classQuery.data
  const users = usersQuery.data?.content || []

  const patchClassMutation = usePatchClass()
  const importParticipantsToClassMutation = useImportParticipantsToClass()

  const handleSubmit = (data: ClassUpdateRequest) => patchClassMutation.mutate(data)

  const handleImportParticipants = (data: ClassImportParticipantsRequest) => importParticipantsToClassMutation.mutate(data)

  return (
    <>
      <PageTitle className="u-mb-30">Class</PageTitle>
      <Grid container spacing={{ xs: '20px', md: '40px' }}>
        <Grid item xs={12} md={8}>
          {(classQuery.isLoading || usersQuery.isLoading) && <CircularProgress/>}
          {claz && <ClassForm claz={claz} onSubmit={handleSubmit} users={users}/>}
        </Grid>
        <Grid item xs={12} md={4}>
          <ClassImportParticipantsForm classId={classId} onSubmit={handleImportParticipants}/>
        </Grid>
      </Grid>
    </>
  )
}
