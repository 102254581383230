import React from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ScaleJobType } from 'types/ScaleJobType'
import useScaleTasks from 'hooks/useScaleTasks'

export const VideoScaleTasksPage = () => {
  
  const tasks = useScaleTasks().data || []

  const tableContent = () => (
    <TableBody>
      {tasks.map(({id, outerJobId, state, type, courseId, lessonId}) => (
        <TableRow key={id} hover>
          <TableCell scope="row">
            {outerJobId}
          </TableCell>
          <TableCell scope="row">
            {state}
          </TableCell>
          <TableCell scope="row">
            {(type === ScaleJobType.COURSE_PREVIEW_VIDEO) && <div>
              <Link to={`/courses/${courseId}/lessons`}>course</Link>
            </div>}
            {(type === ScaleJobType.COURSE_LESSON_VIDEO) && <div>
              <Link to={`/courses/${courseId}/lessons/${lessonId}`}>lesson</Link>
            </div>}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

  return (
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 160 }}>
                  AWS Job Id
                </TableCell>
                <TableCell>
                  Job State
                </TableCell>
                <TableCell>
                  Job video path
                </TableCell>
              </TableRow>
            </TableHead>
            {!isEmpty(tasks) && tableContent()}
          </Table>
        </TableContainer>
      </div>
  );
};
