import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material'
import SaveButton from '../layouts/dashboard/SaveButton'
import DeleteButton from 'components/shared/delete-button/DeleteButton'
import PageTitle from '../layouts/dashboard/PageTitle'
import useTopics from '../../hooks/useTopics'
import useDeleteTopic from 'hooks/useDeleteTopic'


export const TopicsPage = () => {

  const { subjectId }: any = useParams()
  const topicsQuery = useTopics(subjectId)
  const topics = topicsQuery.data || []
  
  const deleteTopicMutation = useDeleteTopic()
  const handleDeleteTopic = (topicId: string) => deleteTopicMutation.mutateAsync(topicId)

  const tableContent = () => (
      <TableBody>
        {topics.map(topic => {
          return <TableRow key={topic.id}>
            <TableCell component="th" scope="row">
              {topic.name}
            </TableCell>
            <TableCell component="th" scope="row">
              <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'flex-end'}}>
                <Link to={`/subjects/${subjectId}/topics/${topic.id}`}>
                  <Button color="primary" component="span"><EditIcon/></Button>
                </Link>
                <DeleteButton onDelete={() => handleDeleteTopic(topic.id)} />
              </Box>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    )

  return (
    <>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Topics</PageTitle>
        <Link to="/topics/new">
          <SaveButton>
            Add topic
          </SaveButton>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {!isEmpty(topics) && tableContent()}
        </Table>
      </TableContainer>
    </>

  )
}
