import React from 'react'
import { Formik } from 'formik'
import { StyledLabel } from 'components/layouts/dashboard/StyledInput'
import { AreaNewSchema } from './AreaNewSchema'

import useCreateArea from 'hooks/useCreateArea'
import FormInput from '../../common/ui/form/form-input/form-input'
import PageTitle from "../../layouts/dashboard/PageTitle"
import SaveButton from "../../layouts/dashboard/SaveButton"

const AreaNewPage = () => {

  const createAreaMutation = useCreateArea()

  const handleSubmit = ({ areaTag, name }: NewAreaRequest) => createAreaMutation.mutateAsync({areaTag: areaTag.trim(), name: name.trim()})

  return (
    <>
      <PageTitle className="u-mb-30">New Area</PageTitle>
      <Formik
        validateOnBlur
        validateOnChange={false}
        validationSchema={AreaNewSchema}
        initialValues={{name: '', areaTag: ''}}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="u-wpx-600 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary">
              <div className="u-pb-30" >
                <StyledLabel>Name:</StyledLabel>
                <FormInput type="name" name="name" InputProps={{sx: {minHeight: '45px'}}} variant="standard"/>
              </div>
              <StyledLabel>Area Tag:</StyledLabel>
              <FormInput type="name" name="areaTag" InputProps={{sx: {minHeight: '45px'}}} variant="standard"/>
              <SaveButton sx={{mt: 5}} type="submit">
                Save
              </SaveButton>
            </div>
          </form>
        )}
      </Formik>    
    </>
  )
}

export default AreaNewPage
