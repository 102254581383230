import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import { UUID } from "utils/types";
import { QuestionPurpose, QuestionType } from "components/questions/constants";
import { deleteConfirmation } from "components/layouts/Modal/contentModal";
import Modal from "components/layouts/Modal/Modal";
import QuestionLine from "components/questions/questions-list/QuestionLine";
import ReactQueryKeys from "types/ReactQueryKeys";
import useAddQuestion from "hooks/questions/useAddQuestion";
import useUnpublishQuestion from "hooks/questions/useUnpublishQuestion";
import usePublishQuestion from "hooks/questions/usePublishQuestion";
import useDeleteQuizQuestion from "hooks/questions/useDeleteQuizQuestion";
import useAddStaticPracticeQuizQuestion from "hooks/staticPracticeQuizzes/useAddStaticPracticeQuizQuestion";
import usePackQuiz from "hooks/packSimulation/usePackQuiz";
import usePublishPackQuiz from "hooks/packSimulation/usePublishPackQuiz";
import useUnpublishPackQuiz from "hooks/packSimulation/useUnpublishPackQuiz";

interface PackSimulationQuizPageParams {
  packSimulationId: UUID;
  quizId: UUID;
}

const PackSimulationQuizPage = () => {
  const queryClient = useQueryClient();
  const { quizId, packSimulationId } = useParams<PackSimulationQuizPageParams>();
  const quiz = usePackQuiz(packSimulationId, quizId).data;
  const history = useHistory();
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState<string>("");

  const addQuestionMutation = useAddQuestion();
  const addStaticPracticeQuizQuestionMutation =
    useAddStaticPracticeQuizQuestion(quizId, {
      async onSuccess({ question }) {
        await history.push(
          `/quizzes/pack-simulations/${packSimulationId}/quizzes/${quizId}/questions/${question.id}`
        );
      },
    });
  const publishQuizMutation = usePublishPackQuiz();
  const unpublishQuizMutation = useUnpublishPackQuiz();
  const publishQuestionMutation = usePublishQuestion();
  const unpublishQuestionMutation = useUnpublishQuestion();
  const deleteQuizQuestionMutation = useDeleteQuizQuestion(quizId);

  const handleDeleteQuestionDialogOpen = (questionId: string) => {
    setDeleteConfirmDialogOpen(true);
    setQuestionIdToDelete(questionId);
  };

  const handleAddQuestion = () =>
    addQuestionMutation.mutate(
      {
        type: QuestionType.MULTIPLE_CHOICE,
        purpose: QuestionPurpose.OFFICIAL_NATIONAL,
      },
      {
        async onSuccess({ id }) {
          addStaticPracticeQuizQuestionMutation.mutate({
            questionId: id,
            number: (quiz?.questions?.length || 0) + 1,
          });
        },
      }
    );

  const handleDeleteQuestion = () =>
    deleteQuizQuestionMutation
      .mutateAsync(questionIdToDelete)
      .then((_) => setDeleteConfirmDialogOpen(false))
      .then(toggleRefetch);

  const toggleQuizPublish = (published: boolean) => {
    const mutation = published ? unpublishQuizMutation : publishQuizMutation;
    mutation.mutateAsync({packSimulationId, quizId}).then(toggleRefetch);
  };

  const toggleQuestionPublish = (questionId: string, published: boolean) => {
    const mutation = published
      ? unpublishQuestionMutation
      : publishQuestionMutation;
    mutation.mutateAsync(questionId).then(toggleRefetch);
  };

  const toggleRefetch = () =>
    queryClient.invalidateQueries([ReactQueryKeys.PACK_QUIZ]);

  return (
    <>
      {quiz && (
        <div>
          <Grid sx={{ mb: "30px" }}>
            <Button
              variant="contained"
              disabled={quiz.published}
              onClick={handleAddQuestion}
            >
              Add question
            </Button>
            <Button
              variant="contained"
              color={quiz.published ? "secondary" : "primary"}
              sx={{ ml: 3, width: "auto" }}
              onClick={() => toggleQuizPublish(quiz.published)}
            >
              {quiz.published ? "Unpublish" : "Publish"}
            </Button>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">№</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Answer Explanation</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Statistic Topic</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quiz.questions &&
                  quiz.questions.map(({ question, number }) => (
                    <TableRow
                      key={question.id}
                      hover
                      style={{ cursor: "pointer" }}
                    >
                      <QuestionLine
                        question={question}
                        number={number}
                        link={`/quizzes/pack-simulations/${packSimulationId}/quizzes/${quizId}/questions/${question.id}`}
                        onChangePublish={toggleQuestionPublish}
                        onOpenDeleteConfirmDialog={
                          handleDeleteQuestionDialogOpen
                        }
                        showPublish={false}
                        canDelete={!quiz.published}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            confirmOpen={deleteConfirmDialogOpen}
            onAction={handleDeleteQuestion}
            onClose={() => setDeleteConfirmDialogOpen(false)}
            content={deleteConfirmation}
          />
        </div>
      )}
    </>
  );
};

export default PackSimulationQuizPage;
