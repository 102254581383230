import { UseMutationOptions, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { updateCourse, patchCourse } from 'services/courses-service'
import { UUID } from 'utils/types'
import { isWeTest } from 'utils/constants'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUpdateCourse = (courseId: UUID, options?: UseMutationOptions<CourseView, AxiosError, CourseUpdateRequest>)
  : UseMutationResult<CourseView, AxiosError, CourseUpdateRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()
  
  return useMutationWithSnackbar(
    (data: CourseUpdateRequest) => isWeTest() ? patchCourse(courseId, data) : updateCourse(courseId, data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.COURSE)
        await history.push('/courses')
      },
      ...options,
    },
  )
  }
  

export default useUpdateCourse
