import { useQuery } from 'react-query'
import { QueryFunction, QueryKey } from 'react-query/types/core/types'
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'

import handleAxiosError from './handleAxiosError'

function useQueryWithSnackbar<TQueryFnData = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, AxiosError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, AxiosError> {
  const {enqueueSnackbar} = useSnackbar()

  return useQuery<TQueryFnData, AxiosError, TData, TQueryKey>(
    queryKey,
    queryFn, {
      async onError(error) {
        handleAxiosError(error, enqueueSnackbar)
      },
      ...options,
    })
}

export default useQueryWithSnackbar
