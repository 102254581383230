import { AxiosError } from 'axios'
import { UseQueryOptions, UseQueryResult } from 'react-query'
import { getLessons } from 'services/lessons-service'
import { Page } from 'utils/api-responses'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useLessons = (
  data: GetLessonsRequest,
  options?: UseQueryOptions<Page<LessonType>, AxiosError>
): UseQueryResult<Page<LessonType>> => useQueryWithSnackbar<Page<LessonType>>(
    [ReactQueryKeys.LESSONS, data.courseId, data.page, data.size],
    () => getLessons(data),
    options,
)

export default useLessons
