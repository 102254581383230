import { styled, Typography } from '@mui/material'

const QuizTotal = styled(Typography)(({theme}) => ({
    ...theme.mixins.toolbar,
    minHeight: '0px !important',
    fontSize: '20px',
    fontWeight: 700,
 
    margin: 0,
    marginLeft: '25px' 
  }))

  export default QuizTotal;
