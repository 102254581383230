import jsonToFormData from '../utils/jsonToFormData'
import { clientDataService } from './client-data-service'

export const createNotesImage = async ({courseId, lessonId, ...data}: CreateLessonNotesImageData): Promise<CreateImageResponse> => {
  return await clientDataService<CreateImageResponse>({
    method: 'post',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/upload-image`,
    data: jsonToFormData(data),
  })
}

export const publishLesson = async ({courseId, lessonId}: PublishLessonRequest): Promise<void> => {
  return await clientDataService<void>({
    method: 'put',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/publish`,
  })
}

export const unpublishLesson = async ({courseId, lessonId}: PublishLessonRequest): Promise<void> => {
  return await clientDataService<void>({
    method: 'put',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/unpublish`,
  })
}

export const addLessonVideoChapters = async ({courseId, lessonId, videoChaptersData}: AddLessonVideoChaptersRequest): Promise<void> => {
  return await clientDataService<void>({
    method: 'post',
    url: `/admin/courses/${courseId}/lessons/${lessonId}/chapters`,
    data: { videoChaptersData },
  })
}

export const getLessonTags = async (tag: string): Promise<Array<string>> => {
  return await clientDataService({
    method: 'get',
    url: `/lessons/tags?tagName=${tag}`
  })
}
