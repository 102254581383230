import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { RouteComponentProps } from 'react-router'
import { isEmpty } from 'lodash'
import { isWeTest } from 'utils/constants'
import { Typography, Grid } from '@mui/material'
import { LinkedQuestionsSection } from '../linked-question/LinkedQuestionsSection'
import { QuestionType, QuestionPurpose } from '../constants'
import { QuestionCreateModel } from 'services/question-service'
import QuestionForm from '../question-form/QuestionForm'
import QuestionFormWrap from 'components/layouts/dashboard/QuestionFormWrap'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQuestion from 'hooks/questions/useQuestion'
import useSubjects from 'hooks/subjects/useSubjects'
import useSaveQuestion from 'hooks/questions/useSaveQuestion'
import useSubjectSimTags from 'hooks/subjects/useSubjectSimTags'
import useSubjectTopics from 'hooks/subjects/useSubjectTopics'
import useSubjectAreas from 'hooks/subjects/useSubjectAreas'
import useCreateLinkedQuestion from 'hooks/questions/useCreateLinkedQuestion'
import useAddLinkedQuestion from 'hooks/questions/useAddLinkedQuestion'
import useSelectItem from 'hooks/useSelectItem'


export const QuestionEditPage = (props: RouteComponentProps<{ questionId: string }>) => {
  const queryClient = useQueryClient()
  const questionId: string = props.match.params.questionId || ''
  const question = useQuestion(questionId).data || {} as QuestionResponse
  const questionSubjectId = question.subject?.id
  const isQuestionPoll = question.purpose === QuestionPurpose.QUESTIONS_POOL
  
  const linkedQuestions = question.linkedQuestions || []
  const mainQuestion = linkedQuestions.find((item) => item.mainQuestion === true)
  const isMainQuestion = isEmpty(linkedQuestions) || mainQuestion?.questionId === questionId
  
  const [subjectId, setSubjectId] = useState<string>('')
  const [questionAreas, setQuestionAreas] = useState<string[]>([])
  const subjects = useSubjects().data || []
  const subjectAreas = useSubjectAreas(subjectId).data
  const subjectTopics = useSubjectTopics(subjectId).data || []
  const subjectSimulationTags = useSubjectSimTags(subjectId, !isEmpty(subjectTopics)).data || []
          
  useEffect(() => {
    questionSubjectId && setSubjectId(questionSubjectId)
    question.areas && setQuestionAreas(question.areas)
  }, [questionSubjectId])

  const createLinkedQuestionMutation = useCreateLinkedQuestion()
  const addLinkedQuestionMutation = useAddLinkedQuestion(questionId)
  const saveQuestionMutation = useSaveQuestion('/questions', questionId)

  const handleCreateLinkedQuestion = () => createLinkedQuestionMutation.mutateAsync(questionId)

  const handleAddLinkedQuestion = () => addLinkedQuestionMutation.mutateAsync({
    type: QuestionType.MULTIPLE_CHOICE,
    purpose: question?.purpose,
    linkId: linkedQuestions[0].linkId
  } as QuestionCreateModel)

  const handleUpdateSubjectOptions = async (subjectId: string) => {
    setSubjectId(subjectId)
    setQuestionAreas([])
    await queryClient.invalidateQueries([ReactQueryKeys.SUBJECT_TOPICS, subjectId])
    await queryClient.invalidateQueries([ReactQueryKeys.SIMULATION_TAG, subjectId])
    await queryClient.invalidateQueries([ReactQueryKeys.SUBJECT_AREAS, subjectId])
  }

  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({ allItems: subjectAreas, initialSelectedItemsIds: questionAreas })

  const handleSubmit = (questionData: QuestionUpsertModelRequest) => {
    const resultData = isWeTest() ? {...questionData, areas: selectedItemIds} : questionData
    return saveQuestionMutation.mutate(resultData)
  }

  return (
    <>
      {!isEmpty(question) && (
        <div>
          <Grid container sx={{justifyContent: 'space-between'}}>
            <Typography variant="h5">Question</Typography>
            {isQuestionPoll &&
              <LinkedQuestionsSection
                canCreateMainQuestion={isEmpty(linkedQuestions)}
                canCreateLinkQuestion={!question.published && !isEmpty(linkedQuestions) && !!questionSubjectId}
                mainQuestionId={mainQuestion?.questionId}
                linkedQuestions={linkedQuestions}
                onCreateLinkedQuestion={handleCreateLinkedQuestion}
                onAddLinkedQuestion={handleAddLinkedQuestion}
              />
            }
          </Grid>
          <QuestionFormWrap className="u-mt-20">
            <QuestionForm
              question={question}
              subjects={subjects}
              subjectAreas={subjectAreas}
              selectedArea={selectedItemIds}
              topics={subjectTopics}
              simulationTags={subjectSimulationTags}
              canSelect={isMainQuestion}
              onAreaChecked={handleItemChecked}
              onAllAreasChecked={handleAllChecked}
              onUpdateSubjectOptions={handleUpdateSubjectOptions}
              onSubmit={handleSubmit}
            />
          </QuestionFormWrap>
        </div>
      )}
    </>
  )
}
