import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { Box, Button } from '@mui/material'

import FormInput from 'components/common/ui/form/form-input/form-input'
import ClassImportParticipantsFormSchema from './ClassImportParticipantsValidationSchema'

type ClassImportParticipantsFormProps = {
  classId: string
  onSubmit: (data: ClassImportParticipantsRequest) => void
}

const ClassImportParticipantsForm: FC<ClassImportParticipantsFormProps> = ({ classId, onSubmit }) => {

  return (
    <Formik
      initialValues={{
        id: classId,
        usernames: '',
      }}
      validationSchema={ClassImportParticipantsFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Box
          className="u-m-0 u-bg-black-secondary"
          sx={{
            p: { xs: '20px', md: '40px' },
          }}
        >
          <FormInput
            name="usernames"
            multiline={true}
            rows={4}
            placeholder="Comma separated emails"
          />
        </Box>
        <Button type="submit" variant="contained" sx={{ mt: '20px' }}>Import</Button>
      </Form>
    </Formik>
  )
}

export default ClassImportParticipantsForm
