import { UseQueryOptions, UseQueryResult } from "react-query";
import useQueryWithSnackbar from "../useQueryWithSnackbar";
import ReactQueryKeys from "types/ReactQueryKeys";
import { getPackQuizzes, PackQuizzes } from "services/pack-quizzes-service";
import { UUID } from "utils/types";
import { AxiosError } from "axios";

const usePackQuizzesList = ( packSimulationId: UUID, options?: UseQueryOptions<PackQuizzes[], AxiosError>)
: UseQueryResult<PackQuizzes[]> => useQueryWithSnackbar<PackQuizzes[]>(
  [ReactQueryKeys.PACK_QUIZZES, packSimulationId],
  () => getPackQuizzes(packSimulationId), {
    enabled: !!packSimulationId,
    ...options,
  },
)

export default usePackQuizzesList;
