import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getScaleTasks } from 'services/scale-tasks-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { ScaleJob } from 'types/ScaleJob'

const useScaleTasks = (options?: UseQueryOptions<ScaleJob[], AxiosError>): UseQueryResult<ScaleJob[]> =>
  useQueryWithSnackbar<ScaleJob[]>(
    [ReactQueryKeys.SCALE_TASKS],
    () => getScaleTasks(),
    options,
  )

export default useScaleTasks
