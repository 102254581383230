import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteLessonQuiz } from 'services/lesson-quiz-service'
import { UUID } from 'utils/types'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useDeleteLessonQuiz = (courseId: UUID, lessonId: UUID, options?: UseMutationOptions<void, AxiosError>)
  : UseMutationResult<void, AxiosError, void> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    () => deleteLessonQuiz(courseId, lessonId),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.LESSON)
      },
      ...options,
    },
  )
}

export default useDeleteLessonQuiz
