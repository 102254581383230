import { clientDataService } from './client-data-service'
import { UUID } from '../utils/types'

export type Subject = {
  name: String
  createdAt: Number
  id: UUID
}

export type Topic = {
  name: String
  subjectId: UUID
  id: UUID
}

export type SimulationTag = {
  name: String
  id: UUID
}

export const getSubjects = (): Promise<SubjectResponse[]> =>
  clientDataService<SubjectResponse[]>({
    method: 'GET',
    url: '/admin/subjects'
  })

export const getSubject = (subjectId: string): Promise<SubjectType> =>
  clientDataService<SubjectType>({
    method: 'GET',
    url: `/admin/subjects/${subjectId}`
  })

export const addSubject = (data: SubjectRequest): Promise<SubjectType> =>
  clientDataService<SubjectType>({
    method: 'POST',
    url: '/admin/subjects',
    data
  })

export const editSubject = (subjectId: UUID, data: SubjectRequest): Promise<SubjectType> =>
  clientDataService<SubjectType>({
    method: 'PUT',
    url: `/admin/subjects/${subjectId}`,
    data
  })

export const getSubjectTopics = (subjectId: UUID): Promise<TopicType[]> =>
  clientDataService<TopicType[]>({
    method: 'GET',
    url: `/admin/topics?subjectId=${subjectId}`
  })

export const getSubjectAreas = (subjectId: string): Promise<AreaResponseView[]> =>
  clientDataService<AreaResponseView[]>({
    method: 'GET',
    url: `/admin/subjects/${subjectId}/areas`
  }) 

export const deleteSubject = (subjectId: string): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/subjects/${subjectId}`,
  })

export const getSubjectSimulationTags = (subjectId: UUID): Promise<SimulationTagType[]> =>
    clientDataService<SimulationTagType[]>({
    method: 'GET',
    url: `/admin/simulation-tags?subjectId=${subjectId}`
  })
