import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useHistory } from 'react-router-dom'

import Modal from 'components/layouts/Modal/Modal'
import { deleteConfirmation } from 'components/layouts/Modal/contentModal'

type ClassActionsProps = {
  classId: string
  isPublic: boolean
  deleteConfirmDialogOpen: boolean
  setDeleteConfirmDialogOpen: (value: boolean) => void
  classIdToDelete: string
  setClassIdToDelete: (value: string) => void
  onDelete: (id: string) => void
}

export const ClassActions: FC<ClassActionsProps> = ({
                                                      classId,
                                                      isPublic,
                                                      deleteConfirmDialogOpen,
                                                      setDeleteConfirmDialogOpen,
                                                      classIdToDelete,
                                                      setClassIdToDelete,
                                                      onDelete,
                                                    }) => {
  const history = useHistory()

  const handleDeleteDialogOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setDeleteConfirmDialogOpen(true)
    setClassIdToDelete(classId)
  }

  const handleDelete = async () => {
    await onDelete(classIdToDelete)
    setDeleteConfirmDialogOpen(false)
  }

  return !isPublic ? (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button color="primary"
                aria-label="upload picture"
                component="span"
                onClick={_ => history.push(`/classes/${classId}/edit`)}
        >
          <EditIcon/>
        </Button>
        <Button onClick={handleDeleteDialogOpen}>
          <DeleteIcon/>
        </Button>
      </Box>
      <Modal
        confirmOpen={deleteConfirmDialogOpen}
        onAction={handleDelete}
        onClose={() => setDeleteConfirmDialogOpen(false)}
        content={deleteConfirmation}/>
    </>
  ) : <></>
}
