import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PageTitle from "components/layouts/dashboard/PageTitle";
import SaveButton from "components/layouts/dashboard/SaveButton";
import DeleteButton from "components/shared/delete-button/DeleteButton";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import useDeleteSubscription from "hooks/subscriptions/useDeleteSubscription";
import useSubscriptions from "hooks/subscriptions/useSubscriptions";
import usePublishSubscription from "hooks/subscriptions/usePublishSubscription";
import useUnpublishSubscription from "hooks/subscriptions/useUnpublishSubscription";
import ReactQueryKeys from "types/ReactQueryKeys";
import { useQueryClient } from "react-query";

const SubscriptionsListPage = () => {
  const queryClient = useQueryClient();
  const addIcon = (elem) =>
    elem === true ? "/images/icons/green.png" : "/images/icons/red.png";
  const subscriptionsList = useSubscriptions().data;
  const deleteSubscriptionMutation = useDeleteSubscription();
  const handleDeleteSubscription = (subscriptionId: string) =>
    deleteSubscriptionMutation.mutateAsync(subscriptionId);

  const publishMutation = usePublishSubscription();
  const unpublishMutation = useUnpublishSubscription();

  const togglePublish = (groupId: string, published: boolean) => {
    const mutation = published ? unpublishMutation : publishMutation;
    mutation.mutateAsync(groupId).then(toggleRefetch);
  };

  const toggleRefetch = () =>
    queryClient.invalidateQueries([ReactQueryKeys.SUBSCRIPTION]);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <div className="u-flex-container u-w-100 u-mb-30">
            <PageTitle>Subscriptions</PageTitle>
            <Link to="/subscriptions/create">
              <SaveButton className="u-bg-sunglo u-br-n">
                Add subscription
              </SaveButton>
            </Link>
          </div>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="u-text-center">Name</TableCell>
                  <TableCell className="u-text-center">Code</TableCell>
                  <TableCell className="u-text-center">Paid</TableCell>
                  <TableCell className="u-text-center">Classes</TableCell>
                  <TableCell className="u-text-center">Courses</TableCell>
                  <TableCell className="u-text-center">
                    Random Quiz Access
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionsList &&
                  subscriptionsList.map((item) => (
                    <TableRow
                      hover
                      key={item.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="u-text-center"
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="u-text-center"
                      >
                        {item.code}
                      </TableCell>
                      <TableCell className="u-text-center">
                        <img
                          src={addIcon(item.paid)}
                          width="15px"
                          height="15px"
                          alt=""
                        />
                      </TableCell>
                      <TableCell className="u-text-center">
                        <img
                          src={addIcon(item.hasClasses)}
                          width="15px"
                          height="15px"
                          alt=""
                        />
                      </TableCell>
                      <TableCell className="u-text-center">
                        <img
                          src={addIcon(item.hasCourses)}
                          width="15px"
                          height="15px"
                          alt=""
                        />
                      </TableCell>
                      <TableCell className="u-text-center">
                        <img
                          src={addIcon(item.randomQuizAccess)}
                          width="15px"
                          height="15px"
                          alt=""
                        />
                      </TableCell>
                      <TableCell>
                        <Link to={`/subscriptions/edit/${item.id}`}>
                          <Button color="primary" component="span">
                            <EditIcon />
                          </Button>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <DeleteButton
                          onDelete={() => handleDeleteSubscription(item.id)}
                        />
                      </TableCell>
                      <TableCell>
                      <Button
                          variant="contained"
                          color={item.published ? "secondary" : "primary"}
                          sx={{ ml: 3, width: "auto" }}
                          style={item.published ? {padding: '6px 16px'} : {padding: '6px 27px'}}
                          onClick={() =>
                            togglePublish(item.id, item.published)
                          }
                        >
                          {item.published ? "Unpublish" : "Publish"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionsListPage;
