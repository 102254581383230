import { AxiosError } from 'axios'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { lowerCase, capitalize } from 'lodash'

const handleAxiosError = (
  error: AxiosError,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
) => {
  const code = error.response?.data?.payload?.code || 'Something went wrong'

  enqueueSnackbar(
    capitalize(lowerCase(code)),
    {variant: 'error'},
  )
}

export default handleAxiosError
