import { styled } from '@mui/material'

const QuestionFormWrap = styled('div')(({theme}) => ({
    ...theme.mixins.toolbar,
    width: '100%',
    minHeight: '0px !important',
    padding: '40px 50px 40px 40px',
    backgroundColor:theme.palette.background.paper
    
  }))

  export default QuestionFormWrap;
