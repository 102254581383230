import {
  Badge,
  Button,
  Chip,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import formatDate, { DateTimeFormats } from "utils/date-format";
import { ImportTask } from "./ImportTask";
import { isEmpty } from "lodash";
import { useState } from "react";

type ErrorTableProps = {
  tasks: ImportTask[];
};

export const ErrorTable = ({ tasks }: ErrorTableProps) => {
  const [expandedTask, setExpandedTask] = useState<ImportTask>({} as ImportTask);
  const [expandedError, setExpandedError] = useState<string>("");
  const [openErrors, setOpenErrors] = useState<boolean>(false);

  const openTask = (expandedTask: ImportTask) => {
    setExpandedTask(expandedTask);
    setExpandedError("");
    !isEmpty(expandedTask.errors) && setOpenErrors(true);
  };

  const closeTask = () => setOpenErrors(false);

  return (
    <TableBody>
      {tasks.map((task) => (
        <TableRow key={task.id} hover>
          <TableCell scope="row" style={{ verticalAlign: "top" }}>
            {formatDate(
              task.createdAt,
              DateTimeFormats.DATE_TIME_D_MMM_YYYY_HH_mm
            )}
          </TableCell>
          <TableCell scope="row" style={{ verticalAlign: "top" }}>
            {task.type}
          </TableCell>
          <TableCell scope="row" style={{ verticalAlign: "top" }}>
            {"" + task.completed}
          </TableCell>
          <TableCell scope="row" style={{ verticalAlign: "top" }}>
            {task.questions}
          </TableCell>
          <TableCell scope="row" style={{ verticalAlign: "top" }}>
            {task.questionsImported}
          </TableCell>
          <TableCell>
            {expandedTask.id === task.id && openErrors ? (
              <>
                <Button onClick={() => closeTask()}>Hide Errors</Button>

                {Object.keys(task.errors).map((keyName: string) => (
                  <div key={keyName} style={{ marginTop: "10px" }}>
                    <span
                      onClick={() => setExpandedError(keyName)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>{keyName}</span>
                      <Badge
                        badgeContent={task.errors[keyName].length}
                        max={100000}
                        color="primary"
                        style={{ width: "25px" }}
                      >
                        :
                      </Badge>
                    </span>
                    {keyName === expandedError && (
                      <div>
                        {task.errors[keyName].map((v, index) => (
                          <Chip
                            key={index}
                            label={v}
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <Button onClick={() => openTask(task)}>
                {!isEmpty(task.errors) ? "Show errors" : "No errors"}
              </Button>
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
