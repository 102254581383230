import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'

import { patchClass } from 'services/class-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const usePatchClass = (options?: UseMutationOptions<void, AxiosError, ClassUpdateRequest>)
  : UseMutationResult<void, AxiosError, ClassUpdateRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    patchClass,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.CLASSES)
        await queryClient.invalidateQueries(ReactQueryKeys.CLASS)
        await history.push('/classes')
      },
      ...options,
    },
    'Class was updated successfully',
  )
}

export default usePatchClass
