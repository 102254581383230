import { useQueryClient, UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { setUserRole } from 'services/users-service'
import { UUID } from 'utils/types'
import { UserRole } from '../../components/users/constants'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useUpdateRole = (
  studentId: UUID,
  options?: UseMutationOptions<void, AxiosError, UserRole>
): UseMutationResult<void, AxiosError, UserRole> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (role: UserRole) => setUserRole(studentId, role),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.USER)
      },
      ...options
    },
)}

export default useUpdateRole
