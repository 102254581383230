import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createTopic } from '../services/topics-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'


const useCreateTopic = ( options?: UseMutationOptions<CreateResponse, AxiosError, CreateTopicRequest>)
  : UseMutationResult<CreateResponse, AxiosError, CreateTopicRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: CreateTopicRequest) => createTopic(data), {
      async onSuccess(_, data) {
        await queryClient.invalidateQueries(ReactQueryKeys.TOPICS)
        await history.push(`/subjects/${data.subjectId}/topics`)
      },
      ...options,
    },
  )
}

export default useCreateTopic
