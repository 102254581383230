import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { completeReport } from 'services/report-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useCompleteReport = (options?: UseMutationOptions<void, AxiosError, ReportRequest>)
  : UseMutationResult<void, AxiosError, ReportRequest> => {
  const queryClient = useQueryClient()
  
  return useMutationWithSnackbar(
    completeReport, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.REPORTS)
      },
      ...options,
    }
  )
  }
  
export default useCompleteReport
