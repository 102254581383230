import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import Modal from 'components/layouts/Modal/Modal'
import { deleteConfirmation } from 'components/layouts/Modal/contentModal'

type BlogActionsProps = {
  blogId: string
  published: boolean
  refetch: () => void
  deleteConfirmDialogOpen: boolean
  setDeleteConfirmDialogOpen: (value: boolean) => void
  blogIdToDelete: string
  setBlogIdToDelete: (value: string) => void
  onPublish: (id: string) => Promise<void>
  onUnpublish: (id: string) => Promise<void>
  onDelete: (id: string) => void
}

export const BlogActions: FC<BlogActionsProps> = ({
                                                    blogId,
                                                    published,
                                                    refetch,
                                                    deleteConfirmDialogOpen,
                                                    setDeleteConfirmDialogOpen,
                                                    blogIdToDelete,
                                                    setBlogIdToDelete,
                                                    onPublish,
                                                    onUnpublish,
                                                    onDelete,
                                                  }) => {
  const togglePublish = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const mutation = published ? onUnpublish : onPublish
    mutation(blogId).then(refetch)
  }

  const handleDeleteDialogOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setDeleteConfirmDialogOpen(true)
    setBlogIdToDelete(blogId)
  }

  const handleDelete = async () => {
    await onDelete(blogIdToDelete)
    setDeleteConfirmDialogOpen(false)
    refetch()
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color={published ? 'secondary' : 'primary'}
          sx={{ ml: 3, width: 'auto', minWidth: '120px' }}
          onClick={togglePublish}
        >
          {published ? 'Unpublish' : 'Publish'}
        </Button>
        <Button onClick={handleDeleteDialogOpen}>
          <DeleteIcon/>
        </Button>
      </Box>
      <Modal
        confirmOpen={deleteConfirmDialogOpen}
        onAction={handleDelete}
        onClose={() => setDeleteConfirmDialogOpen(false)}
        content={deleteConfirmation}/>
    </>
  )
}
