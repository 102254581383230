import { UseQueryResult } from "react-query";
import useQueryWithSnackbar from "../useQueryWithSnackbar";
import { getPackSimulationList } from "services/pack-simulation-quiz-service";
import { PackQuizResponse } from "types/responses/PackQuizResponse";
import ReactQueryKeys from "types/ReactQueryKeys";

const usePackSimulations = (): UseQueryResult<PackQuizResponse[]> =>
  useQueryWithSnackbar<PackQuizResponse[]>(ReactQueryKeys.PACK_SIMULATIONS, () =>
    getPackSimulationList()
  );

export default usePackSimulations;
