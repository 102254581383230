import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getBlogs } from 'services/blog-service'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useBlogs = (data: BlogsSearchRequest, options?: UseQueryOptions<BlogsPageableType, AxiosError>): UseQueryResult<BlogsPageableType> =>
  useQueryWithSnackbar<BlogsPageableType>(
    [ReactQueryKeys.BLOGS, data.page, data.size, data.title, data.previewText, data.text],
    () => getBlogs(data),
    options,
  )

export default useBlogs
