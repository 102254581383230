import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, BrowserRouter, Switch } from "react-router-dom"
import { observer } from "mobx-react-lite"
import {
  CssBaseline,
  ThemeProvider,
} from "@mui/material"
import { Helmet } from 'react-helmet-async'
import { SnackbarProvider } from 'notistack'

import './styles/global.scss'
import './utils/dayjs-config'
import { LoginPage } from "./components/login/LoginPage"
import { isWeTest } from 'utils/constants'
import authStore from './auth/AuthStore'
import styles from "./App.module.scss"
import theme from './theme'
import DashboardLayout from './components/layouts/dashboard/DashboardLayout'
import Routes from './components/routes/Routes'
import SnackbarCloseButton from './components/shared/snackbar/snackbar-close-button/snackbar-close-button'
import SnackbarIcon from './components/shared/snackbar/snackbar-icon/snackbar-icon'

const platformTitle = isWeTest() ? 'WeTest CMS' : 'ProMedTest CMS'
const platformIcon = (isWeTest() || localStorage.getItem('platform') === 'WE_TEST') ? 'faviconWetest.ico' : 'faviconPromedTest.ico'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = observer(() => (
    <BrowserRouter>
      <Helmet>
        <title>{platformTitle}</title>
        <link rel="icon" href={platformIcon} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={5000}
            action={key => <SnackbarCloseButton snackbarKey={key}/>}
            iconVariant={{
              error: <SnackbarIcon><img src="/images/icons/info.svg" alt="Information" className="u-pr-10"/></SnackbarIcon>,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            {(authStore.isAuthenticated()) ? (
              <>
                <CssBaseline/>
                <DashboardLayout>
                  <Switch>
                    <Routes/>
                  </Switch>
                </DashboardLayout>
              </>
            ) : (
              <div className={styles.app}>
                <Route component={LoginPage}/>
              </div>
            )}
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  ),
)

export default App
