import { UseMutationOptions, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { updateLesson } from 'services/lessons-service'
import { UUID } from 'utils/types'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUpdateLesson = (courseId: UUID, lessonId:UUID, options?: UseMutationOptions<any, AxiosError, LessonUpdateRequest>)
  : UseMutationResult<any, AxiosError, LessonUpdateRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()
  
  return useMutationWithSnackbar(
    (data: LessonUpdateRequest) => updateLesson(courseId, lessonId, data), {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.LESSON)
        await history.push(`/courses/${courseId}/lessons`)
    },
    ...options,
    },
  )
  }

export default useUpdateLesson
