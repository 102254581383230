import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { UUID } from 'utils/types'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { CreatePackQuiz, createPackQuiz } from 'services/pack-quizzes-service'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useCreatePackQuiz = (packSimulationId : UUID, options?: UseMutationOptions<void, AxiosError, CreatePackQuiz>)
  : UseMutationResult<void, AxiosError, CreatePackQuiz> => {
  const queryClient = useQueryClient()
  return useMutationWithSnackbar(
    (data: CreatePackQuiz) => createPackQuiz(packSimulationId , data), {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.PACK_QUIZZES)
      },
    ...options,
  }
)}

export default useCreatePackQuiz
