import React, { useState } from 'react'
import { Formik } from 'formik'
import { RouteComponentProps } from 'react-router'
import { Button } from '@mui/material'
import { isEmpty } from 'lodash'
import { isWeTest } from 'utils/constants'
import { EditSubjectSchema } from './EditSubjectSchema'

import PageTitle from '../../layouts/dashboard/PageTitle'
import FormInput from '../../common/ui/form/form-input/form-input'
import useAreas from 'hooks/useAreas'
import useSubject from 'hooks/subjects/useSubject'
import useUpdateSubject from 'hooks/subjects/useUpdateSubject'
import Selector from 'components/shared/selector/Selector'
import useSelectItem from 'hooks/useSelectItem'

export const EditSubjectPage = (props: RouteComponentProps<{ subjectId: string }>) => {
  
  const [subject, setSubject] = useState<SubjectType>({} as SubjectType)
  const subjectId: string = props.match.params.subjectId

  const updateSubjectMutation = useUpdateSubject(subjectId)

  useSubject(subjectId, {
    onSuccess(data) {
      setSubject(data)
    }})
  
  const queryAreas = useAreas()
  const allAreas = queryAreas.data || []

  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({allItems: allAreas, initialSelectedItemsIds: subject.areas})

  const onEdit = (data) => {
    const name = data.name.trim()
    const formattedData = isWeTest() ? { areas: selectedItemIds, name } : { name }
    return updateSubjectMutation.mutate(formattedData)
  }

  return (
    <>
      <PageTitle className="u-mb-30">Edit Subject</PageTitle>
      {!isEmpty(subject) && <Formik
        initialValues={{ name: subject.name }}
        validateOnBlur
        validateOnChange={false}
        validationSchema={EditSubjectSchema}
        onSubmit={onEdit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="u-wpx-600 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary">
              <FormInput type="name" name="name"/>
              {isWeTest() &&
                <Selector
                  allItems={allAreas}
                  selectedItem={selectedItemIds}
                  onItemChecked={handleItemChecked}
                  onAllChecked={handleAllChecked}
                />
              }
              <Button sx={{mt: 5}} variant={"contained"} type="submit">
                Save
              </Button>
            </div>
          </form>
        )}
      </Formik>}
    </>
  )}
