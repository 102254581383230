import React, { useCallback, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid, MenuItem, Select } from '@mui/material'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'

import { UserRole } from './constants'
import { GroupUserInput, StyledInput, StyledLabel } from '../layouts/dashboard/StyledInput'
import PageTitle from '../layouts/dashboard/PageTitle'
import UserAvatar from './user-avatar/user-avatar'
import FormInput from '../common/ui/form/form-input/form-input'
import SaveButton from '../layouts/dashboard/SaveButton'
import useFileUri from 'hooks/useFileUri'
import useCreateUser from 'hooks/users/useCreateUser'
import useSubscriptions from "../../hooks/subscriptions/useSubscriptions"

export const NewUserPage = () => {

  const { change: handleFileChange, fileUri } = useFileUri()
  const [mainRole, setMainRole] = useState<UserRole>(UserRole.STUDENT)
  const subscriptions = useSubscriptions().data
  const inputFileLabelRef = useRef<HTMLLabelElement>(null)
  
  const newUserMutation = useCreateUser()

  const onSubmit = (data: any) => {
    const userPlanCode = mainRole === UserRole.STUDENT ? data.subscriptionPlan : 'NONE'
    const userPlanId = mainRole === UserRole.STUDENT ?
        subscriptions?.find(({code}) => code === data.subscriptionPlan)?.id : 'NONE'

    const newStudent: CreateUserRequest = {
      username: data.username,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      nickname: data.nickname,
      subscriptionPlan: userPlanCode,
        subscriptionId: userPlanId,
      role: mainRole,
    }
    if (!data.username) {
      alert('No Username');
    } else {
      if (data.photo) {
        newStudent.profileImage = data.photo
      }
      newUserMutation.mutate(newStudent)
    }
  }
  
  const handleUserAvatarClick = useCallback(
    () => {
      inputFileLabelRef.current!.click()
    },
    [],
  )

  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px', width: '600px' }}>
        <PageTitle>New User</PageTitle>
        <Select id="role"
                name="role"
                size="small"
                value={mainRole}
                onChange={(e) => setMainRole(e.target.value as UserRole)}
        >
          <MenuItem value="STUDENT">STUDENT</MenuItem>
          <MenuItem value="TEACHER">TEACHER</MenuItem>
          <MenuItem value="ADMIN">ADMIN</MenuItem>
        </Select>
      </Grid>
      <Formik
        initialValues={{
          username: '',
          password: '',
          firstName: '',
          lastName: '',
          nickname: '',
          photo: '',
          subscriptionPlan: '',
          areaId: '',
        }}
        onSubmit={onSubmit}
      >{(props) => (
        <Form>
          <Grid container spacing={3} className="u-wpx-600 u-bg-black-secondary u-m-0">
            <Grid item xs={12} className="u-pl-48 u-pr-48 u-pt-42" sx={{display: 'flex', alignItems: 'center'}}>
              <UserAvatar
                className={`${fileUri === null && 'u-bg-cape-cod u-br-50'}`}
                photo={fileUri === null ? <PermIdentityOutlinedIcon sx={{width: '60%', height: '60%'}}/> : fileUri}
                onClick={handleUserAvatarClick}
              />
              <Box sx={{marginLeft: '20px', display: 'flex', flexDirection: 'column'}}>
                <p className="u-m-0 u-wpx-160 u-fs-11 u-lh-17">Change your Profile Image JPG or PNG up to 10MB.</p>
                <label
                  ref={inputFileLabelRef}
                  htmlFor="photo"
                  className="u-border-1px-solid u-br-3 u-cursor-pointer u-pl-45 u-pt-15 u-pb-15 u-pr-45 u-mt-15">
                  Photo
                </label>
                <FormInput
                  id="photo"
                  onChange={(event) =>
                    handleFileChange((event.target as HTMLInputElement).files![0])}
                  type="file"
                  name="photo"
                  hidden
                />
              </Box>
            </Grid>

            <GroupUserInput>
              <StyledLabel htmlFor="firstName">First name</StyledLabel>
              <StyledInput
                id="firstName"
                type="text"
                name="firstName"
                placeholder="Fill first name"
                value={props.values.firstName}
                onChange={props.handleChange}/>

              <StyledLabel htmlFor="lastName" className="u-pt-48">Last name</StyledLabel>
              <StyledInput
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Fill last name"
                value={props.values.lastName}
                onChange={props.handleChange}/>

              <StyledLabel htmlFor="nickname" className="u-pt-48">Nickname</StyledLabel>
              <StyledInput
                id="nickname"
                type="text"
                name="nickname"
                placeholder="Fill nickname"
                value={props.values.nickname}
                onChange={props.handleChange}
              />
            </GroupUserInput>
            {mainRole === 'STUDENT' && subscriptions && <Grid item xs={12} className="u-pl-48">
              <PageTitle className="u-pb-30 u-weight-400 u-fs-24">Subscription plan</PageTitle>
                <Select id="subscriptionPlan"
                        name="subscriptionPlan"
                        value={props.values.subscriptionPlan}
                        onChange={props.handleChange}
                >
                    {subscriptions.map(({ name, id, code }) => (
                        <MenuItem key={ id } value={ code }>{ name }</MenuItem>
                    ))}
                </Select>
            </Grid>}
            <Grid item xs={12} className="u-pt-30 u-pl-48 u-flex-column">
              <PageTitle className="u-pb-30 u-weight-400 u-fs-24">Login information</PageTitle>
              <StyledLabel>Account email</StyledLabel>
              <StyledInput
                type="email"
                name="username"
                placeholder="Fill username"
                value={props.values.username}
                onChange={props.handleChange}
              />
              <StyledLabel className="u-pt-30">Current password </StyledLabel>
              <StyledInput
                type="password"
                name="password"
                placeholder="Fill password"
                value={props.values.password}
                onChange={props.handleChange}
              />
              <div className="u-pt-48 u-pb-42">
                <SaveButton type="submit" className="u-mr-15 u-wpx-214">Save</SaveButton>
                <SaveButton type="button" className="u-bg-mine-shaft u-wpx-122">Cancel</SaveButton>
              </div>
            </Grid>
          </Grid>
        </Form>)}
      </Formik>
    </>
  )
}
