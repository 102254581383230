import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { addUser } from 'services/users-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateUser = (options?: UseMutationOptions<UserResponse, AxiosError, CreateUserRequest>)
  : UseMutationResult<UserResponse, AxiosError, CreateUserRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    addUser,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.USERS)
        await history.push('/users')
      },
      ...options,
    },
  )
}

export default useCreateUser
