import React, { ChangeEvent } from "react";
import {
  Box,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  SxProps,
  Theme,
} from "@mui/material";
import { StyledLabel } from "components/layouts/dashboard/StyledInput";

type SelectebleItem = {
  id: string
  name?: string
  title?: string
};

type OptionSelectorProps = {
  title?: string
  allItems: SelectebleItem[];
  selectedItem?: string[];
  onItemChecked: (itemId: string) => void;
  onAllChecked: (e: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
};

const Selector = ({
  allItems,
  selectedItem = [],
  onItemChecked,
  onAllChecked,
  title,
  sx
}: OptionSelectorProps) => {
  const isAllSelected =
    allItems.length > 0 && selectedItem.length === allItems.length;

  return (
    <Box sx={{ mt: "30px", ...sx }}>
      <StyledLabel>{title || 'Available For'}</StyledLabel>
      <Box sx={{ mt: "15px" }}>
        <Grid sx={{ mb: "20px" }}>
          <FormControlLabel
            label="Select All"
            value="all"
            control={
              <Checkbox
                name="areas"
                checked={isAllSelected}
                onChange={(e) => onAllChecked(e)}
                indeterminate={
                  selectedItem.length > 0 &&
                  selectedItem.length < allItems.length
                }
              />
            }
          />
          <FormGroup sx={{ ml: "30px" }}>
            {allItems.map((item) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name="areas"
                    checked={selectedItem.includes(item.id)}
                    onChange={() => onItemChecked(item.id)}
                  />
                }
                label={item.name || item.title || ''}
                key={item.id}
              />
            ))}
          </FormGroup>
        </Grid>
      </Box>
    </Box>
  );
};

export default Selector;
