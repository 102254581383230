import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getSubject } from 'services/subject-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useSubject = (subjectId: string, options?: UseQueryOptions<SubjectType, AxiosError>)
  : UseQueryResult<SubjectType> => useQueryWithSnackbar<SubjectType>(
    [ReactQueryKeys.SUBJECT, subjectId],
    () => getSubject(subjectId), {
      enabled: !!subjectId,
      ...options,
    },
  )

export default useSubject
