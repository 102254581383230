import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { unpublishOfficialNationalQuizQuestion } from 'services/official-national-quiz-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUnpublishOfficialNationalQuiz = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    unpublishOfficialNationalQuizQuestion, {
      async onSuccess() { 
        await queryClient.invalidateQueries([ReactQueryKeys.OFFICIAL_NATIONAL_QUIZZES])
      },
    ...options,
  }
)}

export default useUnpublishOfficialNationalQuiz
