import { clientDataService } from './client-data-service'

const basicURL = '/admin/areas'

export const getAreas = (): Promise<AreaResponseView[]> =>
  clientDataService<AreaResponseView[]>({
    method: 'GET',
    url: basicURL,
  })

export const getAreaSubjects = (areaId: string): Promise<AreaSubjectResponse> =>
  clientDataService<AreaSubjectResponse>({
    method: 'GET',
    url: `${basicURL}/${areaId}/subjects`,
  })

export const createArea = (data: NewAreaRequest): Promise<AreaResponseView> =>
  clientDataService<AreaResponseView>({
    method: 'POST',
    url: basicURL,
    data
  })

export const updateArea = (data: UpdateAreaRequest): Promise<AreaResponseView> =>
  clientDataService<AreaResponseView>({
    method: 'PATCH',
    url: `${basicURL}/${data.id}`,
    data
  })

export const deleteArea = (areaId: string): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `${basicURL}/${areaId}`,
  })
 
export const getLessonArea = async (courseId: string): Promise<AreaResponseView[]> => {
  return await clientDataService({
    method: 'get',
    url: `/admin/courses/${courseId}/areas`
  })
}
