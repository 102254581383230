import { UseMutationOptions } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { UUID } from 'utils/types'
import { QuizQuestion } from 'components/quizzes/quizzes'
import { addOfficialNationalQuizQuestion, OfficialNationalQuizQuestionUpsertModel } from 'services/official-national-quiz-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useAddOfficialNationalQuizQuestion = (quizId: UUID, options?: UseMutationOptions<QuizQuestion, AxiosError, OfficialNationalQuizQuestionUpsertModel>)
  : UseMutationResult<QuizQuestion, AxiosError, OfficialNationalQuizQuestionUpsertModel> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: OfficialNationalQuizQuestionUpsertModel) => addOfficialNationalQuizQuestion(quizId, data), {
      async onSuccess({question}) { 
        await history.push(`/quizzes/official-national-simulations/${quizId}/questions/${question.id}`)
      },
    ...options,
  }
)}

export default useAddOfficialNationalQuizQuestion
