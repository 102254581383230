import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TableContainer,
  TextField,
} from "@mui/material";
import {
  StyledInput,
  StyledLabel,
} from "components/layouts/dashboard/StyledInput";
import { FieldArray, Form, Formik } from "formik";
import dayjs, { Dayjs } from "dayjs";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { DatePicker } from "@mui/lab";
import { useState } from "react";
import Selector from "components/shared/selector/Selector";
import QuizNewFormSchema from "./shema/QuizNewFormSchema";
import RulePoints from "./RulePoints";
import SectionsForm from "./SectionsForm";
import {
  QuizRulesSourceType,
  QuizRulesType,
} from "components/quizzes/constants";
import SectionsList from "../lists/SectionsList";

type QuizNewRulesFormProps = {
  quiz: any;
  onSubmit: (group: any) => void;
  isCreate?: boolean;
  selectedSectionIndex: number;
  handleSectionSelection: (section: any, index: number) => void;
  subjects: SubjectResponse[];
};

const QuizRulesForm = ({
  quiz,
  onSubmit,
  selectedSectionIndex,
  subjects,
  handleSectionSelection,
  isCreate
}: QuizNewRulesFormProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const isImportType = (source) => source === QuizRulesSourceType.IMPORT_QUESTION

  const optionsSource = [
    { id: 1, name: QuizRulesSourceType.IMPORT_QUESTION },
    { id: 2, name: QuizRulesSourceType.QUESTION_POOL },
  ];

  const onKeyDown = (e) => e.preventDefault();

  const handleAddSection = () => setIsOpen(true);

  return (
    <Box>
      <Formik
        initialValues={{ ...quiz }}
        validationSchema={QuizNewFormSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box style={{ width: 600 }}>
                <Box className="u-flex-container" sx={{alignItems: 'baseline'}}>
                  <StyledLabel>Name</StyledLabel>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <StyledInput
                    type="text"
                    name="name"
                    placeholder="Fill name"
                    value={formProps.values.name}
                    onChange={formProps.handleChange}
                  />
                  {formProps.errors.name && formProps.touched.name ? (
                    <div style={{ color: "red" }}>{formProps.errors.name}</div>
                  ) : null}
                  </Box>
                </Box>
                <div className="u-flex-container u-pt-30">
                  <StyledLabel>Available from</StyledLabel>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      value={formProps.values.availableFrom}
                      onChange={(newValue: Dayjs | null) => {
                        formProps.setFieldValue(
                          "availableFrom",
                          newValue?.valueOf()
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={onKeyDown}
                          helperText={null}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formProps.errors.availableFrom &&
                  formProps.touched.availableFrom ? (
                    <div style={{ color: "red" }}>
                      {formProps.errors.availableFrom}
                    </div>
                  ) : null}
                </div>
                <Box mb={2} mt={5} className="u-flex-container">
                  <StyledLabel>Source</StyledLabel>
                  <Select
                    value={formProps.values.source}
                    sx={{ width: 300 }}
                    size="small"
                    onChange={(e) =>
                      formProps.setFieldValue("source", e.target.value)
                    }
                  >
                    {optionsSource.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <RulePoints
                  correctAnswerPoints={formProps.values.correctAnswerPoints}
                  incorrectAnswerPoints={formProps.values.incorrectAnswerPoints}
                  noAnswerPoints={formProps.values.noAnswerPoints}
                  onChange={formProps.setFieldValue}
                />
              </Box>
              <Box style={{ width: 600 }}>
                  <Box
                    sx={{
                      backgroundColor: "#1F2021",
                      backgroundImage:
                        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                    }}
                  >
                    <Box pb={1} pt={1} pl={1} className="u-flex-container">
                      <StyledLabel>Ranking</StyledLabel>
                      <RadioGroup
                        row
                        name="controlled-radio-buttons-group"
                        value={isImportType(formProps.values.source) ? formProps.values.ranking : false}
                        onChange={(e) =>
                          formProps.setFieldValue(
                            "ranking",
                            !formProps.values.ranking
                          )
                        }
                      >
                        <FormControlLabel
                          value="true"
                          disabled={!isCreate || !isImportType(formProps.values.source)} 
                          control={<Radio />}
                          disableTypography
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          disabled={!isCreate || !isImportType(formProps.values.source)} 
                          disableTypography
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </Box>            
                  <Box
                    sx={{
                      backgroundColor: "#1F2021",
                      backgroundImage:
                        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                    }}
                  >
                    <Box pb={1} pt={2} pl={1} className="u-flex-container">
                      <StyledLabel>Divided by sections</StyledLabel>
                      <RadioGroup
                        row
                        name="controlled-radio-buttons-group"
                        value={formProps.values.divided}
                        onChange={(e) =>
                          formProps.setFieldValue(
                            "divided",
                            !formProps.values.divided
                          )
                        }
                      >
                        <FormControlLabel
                          value="true"
                          disabled={!isCreate} 
                          disableTypography
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          disabled={!isCreate} 
                          disableTypography
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                    {formProps.values.divided === false && (
                    <Box
                      mb={2}
                      pb={1}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 10px",
                        borderRadius: 0,
                        marginTop: 0
                      }}
                    >
                      <StyledLabel>Time for simulation (seconds)</StyledLabel>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        inputProps={{ min: 0, pattern: "[0-9]*" }}
                        value={formProps.values.timeLimit}
                        onChange={(e) =>
                          formProps.setFieldValue(
                            "timeLimit",
                            +e.target.value
                          )
                        }
                      />
                    </Box>)}
                  </Box>
              </Box>
            </Box>
            <Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "600px",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      mb={3}
                      mt={5}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <StyledLabel>Sections</StyledLabel>
                      <Button
                        variant="contained"
                        sx={{ width: 150 }}
                        onClick={handleAddSection}
                        type="button"
                      >
                        Add section
                      </Button>
                    </Grid>
                    <SectionsList
                      selectedSectionIndex={selectedSectionIndex}
                      onSelect={handleSectionSelection}
                      isDivide={formProps.values.divided}
                      formProps={formProps}
                      setDisabled={setDisabled}
                    />
                  </Box>
            </Box>
            <Box mt={5}>
              <TableContainer>
                <Button type="submit" variant="contained" disabled={disabled}>
                  Save
                </Button>
              </TableContainer>
            </Box>
            <Modal
              open={isOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                top: "35%",
                left: "36%",
                width: "600px",
                height: "fit-content",
                background: "gray",
              }}
            >
              <Box>
                <FieldArray
                  name={`sections`}
                  render={(sectionsArrayHelpers) => (
                    <SectionsForm
                      subjects={subjects}
                      formProps={formProps}
                      setIsOpen={setIsOpen}
                      sectionsArrayHelper={sectionsArrayHelpers}
                    />
                  )}
                />
              </Box>
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default QuizRulesForm;
