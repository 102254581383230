import { useMutation } from 'react-query'
import { MutationFunction } from 'react-query/types/core/types'
import { UseMutationOptions, UseMutationResult } from 'react-query/types/react/types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import handleAxiosError from './handleAxiosError'

function useMutationWithSnackbar<TData = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, AxiosError, TVariables, TContext>, 'mutationFn'>,
  successMessage?: string,
  errorMessage?: string,
): UseMutationResult<TData, AxiosError, TVariables, TContext> {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context)
      }
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' })
      }
    },
    onError: (error, variables, context) => {
      if (options?.onError) {
        options?.onError(error, variables, context)
      }
      errorMessage ?
        enqueueSnackbar(errorMessage, { variant: 'error' })
        : handleAxiosError(error, enqueueSnackbar)
    },
  })
}

export default useMutationWithSnackbar
