import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { Page } from '../../utils/api-responses'
import { getUsersByParams, GetUsersQueryParams } from 'services/users-service'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUsers = (
  data: GetUsersQueryParams,
  options?: UseQueryOptions<Page<UserSlimView>, AxiosError>
): UseQueryResult<Page<UserSlimView>> => useQueryWithSnackbar<Page<UserSlimView>>(
    [ReactQueryKeys.USERS, data.page, data.size, data.firstName, data.lastName, data.nickname, data.username, data.mainRole, data.areaName],
    () => getUsersByParams(data),
    options,
  )

export default useUsers
