import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import useMutationWithSnackbar from '../../hooks/useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { deleteSubscription } from 'services/subscription-service'


const useDeleteSubscription = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteSubscription, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.SUBSCRIPTIONS)
      },
      ...options,
    }
  )
}

export default useDeleteSubscription
