import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteLesson } from 'services/lessons-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useDeleteLesson = (options?: UseMutationOptions<void, AxiosError, LessonActionRequest>)
  : UseMutationResult<void, AxiosError, LessonActionRequest> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteLesson, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.LESSONS)
      }, 
      ...options,
    }  
  )}

export default useDeleteLesson
