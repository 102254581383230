import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Form, Formik } from 'formik'
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'

import { QuestionPurpose, QuestionType } from '../constants'
import { QuestionSlimListPageable } from 'types/responses/QuestionSlimListPageable'
import { deleteConfirmation } from 'components/layouts/Modal/contentModal'
import Modal from 'components/layouts/Modal/Modal'
import PageTitle from '../../layouts/dashboard/PageTitle'
import SaveButton from '../../layouts/dashboard/SaveButton'
import usePageable from 'hooks/usePageable'
import useAddQuestion from 'hooks/questions/useAddQuestion'
import useQuestions from 'hooks/questions/useQuestions'
import useUnpublishQuestion from 'hooks/questions/useUnpublishQuestion'
import usePublishQuestion from 'hooks/questions/usePublishQuestion'
import useDeleteQuestion from 'hooks/questions/useDeleteQuestion'
import QuestionLinePage from './QuestionLinePage'
import { isWeTest } from 'utils/constants'


export const QuestionsListPage = () => {
  const history = useHistory()
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [questionIdToDelete, setQuestionIdToDelete] = useState<string>('')

  const addQuestionMutation = useAddQuestion()
  const deleteQuestionMutation = useDeleteQuestion()
  const publishQuestionMutation = usePublishQuestion()
  const unpublishQuestionMutation = useUnpublishQuestion()

  const {
    rowsPerPage,
    pageNumber,
    textFilter,
    secondaryFilter,
    handleChangePage,
    handleChangeBack,
    handleChangeRowsPerPage,
    clearFilters,
    handleSearch
  } = usePageable()
  
  const questionsQuery = useQuestions({
    size: rowsPerPage,
    page: pageNumber,
    text: textFilter,
    answerExplanation: secondaryFilter
  })
  const questionsPage = questionsQuery.data || {} as QuestionSlimListPageable

  const handleDeleteQuestionDialogOpen = (questionId: string) => {
    setDeleteConfirmDialogOpen(true)
    setQuestionIdToDelete(questionId)
  }
  
  const handleAddQuestion = () =>
    addQuestionMutation.mutate({
      type: QuestionType.MULTIPLE_CHOICE,
      purpose: QuestionPurpose.QUESTIONS_POOL,
    }, {
      async onSuccess(data) {
        await history.push(`/questions/${data.id}`)
      }
    })
  
  const handleDeleteQuestion = () => deleteQuestionMutation.mutateAsync(questionIdToDelete)
    .then(_ => setDeleteConfirmDialogOpen(false))
  
  const toggleQuestionPublish = (questionId: string, published: boolean) => {
    const mutation = published ? unpublishQuestionMutation : publishQuestionMutation
    mutation.mutateAsync(questionId)
  }

  return (
    <div>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Question</PageTitle>
        <SaveButton onClick={handleAddQuestion}>
          + New question
        </SaveButton>
      </div>
      <Box
        sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
      >
        <Formik
          initialValues={{text: '', answerExplanation: ''}}
          onSubmit={({text, answerExplanation}) => handleSearch({text, secondaryText: answerExplanation})}
        >{(props => (
          <Form className="u-flex-container">
            <span style={{paddingTop: '28px', display: 'inline-block'}}>Filters:</span>
            <TextField id="text"
                       name="text"
                       label="Text"
                       variant="standard"
                       value={props.values.text}
                       onChange={props.handleChange}/>
            <TextField id="answerExplanation"
                       name="answerExplanation"
                       label="Answer explanation"
                       variant="standard"
                       value={props.values.answerExplanation}
                       onChange={props.handleChange}/>
            <div className="u-d-flex">
              <SaveButton type='submit'>Find</SaveButton>
                <SaveButton type='button'
                  onClick={() => {
                    props.setValues({text: '', answerExplanation: ''})
                    clearFilters()
                  }}
                  className="u-wpx-165 u-ml-20">
                  Clear filters
                </SaveButton>
            </div>
          </Form>
        ))}
        </Formik>
      </Box>
      <br/>
      <div>
        {!isEmpty(questionsPage) &&
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Subject</TableCell>
                {!isWeTest() && <>
                  <TableCell>Topic</TableCell>
                  <TableCell>Simulation Tag</TableCell>
                  <TableCell>Difficulty</TableCell>
                </>}
                <TableCell>Purpose</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionsPage.content.map((question) => (
                <TableRow key={question.id} hover style={{cursor: "pointer"}}>
                  <QuestionLinePage
                    question={question}
                    canDelete={question.purpose === QuestionPurpose.QUESTIONS_POOL}
                    link={`/questions/${question.id}`}
                    onChangePublish={toggleQuestionPublish}
                    onOpenDeleteConfirmDialog={handleDeleteQuestionDialogOpen}
                  />
                </TableRow>
              ))}   
            </TableBody>
          </Table>
          <Grid container spacing={3}>
            <Grid item xs={6} display="flex" alignItems="center">
              <Pagination
                size={"medium"}
                count={questionsPage.totalPages}
                onChange={handleChangeBack}
                page={pageNumber + 1}
                boundaryCount={2}/>
            </Grid>
            <Grid item xs={6}>
              <TablePagination
                component="div"
                count={questionsPage.totalElements}
                page={pageNumber}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid> 
          </Grid>
        </TableContainer>
        }
      </div>
      <Modal
        confirmOpen={deleteConfirmDialogOpen}
        onAction={handleDeleteQuestion}
        onClose={() => setDeleteConfirmDialogOpen(false)}
        content={deleteConfirmation}
      />
    </div>
  )
}
