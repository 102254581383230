import { UUID } from '../utils/types'
import { Page } from '../utils/api-responses'
import { clientDataService } from './client-data-service'
import { UserRole } from '../components/users/constants'
import { UserProfileAdminView } from 'types/UserProfileAdminView'
import jsonToFormData from '../utils/jsonToFormData'
import { UserClassesResponse } from 'types/responses/UserClassesResponse'

export type GetUsersQueryParams = {
  size: number
  page: number
  text?: string | null
  username?: string | null
  firstName?: string | null,
  lastName?: string | null,
  nickname?: string | null,
  areaName?: string | null,
  mainRole?: string | null,
}

export type StudentResetPasswordResult ={
  password: string
}

export const getUser = (studentId: UUID): Promise<UserProfileAdminView> =>
  clientDataService<UserProfileAdminView>({
    method: 'GET',
    url: `/admin/students/${studentId}`
  })

export const getUsersByParams = (params: GetUsersQueryParams): Promise<Page<UserSlimView>> =>
  clientDataService<Page<UserSlimView>>({
    method: 'GET',
    url: `/admin/students?sort=username`,
    params
  })

export const addUser = (data: CreateUserRequest): Promise<UserResponse> =>
  clientDataService<UserResponse>({
    method: 'POST',
    url: '/admin/students',
    data: jsonToFormData(data)
  })

export const patchUser = (studentId: UUID, data: UserProfileAdminView): Promise<UserResponse> =>
  clientDataService<UserResponse>({
    method: 'PATCH',
    url: `/admin/students/${studentId}`,
    data: jsonToFormData(data)
  })

export const deleteUser = (studentId: UUID): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/students/${studentId}`
  })

export const resetUserPassword = (studentId: UUID): Promise<StudentResetPasswordResult> =>
  clientDataService<StudentResetPasswordResult>({
    method: 'PUT',
    url: `/admin/students/${studentId}/reset-password`
  })

export const setUserRole = (studentId: UUID, role: UserRole): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/admin/users/${studentId}/set-role`,
    data: {role}
  })

export const downgradePlan = (): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: '/admin/students/downgrade-paid-plans',
  })

  export const restoreUser = (studentId: string): Promise<void> =>
    clientDataService<void>({
      method: 'PUT',
      url: `/admin/students/${studentId}/restore`,
    })

  export const getUserClassesList = (userId: UUID): Promise<UserClassesResponse[]> =>
    clientDataService<UserClassesResponse[]>({
      method: 'GET',
      url: `/admin/users/${userId}/classes`
    })
