import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

interface ModalView {
  content: DialogType
  confirmOpen: boolean
  onAction: () => void
  onClose: () => void
}

const Modal = ({confirmOpen, onAction, onClose, content}: ModalView) => {
  const {dialogTitle, dialogContent, buttonText} = content

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClose()
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onAction()
  }

  return (
    <Dialog
      open={confirmOpen || false}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="outlined"
                color="error"
                onClick={handleSubmit}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
