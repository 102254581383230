import { useHistory } from 'react-router-dom'
import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createBlog } from 'services/blog-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateBlog = (options?: UseMutationOptions<CreateResponse, AxiosError>)
  : UseMutationResult<CreateResponse, AxiosError, void> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    createBlog,
    {
      async onSuccess(blog) {
        await history.push(`/blogs/${blog.id}/edit`)
      },
      ...options,
    },
  )
}

export default useCreateBlog
