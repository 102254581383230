import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getBlog } from 'services/blog-service'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useBlog = (blogId: string, options?: UseQueryOptions<BlogType, AxiosError>): UseQueryResult<BlogType> =>
  useQueryWithSnackbar<BlogType>(
    [ReactQueryKeys.BLOG, blogId],
    () => getBlog(blogId), {
      enabled: !!blogId,
      ...options,
    },
  )

export default useBlog
