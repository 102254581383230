import { styled } from '@mui/material'

export const StyledInput = styled('input')(({theme}) => ({
    ...theme.mixins.toolbar,
    minHeight:'60px !important',
    width: '500px',
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
    outline: 'none',
    borderBottom: '2px solid #525151',
    
  }))

  export const StyledLabel = styled('label')(({theme}) => ({
    ...theme.mixins.toolbar,
    zIndex: 100,
    minHeight:'0px !important',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: theme.palette.text.secondary,
    
  }))

  export const GroupUserInput = styled('div')(({theme}) => ({
      ...theme.mixins.toolbar,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '50px',

      borderTop: '2px solid #525151',
      borderBottom: '2px solid #525151',
      padding: '50px'
   
  }))


