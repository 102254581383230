import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteButton from "components/shared/delete-button/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";
import { QuizRulesListType } from 'types/QuizRulesList'
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const addIcon = (elem) =>
  elem === true ? "/images/icons/green.png" : "/images/icons/red.png";

  type QuizRulesListProps = {
    quizRulesList: QuizRulesListType[] | undefined
    onDelete: (ruleId: string) => Promise<void>
  };

const QuizRulesList = ({quizRulesList, onDelete}: QuizRulesListProps) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className="u-text-center">Name</TableCell>
          <TableCell className="u-text-center">Type</TableCell>
          <TableCell className="u-text-center">Available from</TableCell>
          <TableCell className="u-text-center">Source</TableCell>
          <TableCell className="u-text-center">Ranking</TableCell>
          <TableCell className="u-text-center"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {quizRulesList && quizRulesList.map((item) => (
          <TableRow
            hover
            key={item.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              cursor: "pointer",
            }}
          >
            <TableCell className="u-text-center">{item.name}</TableCell>
            <TableCell className="u-text-center">{item.type}</TableCell>
            <TableCell className="u-text-center">{dayjs(item.availableFrom).format("MM/DD/YYYY")}</TableCell>
            <TableCell className="u-text-center">{item.source}</TableCell>
            <TableCell className="u-text-center">
              <img
                src={addIcon(item.ranking)}
                width="15px"
                height="15px"
                alt=""
              />
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Link to={`/quizzes/generate-rules/${item.id}/edit`}>
                <Button color="primary" component="span">
                  <EditIcon />
                </Button>
                </Link>
                <DeleteButton
                  onDelete={() => onDelete(item.id)}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default QuizRulesList;
