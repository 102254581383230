import serverApi from './server-api-service'
import { clientDataService } from './client-data-service'
import { Page, ApiResponse } from '../utils/api-responses'
import { UUID } from '../utils/types'
import { AxiosRequestConfig } from 'axios'
import jsonToFormData from '../utils/jsonToFormData'

export const getCourses = ({size, page}: GetCoursesRequest): Promise<Page<CourseView>> =>
  clientDataService<Page<CourseView>>({
    method: 'GET',
    url: `/admin/courses?size=${size}&page=${page}&sort=number`,
  })

export const getCourse = (courseId: UUID): Promise<CourseView> =>
  clientDataService<CourseView>({
    method: 'GET',
    url: `/admin/courses/${courseId}`,
  })

export const addCourse = (data: CourseUpdateRequest): Promise<CourseView> =>
  clientDataService<CourseView>({
    method: 'POST',
    url: '/admin/courses',
    data
  })

export const updateCourse = (courseId: UUID, data: CourseUpdateRequest): Promise<CourseView> =>
  clientDataService<CourseView>({
    method: 'PUT',
    url: `/admin/courses/${courseId}`,
    data: jsonToFormData(data)
  })

export const patchCourse = (courseId: UUID, data: CourseUpdateRequest): Promise<CourseView> =>
  clientDataService<CourseView>({
    method: 'PATCH',
    url: `/admin/courses/${courseId}`,
    data: jsonToFormData(data)
  })

export const publishCourse = (courseId: UUID): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/admin/courses/${courseId}/publish`,
  })

export const unpublishCourse = (courseId: UUID): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/admin/courses/${courseId}/unpublish`,
  })

export const deleteCourse = (courseId: UUID): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/courses/${courseId}`,
  })

export const uploadVideoToCourse = (courseId: UUID, data: any, config: AxiosRequestConfig): Promise<ApiResponse<UploadVideoResponse>> => {
    return serverApi.post(`/admin/courses/${courseId}/upload-video`, data, config)
}
