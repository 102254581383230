import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { publishSubscription } from 'services/subscription-service'

const usePublishSubscription = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    publishSubscription, {
      async onSuccess() { 
        await queryClient.invalidateQueries([ReactQueryKeys.SUBSCRIPTIONS])
      },
    ...options,
  }
)}

export default usePublishSubscription
