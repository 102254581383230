import React from 'react'
import { useParams } from 'react-router-dom'
import PageTitle from '../layouts/dashboard/PageTitle'
import { SimulationTagForm } from './SimulationTagForm'
import useTag from 'hooks/useTag'
import useUpdateTag from 'hooks/useUpdateTag'


export const SimulationTagEditPage = () => {
  const updateTagMutation = useUpdateTag()
  const handleUpdateTags = (data: UpdateTagView) => updateTagMutation.mutate(data)
  const {simulationTagId}: any = useParams()

  const tagQuery = useTag(simulationTagId)
  const tag = tagQuery.data

  return (
    <>
      {tag && (
        <>
          <PageTitle className='u-mb-30'>Simulation Tag</PageTitle>
          <SimulationTagForm tag={tag} handleSubmit={handleUpdateTags}/>
        </>
      )}
    </>
  )
}
