import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Button, Box, MenuItem, Menu } from '@mui/material'

const cleanText = (text) => text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ')

interface LinkedQuestionsSectionProps {
  mainQuestionId?: string
  linkedQuestions: LinkedQuestionsView[]
  canCreateMainQuestion?: boolean
  canCreateLinkQuestion?: boolean
  onCreateLinkedQuestion?: () => void
  onAddLinkedQuestion: () => void
}

export const LinkedQuestionsSection = ({
                              mainQuestionId,
                              linkedQuestions = [],
                              canCreateMainQuestion=false,
                              canCreateLinkQuestion=false,
                              onAddLinkedQuestion,
                              onCreateLinkedQuestion,
}: LinkedQuestionsSectionProps) => {
  const menuRef = useRef(null)
  const [open, setOpen] = useState<boolean>(false)

  const toggleClick = () => setOpen(!open)

  const handleClose = () => setOpen(false)

  return (
  <Box sx={{display: 'flex'}}>
    {canCreateMainQuestion &&
      <Button variant="contained" onClick={onCreateLinkedQuestion}>
        main question
      </Button>
    }
    {canCreateLinkQuestion &&
      <Button
        variant="contained"
        onClick={onAddLinkedQuestion}
        sx={{ml: '20px', mr: '20px'}}
      >
        Add linked question
      </Button>
    }
    {!isEmpty(linkedQuestions) &&
      <Box>
        <Button
          ref={menuRef}
          onClick={toggleClick}
        >
          list linked question
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={menuRef.current}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {linkedQuestions.map(({ questionText, mainQuestion, questionId }) =>
            <MenuItem key={questionId}>
              {isEmpty(questionText) ?
                <Link
                  to={`/questions/${mainQuestionId}/linked/${questionId}`}
                  style={{textDecoration: 'none', minWidth: '30px', color: mainQuestion ? '#E25F60' : 'unset'}}
                >
                  Empty Question
                </Link>
                :
                <Link
                  to={`/questions/${questionId}`}
                  style={{textDecoration: 'none', minWidth: '30px', color: mainQuestion ? '#E25F60' : 'unset'}}
                >
                  {cleanText(questionText)}
                </Link>
              }
            </MenuItem>
          )}
        </Menu>
      </Box>
    }
  </Box>   
  )}
