import React, { ChangeEvent } from 'react'
import {
    Box,
    Typography,
    Grid,
    Autocomplete,
    TextField,
    List,
    ListItem,
    IconButton
} from '@mui/material'

type LessonTagsProps = {
    options: string[]
    selectedTags?: string[]
    onSearch?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onChange: (tag: string) => void
    onDeleteTag?: (tag:string) => void
  }

const LessonTags = ({selectedTags, options, onSearch, onChange, onDeleteTag}: LessonTagsProps) => (
  <Box sx={{mt: '30px'}}>
    <Box sx={{textTransform: 'uppercase'}}>Tags</Box>
    <Box className="u-bg-black-secondary" sx={{p: '28px', mt: '15px'}}>
      <Typography sx={{textTransform: 'uppercase', mb: '15px'}}>Add tag</Typography>
      <Grid sx={{display: 'flex', mb: '20px'}}>
        <Autocomplete
          id="tags-standard"
          options={options}
          getOptionLabel={(option) => option}
          sx={{width: '100%'}}
          onChange={(e, value) => value && onChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Tags"
              onChange={onSearch}
            />
          )}
        />
      </Grid>
      <List sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              maxWidth: '100%',
            }}
      >
        {selectedTags && selectedTags.map((tagName: string, index: any) => (
          <ListItem
            key={index}
            sx={{
              justifyContent: 'space-between',
              maxWidth: 'calc(50% - 10px)',
              p: '10px 20px',
              mb: '20px',
              backgroundColor: 'rgb(45, 46, 47)'
            }}
          >
            {tagName}
            <IconButton
              sx={{width: '25px', height: '25px', backgroundColor: '#C4C4C4'}}
              onClick={() => onDeleteTag && onDeleteTag(tagName)}
            >
              <img src="/images/icons/close-black.svg" alt="button close"/>
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  </Box>
)

export default LessonTags
