import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import {Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import PageTitle from "components/layouts/dashboard/PageTitle";
import SaveButton from "components/layouts/dashboard/SaveButton";
import DeleteButton from "components/shared/delete-button/DeleteButton";
import EditIcon from "@mui/icons-material/Edit";

import usePackSimulations from "hooks/packSimulation/usePackSimulations";
import usePublishPackSimulation from "hooks/packSimulation/usePublishPackSimulation";
import useUnpublishPackSimulation from "hooks/packSimulation/useUnpublishPackSimulation";
import useDeletePackSimulation from "../../../hooks/packSimulation/useDeletePackSimulation";


const PackSimulationsListPage = () => {
    const history = useHistory();
    const packSimulationList = usePackSimulations().data;

    const publishPackMutation = usePublishPackSimulation();
    const unpublishPackMutation = useUnpublishPackSimulation();
    const deletePackSimulationMutation = useDeletePackSimulation();

    const handleDeletePackSimulation = (packSimulationId: string) =>
        deletePackSimulationMutation.mutateAsync(packSimulationId);

    const togglePublish = (packSimulationId: string, published: boolean) => {
        const mutation = published ? unpublishPackMutation : publishPackMutation;
        mutation.mutateAsync(packSimulationId)
    };

    const handleQuiz = (packSimulationId: string) =>
        history.push(`/quizzes/pack-simulations/${packSimulationId}/quizzes`);

    return (
        <Grid container>
            <Grid item>
                <div className="u-flex-container u-w-100 u-mb-30">
                    <PageTitle>Pack Simulation</PageTitle>
                    <Link to="/quizzes/pack-simulations/new">
                        <SaveButton className="u-bg-sunglo u-br-n">Add pack</SaveButton>
                    </Link>
                </div>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Available from</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {packSimulationList && packSimulationList.length !== 0 ?
                                packSimulationList.map((simulation) => (
                                    <TableRow
                                        hover
                                        key={simulation.id}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={(_) => handleQuiz(simulation.id)}
                                        >
                                            {simulation.title}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={(_) => handleQuiz(simulation.id)}
                                        >
                                            <Box sx={{ marginRight: "100px" }}>
                                                {dayjs(simulation.availableFrom).format("MM/DD/YYYY")}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{ display: "flex", justifyContent: "space-around" }}
                                            >
                                                <Link to={`/quizzes/pack-simulations/${simulation.id}/edit`}>
                                                    <Button color="primary" component="span">
                                                        <EditIcon />
                                                    </Button>
                                                </Link>
                                                <DeleteButton onDelete={() => handleDeletePackSimulation(simulation.id)}/>
                                                <Button
                                                    variant="contained"
                                                    color={simulation.published ? "secondary" : "primary"}
                                                    sx={{ ml: 3, width: "auto" }}
                                                    style={simulation.published ? {padding: '6px 16px'} : {padding: '6px 27px'}}
                                                    onClick={() =>
                                                        togglePublish(simulation.id, simulation.published)
                                                    }
                                                >
                                                    {simulation.published ? "Unpublish" : "Publish"}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                <TableRow>
                                    <TableCell>Haven't quizzes yet</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default PackSimulationsListPage;
