import React, { FC } from 'react'
import { AxiosRequestConfig } from 'axios'

import VideoUpload from '../../shared/video-upload/VideoUpload'
import { uploadVideoToLesson } from 'services/lessons-service'

type LessonVideoProps = {
  lesson: LessonType
}

const LessonVideo: FC<LessonVideoProps> = ({lesson}) => {
  const {id: lessonId, courseId, videoUri} = lesson

  const handleSubmit = (formData: FormData, config: AxiosRequestConfig) =>
    uploadVideoToLesson(courseId, lessonId, formData, config)

  return (
    <VideoUpload
      videoUri={videoUri}
      onSubmit={handleSubmit}
      addChaptersLink={`/courses/${courseId}/lessons/${lessonId}/video-chapters`}
    />
  )
}

export default LessonVideo
