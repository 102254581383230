import serverApi from './server-api-service'
import { clientDataService } from './client-data-service'
import { UUID } from '../utils/types'
import { Page } from '../utils/api-responses'
import { AxiosRequestConfig } from 'axios'
import { ApiResponse } from '../utils/api-responses'
import jsonToFormData from '../utils/jsonToFormData'



export const getLessons = ({courseId, size, page}: GetLessonsRequest): Promise<Page<LessonType>> =>
  clientDataService<Page<LessonType>>({
    method: 'GET',
    url: `/admin/courses/${courseId}/lessons?size=${size}&page=${page}&sort=number`,
  })

export const getLesson = (courseId: UUID, lessonId: UUID): Promise<LessonType> =>
  clientDataService<LessonType>({
    method: 'GET',
    url: `/admin/courses/${courseId}/lessons/${lessonId}`,
  })

export const createLesson = (courseId: UUID, data: LessonUpdateRequest): Promise<any> =>
  clientDataService<any>({
    method: 'POST',
    url: `/admin/courses/${courseId}/lessons`,
    data
  })

export const deleteLesson = ({courseId, lessonId}: LessonActionRequest): Promise<void> =>
  clientDataService({
    method: 'DELETE',
    url: `/admin/courses/${courseId}/lessons/${lessonId}`
  })

export const updateLesson = (courseId: UUID, lessonId: UUID, data: LessonUpdateRequest): Promise<LessonType> =>
  clientDataService<LessonType>({
    method: 'PATCH',
    url: `/admin/courses/${courseId}/lessons/${lessonId}`,
    data: jsonToFormData(data)
  })

export const uploadVideoToLesson = (courseId: UUID, lessonId: UUID, data: any, config: AxiosRequestConfig): Promise<ApiResponse<any>> => {
    return serverApi.post(`/admin/courses/${courseId}/lessons/${lessonId}/upload-video`, data, config)
}

