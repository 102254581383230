import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../../layouts/dashboard/PageTitle'
import SaveButton from '../../layouts/dashboard/SaveButton'
import AreasList from './AreasList'
import useAreas from 'hooks/useAreas'
import useDeleteArea from 'hooks/useDeleteArea'

const AreasPage = () => {
  
  const areas = useAreas().data || []
  const deleteAreaMutation = useDeleteArea()

  const handleDeleteArea = (areaId: string) => deleteAreaMutation.mutate(areaId)

  return (
          <>
            <div className="u-flex-container u-mb-30">
              <PageTitle>Areas</PageTitle>
              <Link to="/areas/new">
                <SaveButton>
                  Add area
                </SaveButton>
              </Link>
            </div>
            <AreasList allAreas={areas} onDelete={handleDeleteArea}/>
          </> 
  )
}

export default AreasPage
