import useCreateQuizRule from "hooks/useCreateQuizRule";
import { useState } from "react";
import useSubjects from "hooks/subjects/useSubjects";
import QuizRulesForm from "./forms/QuizRulesForm";
import { Sections } from "types/requests/CreateQuizRuleRequest";
import { QuizRulesType } from "../constants";
import { RuleInterface } from "./types/quizRule";
import { object } from "yup";

const QuizNewRulesPage = () => {
  const subjects = useSubjects().data || [];


  const createQuizRuleMutation = useCreateQuizRule();
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(-1);

  const handleSectionSelection = (section: any, index: number) => {
    setSelectedSectionIndex(index);
  };

  const formatData = (sectionsForm: any, divided: boolean) => {
    let sections = sectionsForm.map(
      ({ name, questionsAmount, timeLimit, subjects }, index) => ({
        name,
        questionsAmount: Number(questionsAmount),
        timeLimit: divided ? Number(timeLimit) : null,
        sortOrder: index,
        subjects: subjects
          .map((subject) => ({
            subjectId: subject.subject.id,
            tagProbabilities: subject.tagProbabilities.map(
              ({ probability, simulationTag }) => ({
                probability,
                tagId: simulationTag.id,
              })
            ),
          }))
          .flat(),
      })
    );

    return sections;
  };

  const handleSubmit = (rule: RuleInterface ) => {
    createQuizRuleMutation.mutate({
      name: rule.name,
      availableFrom: rule.availableFrom,
      correctAnswerPoints: rule.correctAnswerPoints * 100,
      dividedBySections: rule.divided,
      incorrectAnswerPoints: rule.incorrectAnswerPoints * 100,
      noAnswerPoints: rule.noAnswerPoints * 100,
      ranking: rule.ranking,
      sections: formatData(rule.sections, rule.divided),
      source: rule.source,
      timeLimit: rule.timeLimit,
      type: QuizRulesType.SIMULATION,
    });
  };

  const getInitialState = () => {
    return {
      name: "",
      availableFrom: Date.now(),
      source: "IMPORT_QUESTION",
      ranking: true,
      correctAnswerPoints: 0,
      incorrectAnswerPoints: 0,
      noAnswerPoints: 0,
      divided: true,
      sections: [] as Sections[],
    };
  };

  return (
    <QuizRulesForm
      quiz={getInitialState()}
      onSubmit={handleSubmit}
      selectedSectionIndex={selectedSectionIndex}
      handleSectionSelection={handleSectionSelection}
      subjects={subjects}
      isCreate
    />
  );
};

export default QuizNewRulesPage;
