import React, { FC, memo, useState } from 'react'
import { ErrorMessage, Field, FieldProps } from 'formik'
import cn from 'classnames'
import { FormikHelpers } from 'formik/dist/types'
import { Editor } from '@tinymce/tinymce-react'
import { IAllProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor'
import { IProps } from '@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor'

import config from 'utils/config'
import FormEditorSkeleton from './components/form-editor-skeleton/form-editor-skeleton'
import styles from './form-editor-mobile.module.scss'
import setDebouncedFieldValue from 'utils/setDebouncedFieldValue'

interface FormEditorProps extends IAllProps {
  name: string
  label?: string
  height?: number
  placeholder?: string
  imageUploadHandler?: IProps['init']['images_upload_handler']
  mathtype?: boolean
}

const FormEditor: FC<FormEditorProps> = ({
                                           name,
                                           label = '',
                                           height = 300,
                                           placeholder = '',
                                           imageUploadHandler,
                                           mathtype = false,
                                           ...restProps
                                         }) => {
  const [loading, setLoading] = useState(true)

  const handleEditorChange = (
    value: string,
    setFieldValue: FormikHelpers<unknown>['setFieldValue'],
  ) => {
    setDebouncedFieldValue({name, value, setFieldValue})
  }

  return (
    <Field
      name={name}
      id={name}>
      {({field, form: {touched, errors, setFieldValue, setFieldTouched, initialValues}}: FieldProps) => {
        const isError = !!(touched[field.name] && errors[field.name])

        return (
          <div className={styles.container}>

            {label && (
              <label className={cn(styles.label, isError && styles.error)}>
                {label}
              </label>
            )}

            {loading && <FormEditorSkeleton height={height}/>}

            <div className={cn(loading && styles.hide, !loading && styles.show)}>
              <Editor
                initialValue={initialValues[field.name]}
                apiKey={config.tinyMCEKey}
                textareaName={name}
                id={name}
                onBlur={() => setFieldTouched(name, true)}
                onEditorChange={(content) => handleEditorChange(content, setFieldValue)}
                onInit={() => setLoading(false)}
                init={{
                  placeholder,
                  height,
                  menubar: false,
                  icons_url: '/js/promedtest/icons.js',
                  icons: 'promedtest',
                  skin_url: '/styles/skins/ui/promed-dark',
                  content_css: '/styles/skins/content/promed-dark/content.css',
                  plugins: 'image lists paste',
                  toolbar: `
                    formatselect fontsizeselect | align | bold italic underline strikethrough | 
                    forecolor | undo redo | bullist numlist outdent indent | removeformat
                    ${imageUploadHandler ? ' | link image' : ''}
                    ${mathtype ? ' | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry' : ''}
                  `,
                  fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
                  image_title: true,
                  image_caption: true,
                  file_picker_types: 'image',
                  images_upload_handler: imageUploadHandler,
                  external_plugins: mathtype ? {
                    'tiny_mce_wiris': 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
                  } : {},
                  language: 'it',
                  setup: (editor) => {
                    mathtype && editor.on('init', (_) => {
                      editor.focus()
                    })
                  },
                  init_instance_callback: (editor) => {
                    mathtype && editor.on('change', (_) => {
                      Array.prototype.forEach.call(editor.contentDocument.getElementsByClassName('Wirisformula'), (wirisImage) => {
                        wirisImage.src = wirisImage.src.replaceAll('%23000000', '%23FFFFFF')
                        wirisImage.src = wirisImage.src.replaceAll('3Ctext%20', '3Ctext%20fill%3D%22%23FFFFFF%22%20')
                        wirisImage.src = wirisImage.src.replaceAll('font-family%3D%22Arial%22', 'font-family%3D%22Inter%22')
                        wirisImage.src = wirisImage.src.replaceAll('font-size%3D%2216%22', 'font-size%3D%2214%22')
                      })
                    })
                  },
                } as IProps['init']}
                {...restProps}
              />
            </div>

            <ErrorMessage name={name}/>
          </div>
        )
      }}
    </Field>
  )
}

export default memo(FormEditor)
