import React from "react"
import { useParams } from "react-router-dom"
import { TopicForm } from "./TopicForm"
import PageTitle from "../layouts/dashboard/PageTitle"
import useTopic from '../../hooks/useTopic'
import useUpdateTopic from '../../hooks/useUpdateTopic'

export const TopicEditPage = () => {
  const { topicId }: any = useParams()
  const topicQuery = useTopic(topicId)
  const topic = topicQuery.data
  
  const createUpdateTopicMutation = useUpdateTopic()
  const handleUpdateTopics = (data: TopicSearchView) => createUpdateTopicMutation.mutate(data)
  
  return (
    <>
      {topic && (
        <>
          <PageTitle className="u-mb-30">Topic</PageTitle>
          <TopicForm topic={topic} handleSubmit={handleUpdateTopics}/>
        </>
      )}
    </>
  )
}
