import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useHistory, Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import SaveButton from "components/layouts/dashboard/SaveButton";
import PageTitle from "components/layouts/dashboard/PageTitle";
import { UUID } from "utils/types";
import ReactQueryKeys from "types/ReactQueryKeys";
import { useQueryClient } from "react-query";
import usePackQuizzesList from "hooks/packSimulation/usePackQuizzesList";
import usePublishPackQuiz from "hooks/packSimulation/usePublishPackQuiz";
import useUnpublishPackQuiz from "hooks/packSimulation/useUnpublishPackQuiz";

interface PackSimulationQuizzesListProps {
  packSimulationId: UUID;
}

const PackSimulationQuizzesList = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { packSimulationId } = useParams<PackSimulationQuizzesListProps>();

  const publishQuizMutation = usePublishPackQuiz();
  const unpublishQuizMutation = useUnpublishPackQuiz();

  const togglePublish = (quizId: string, published: boolean) => {
    const mutation = published ? unpublishQuizMutation : publishQuizMutation;
    mutation.mutateAsync({packSimulationId, quizId}).then(toggleRefetch);
  };

  const toggleRefetch = () =>
    queryClient.invalidateQueries([ReactQueryKeys.PACK_QUIZZES]);

  const quizzes = usePackQuizzesList(packSimulationId).data;
  const handleQuizSelection = (quizId: string) =>
    history.push(
      `/quizzes/pack-simulations/${packSimulationId}/quizzes/${quizId}`
    );

  return (
    <Grid container>
      <Grid item xs={8}>
        <div className="u-flex-container u-w-100 u-mb-30">
          <PageTitle>Pack Simulation Quizzes</PageTitle>
          <Link
            to={`/quizzes/pack-simulations/${packSimulationId}/new`}
          >
            <SaveButton className="u-bg-sunglo u-br-n">Create</SaveButton>
          </Link>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizzes &&
                quizzes.map(({ id, quizDetails, published }) => (
                  <TableRow
                    key={id}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: "60%" }}
                      onClick={(_) => handleQuizSelection(id)}
                    >
                      {quizDetails.name}
                    </TableCell>
                    <TableCell align="right">
                      <Link
                        to={`/quizzes/pack-simulations/${packSimulationId}/quizzes/${id}/edit`}
                      >
                        <Button color="primary" component="span">
                          <EditIcon />
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color={published ? "secondary" : "primary"}
                        sx={{ ml: 3, width: "auto" }}
                        style={
                          published
                            ? { padding: "6px 16px" }
                            : { padding: "6px 27px" }
                        }
                        onClick={() => togglePublish(id, published)}
                      >
                        {published ? "Unpublish" : "Publish"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PackSimulationQuizzesList;
