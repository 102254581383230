import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { UUID } from 'utils/types'
import { getOfficialNationalSimulationQuiz, OfficialNationalQuiz } from 'services/official-national-quiz-service'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useOfficialNationalSimulationQuiz = (quizId: UUID, options?: UseQueryOptions<OfficialNationalQuiz, AxiosError>)
  : UseQueryResult<OfficialNationalQuiz> => useQueryWithSnackbar<OfficialNationalQuiz>(
    [ReactQueryKeys.OFFICIAL_NATIONAL_QUIZ, quizId],
    () => getOfficialNationalSimulationQuiz(quizId), {
      enabled: !!quizId,
      ...options,
    },
  )

export default useOfficialNationalSimulationQuiz
