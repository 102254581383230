import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { createPackSimulation } from 'services/pack-simulation-quiz-service'
import { CreatePackRequest } from 'types/requests/CreatePackRequest'
import { CreatePackResponse } from 'types/responses/CreatePackResponse'
import { useHistory } from 'react-router-dom'

const useCreatePackSimulation = (options?: UseMutationOptions<CreatePackResponse, AxiosError, CreatePackRequest>)
  : UseMutationResult<CreatePackResponse, AxiosError, CreatePackRequest> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: CreatePackRequest) => createPackSimulation(data),
    {
      async onSuccess() {
        history.push("/quizzes/pack-simulations")
      },
      ...options,
    },
  )
}

export default useCreatePackSimulation
