import serverApi from './server-api-service'
import authStore from '../auth/AuthStore'
import { logInCdn, logOutCdn } from './cdn-auth-service'
import { ApiResponse } from '../utils/api-responses'

type AuthRequest = {
  username: String,
  password: String
}

type AuthResponse = {
  token: string
}

export const auth = (data: AuthRequest): Promise<void> => {
  return serverApi.post<AuthRequest, ApiResponse<AuthResponse>>('/auth', data)
    .then(({payload}) => logInCdn(payload.token)
      .then(_ => {
        localStorage.setItem('loggedIn', 'true')
        authStore.setAuthenticated(true)
      }),
    )
}

export const logout = (): Promise<void> => {
  return serverApi.post('/logout')
    .then(_ => logOutCdn()
      .then(_ => {
        localStorage.setItem('loggedIn', 'false')
        authStore.setAuthenticated(false)
      }),
    )
}
