import { isWeTest } from 'utils/constants'

const commonNavLinks = [
  {
    text: 'Questions',
    icon: '/images/icons/navbar/questions.svg',
    link: '/questions',
  },
  {
    text: 'Subjects',
    icon: '/images/icons/navbar/courses.svg',
    link: '/subjects',
  },
  {
    text: 'Courses',
    icon: '/images/icons/navbar/play_circle_white.svg',
    link: '/courses',
  },
  {
    text: 'Students',
    icon: '/images/icons/navbar/users.svg',
    link: '/users',
  },
  {
    text: 'Scale tasks',
    icon: '/images/icons/navbar/settings.svg',
    link: '/management/video-scale-tasks',
  },
  {
    text: 'Import questions',
    icon: '/images/icons/navbar/import-question.svg',
    link: '/management/import-questions',
  },

  {
    text: 'Reports',
    icon: '/images/icons/navbar/reports.svg',
    link: '/reports',
  }
]

const navLinks = isWeTest()
  ? [
      ...commonNavLinks,
      {
        text: 'Blogs',
        icon: '/images/icons/navbar/blogs.svg',
        link: '/blogs',
      },
      {
        text: 'Areas',
        icon: '/images/icons/navbar/reports.svg',
        link: '/areas',
      },
    ] : [
      {
        text: 'Quiz rules',
        icon: '/images/icons/navbar/quizzes.svg',
        link: '/quizzes/generate-rules',
      },
      ...commonNavLinks.slice(0, 4),
        {
          text: 'Monthly simulations',
          icon: '/images/icons/navbar/monthly_sim.svg',
          link: '/quizzes/monthly-simulations',
        },
        {
          text: 'Official simulations',
          icon: '/images/icons/navbar/official-simulation.svg',
          link: '/quizzes/official-national-simulations',
        },
        {
          text: 'Pack simulations',
          icon: '/images/icons/navbar/official-simulation.svg',
          link: '/quizzes/pack-simulations',
        },
      ...commonNavLinks.slice(4, 8),
      {
        text: 'Classes',
        icon: '/images/icons/navbar/classes.svg',
        link: '/classes',
      },
      {
        text: 'Reset plan',
        icon: '/images/icons/navbar/reset_plan.svg',
        link: '/reset-plan',
      },
      {
      text: 'Subscription',
      icon: '/images/icons/navbar/reset_plan.svg',
      link: '/subscriptions',
    },
  ]

export default navLinks
