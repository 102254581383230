import { useQueryClient } from 'react-query'
import { deleteImportTasks } from '../services/import-tasks-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from '../types/ReactQueryKeys'

const useDeleteTasks = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteImportTasks, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.IMPORT_TASKS)
      }
    }
  )
}

export default useDeleteTasks
