import React, { useEffect, useState } from 'react'
import { Input, Grid } from '@mui/material'
import s from './CourseForm.module.css'
import { FormikProps } from 'formik'
import * as yup from 'yup'
import { StyledLabel, StyledInput } from '../../layouts/dashboard/StyledInput'
import config from 'utils/config'
import SaveButton from '../../layouts/dashboard/SaveButton'

interface FormValuesInterface {
    name: string
    number: number
}

interface CourseFormProps {
    formik: FormikProps<FormValuesInterface>
    uploadedImageURL?: string
}

export const validationSchema = yup.object({
    number: yup.number().required().min(0),
    name: yup.string().required()
})

export const CourseForm = ({formik, uploadedImageURL}: CourseFormProps) => {

    const [imgPreview, setImgPreview] = useState<any>(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (uploadedImageURL) {
            setImgPreview(config.cdnBaseUri + uploadedImageURL)
        } else {
            setImgPreview(null)
        }
    }, [uploadedImageURL])


    const handleImageChange = (e: any) => {
        setError(false);
        const selected = e.target.files[0];
        const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
        if (selected && ALLOWED_TYPES.includes(selected.type)) {
            let reader = new FileReader();
            reader.onloadend = () => {
                // @ts-ignore
                setImgPreview(reader.result);
            };
            reader.readAsDataURL(selected);
            formik.setFieldValue("previewImage", selected)
        } else {
            setError(true);
        }
    };

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} className="u-wpx-600 u-m-0 u-bg-black-secondary">
                <Grid item xs={12} className="u-pl-48">
                  <StyledLabel>Number:</StyledLabel>
                  <StyledInput
                    name="number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} className="u-pl-48">
                  <StyledLabel>Title:</StyledLabel>
                  <br/>
                  <StyledInput
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} className="u-pl-48 u-pr-48">
                  <StyledLabel>Preview Image:</StyledLabel>

                <div className={`${s.previewWrapper} u-pt-20`}>
                    <div className={s.container}>
                        {error && <p className={s.errorMsg}>File not supported</p>}
                        <div
                            className={s.imgPreview}
                            style={{
                                background: imgPreview
                                    ? `url("${imgPreview}") no-repeat center/cover`
                                    : "grey"
                            }}
                        >
                            {imgPreview ?
                                (
                                    <button className={s.removeButton}
                                            type="button"
                                            onClick={() => {
                                                setImgPreview(null)
                                                formik.setFieldValue("deletePreviewImage", true)
                                            }}>
                                        Remove image
                                    </button>
                                )
                                :
                                (
                                    <>
                                        <p>Add an image</p>
                                        <label htmlFor="fileUpload" className={s.customFileUpload}>
                                            Choose file
                                        </label>
                                        <Input type="file" id="fileUpload" onChange={handleImageChange}/>
                                        <span>(jpg, jpeg or png)</span>
                                    </>
                                )}
                        </div>
                    </div>
                    <SaveButton className="u-wpx-120 u-mt-30 u-mb-30">Save</SaveButton>
                </div>
                </Grid>
              </Grid>
            </form>
        </div>
    );
};

