import React from 'react'
import {
  Box,
  Button,
  CSSObject,
  Drawer,
  DrawerProps, List,
  ListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  styled,
  Theme,
} from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import navLinks from '../../routes/navLinks'

export const NAVBAR_WIDTH = 160
export const NAVBAR_WIDTH_MOBILE = 183
export const NAVBAR_WIDTH_COLLAPSED = 65

const openedMixin = (theme: Theme): CSSObject => ({
  border: 0,
  height: 'calc(100% - 52px)',
  overflowY: 'scroll',
  [theme.breakpoints.down('sm')]: {
    width: NAVBAR_WIDTH_MOBILE,
  },
  [theme.breakpoints.up('sm')]: {
    width: NAVBAR_WIDTH,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedMixin = (theme: Theme): CSSObject => ({
  height: 'calc(100% - 52px)',
  overflowY: 'scroll',
  overflowX: 'hidden',
  border: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
  [theme.breakpoints.up('sm')]: {
    width: NAVBAR_WIDTH_COLLAPSED,
  },
})

interface NavBarDrawerProps extends DrawerProps {
  navCollapsed: boolean;
}

const NavBarDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'navCollapsed' })<NavBarDrawerProps>(() => (
  ({ theme, navCollapsed }) => ({
    width: NAVBAR_WIDTH,
    overflowX: 'hidden',
    '& .MuiDrawer-paper': {
      width: NAVBAR_WIDTH,
    },
    '& .MuiDrawer-docked.MuiDrawer-paper': {
      top: '50px',
    },
    '& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft': {
      top: '50px',
    },
    ...(!navCollapsed && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(navCollapsed && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })))


const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    textTransform: 'none',
    fontSize: theme.typography.fontSize,
  },
}))

type NavBarProps = {
  navCollapsed: boolean
  toggleNav: () => void
}

function NavBar({
                  navCollapsed,
                  toggleNav,
                }: NavBarProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const isSelected = (link: string) => link === location.pathname || location.pathname.includes(link)

  return (
    <NavBarDrawer
      variant="permanent"
      anchor="left"
      navCollapsed={navCollapsed}
      sx={{
        position: { xs: 'absolute', sm: 'unset' },
        '& .MuiDrawer-docked.MuiDrawer-paper': { top: { xs: '50px', md: 0 } },
      }}
    >
      <List component="nav" sx={{ p: 0 }}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <ListItem sx={{ pl: 0, height: '50px', display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-start' }}
                    component={Button} onClick={toggleNav}>
            <ListItemIcon sx={{ minWidth: '65px', justifyContent: 'center' }}>
              <img src="/images/icons/navbar/home.svg" alt={t('dashboard.menu.menu')}/>
            </ListItemIcon>
          </ListItem>
        </Box>
        {
          navLinks.map(({ text, icon, link }) => (
            <ListItem
              className="Navigation"
              sx={{ pl: 0, height: '50px' }}
              component={Button} key={link}
              onClick={() => history.push(link)}
              selected={isSelected(link)}
            >
              <ListItemIcon sx={{ minWidth: '65px', justifyContent: 'center' }}>
                <img src={icon} alt={t(text)} className="Navigation-icon"/>
              </ListItemIcon>
              <ListItemText primary={t(text)} className="Navigation-text"/>
            </ListItem>
          ))
        }
      </List>
    </NavBarDrawer>
  )
}

export default NavBar
