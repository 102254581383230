import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getLessonQuiz } from 'services/lesson-quiz-service'
import { UUID } from 'utils/types'
import { Quiz } from 'components/quizzes/quizzes'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useLessonQuiz = (
                        courseId: UUID,
                        lessonId: UUID,
                        quizId: UUID,
                        options?: UseQueryOptions<Quiz, AxiosError>
                      )
  : UseQueryResult<Quiz> => useQueryWithSnackbar<Quiz>(
    [ReactQueryKeys.LESSON_QUIZ, courseId, lessonId, quizId],
    () => getLessonQuiz(courseId, lessonId, quizId), {
      enabled: !!courseId && !!lessonId && !!quizId,
      ...options,
    }
  )

export default useLessonQuiz
