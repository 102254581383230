import React, { FC, useState } from 'react'
import { Box } from '@mui/material'

import FixedBottomModal from '../modal/FixedBottomModal'

type FixedBottomMenuItemProps = {
  menuItem: MenuItem
}

const FixedBottomMenuItem: FC<FixedBottomMenuItemProps> = ({menuItem}) => {
  const [nestedOpen, setNestedOpen] = useState(false)

  const {icon, title, nestedItems, onClick, selected} = menuItem

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    if (nestedItems) {
      setNestedOpen(true)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '20px',
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {icon && <img src={icon} className="u-pr-15" alt={title}/>}
          <span className="u-fs-14 u-lh-17">{title}</span>
          {selected && <img src="/images/icons/selected-menu.svg" className="u-pl-15" alt="Selected"/>}
        </Box>
        <Box>
          {nestedItems?.find(({selected}) => selected)?.title}
        </Box>
      </Box>
      {nestedItems && (
        <FixedBottomModal
          open={nestedOpen}
          onClose={() => setNestedOpen(false)}
        >
          <Box>{title}</Box>
          {nestedItems.map((nestedItem) => <FixedBottomMenuItem menuItem={nestedItem}/>)}
        </FixedBottomModal>
      )}
    </>
  )
}

export default FixedBottomMenuItem
