import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { Box } from '@mui/material'
import { NewSubjectSchema } from './NewSubjectSchema'
import { isWeTest } from 'utils/constants'
import { StyledLabel } from 'components/layouts/dashboard/StyledInput'

import PageTitle from '../../layouts/dashboard/PageTitle'
import FormInput from 'components/common/ui/form/form-input/form-input'
import SaveButton from '../../layouts/dashboard/SaveButton'
import useAreas from 'hooks/useAreas'
import useCreateSubject from 'hooks/subjects/useCreateSubject'
import Selector from 'components/shared/selector/Selector'
import useSelectItem from 'hooks/useSelectItem'

export const NewSubjectPage = () => {

  const queryAreas = useAreas()
  const allAreas = queryAreas.data || []
  const newSubjectMutation = useCreateSubject()
  
  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({ allItems: allAreas })
  
  const handleSubmit = (data: SubjectRequest) => {
    const formattedData = isWeTest() ? {...data, areas: selectedItemIds} : data
    return newSubjectMutation.mutate(formattedData)
  }

  return (
    <>
      <PageTitle className="u-mb-30">New Subject</PageTitle>
      <Formik
        initialValues={{ name: '', areas: [] }}
        validationSchema={NewSubjectSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box sx={{maxWidth: '600px', mb: '30px', p: '30px', backgroundColor: '#1F2021'}}>
            <StyledLabel>Name:</StyledLabel>
            <FormInput type="name" name="name" variant="standard"/>
            {isWeTest() && !isEmpty(allAreas) &&
              <Selector
                allItems={allAreas}
                selectedItem={selectedItemIds}
                onItemChecked={handleItemChecked}
                onAllChecked={handleAllChecked}
              />
            }
          </Box>
          <SaveButton type="submit">Save</SaveButton>
        </Form>
      </Formik>
    </>
  )
}
