import { Box, Grid } from '@mui/material'
import PageTitle from '../../layouts/dashboard/PageTitle'
import SaveButton from 'components/layouts/dashboard/SaveButton'
import { Link } from 'react-router-dom'
import useQuizRulesList from 'hooks/useQuizRulesList'
import useDeleteQuizRule from 'hooks/useDeleteQuizRule'
import QuizRulesList from './lists/QuizRulesList'

const QuizGenerateRulesPage = () => {

  const quizRulesList = useQuizRulesList().data
  const deleteGroupMutation = useDeleteQuizRule();
  
  const handleDeleteRule = (ruleId: string) =>
    deleteGroupMutation.mutateAsync(ruleId);

  return (
    <Grid container item xs={12}>
        <div className="u-flex-container u-w-100 u-mb-30">
        <PageTitle>Quiz rules</PageTitle>
        <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <Link to="/quizzes/generate-rules/new">
            <SaveButton className="u-bg-sunglo u-br-n u-w-100">Create quiz</SaveButton>
          </Link>
          <Link to="/quizzes/generate-rules/statistics-reports">
            <SaveButton className="u-bg-sunglo u-br-n u-w-100">Get statistics report</SaveButton>
          </Link>
          </Box>
        </div>
      <Grid className="u-w-100 u-d-flex u-flex-between u-mt-30 u-overflow-ellipsis">
        <Grid item xs={10}>
          <QuizRulesList
          quizRulesList={quizRulesList}
          onDelete={handleDeleteRule}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuizGenerateRulesPage;
