import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createClass } from 'services/class-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateClass = (options?: UseMutationOptions<CreateResponse, AxiosError, CreateClassRequest>)
  : UseMutationResult<CreateResponse, AxiosError, CreateClassRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    createClass,
    {
      async onSuccess(claz) {
        await queryClient.invalidateQueries(ReactQueryKeys.CLASSES)
        await history.push(`/classes/${claz.id}/edit`)
      },
      ...options,
    },
  )
}

export default useCreateClass
