import { AxiosError } from 'axios'
import { UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { updateQuizRulesList } from 'services/quiz-generate-rules-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { UpdateRulesListRequest } from 'types/requests/UpdateRulesRequest'
import { GetRuleData } from 'types/responses/GetRuleData'
import { UUID } from 'utils/types'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useUpdateQuizRulesList = (quizRuleId: UUID, options?: UseMutationOptions<GetRuleData, AxiosError, UpdateRulesListRequest>)
: UseMutationResult<GetRuleData, AxiosError, UpdateRulesListRequest> => {

  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data) => updateQuizRulesList(quizRuleId,data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.QUIZ_RULE)
        await history.push('/quizzes/generate-rules')
      },
      ...options
    }
)}

export default useUpdateQuizRulesList
