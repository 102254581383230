import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteTag } from 'services/simulationtags-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import ReactQueryKeys from '../types/ReactQueryKeys'

const useDeleteTag = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteTag, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.TAGS)
      },
      ...options,
    }
  )
}

export default useDeleteTag
