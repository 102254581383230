import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getPlannedDate } from 'services/monthly-simulation-quiz-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const usePlannedDate = (options?: UseQueryOptions<number, AxiosError>): UseQueryResult<number> =>
  useQueryWithSnackbar<number>(
  [ReactQueryKeys.PLANNED_DATE],
  () => getPlannedDate(),
  options,
)

export default usePlannedDate
