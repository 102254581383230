import { FC, FormEvent, useEffect, useState } from 'react'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { Alert } from '@mui/lab'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

type FileUploadProps = {
  fileType: 'image' | 'video' | 'file'
  onSubmit: (
    e: FormEvent<HTMLFormElement>,
    file: File | undefined,
    setUploadPercentage: (value: number) => void,
    setUploadComplete: (value: boolean) => void,
  ) => Promise<void>
}


const FileUpload: FC<FileUploadProps> = ({fileType, onSubmit}) => {
  const [uploadComplete, setUploadComplete] = useState(false)
  const [file, setFile] = useState<File>()
  const [uploadPercentage, setUploadPercentage] = useState<any>(0)

  useEffect(() => {
    if (uploadComplete) {
      setTimeout(() => {
        setUploadComplete(false)
        setFile(undefined)
        setUploadPercentage(0)
      }, 3000)
    }
  }, [uploadComplete])

  const onChange = (e: any) => {
    setFile(e.target.files[0])
  }

  return (
    <Box
      sx={{
        width: '100%',
        background: '#2D2E2F',
        borderRadius: '3px',
        p: '28px',
      }}
    >
      {uploadComplete && (
        <Alert severity="success" sx={{display: "flex", justifyContent: "center"}}>File was uploaded</Alert>
      )}
      <form onSubmit={(e) => onSubmit(e, file, setUploadPercentage, setUploadComplete)}>
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <label
            htmlFor="fileToUpload"
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CloudUploadIcon/>
            <Box>
              {`Choose ${fileType}`}
            </Box>
          </label>
          <input
            hidden
            type="file"
            id="fileToUpload"
            name="fileToUpload"
            onChange={onChange}
          />

          {file && !uploadComplete && (
            <Box sx={{width: '100%', mt: 3}}>
              <Typography sx={{mb: '20px', textAlign: 'center'}}>{file.name}</Typography>
              <LinearProgress variant="determinate" value={uploadPercentage}
                              sx={{height: "15px", borderRadius: "5px"}}/>
            </Box>
          )}

          {file && (
            <Button type="submit" variant="contained" sx={{mt: 3, textTransform: 'none'}}>Upload</Button>
          )}

        </Box>
      </form>
    </Box>
  )
}

export default FileUpload

