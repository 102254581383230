import React, { FC } from 'react'
import { Box } from '@mui/material'
import { findLastIndex } from 'lodash'

import { secondsToFullTimeFormat } from 'utils/duration'


type SliderValueTooltipProps = {
  percents: number
  durationSeconds: number
  videoChapters?: AddVideoChapterData[]
}

const SliderValueTooltip: FC<SliderValueTooltipProps> = ({percents, durationSeconds, videoChapters = []}) => {
  const seconds = durationSeconds * percents / 100
  const chapterIndex = findLastIndex(videoChapters, ({startSecond}) => startSecond < seconds)
  const chapter = videoChapters[chapterIndex]

  return (
    chapter ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>{chapterIndex + 1}. {chapter?.name}</Box>
        <Box>{secondsToFullTimeFormat(Math.floor(seconds))}</Box>
      </Box>
    ) : <></>
  )
}

export default SliderValueTooltip
