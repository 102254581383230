import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getSubjectSimulationTags } from 'services/subject-service'
import { UUID } from 'utils/types'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useSubjectSimTags = (subjectId: UUID, isTopicNotEmpty: boolean, options?: UseQueryOptions<SimulationTagType[], AxiosError>)
  : UseQueryResult<SimulationTagType[]> => useQueryWithSnackbar<SimulationTagType[]>(
    [ReactQueryKeys.SIMULATION_TAG, subjectId],
    () => getSubjectSimulationTags(subjectId), {
    enabled: !!subjectId && isTopicNotEmpty,
    ...options,
    }
  )
  
export default useSubjectSimTags
