import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { findMonthlySimulationQuizzes } from 'services/monthly-simulation-quiz-service'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useMontlySimulationQuizzes = (options?: UseQueryOptions<QuizShortPageableType, AxiosError>)
  : UseQueryResult<QuizShortPageableType> => useQueryWithSnackbar<QuizShortPageableType>(
    [ReactQueryKeys.MONTHLY_SIMULATION_QUIZZES],
    () => findMonthlySimulationQuizzes(),
    options,
  )

export default useMontlySimulationQuizzes
