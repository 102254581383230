import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getTopic } from '../services/topics-service'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useTopic = (topicId: string, options?: UseQueryOptions<TopicSearchView, AxiosError>)
  : UseQueryResult<TopicSearchView> => useQueryWithSnackbar<TopicSearchView>(
    [ReactQueryKeys.TOPICS, topicId],
    () => getTopic(topicId),
    {
      enabled: !!topicId,
      ...options,
    },
  )

export default useTopic
