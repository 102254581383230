import useCreatePackSimulation from "hooks/packSimulation/useCreatePackSimulation";
import useSubscriptions from "hooks/subscriptions/useSubscriptions";
import useQuizRulesList from "hooks/useQuizRulesList";
import useSelectItem from "hooks/useSelectItem";
import { CreatePackRequest } from "types/requests/CreatePackRequest";
import PageTitle from "components/layouts/dashboard/PageTitle";
import PackSimulationForm from "../pack-form/PackSimulationForm";

export const PackSimulationNew = () => {
  const newPackSimulationMutation = useCreatePackSimulation();

  const allSubscriptions = useSubscriptions().data;
  const allRules = useQuizRulesList().data;

  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({ allItems: allSubscriptions });

  const handleSubmit = (data: CreatePackRequest) => {
  const formattedData = { subscriptionIds: selectedItemIds, ...data };
   newPackSimulationMutation.mutate(formattedData);
  };

  const getInitialState = () => {
    return {
      title: "",
      code: "",
      availableFrom: Date.now(),
      quizRuleId: "",
      purchaseLink: ""
    };
  };

  return (
    <div className="u-wpx-900 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary">
      <PageTitle>Add Simulation Pack</PageTitle>
      <PackSimulationForm
        allSubscriptions={allSubscriptions}
        group={getInitialState()}
        onSubmit={handleSubmit}
        handleSubscriptionChecked={handleItemChecked}
        handleAllChecked={handleAllChecked}
        selectedSubscriptionIds={selectedItemIds}
        allRules={allRules}
      />
    </div>
  );
};
