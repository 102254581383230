type JsonData = Record<string, any> | null

export const buildFormData = (formData: FormData, data: JsonData, parentKey?: string): void => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key: string) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    formData.append('' + parentKey, data as any)
  }
}

const jsonToFormData = (data: JsonData): FormData => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export default jsonToFormData
