import { AxiosError } from 'axios'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { addLinkedQuestion, QuestionCreateModel } from 'services/question-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useAddLinkedQuestion = (
  questionId: string,
  options?: UseMutationOptions<QuestionResponse, AxiosError, QuestionCreateModel>
): UseMutationResult<QuestionResponse, AxiosError, QuestionCreateModel> => {
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: QuestionCreateModel) => addLinkedQuestion(data),
    {
      async onSuccess(data) {
        await queryClient.invalidateQueries(ReactQueryKeys.QUESTION)
        await history.push(`/questions/${questionId}/linked/${data.id}`)
      },
    ...options,
    },
)}

export default useAddLinkedQuestion
