import { UseMutationOptions } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { editSubject } from 'services/subject-service'
import { UUID } from 'utils/types'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useUpdateSubject = (subjectId: UUID, options?: UseMutationOptions<SubjectType, AxiosError, SubjectRequest>)
  : UseMutationResult<SubjectType, AxiosError, SubjectRequest> => {
  const history = useHistory()
  
  return useMutationWithSnackbar(
    (data: SubjectRequest) => editSubject(subjectId, data),
    {
      async onSuccess() {
        await history.push('/subjects')
      },
      ...options,
    },
  )
  }
  

export default useUpdateSubject
