import React, { useState, useRef, FC, useEffect, useCallback } from 'react'
import { Box, IconButton } from '@mui/material'
import { debounce, isArray } from 'lodash'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

import { VideoQualityVariant } from 'types/VideoQualityVariant'
import { VideoQualityVariantType } from 'types/VideoQualityVariantType'
import { DEBOUNCE_AUTOSAVE_MILLI, FULL_TIME_FORMAT } from 'utils/constants'
import { getDurationSecondsFormatted } from 'utils/duration'
import VideoPlayerControls from './VideoPlayerControls'
import config from 'utils/config'

type VideoPlayerProps = {
  title?: string
  titleDescription?: string
  previewMode?: boolean
  videoFileUri: string
  videoVariants?: VideoQualityVariant[]
  videoChapters?: AddVideoChapterData[]
  videoStartTimeSeconds?: number
  onWatchUpdate?: (currentVideoTime: number) => void
  invalidate?: () => void
}

const QUALITY_1080P = '1080p'
const QUALITY_720P = '720p'

const VideoPlayer: FC<VideoPlayerProps> = ({
                                             title,
                                             titleDescription,
                                             previewMode = false,
                                             videoFileUri,
                                             videoVariants = [],
                                             videoChapters = [],
                                             videoStartTimeSeconds = 0,
                                             onWatchUpdate,
                                             invalidate,
                                           }) => {
  const qualityScales = videoVariants?.length > 0 ? ['Original', QUALITY_1080P, QUALITY_720P] : []

  const getVideoVariant = (scale: string) => {
    switch (scale) {
      case QUALITY_1080P:
        return videoVariants.find(({variant}) => variant === VideoQualityVariantType.VARIANT_1080P)
      case QUALITY_720P:
        return videoVariants.find(({variant}) => variant === VideoQualityVariantType.VARIANT_720P)
      default:
        return videoVariants.find(({variant}) => variant === VideoQualityVariantType.VARIANT_ORIGINAL)
    }
  }

  const getVideoVariantUri = (scale: string) => {
    const videoVariant = getVideoVariant(scale)
    return videoVariant ? `${config.cdnBaseUri}${videoVariant.videoUri}` : videoFileUri
  }

  const [playedTime, setPlayedTime] = useState(0)
  const [qualityScale, setQualityScale] = useState(QUALITY_1080P)
  const [scaledVideoFileUri, setScaledVideoFileUri] = useState<string>()
  const [state, setState] = useState({
    playing: false,
    muted: false,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    fullscreen: false,
  })

  const {playing, muted, playbackRate, played, seeking, fullscreen} = state

  const videoRef = useRef<ReactPlayer>(null)
  const videoContainerRef = useRef<HTMLDivElement>()
  const controlsRef = useRef<HTMLDivElement>(null)

  const toggleFullScreenState = () =>
    setState((prevState) => ({...prevState, fullscreen: !prevState.fullscreen}))

  useEffect(
    () => {
      document.addEventListener("fullscreenchange", toggleFullScreenState)

      return () => {
        document.removeEventListener("fullscreenchange", toggleFullScreenState)
      }
    },
    [],
  )

  useEffect(
    () => {
      setScaledVideoFileUri(getVideoVariantUri(QUALITY_1080P))
    },
    [videoFileUri],
  )

  const handlePlayPause = () => {
    setState({...state, playing: !state.playing})
  }

  const handleMute = () => {
    setState({...state, muted: !state.muted})
  }

  const handleRewind = () => {
    videoRef.current?.seekTo(videoRef.current.getCurrentTime() - 15)
  }

  const handleFastforward = () => {
    videoRef.current?.seekTo(videoRef.current.getCurrentTime() + 15)
  }

  const handlePlaybackRate = (value: number) => {
    setState({...state, playbackRate: value})
  }

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(videoContainerRef.current)
    }
  }

  const handleProgress = (changeState: any) => {
    if (playedTime > 3 && controlsRef.current) {
      controlsRef.current.style.visibility = 'hidden'
      setPlayedTime(0)
    }

    if (controlsRef.current && controlsRef.current.style.visibility === 'visible') {
      setPlayedTime(playedTime + 1)
    }

    if (!state.seeking) {
      setState({...state, ...changeState})
    }
  }

  const getNumberOrLastArrayValue = (value: number | number[]) =>
    isArray(value)
      ? (value.length > 0 ? value[value.length - 1] : 0)
      : value

  const handleSeekChange = (evt: any, newValue: number | number[]) => {
    const value = getNumberOrLastArrayValue(newValue)
    setState({...state, played: Number(parseFloat((value / 100).toString()))})
  }

  const handleSeekMouseUp = (evt: any, newValue: number | number[]) => {
    setState({...state, seeking: false})
    const value = getNumberOrLastArrayValue(newValue)
    videoRef.current?.seekTo(value / 100)
  }

  const handleSeekMouseDown = () => {
    setState({...state, seeking: true})
  }

  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'visible'
      setPlayedTime(0)
    }
  }

  const handleQualityScaleChange = (scale: string) => {
    setQualityScale(scale)
    invalidate && invalidate()
  }

  useEffect(() => {
    setScaledVideoFileUri(getVideoVariantUri(qualityScale))
  }, [qualityScale])

  const currentTimeSeconds = videoRef.current ? videoRef.current.getCurrentTime() : 0
  const durationSeconds = videoRef.current ? videoRef.current.getDuration() : 0
  const elapsedTimeFormatted = getDurationSecondsFormatted(currentTimeSeconds, FULL_TIME_FORMAT)
  const totalDurationFormatted = getDurationSecondsFormatted(durationSeconds, FULL_TIME_FORMAT)

  useEffect(() => {
    if (videoStartTimeSeconds > 0 && durationSeconds !== 0) {
      handleSeekMouseDown()
      handleSeekMouseUp(null, videoStartTimeSeconds / durationSeconds * 100)
    }
  }, [videoStartTimeSeconds, durationSeconds])

  const onWatchUpdateDebounced = useCallback(
    debounce(
      (nextValue: number) => {
        if (onWatchUpdate) {
          onWatchUpdate(nextValue)
        }
      },
      DEBOUNCE_AUTOSAVE_MILLI,
    ),
    [],
  )

  useEffect(
    () => {
      if (playing && onWatchUpdate) {
        onWatchUpdateDebounced(currentTimeSeconds)
      }
    },
    [onWatchUpdate, playing, currentTimeSeconds],
  )

  return (
    <Box className="VideoPlayer u-position-relative" ref={videoContainerRef} sx={{display: 'flex'}}
         onMouseMove={handleMouseMove}
    >
      <ReactPlayer
        key={scaledVideoFileUri}
        ref={videoRef}
        url={scaledVideoFileUri}
        playing={playing}
        muted={muted}
        playbackRate={playbackRate}
        onProgress={handleProgress}
        width="100%"
        height="100%"
      />
      {fullscreen && <Box className="u-position-absolute u-top u-h-100 u-w-100" onClick={handlePlayPause}/>}
      {!fullscreen && (
        <IconButton className="VideoPlayer-button" onClick={handlePlayPause}
                    aria-label="next" size="large"
                    sx={{
                      position: 'absolute',
                      top: `calc(50% - 8px)`,
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}>
          {playing ?
            <img
              src="/images/icons/pause.svg"
              alt="Pause video"
            />
            : <img
              src="/images/icons/play-button-red.svg"
              alt="Play video"
            />
          }
        </IconButton>
      )}
      <VideoPlayerControls
        title={title}
        titleDescription={titleDescription}
        previewMode={previewMode}
        qualityScale={qualityScale}
        qualityScales={qualityScales}
        videoChapters={videoChapters}
        muted={muted}
        fullscreen={fullscreen}
        playing={playing}
        played={played}
        durationSeconds={durationSeconds}
        playbackRate={playbackRate}
        elapsedTimeFormatted={elapsedTimeFormatted}
        totalDurationFormatted={totalDurationFormatted}
        seeking={seeking}
        innerRef={controlsRef}
        onPlayPause={handlePlayPause}
        onRewind={handleRewind}
        onFastforward={handleFastforward}
        onMute={handleMute}
        onPlaybackRateChange={handlePlaybackRate}
        onToggleFullScreen={handleFullScreen}
        onSeek={handleSeekChange}
        onSeekMouseUp={handleSeekMouseUp}
        onSeekMouseDown={handleSeekMouseDown}
        onQualityScaleChange={handleQualityScaleChange}
      />
    </Box>
  )
}

export default VideoPlayer
