import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteSubject } from '../../services/subject-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'


const useDeleteSubject = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteSubject, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.SUBJECTS)
      },
      ...options, 
    }
  )
}
  
export default useDeleteSubject
