import React from 'react'
import { Form, Formik } from 'formik'
import { Box, Button } from '@mui/material'
import { StyledInput } from '../layouts/dashboard/StyledInput'

type TopicFormProps = {
  topic: TopicSearchView
  handleSubmit(topic: TopicSearchView): void
};

export const TopicForm = (props: TopicFormProps) => {
  const {topic, handleSubmit} = props
  return (
    <Formik
      initialValues={{name: topic.name, subjectId: topic.subjectId, id: topic.id}}
      onSubmit={handleSubmit}>
      {(props) => (
        <Form>
          <Box style={{width: "800px"}}>
            <StyledInput id="name" name="name" value={props.values.name} onChange={props.handleChange}/>
          </Box>
          <br/>
          <Button type="submit" variant="contained">Save</Button>
        </Form>
      )}
    </Formik>
  )
}
