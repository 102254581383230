import dayjs, { Dayjs } from 'dayjs'

export enum DateTimeFormats {
  MONTH = 'MMMM',
  TIME_HH_mm = 'HH:mm',
  DATE_DD_MMM = 'DD MMM',
  DATE_D_MMM_YYYY = 'D MMM YYYY',
  DATE_TIME_D_MMM_YYYY_HH_mm = 'D MMM YYYY HH:mm',
}


/**
 * @param date - date
 * @param format - format to which the incoming date will be formatted
 */
const formatDate = (date: number | string | Date | Dayjs, format = DateTimeFormats.DATE_D_MMM_YYYY): string =>
  dayjs(date).format(format)

export default formatDate
