import {Box, Button, TableCell, TableRow, Typography,} from '@mui/material'
import {AmountForm} from 'components/shared/amount-form/AmountForm'
import {SimulationTagAmountSchema} from 'components/shared/amount-form/SimulationTagAmountSchema'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {RuleTopicProbability} from "types/RuleTopicProbability";
import {FieldArrayRenderProps} from "formik";
import {TagDataRequest} from "types/requests/TagDataRequest";

type SimulationTagListProps = {
  probabilityArrayHelper: FieldArrayRenderProps,
  simulationTagsProbability: RuleTopicProbability[]
  isTagOpen: (id: string) => boolean
  onSelect: (tag: TagDataRequest) => void
  onClose: () => void
}

const SimulationTagList = ({
  probabilityArrayHelper,
  simulationTagsProbability,
  isTagOpen,
  onSelect,
  onClose,
}: SimulationTagListProps) => {

  const isDeleted = (probability: number) => probability === 0

  const handleChange = (index: number, probability) => {
    probabilityArrayHelper.form.setFieldValue(
      `${probabilityArrayHelper.name}[${index}].probability`,
      probability,
    )
  }

  const handleDelete = (tagIndex: number) => {
    handleChange(tagIndex, 0);
  }

  return (
    <>
      {simulationTagsProbability.map(({simulationTag, subjectId, probability}, index) => (
        <TableRow
          hover
          key={simulationTag.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
        >
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell component="th" scope="row"
            sx={{color: isDeleted(probability) ? '#686868' : '#FFFFFF'}}
          >
            {simulationTag.name}
          </TableCell>
          <TableCell align="right" sx={{pr: 0}}>
            {!isTagOpen(simulationTag.id) &&
              <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <Typography sx={{color: isDeleted(probability) ? '#686868' : '#FFFFFF'}}>
                  {((probability || 0) / 100).toFixed(2)}%
                </Typography>
                <Button
                  onClick={() => onSelect({ subjectId, simulationTagId: simulationTag.id, probability })}
                >
                  <EditIcon/>
                </Button>
                <Button
                  onClick={() => handleDelete(index)}
                  disabled={isDeleted(probability)}
                >
                  <DeleteIcon/>
                </Button>
              </Box>
            }
            {isTagOpen(simulationTag.id) &&
              <AmountForm
                initialValue={probability}
                validateSchema={SimulationTagAmountSchema}
                onSave={(probability) => handleChange(index, probability)}
                onClose={onClose}
              />
            }
          </TableCell>
        </TableRow>
      ))}
    </>
)}

export default SimulationTagList
