import serverApi from './server-api-service'
import { clientDataService } from './client-data-service'
import { ApiPageableResponse } from '../utils/api-responses'
import { UUID } from '../utils/types'
import { QuizType } from '../components/quizzes/constants'
import { QuizSpecialty } from '../components/questions/constants'
import { QuizQuestion } from '../components/quizzes/quizzes'

export type OfficialNationalQuiz = {
  id: UUID
  quizType: QuizType
  questions: QuizQuestion[]
  published: boolean
  createdAt: number
  modifiedAt: number
  quizDetails: QuizDetails
}

export type QuizDetails = {
  specialty: QuizSpecialty
  name: string
  year: number
}

export type OfficialNationalSimulationQuizUpsertModel = {
  specialty: string
  name: string
  year: number
}

export type OfficialNationalQuizQuestionUpsertModel = {
  number: number
  questionId: UUID
}

export const createOfficialNationalSimulationQuiz = async (data: OfficialNationalSimulationQuizUpsertModel): Promise<OfficialNationalQuizShort> =>
  await clientDataService<OfficialNationalQuizShort>({
    method: 'POST',
    url: '/admin/quizzes/official-national-simulations',
    data
  })

export const addOfficialNationalQuizQuestion = async (quizId: UUID, data: OfficialNationalQuizQuestionUpsertModel): Promise<QuizQuestion> =>
  await clientDataService<QuizQuestion>({
    method: 'POST',
    url: `/admin/quizzes/official-national-simulations/${quizId}/questions`,
    data
  })

export const publishOfficialNationalQuizQuestion = async (quizId: UUID): Promise<void> =>
  await clientDataService<void>({
    method: 'PUT',
    url: `/admin/quizzes/official-national-simulations/${quizId}/publish`,
  })

export const unpublishOfficialNationalQuizQuestion = async (quizId: UUID): Promise<void> =>
  await clientDataService<void>({
    method: 'PUT',
    url: `/admin/quizzes/official-national-simulations/${quizId}/unpublish`,
  })

export const findOfficialNationalSimulationQuizzes = (): Promise<ApiPageableResponse<OfficialNationalQuizShort>> => {
  return serverApi.get('/admin/quizzes/official-national-simulations?size=1000&sort=specialty&sort=year,desc')
}

export const getOfficialNationalSimulationQuiz = (quizId: UUID): Promise<OfficialNationalQuiz> =>
  clientDataService<OfficialNationalQuiz>({
    method: 'GET',
    url: `/admin/quizzes/official-national-simulations/${quizId}`,
  })
