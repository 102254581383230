import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getAreas } from 'services/area-service'
import { isWeTest } from 'utils/constants'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useAreas = (options?: UseQueryOptions<AreaResponseView[], AxiosError>): UseQueryResult<AreaResponseView[]> =>
  useQueryWithSnackbar<AreaResponseView[]>(
    ReactQueryKeys.AREAS,
    () => getAreas(),
    {
      enabled: isWeTest(),
      ...options,
    },
  )

export default useAreas
