import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getTag } from 'services/simulationtags-service'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'


const useTag = (tagId: string, options?: UseQueryOptions<UpdateTagView, AxiosError>)
  : UseQueryResult<UpdateTagView> => useQueryWithSnackbar<UpdateTagView>(
    [ReactQueryKeys.TAG, tagId],
    () => getTag(tagId),
    {
      enabled: !!tagId,
      ...options,
    },
  )

export default useTag
