import React, { useEffect, ChangeEvent } from 'react'
import { Field, Form, Formik } from 'formik'
import { isEmpty, isUndefined } from 'lodash'
import { IProps } from '@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor'
import { Box, Button, Grid, MenuItem, Select, Typography, TextField, ListItem, ListItemIcon, Checkbox, ListItemText } from '@mui/material'
import { UUID } from 'utils/types'
import { isWeTest } from 'utils/constants'
import { QuestionConstants, QuestionPurpose } from '../constants'
import FormEditor from '../../common/ui/form/form-editor/form-editor'
import blobToFile from 'utils/blobToFile'
import QuestionFormSchema from './QuestionFormSchema'

import useCreateQuestionImage from 'hooks/questions/useCreateQuestionImage'
import Selector from 'components/shared/selector/Selector'


type QuestionEditFormProps = {
  question: QuestionResponse
  subjects?: SubjectResponse[]
  subjectAreas?: AreaResponseView[]
  selectedArea?: string[]
  topics?: TopicType[]
  simulationTags?: SimulationTagType[]
  onUpdateSubjectOptions?: (subjectId: UUID) => void
  onAreaChecked?: (areaId: string) => void
  onAllAreasChecked?: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (question: QuestionUpsertModelRequest) => void
  canSelect?: boolean
};

const questionOptions = ['a', 'b', 'c', 'd', 'e']

const QuestionForm = ({
  question,
  subjects = [],
  subjectAreas,
  selectedArea,
  topics=[],
  simulationTags=[],
  canSelect=true,
  onUpdateSubjectOptions,
  onAreaChecked,
  onAllAreasChecked,
  onSubmit }: QuestionEditFormProps) => {

  if (isEmpty(question.options)) {
    question.options = Array(QuestionConstants.OPTIONS_EXACT_SIZE)
      .fill({})
      .map((_, index) => {
        return {
          number: index + 1,
          text: '',
          correct: false,
        }
      })
  }

  const poolQuestion = question.purpose === QuestionPurpose.QUESTIONS_POOL
  const lessonQuestion = question.purpose === QuestionPurpose.LESSON
  const officialNationalQuestion = question.purpose === QuestionPurpose.OFFICIAL_NATIONAL
  const staticPracticeQuestions = question.purpose === QuestionPurpose.STATIC_PRACTICE

  useEffect(() => {
    setTimeout(() => document.getElementById('submit')?.focus(), 3000)
  }, [])

  const createQuestionMutation = useCreateQuestionImage()

  const handleQuestionImageUpload: IProps['init']['images_upload_handler'] = function (blobInfo, success) {
    const image = blobToFile(blobInfo.blob(), blobInfo.filename())

    createQuestionMutation.mutate({
      questionId: question.id,
      image,
    } as CreateQuestionImageData, {
      onSuccess({imageUri}) {
        success(imageUri)
      },
    })
  }

  return (
    <Formik
      initialValues={{
        subjectId: question.subject?.id,
        topicId: question.topic?.id,
        simulationTagId: question.simulationTag?.id,
        ...question,
      }}
      validationSchema={QuestionFormSchema}
      onSubmit={onSubmit}
    >{(props) => (
      <Form>
        <Box>
          <div>
            <FormEditor
              label="Question"
              name="text"
              imageUploadHandler={handleQuestionImageUpload}
              mathtype={true}
            />
          </div>
          <br/>
          <div>
            <FormEditor
              label="Answer Explanation"
              name="answerExplanation"
              imageUploadHandler={handleQuestionImageUpload}
              mathtype={true}
            />
          </div>
          <br/>
          {!lessonQuestion &&
            <div>
              {props.errors.subject && props.touched.subject ?
                <div style={{color: "red"}}>{props.errors.subject}</div> : null
              }
                <Typography>Subject</Typography>
                
                {canSelect
                  ? <Select id="subjectId"
                      name="subjectId"
                      value={props.values.subjectId}
                      onChange={e => {
                        props.setFieldValue('topicId', '')
                        props.setFieldValue('simulationTagId', '')
                        props.handleChange(e)
                        onUpdateSubjectOptions && onUpdateSubjectOptions(e.target.value as UUID)
                      }}>
                        {!isEmpty(subjects) && subjects.map(s =>
                          <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>,
                        )}
                      </Select>
                  : <TextField defaultValue={!isUndefined(question.subject.name) ? question.subject.name : ''} InputProps={{readOnly: true}}/>
              }
            <br/>
          </div>}
          {(!lessonQuestion) && !isWeTest() &&
            <div>
              <Typography>Topic</Typography>
                {canSelect
                  ? <Select id="topicId"
                      name="topicId"
                      value={props.values.topicId}
                      onChange={props.handleChange}>
                        {!isEmpty(topics) && topics.map(t =>
                          <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>,
                        )}
                    </Select>
                  : <TextField defaultValue={question.topic.name || ''} InputProps={{readOnly: true}}/>
                }
            <br/>
          </div>}
          {poolQuestion && !isWeTest() &&
            <div>
              <Typography>Simulation Tag</Typography>
                {canSelect
                  ? <Select id="simulationTagId"
                      name="simulationTagId"
                      value={props.values.simulationTagId}
                      onChange={props.handleChange}>
                        {!isEmpty(simulationTags) && simulationTags.map(t =>
                          <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>,
                        )}
                    </Select>
                  : <TextField defaultValue={question.simulationTag.name || ''} InputProps={{readOnly: true}}/>
                }
            <br/>
            </div>}
          {!officialNationalQuestion && !staticPracticeQuestions && !isWeTest() &&
            <div>
              <Typography>Difficulty</Typography>
              <Select id="difficulty"
                      name="difficulty"
                      value={props.values.difficulty}
                      onChange={props.handleChange}>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            <br/>
          </div>}
          {props.errors.options && props.touched.options ?
            <div style={{color: "red"}}>{props.errors.options}</div> : null
          }
          <br/>
          {question.options.map((option, index) =>
            <Grid container key={option.number} spacing={1}>
              <Grid item style={{textAlign: "right"}}>
                <Typography variant="inherit">{questionOptions[index]}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Field name={`options[${index}].correct`}
                       type="checkbox"/>
              </Grid>
              <Grid item xs={10}>
                <FormEditor
                  name={`options.${index}.text`}
                  initialValue={option.text}
                  imageUploadHandler={handleQuestionImageUpload}
                  mathtype={true}
                />
                <br/>
              </Grid>
            </Grid>,
          )}
            {canSelect && subjectAreas
              ? onAreaChecked && onAllAreasChecked &&
                <Selector
                  allItems={subjectAreas}
                  selectedItem={selectedArea}
                  onItemChecked={onAreaChecked}
                  onAllChecked={onAllAreasChecked}
              />
              : subjectAreas?.filter(({ id }) => selectedArea?.includes(id)).map(({ name, id }) => (
                <ListItem key={id}>
                  <ListItemIcon>
                    <Checkbox edge="start" checked disabled />
                  </ListItemIcon>
                  <ListItemText id={id} primary={name || ''} />
                </ListItem>      
              ))
          }  
        </Box>
        <br/>
        <Button id="submit" type="submit" variant="contained">Save</Button>
      </Form>
    )}
    </Formik>
  )
}

export default QuestionForm
