import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getCourse } from 'services/courses-service'
import { UUID } from 'utils/types'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useCourse = (courseId: UUID, options?: UseQueryOptions<CourseView, AxiosError>)
  : UseQueryResult<CourseView> =>
  useQueryWithSnackbar<CourseView>(
    ReactQueryKeys.COURSE,
    () => getCourse(courseId), {
      enabled: !!courseId,
      ...options,
    },
  )

export default useCourse
