export enum QuizType {
  MONTHLY_SIMULATION = 'MONTHLY_SIMULATION',
  RANDOM_SIMULATION = 'RANDOM_SIMULATION',
  OFFICIAL_NATIONAL_SIMULATION = 'OFFICIAL_NATIONAL_SIMULATION',
  LESSON = 'LESSON',
  STATIC_PRACTICE = 'STATIC_PRACTICE',
  PRACTICE = 'PRACTICE'
}

export enum QuizRulesType {
  SIMULATION = 'SIMULATION',
  EXERCITATION = 'EXERCISE'
}

export enum QuizRulesSourceType {
  QUESTION_POOL = 'QUESTION_POOL',
  IMPORT_QUESTION = 'IMPORT_QUESTION'
}

export const maxCountQuestion = 60
