import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { StyledLabel } from "components/layouts/dashboard/StyledInput";
import { FieldArrayRenderProps, FormikProps, FormikValues } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { UUID } from "../../../../utils/types";

type RulesSectionsFormProps = {
  sectionsArrayHelper: FieldArrayRenderProps;
  subjects: SubjectResponse[];
  formProps: FormikProps<FormikValues>;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
};

const SectionsForm = ({
  sectionsArrayHelper,
  subjects,
  setIsOpen,
  formProps,
}: RulesSectionsFormProps) => {
  const [selectedSubjects, setSelectedSubjects] = useState<UUID[]>([]);
  const validationQuestions = (questions) => questions === '0'
  const newSectionPath = `sections[${
    sectionsArrayHelper.form.values.sections.length - 1
  }]`;

  useEffect(() => {
    sectionsArrayHelper.push({
      name: "",
      timeLimit: null,
      questionsAmount: null,
      subjects: [],
    });
  }, []);
  const lockedSubjects = formProps.values.sections.flatMap((section) =>
    section.subjects.map((subject) => subject.subject.id)
  );
  const hasEmptyFields: boolean = formProps.values.sections.flatMap(({ name, timeLimit, questionsAmount }) =>
      isEmpty(name) ||
      (formProps.values.divided === true && isEmpty(timeLimit)) ||
      isEmpty(questionsAmount) || validationQuestions(questionsAmount) ||
      isEmpty(selectedSubjects)

  )[0];

  const handleAddSection = () => {
    const sectionSubjects = subjects
      .filter((subject) => selectedSubjects.includes(subject.id))
      .map((subject) => ({ subject: subject, tagProbabilities: [] }));

    setIsOpen(false);
    formProps.setFieldValue(`${newSectionPath}.subjects`, sectionSubjects);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedSubjects>) => {
    const {
      target: { value },
    } = event;
    setSelectedSubjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCancelClick = () => {
    sectionsArrayHelper.pop();
    setIsOpen(false);
  };

  return (
    <Box mb={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box ml={10} mr={10}>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledLabel>Name</StyledLabel>
            <TextField
              type="text"
              placeholder="Fill name"
              autoComplete="off"
              sx={{ width: "300px" }}
              onChange={(e) => {
                sectionsArrayHelper.form.setFieldValue(
                  `${newSectionPath}.name`,
                  e.target.value
                );
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <>
              {formProps.values.divided === true && (
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <StyledLabel>Time Limit</StyledLabel>
                  <TextField
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    inputProps={{ min: 1, pattern: "[0-9]*" }}
                    onChange={(e) => {
                      sectionsArrayHelper.form.setFieldValue(
                        `${newSectionPath}.timeLimit`,
                        e.target.value
                      );
                    }}
                  />
                </Box>
              )}
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledLabel>Questions</StyledLabel>
                <TextField
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  name={`${newSectionPath}.questionsAmount`}
                  size="small"
                  inputProps={{ min: 1, pattern: "[0-9]*" }}
                  onChange={(e) =>
                    sectionsArrayHelper.form.setFieldValue(
                      `${newSectionPath}.questionsAmount`,
                      e.target.value
                    )
                  }
                />
              </Box>
            </>
            <Box
              mt={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledLabel>Subjects</StyledLabel>
              <Select
                multiple
                value={selectedSubjects}
                onChange={handleChange}
                sx={{ width: "300px" }}
              >
                {subjects.map((subject) => {
                  return (
                    <MenuItem
                      key={subject.id}
                      disabled={lockedSubjects.includes(subject.id)}
                      selected={selectedSubjects.includes(subject.id)}
                      value={subject.id}
                    >
                      {subject.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box ml={10}>
          <Button
            type="submit"
            variant="contained"
            onClick={handleAddSection}
            disabled={hasEmptyFields}
          >
            Create
          </Button>
        </Box>
        <Box mr={10}>
          <Button type="button" variant="outlined" onClick={handleCancelClick}>
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionsForm;
