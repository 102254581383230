import { clientDataService } from './client-data-service'
import { ImportQuestionsRequest } from 'types/requests/ImportQuestionsRequest'
import { ImportTask } from '../components/management/import/questions/ImportTask'
import { isWeTest } from 'utils/constants'

const baseURL = isWeTest() ? '/admin/questions' : '/admin/quizzes'

export const getImportTasks = (): Promise<ImportTask[]> =>
  clientDataService<ImportTask[]>({
    method: 'GET',
    url: '/admin/tasks'
  })

export const deleteImportTasks = (): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: '/admin/tasks'
  })

export const importQuestionsPool = ({data, link, config}: ImportQuestionsRequest): Promise<ImportTask[]> =>
  clientDataService<ImportTask[]>({
    method: 'POST',
    url: `${baseURL}/${link}`,
    data,
    onUploadProgress: config.onUploadProgress
  })
