import { UseMutationOptions } from 'react-query'
import { useSnackbar } from 'notistack'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { resetUserPassword, StudentResetPasswordResult } from 'services/users-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useResetPassword = (options?: UseMutationOptions<StudentResetPasswordResult, AxiosError, string>)
  : UseMutationResult<StudentResetPasswordResult, AxiosError, string> => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutationWithSnackbar(
    resetUserPassword, {
      async onSuccess(data) {
        enqueueSnackbar(
          `Student new password - '${data.password}'`,
          { variant: 'success' },
        )
      },
      ...options,
    }
  )}

export default useResetPassword
