import React, { useState } from "react";
import { Form, Formik } from "formik";
import { RouteComponentProps } from "react-router";
import { Box, Button, TextField, Typography } from "@mui/material";

import PageTitle from "components/layouts/dashboard/PageTitle";
import { StyledInput } from "components/layouts/dashboard/StyledInput";
import { object, string } from "yup";
import { useHistory } from "react-router-dom";
import usePackQuiz from "hooks/packSimulation/usePackQuiz";
import useEditPackQuiz from "hooks/packSimulation/useEditPackQuiz";
import { EditPackQuizRequest } from "services/pack-quizzes-service";

export const EditQuizSchema = object().shape({
  title: string().required().trim(),
});

export const PackSimulationQuizEdit = (
  props: RouteComponentProps<{ packSimulationId: string; quizId: string }>
) => {
  const history = useHistory();
  const quizId: string = props.match.params.quizId;
  const packSimulationId: string = props.match.params.packSimulationId;

  const updateQuizMutation = useEditPackQuiz(packSimulationId, quizId, {
    async onSuccess() {
      await history.push(`/quizzes/pack-simulations/${packSimulationId}/quizzes`);
    },
  });

  const valuesQuiz = usePackQuiz(packSimulationId, quizId).data;

  const getInitialState = (title: string)  => {
    return {
      title: title,
    };
  };

  const onEdit = (data: EditPackQuizRequest) => {
    return updateQuizMutation.mutate(data);
  };

  return (
    <>
      <PageTitle className="u-mb-30">Edit Quiz</PageTitle>
      {valuesQuiz && (
        <Formik
          initialValues={{ ...getInitialState(valuesQuiz.quizDetails.name) }}
          validateOnBlur
          validateOnChange={false}
          validationSchema={EditQuizSchema}
          onSubmit={onEdit}
        >
          {(props) => (
            <Form>
              <Box
                className="u-wpx-600 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography className="u-mt-10">Name</Typography>
                <StyledInput
                  type="text"
                  name="title"
                  value={props.values.title}
                  onChange={props.handleChange}
                  sx={{ fontSize: "20px" }}
                />
                <Button sx={{ mt: 5 }} variant={"contained"} type="submit">
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
