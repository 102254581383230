import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { createQuizRule } from 'services/quiz-generate-rules-service'
import { CreateQuizRuleRequest } from 'types/requests/CreateQuizRuleRequest'

const useCreateQuizRule = (
  options?: UseMutationOptions<CreateQuizRuleResponse, AxiosError, CreateQuizRuleRequest>
): UseMutationResult<CreateQuizRuleResponse, AxiosError, CreateQuizRuleRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: CreateQuizRuleRequest) => createQuizRule(data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.QUIZ_RULES)
        await history.push('/quizzes/generate-rules')
      },
    ...options,
    },
  )
}

export default useCreateQuizRule
