import { Field, Form, Formik } from 'formik'
import { MenuItem, Select } from '@mui/material'
import { isEmpty } from 'lodash'
import PageTitle from '../layouts/dashboard/PageTitle'
import SaveButton from '../layouts/dashboard/SaveButton'
import useCreateTopic from '../../hooks/useCreateTopic'
import useSubjects from 'hooks/subjects/useSubjects'


export const NewTopicPage = () => {
  const subjects = useSubjects().data || []

  const createTopicMutation = useCreateTopic()
  const handleSubmit = (data: CreateTopicRequest) => createTopicMutation.mutate(data)

  return (
    <>
      <PageTitle className="u-mb-30">New Topic</PageTitle>
      <Formik
        initialValues={{name: '', subjectId: ''}}
        onSubmit={handleSubmit}
      >{(props => (
        <Form className="u-flex-container u-wpx-600">
          <Field type="name" name="name" className="u-hpx-40 u-w-50"/>
          <Select id="subjectId"
                  name="subjectId"
                  value={props.values.subjectId}
                  onChange={props.handleChange}
                  className="u-hpx-40 u-wpx-180">
            {!isEmpty(subjects) && subjects.map(subject => <MenuItem value={subject.id}>{subject.name}</MenuItem>)}
          </Select>
          <SaveButton type="submit">Save</SaveButton>
        </Form>
      ))}

      </Formik>
    </>
  )
}
