import { styled } from '@mui/material'

const PageWrapper = styled('section')(({theme}) => ({
    ...theme.mixins.toolbar,
    height: '100%', 
    width: '100%',
    overflow: 'auto',
    paddingTop:'90px', 
    paddingLeft: '50px', 
    paddingRight:'50px',
    paddingBottom: '60px'
  }))

  export default PageWrapper;
