import React from 'react'
import { isEmpty } from 'lodash'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import formatDate, { DateTimeFormats } from 'utils/date-format'
import { useHistory, Link } from 'react-router-dom'

import SaveButton from 'components/layouts/dashboard/SaveButton'
import PageTitle from 'components/layouts/dashboard/PageTitle'
import useOfficialNationalSimulationQuizzes from 'hooks/officialNationalSimulation/useOfficialNationalSimulationQuizzes'
import usePublishOfficialNationalQuiz from 'hooks/officialNationalSimulation/usePublishOfficialNationalQuiz'
import useUnpublishOfficialNationalQuiz from 'hooks/officialNationalSimulation/useUnpublishOfficialNationalQuiz'

const OfficialNationalSimulationsListPage = () => {
  const history = useHistory()
  const quizzes = useOfficialNationalSimulationQuizzes().data?.payload.content || []
  
  const publishQuizMutation = usePublishOfficialNationalQuiz()
  const unpublishQuizMutation = useUnpublishOfficialNationalQuiz()

  const handleQuizSelection = (quizId: string) => history.push(`/quizzes/official-national-simulations/${quizId}`)

  const toggleQuizPublish = (id: string, published: boolean) => {
    const mutation = published ? unpublishQuizMutation : publishQuizMutation
    mutation.mutate(id)
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <div className="u-flex-container u-w-100 u-mb-30">
          <PageTitle>Official simulation</PageTitle>
          <Link to="/quizzes/official-national-simulations/questions/new">
            <SaveButton className="u-bg-sunglo u-br-n">Create</SaveButton>
          </Link>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Specialty</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(quizzes) &&
                quizzes.map(({ id, quizDetails, createdAt, published }: OfficialNationalQuizShort) =>
                <TableRow key={id} hover sx={{'&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}>
                  <TableCell component="th" scope="row" onClick={_ => handleQuizSelection(id)}>
                    {quizDetails.specialty || ''}
                  </TableCell>
                  <TableCell component="th" scope="row" onClick={_ => handleQuizSelection(id)}>
                    {quizDetails.year || 0}
                  </TableCell>
                  <TableCell component="th" scope="row" onClick={_ => handleQuizSelection(id)}>
                    {formatDate(createdAt, DateTimeFormats.DATE_TIME_D_MMM_YYYY_HH_mm)}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      size="small"
                      color={published ? 'secondary' : 'primary'}
                      sx={{ml: 3, width: 'auto'}}
                      onClick={() => toggleQuizPublish(id, published)}
                    >
                      {published ? 'Unpublish' : 'Publish'}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default OfficialNationalSimulationsListPage;
