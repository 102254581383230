import { AxiosError } from 'axios'
import { UseQueryOptions, UseQueryResult } from 'react-query'
import { QuestionSlimListPageable } from 'types/responses/QuestionSlimListPageable'
import { getQuestions, GetQuestionsQueryParams  } from 'services/question-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useQuestions = (
  data: GetQuestionsQueryParams,
  options?: UseQueryOptions<QuestionSlimListPageable, AxiosError>
): UseQueryResult<QuestionSlimListPageable> => useQueryWithSnackbar<QuestionSlimListPageable>(
    [ReactQueryKeys.QUESTIONS, data.page, data.size, data.text, data.answerExplanation],
    () => getQuestions(data),
    options,
)

export default useQuestions
