import React, { FC } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { QuizShort } from '../../quizzes'
import formatDate, { DateTimeFormats } from 'utils/date-format'


type MonthlySimulationsListProps = {
  quizzes: QuizShort[]
  handleQuizSelection: (id: string) => void
}

const MonthlySimulationsList: FC<MonthlySimulationsListProps> = ({quizzes, handleQuizSelection}) => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Month</TableCell>
          <TableCell>Created</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {quizzes && quizzes.map(quiz =>
          <TableRow
            hover
            key={quiz.id}
            onClick={_ => handleQuizSelection(quiz.id)}
            sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            cursor: "pointer"
          }}>
            <TableCell component="th" scope="row">
              {formatDate(quiz.createdAt, DateTimeFormats.MONTH)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatDate(quiz.createdAt, DateTimeFormats.DATE_TIME_D_MMM_YYYY_HH_mm)}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
  )

export default MonthlySimulationsList
