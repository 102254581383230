import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  TextField,
  Typography
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { isEmpty } from 'lodash'
import { confirmationSimulation } from '../../../layouts/Modal/contentModal'
import DateAdapter from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import PageTitle from '../../../layouts/dashboard/PageTitle'
import SaveButton from '../../../layouts/dashboard/SaveButton'
import Modal from '../../../layouts/Modal/Modal'
import usePlannedDate from 'hooks/monthlySimulation/usePlannedDate'
import useMontlySimulationQuizzes from 'hooks/monthlySimulation/useMontlySimulationQuizzes'
import useGenerateMonthlySimulationQuiz from 'hooks/monthlySimulation/useGenerateMonthlySimulationQuiz'
import MonthlySimulationsList from './MonthlySimulationsList'
import useUpdatePlannedDate from 'hooks/monthlySimulation/useUpdatePlannedDate'


const MonthlySimulationsListPage = () => {
  const history = useHistory()
  const quizzes = useMontlySimulationQuizzes().data?.content || []
  const dateQuery = usePlannedDate()

  const [plannedDate, setPlannedDate] = useState<Dayjs | null>(dayjs(dateQuery.data))
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const generateMonthlyQuizMutation = useGenerateMonthlySimulationQuiz()
  const updatePlannedDateMutation = useUpdatePlannedDate()
  
  const handleCreateBlog = () => generateMonthlyQuizMutation.mutateAsync()
    .then(_ => setConfirmDialogOpen(false))
  
  const handleUpdatePlannedDate = () => plannedDate && updatePlannedDateMutation.mutate(+plannedDate)

  const handleQuizSelection = (quizId: string ) => history.push(`/quizzes/monthly-simulations/${quizId}`)

  return (
    <>
    <Grid container>
      <div className="u-flex-container u-w-100 u-mb-30">
        <PageTitle>Monthly simulation</PageTitle>
        <SaveButton 
          onClick={() => setConfirmDialogOpen(true)}
          className="u-bg-sunglo u-br-n">
            TEST Generate new quiz
        </SaveButton>
      </div>
      <div className="u-w-vw">
        <Grid item xs={3} sx={{ alignSelf: 'start', display: 'flex', flexDirection: 'column' }}>
          {!plannedDate && <Typography mb={2} sx={{color: 'red'}}>Specify next planned date</Typography>}
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="Monthly simulation generate date"
              value={plannedDate}
              minDate={dayjs().startOf('day')}
              onChange={(newValue: Dayjs | null) => newValue && setPlannedDate(newValue.startOf('day'))}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <SaveButton onClick={handleUpdatePlannedDate}>Save planned date</SaveButton>
        </Grid>
        <Grid item xs={5} className="u-pt-30">      
          {!isEmpty(quizzes) && <MonthlySimulationsList quizzes={quizzes} handleQuizSelection={handleQuizSelection}/>}
        </Grid>
        </div>
    </Grid>
    <Modal
      confirmOpen={confirmDialogOpen}
      onAction={handleCreateBlog}
      onClose={() => setConfirmDialogOpen(false)}
      content={confirmationSimulation}/>
  </>
  )
};

export default MonthlySimulationsListPage;
