import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { UUID } from 'utils/types'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getPackQuiz, PackQuiz } from 'services/pack-quizzes-service'

const usePackQuiz = (packSimulationId: UUID, quizId: UUID, options?: UseQueryOptions<PackQuiz, AxiosError>)
  : UseQueryResult<PackQuiz> => useQueryWithSnackbar<PackQuiz>(
    [ReactQueryKeys.PACK_QUIZ, quizId],
    () => getPackQuiz(packSimulationId, quizId), {
      enabled: !!quizId,
      ...options,
    },
  )

export default usePackQuiz
