import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { QuizRulesListType} from 'types/QuizRulesList'
import { getQuizRulesList } from 'services/quiz-generate-rules-service'

const useQuizRulesList = (options?: UseQueryOptions<QuizRulesListType[], AxiosError>)
  : UseQueryResult<QuizRulesListType[]> => useQueryWithSnackbar<QuizRulesListType[]>(
    [ReactQueryKeys.QUIZ_RULES],
    () => getQuizRulesList(),
    options,
  )

export default useQuizRulesList
