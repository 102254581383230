import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deletePackSimulation } from 'services/pack-simulation-quiz-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useDeletePackSimulation = (options?: UseMutationOptions<void, AxiosError, string>)
    : UseMutationResult<void, AxiosError, string> => {
    const queryClient = useQueryClient()

    return useMutationWithSnackbar(
        deletePackSimulation, {
            async onSuccess() {
                await queryClient.invalidateQueries(ReactQueryKeys.PACK_SIMULATIONS)
            },
            ...options,
        }
    )
}

export default useDeletePackSimulation
