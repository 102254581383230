import React, { FC } from 'react'

import FixedBottomModal from '../modal/FixedBottomModal'
import FixedBottomMenuItem from './FixedBottomMenuItem'

type FixedBottomNestedMenuProps = {
  open: boolean
  onClose: () => void
  menuItems: (MenuItem | false)[]
}

const FixedBottomNestedMenu: FC<FixedBottomNestedMenuProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 menuItems,
                                                               }) => {
  return (
    <FixedBottomModal
      open={open}
      onClose={onClose}
    >
      {menuItems.map((menuItem) => menuItem && <FixedBottomMenuItem key={menuItem.title} menuItem={menuItem}/>)}
    </FixedBottomModal>
  )
}

export default FixedBottomNestedMenu
