import { useHistory } from 'react-router-dom'
import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { addSubject } from 'services/subject-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateSubject = (options?: UseMutationOptions<SubjectType, AxiosError, SubjectRequest>)
  : UseMutationResult<SubjectType, AxiosError, SubjectRequest> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: SubjectRequest) => addSubject(data),
    {
      async onSuccess() {
        await history.push('/subjects')
      },
      ...options,
    },
  )
}

export default useCreateSubject
