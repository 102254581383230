import { Form, Formik } from "formik";
import { Alert, Box, Button } from "@mui/material";
import { StyledInput, StyledLabel, } from "components/layouts/dashboard/StyledInput";
import { object, string } from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CreatePackQuiz } from "services/pack-quizzes-service";

const PackQuizCreateFormSchema = object().shape({
  title: string().required(),
});

type QuizFormProps = {
  quiz?: CreatePackQuiz;
  onSubmit(quiz: CreatePackQuiz): void;
};

const QuizForm = ({ quiz, onSubmit }: QuizFormProps) => {
  return (
    <Formik
      initialValues={{
        ...quiz,
        title: quiz ? quiz.title : "",
      }}
      validationSchema={PackQuizCreateFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Box style={{ width: "800px" }}>
            <div className="u-flex-container">
              <StyledLabel>Name</StyledLabel>
              <StyledInput
                type="text"
                name="title"
                placeholder="Fill name"
                value={props.values.title}
                onChange={props.handleChange}
              />
              {props.errors.title && props.touched.title ? (
                <div style={{ color: "red" }}>{props.errors.title}</div>
              ) : null}
            </div>

            <Box className="u-flex-container u-mt-60 u-mb-30">
              <StyledLabel>Import quiz questions (optional)</StyledLabel>
              <Box
                sx={{
                  width: "200px",
                  height: "100px",
                  background: "#2D2E2F",
                  borderRadius: "3px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="questionsData"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {props.values.questionsData ? (
                    <Alert
                      severity="success"
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      File was selected
                    </Alert>
                  ) : (
                    <>
                      <CloudUploadIcon />
                      <Box>Choose file</Box>
                    </>
                  )}
                </label>
              </Box>
              <input
                hidden
                type="file"
                id="questionsData"
                name="questionsData"
                onChange={(e) => {
                  const file = e.target?.files?.[0];
                  if (file) props.setFieldValue("questionsData", file);
                }}
              />
            </Box>
          </Box>
          <Button type="submit" variant="contained">
            Create
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default QuizForm;
