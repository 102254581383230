import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getSimulationTags } from 'services/simulationtags-service'
import { TagSearchView } from 'types/TagSearchView'
import { isWeTest } from 'utils/constants'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useTags = (subjectId: string, options?: UseQueryOptions<TagSearchView[], AxiosError>)
  : UseQueryResult<TagSearchView[]> => useQueryWithSnackbar<TagSearchView[]>(
    [ReactQueryKeys.TAGS, subjectId],
    () => getSimulationTags(subjectId),
    {
      enabled: !!subjectId && !isWeTest(),
      ...options,
    },
  ) 

export default useTags
