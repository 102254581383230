import { debounce } from 'lodash'
import { FormikHelpers } from 'formik/dist/types'

type SetDebouncedFieldValueParams = {
  setFieldValue: FormikHelpers<unknown>['setFieldValue']
  name: string
  value: unknown
}

const setDebouncedFieldValue =
  debounce(({name, value, setFieldValue}: SetDebouncedFieldValueParams): void => {
    setFieldValue(name, value)
  }, 200)

export default setDebouncedFieldValue
