import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { CircularProgress, Grid } from '@mui/material'

import PageTitle from 'components/layouts/dashboard/PageTitle'
import BlogForm from '../blog-form/BlogForm'
import useAreas from 'hooks/useAreas'
import useBlog from 'hooks/useBlog'
import usePatchBlog from 'hooks/usePatchBlog'
import Selector from 'components/shared/selector/Selector'
import useSelectItem from 'hooks/useSelectItem'

export const BlogEditPage = () => {
  const { blogId }: any = useParams()
  const history = useHistory()

  const areasQuery = useAreas()
  const blogQuery = useBlog(blogId)

  const patchBlogMutation = usePatchBlog()

  const areas = areasQuery.data || []
  const blog = blogQuery.data

  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem(
    {
      allItems: areas,
      initialSelectedItemsIds: blog?.areas,
    })

  const handleSubmit = (data: BlogUpdateRequest) => patchBlogMutation.mutate({
    ...data,
    areasIds: selectedItemIds.toString(),
  }, {
    onSuccess() {
      history.push('/blogs')
    },
  })

  return (
    <>
      <PageTitle className="u-mb-30">Blog</PageTitle>
      <Grid container spacing={{xs: '20px', md: '40px'}}>
        <Grid item xs={12} md={8}>
          {blogQuery.isLoading && <CircularProgress/>}
          {blog && <BlogForm blog={blog} onSubmit={handleSubmit}/>}
        </Grid>
        <Grid item xs={12} md={4}>
          {areasQuery.isLoading && <CircularProgress/>}
          {!isEmpty(areas) && (
            <Selector
              allItems={areas}
              selectedItem={selectedItemIds}
              onItemChecked={handleItemChecked}
              onAllChecked={handleAllChecked}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
