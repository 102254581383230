import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getLesson } from 'services/lessons-service'
import { UUID } from 'utils/types'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useLesson = (courseId: UUID, lessonId: UUID, options?: UseQueryOptions<LessonType, AxiosError>): UseQueryResult<LessonType> =>
  useQueryWithSnackbar<LessonType>(
    [ReactQueryKeys.LESSON, courseId, lessonId],
    () => getLesson(courseId, lessonId), {
      enabled: !!courseId && !!lessonId,
      ...options,
    },
  )

export default useLesson
