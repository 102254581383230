import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { publishBlog } from 'services/blog-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const usePublishBlog = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> =>
  useMutationWithSnackbar(
    publishBlog,
    options,
  )

export default usePublishBlog
