import { AxiosError } from 'axios'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { createMainQuestion  } from 'services/question-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateLinkedQuestion = (
  options?: UseMutationOptions<void, AxiosError, string>
): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    createMainQuestion,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.QUESTION)
      },
      ...options,
    },
    'Main question was created successfully'
)}

export default useCreateLinkedQuestion
