import { useState, ChangeEvent, useEffect } from "react";

interface useSelectItemsResult {
  handleItemChecked: (planId: string) => void;
  handleAllChecked: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedItemIds: string[];
  handleClear: () => void
}

type SelectebleItem = {
  id: string
  name?: string
  title?: string
};

interface useSelectItemsOptions {
  allItems?: SelectebleItem[];
  initialSelectedItemsIds?: string[];
}

const useSelectItems = ({
  allItems = [],
  initialSelectedItemsIds,
}: useSelectItemsOptions): useSelectItemsResult => {
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  useEffect(() => {
    initialSelectedItemsIds && setSelectedItemIds(initialSelectedItemsIds);
  }, [initialSelectedItemsIds]);

  const handleItemChecked = (id: string) => {
    const  checkItem = selectedItemIds.find((itemId) => itemId === id);
    if ( checkItem) {
      setSelectedItemIds(selectedItemIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedItemIds((prevState) => [...prevState, id]);
    }
  };

  const handleAllChecked = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "all") {
      const onlyItemsId = allItems.map((item) => item.id);

      setSelectedItemIds(
        selectedItemIds.length === allItems.length
          ? ([] as string[])
          : onlyItemsId
      );
      return;
    }
  };

  const handleClear = () => {
    setSelectedItemIds([]);
  };

  return { handleItemChecked, handleAllChecked, selectedItemIds, handleClear };
};

export default useSelectItems;
