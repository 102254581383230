import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createOfficialNationalSimulationQuiz, OfficialNationalSimulationQuizUpsertModel } from 'services/official-national-quiz-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateOfficialNational = (
  options?: UseMutationOptions<OfficialNationalQuizShort, AxiosError, OfficialNationalSimulationQuizUpsertModel>
): UseMutationResult<OfficialNationalQuizShort, AxiosError, OfficialNationalSimulationQuizUpsertModel> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: OfficialNationalSimulationQuizUpsertModel) => createOfficialNationalSimulationQuiz(data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.OFFICIAL_NATIONAL_QUIZZES)
        await history.push('/quizzes/official-national-simulations')
      },
    ...options,
    },
  )
}

export default useCreateOfficialNational
