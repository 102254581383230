import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getCourses } from 'services/courses-service'
import { Page } from 'utils/api-responses'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useCourses = (
  data: GetCoursesRequest,
  options?: UseQueryOptions<Page<CourseView>, AxiosError>
)
  : UseQueryResult<Page<CourseView>> =>
  useQueryWithSnackbar<Page<CourseView>>(
    [ReactQueryKeys.COURSES, data.page, data.size],
    () => getCourses(data),
    options,
  )

export default useCourses
