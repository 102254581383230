import { useHistory } from 'react-router-dom'
import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createLesson } from 'services/lessons-service'
import { UUID } from 'utils/types'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useCreateLesson = (courseId: UUID, options?: UseMutationOptions<LessonType, AxiosError, LessonUpdateRequest>)
  : UseMutationResult<LessonType, AxiosError, LessonUpdateRequest> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: LessonUpdateRequest) => createLesson(courseId, data),
    {
      async onSuccess(lesson) {
        await history.push(`/courses/${courseId}/lessons/${lesson.id}`)
      },
      ...options,
    },
  )
}

export default useCreateLesson
