export enum QuestionConstants {
  OPTIONS_EXACT_SIZE = 5
}

export enum QuizSpecialty {
  MEDICAL = 'MEDICAL',
  VETERINARY = 'VETERINARY',
  HEALTHCARE = 'HEALTHCARE'
}

export enum QuestionType {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
}

export enum QuestionPurpose {
  LESSON = 'LESSON',
  OFFICIAL_NATIONAL = 'OFFICIAL_NATIONAL',
  QUESTIONS_POOL = 'QUESTIONS_POOL',
  STATIC_PRACTICE ='STATIC_PRACTICE'
}

export const questionPurposes = [
  QuestionPurpose.LESSON,
  QuestionPurpose.QUESTIONS_POOL,
]
