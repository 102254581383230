import React from 'react'
import { UUID } from 'utils/types'
import QuestionForm from '../question-form/QuestionForm'
import QuestionFormWrap from '../../layouts/dashboard/QuestionFormWrap'


interface QuestionEditProps {
  question: QuestionResponse
  subjects?: SubjectResponse[]
  topics?: TopicType[]
  simulationTags?: SimulationTagType[]
  onUpdateSubjectOptions?: (subjectId: UUID) => void
  onSubmit: (question: QuestionUpsertModelRequest) => void
  canSelect?: boolean
}

export const QuestionEdit = ({
                              question,
                              subjects,
                              topics,
                              simulationTags,
                              canSelect,
                              onUpdateSubjectOptions,
                              onSubmit
                            }: QuestionEditProps) => (
    <QuestionFormWrap className="u-mt-20">
      <QuestionForm
        question={question}
        subjects={subjects}
        topics={topics}
        simulationTags={simulationTags}
        canSelect={canSelect}
        onUpdateSubjectOptions={onUpdateSubjectOptions}
        onSubmit={onSubmit}
      />
    </QuestionFormWrap>
  )
