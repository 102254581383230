import React from 'react'
import { Route } from 'react-router-dom'
import routes from 'config/routes'

const Routes = () => (
  <>
    <Route exact path="/"/>
    {routes.map(({component, path}) => (
      <Route key={path} exact path={path} component={component}/>
    ))
    }
    <Route exact path="/404"/>
  </>
)

export default Routes
