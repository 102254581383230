import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getSubjectAreas } from 'services/subject-service'
import { isWeTest } from 'utils/constants'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useSubjectAreas = (subjectId: string, options?: UseQueryOptions<AreaResponseView[], AxiosError>): UseQueryResult<AreaResponseView[]> =>
  useQueryWithSnackbar<AreaResponseView[]>(
    [ReactQueryKeys.SUBJECT_AREAS, subjectId],
    () => getSubjectAreas(subjectId),
    {
      enabled: isWeTest() && !!subjectId,
      ...options,
    },
  )

export default useSubjectAreas
