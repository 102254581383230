import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteBlog } from 'services/blog-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useDeleteBlog = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => useMutationWithSnackbar(
    deleteBlog,
    options,
  )

export default useDeleteBlog
