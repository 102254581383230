import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getUser } from 'services/users-service'
import { UserProfileAdminView } from 'types/UserProfileAdminView'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUser = (studentId: string, options?: UseQueryOptions<UserProfileAdminView, AxiosError>)
  : UseQueryResult<UserProfileAdminView> => useQueryWithSnackbar<UserProfileAdminView>(
    [ReactQueryKeys.USER, studentId],
    () => getUser(studentId), {
      enabled: !!studentId,
      ...options,
    },
  )

export default useUser
