import React, { ChangeEvent, FC, InputHTMLAttributes, memo } from 'react'
import { ErrorMessage, Field, FieldProps } from 'formik'
import cn from 'classnames'
import { FormikHelpers } from 'formik/dist/types'
import { InputLabelProps, TextField, TextFieldProps } from '@mui/material'
import styles from './form-input-mobile.module.scss'

type FormInputProps = TextFieldProps & {
  name: string
  label?: string
  type?: InputHTMLAttributes<unknown>['type']
  multiline?: boolean
  rows?: number
  fullWidth?: boolean
  disableUnderline?: boolean
  placeholder?: string
  hidden?: boolean
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  inputClassName?: string
  disableAutocomplete?: boolean
  InputLabelProps?: Partial<InputLabelProps>
}

const FormInput: FC<FormInputProps> = ({
  type = 'text',
  multiline= false,
  rows = 1,
  fullWidth = true,
  name,
  label= '',
  disableUnderline = false,
  placeholder= '',
  hidden = false,
  onChange,
  inputClassName,
  InputLabelProps,
  disableAutocomplete = false,
  ...restProps
}) => {
  const handlerChange = (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<unknown>['setFieldValue']
  ) => {
    if (onChange) {
      onChange(e)
    }

    if (type === 'file') {
      setFieldValue(name, (e.target as HTMLInputElement).files![0])
    } else {
      setFieldValue(name, e.target.value)
    }
  }

  return (
    <Field
      name={name}
      id={name}>
      {({ field, form: { touched, errors, setFieldValue, setFieldTouched, values, initialValues } }: FieldProps) => {
        const isError = !!(touched[field.name] && errors[field.name])

        return (
          <div className={cn(styles.formInput, hidden && styles.hidden)}>
            <TextField
              className={inputClassName}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handlerChange(e, setFieldValue)}
              error={isError}
              type={type}
              autoComplete={ disableAutocomplete ? `new-${name}` : '' }
              multiline={multiline}
              rows={rows}
              fullWidth={fullWidth}
              id={name}
              InputProps={{ disableUnderline }}
              label={label}
              placeholder={placeholder}
              defaultValue={initialValues[name] || values[name]}
              InputLabelProps={InputLabelProps}
              {...restProps}
            />
            <ErrorMessage name={name} />
          </div>
        )
      }}
    </Field>
  )
}

export default memo(FormInput)
