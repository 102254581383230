import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteCourse } from 'services/courses-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useDeleteCourse = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteCourse, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.COURSES)
      }, 
      ...options,
    }  
  )}

export default useDeleteCourse
