import { clientDataService } from './client-data-service'
import config from '../utils/config'

export const logInCdn = async (token: string): Promise<void> => {
  return await clientDataService<void>({
    method: 'POST',
    url: `${config.cdnBaseUri}/auth`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  })
}

export const logOutCdn = async (): Promise<void> => {
  return await clientDataService<void>({
    method: 'POST',
    url: `${config.cdnBaseUri}/logout`,
    data: {},
  })
}
