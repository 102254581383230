import p from '../pages';
import {isWeTest} from "../utils/constants";

const routesDependsOnPlatform = isWeTest() ? [
    {
        exact: true,
        component: p.AreasPage,
        path: '/areas'
    },
    {
        exact: true,
        component: p.AreaNewPage,
        path: '/areas/new'
    },
    {
        exact: true,
        component: p.BlogsListPage,
        path: '/blogs'
    },
    {
        exact: true,
        component: p.BlogEditPage,
        path: '/blogs/:blogId/edit'
    },
] : [
    {
        exact: true,
        component: p.ClassesListPage,
        path: '/classes'
    },
    {
        exact: true,
        component: p.ClassEditPage,
        path: '/classes/:classId/edit'
    },
    {
        exact: true,
        component: p.QuizGenerateRulesPage,
        path: '/quizzes/generate-rules'
    },
    {
        exact: true,
        component: p.QuizNewRulesPage,
        path: '/quizzes/generate-rules/new'
    },
    {
        exact: true,
        component: p.QuizRulesReportsPage,
        path: '/quizzes/generate-rules/statistics-reports'
    },
    {
        exact: true,
        component: p.QuizEditRulesPage,
        path: '/quizzes/generate-rules/:ruleId/edit'
    },
    {
        exact: true,
        component: p.ResetPlanPage,
        path: '/reset-plan'
    },
    {
        exact: true,
        component: p.SubscriptionsListPage,
        path: '/subscriptions'
    },
    {
        exact: true,
        component: p.SubscriptionCreate,
        path: '/subscriptions/create'
    },
    {
        exact: true,
        component: p.SubscriptionEdit,
        path: '/subscriptions/edit/:subscriptionId'
    },

    {
        exact: true,
        component: p.MonthlySimulationsListPage,
        path: '/quizzes/monthly-simulations'
    },
    {
        exact: true,
        component: p.MonthlySimulationsQuizPage,
        path: '/quizzes/monthly-simulations/:quizId'
    },
    {
        exact: true,
        component: p.MonthlySimulationQuestionEditPage,
        path: '/quizzes/monthly-simulations/:quizId/questions/:questionId'
    },
    {
        exact: true,
        component: p.OfficialNationalSimulationsListPage,
        path: '/quizzes/official-national-simulations'
    },
    {
        exact: true,
        component: p.OfficialNationalNew,
        path: '/quizzes/official-national-simulations/questions/new'
    },
    {
        exact: true,
        component: p.OfficialNationalQuizPage,
        path: '/quizzes/official-national-simulations/:quizId'
    },
    {
        exact: true,
        component: p.OfficialNationalQuestionEditPage,
        path: '/quizzes/official-national-simulations/:quizId/questions/:questionId'
    },
    {
        exact: true,
        component: p.PackSimulationsListPage,
        path: '/quizzes/pack-simulations'
    },
    {
        exact: true,
        component: p.PackSimulationNew,
        path: '/quizzes/pack-simulations/new'
    },
    {   exact: true,
        component: p.PackSimulationQuizzesList,
        path: '/quizzes/pack-simulations/:packSimulationId/quizzes'

    },
    {
        exact: true,
        component: p.PackSimulationEdit,
        path: '/quizzes/pack-simulations/:packSimulationId/edit'
    },
    {   exact: true,
        component: p.PackSimulationQuizCreateNew,
        path: '/quizzes/pack-simulations/:packSimulationId/new'
    },
    {   exact: true,
        component: p.PackSimulationQuizEdit,
        path: '/quizzes/pack-simulations/:packSimulationId/quizzes/:quizId/edit'
    },
    {   exact: true,
        component: p.PackSimulationQuizPage,
        path: '/quizzes/pack-simulations/:packSimulationId/quizzes/:quizId'
    },
    {   exact: true,
        component: p.PackSimulationQuestionEditPage,
        path: '/quizzes/pack-simulations/:packSimulationId/quizzes/:quizId/questions/:questionId'
    },
]

const routes = [
    {
        exact: true,
        component: p.QuestionsListPage,
        path: '/questions'
    },
    {
        exact: true,
        component: p.CreateLinkedQuestionPage,
        path: '/questions/:questionId/linked/:linkedQuestionId'
    },
    {
        exact: true,
        component: p.NewTopicPage,
        path: '/topics/new'
    },
    {
        exact: true,
        component: p.QuestionEditPage,
        path: '/questions/:questionId'
    },
    {
        exact: true,
        component: p.SubjectsPage,
        path: '/subjects'
    },
    {
        exact: true,
        component: p.NewSubjectPage,
        path: '/subjects/new'
    },
    {
        exact: true,
        component: p.EditSubjectPage,
        path: '/subjects/:subjectId/edit'
    },
    {
        exact: true,
        component: p.TopicsPage,
        path: '/subjects/:subjectId/topics'
    },
    {
        exact: true,
        component: p.TopicEditPage,
        path: '/subjects/:subjectId/topics/:topicId'
    },
    {
        exact: true,
        component: p.SimulationTagsPage,
        path: '/subjects/:subjectId/tags'
    },
    {
        exact: true,
        component: p.SimulationTagEditPage,
        path: '/subjects/:subjectId/tags/:simulationTagId'
    },
    {
        exact: true,
        component: p.NewTagPage,
        path: '/tags/new'
    },
    {   exact: true,
        component: p.CoursesPage,
        path: '/courses'
    },
    {
        exact: true,
        component: p.CourseNewPage,
        path: '/courses/new'
    },
    {
        exact: true,
        component: p.CourseEditPage,
        path: '/courses/:courseId/edit'
    },
    {
        exact: true,
        component: p.LessonsListPage,
        path: '/courses/:courseId/lessons'
    },
    {
        exact: true,
        component: p.LessonEditPage,
        path: '/courses/:courseId/lessons/:lessonId'
    },
    {
        exact: true,
        component: p.LessonQuizEditPage,
        path: '/courses/:courseId/lessons/:lessonId/quizzes/:quizId'
    },
    {
        exact: true,
        component: p.LessonQuestionEditPage,
        path: '/courses/:courseId/lessons/:lessonId/quizzes/:quizId/questions/:questionId'
    },
    {
        exact: true,
        component: p.LessonVideoChaptersPage,
        path: '/courses/:courseId/lessons/:lessonId/video-chapters'
    },
    {
        exact: true,
        component: p.NewStudentPage,
        path: '/users/user/new'
    },
    {
        exact: true,
        component: p.StudentsListPage,
        path: '/users'
    },
    {
        exact: true,
        component: p.EditStudentPage,
        path: '/users/:studentId'
    },
    {
        exact: true,
        component: p.VideoScaleTasksPage,
        path: '/management/video-scale-tasks'
    },
    {
        exact: true,
        component: p.ImportQuestionsPage,
        path: '/management/import-questions'
    },
    {
        exact: true,
        component: p.ReportsPage,
        path: '/reports'
    },
    ...routesDependsOnPlatform
];

export default routes;
