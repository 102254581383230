import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { UUID } from 'utils/types'
import { QuestionEdit } from '../../../questions/question-edit/QuestionEdit'
import useQuestion from 'hooks/questions/useQuestion'
import useSubjects from 'hooks/subjects/useSubjects'
import useSaveQuestion from 'hooks/questions/useSaveQuestion'
import useSubjectTopics from 'hooks/subjects/useSubjectTopics'
import ReactQueryKeys from 'types/ReactQueryKeys'


interface OfficialNationalQuestionEditPageParams {
  quizId: UUID
  questionId: UUID
}

const OfficialNationalQuestionEditPage = () => {
  const queryClient = useQueryClient()
  const { quizId, questionId } = useParams<OfficialNationalQuestionEditPageParams>()
  const question = useQuestion(questionId).data || {} as QuestionResponse
  const questionSubjectId = question?.subject?.id || ''
  const subjects = useSubjects().data || []
  const [subjectId, setSubjectId] = useState<string>('')
  const subjectTopics = useSubjectTopics(subjectId).data || []

  useEffect(() => {
    questionSubjectId && setSubjectId(questionSubjectId)
  }, [questionSubjectId])

  const saveQuestionMutation = useSaveQuestion(`/quizzes/official-national-simulations/${quizId}`, questionId)
  
  const handleUpdateSubjectOptions = async (subjectId: string) => {
    setSubjectId(subjectId)
    await queryClient.invalidateQueries([ReactQueryKeys.SUBJECT_TOPICS, subjectId])
  }

  const handleSubmit = (question: QuestionUpsertModelRequest) => saveQuestionMutation.mutate(question)

  return (
    <div>
      <Typography variant="h5">Question</Typography>
      {!isEmpty(question) &&
        <QuestionEdit question={question}
                      subjects={subjects}
                      topics={subjectTopics}
                      onUpdateSubjectOptions={handleUpdateSubjectOptions}
                      onSubmit={handleSubmit}
        />
      }
    </div>
  )
}

export default OfficialNationalQuestionEditPage
