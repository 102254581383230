import { Field, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { MenuItem, Select } from '@mui/material'
import { isEmpty } from 'lodash'
import PageTitle from '../layouts/dashboard/PageTitle'
import SaveButton from '../layouts/dashboard/SaveButton'
import useSubjects from 'hooks/subjects/useSubjects'
import useCreateTag from 'hooks/useCreateTag'


export const NewTagPage = () => {
  const history = useHistory()
  const subjects = useSubjects().data || []

  const createTopicMutation = useCreateTag()
  const handleSubmit = (data: CreateSimulationTagRequest) => createTopicMutation.mutateAsync(data)
    .then(_ => history.push(`/subjects/${data.subjectId}/tags`))

  return (
    <>
      <PageTitle className='u-mb-30'>New Simulation Tag</PageTitle>
      <Formik
        initialValues={{name: '', subjectId: ''}}
        onSubmit={handleSubmit}
      >{(props => (
        <Form className='u-flex-container u-wpx-600'>
          <Field type='name' name='name' className='u-hpx-40 u-w-50'/>
          <Select id='subjectId'
                  name='subjectId'
                  value={props.values.subjectId}
                  onChange={props.handleChange}
                  className='u-hpx-40 u-wpx-180'>
            {!isEmpty(subjects) && subjects.map(subject => <MenuItem value={subject.id}>{subject.name}</MenuItem>)}
          </Select>
          <SaveButton type='submit'>Save</SaveButton>
        </Form>
      ))}
      </Formik>
    </>
  )
}
