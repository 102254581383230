import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { createNotesImage } from '../services/lesson-service'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateLessonNotesImage = (options?: UseMutationOptions<CreateImageResponse, AxiosError, CreateLessonNotesImageData>)
  : UseMutationResult<CreateImageResponse, AxiosError, CreateLessonNotesImageData> => {
  return useMutationWithSnackbar(
    createNotesImage,
    options,
  )
}

export default useCreateLessonNotesImage
