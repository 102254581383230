import React, {ChangeEvent} from 'react'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { isEmpty } from 'lodash'
import { CourseForm, validationSchema } from '../course-form/CourseForm'
import PageTitle from '../../layouts/dashboard/PageTitle'
import CourseVideo from '../course-video/CourseVideo'
import Selector from 'components/shared/selector/Selector'

interface CourseEditProps {
  course: CourseView
  allAreas: AreaResponseView[]
  selectedAreaIds: string[]
  handleAreaChecked: (areaId: string) => void
  handleAllChecked: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (data: CourseUpdateRequest) => void
}

const CourseEdit = ({ course,
                      selectedAreaIds,
                      allAreas,
                      handleAreaChecked,
                      handleAllChecked,
                      onSubmit
                    }: CourseEditProps) => {

  const formik = useFormik({
    initialValues: {
      number: course?.number || 0,
      name: course?.name || '',
    },
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  })

  return (
        <>
          <PageTitle className="u-mb-30">Course</PageTitle>
          <Grid container>
            <Grid item xs={12} md={8}>
              <CourseForm formik={formik} uploadedImageURL={course.previewImageUri}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <CourseVideo course={course}/>
              {!isEmpty(allAreas) &&
                <Selector
                  allItems={allAreas}
                  selectedItem={selectedAreaIds}
                  onItemChecked={handleAreaChecked}
                  onAllChecked={handleAllChecked}
                />
              }
            </Grid>
          </Grid>
        </>
  )}

export default CourseEdit
