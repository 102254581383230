import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { PublishPackQuizRequest, unpublishPackQuiz } from 'services/pack-quizzes-service'

const useUnpublishPackQuiz = (options?: UseMutationOptions<void, AxiosError, PublishPackQuizRequest>)
  : UseMutationResult<void, AxiosError, PublishPackQuizRequest> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    unpublishPackQuiz, {
      async onSuccess() { 
        await queryClient.invalidateQueries([ReactQueryKeys.PACK_QUIZZES])
      },
    ...options,
  }
)}

export default useUnpublishPackQuiz
