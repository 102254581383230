import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { patchBlog } from 'services/blog-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const usePatchBlog = (options?: UseMutationOptions<void, AxiosError, BlogUpdateRequest>)
  : UseMutationResult<void, AxiosError, BlogUpdateRequest> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    patchBlog,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.BLOG)
      },
      ...options,
    },
  )

}
  
export default usePatchBlog
