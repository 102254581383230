import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Form, Formik } from 'formik'
import {
  Box, CircularProgress,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'

import PageTitle from 'components/layouts/dashboard/PageTitle'
import SaveButton from 'components/layouts/dashboard/SaveButton'
import useBlogs from 'hooks/useBlogs'
import config from 'utils/config'
import useCreateBlog from 'hooks/useCreateBlog'
import FormInput from 'components/common/ui/form/form-input/form-input'
import { BlogActions } from './BlogActions'
import ReactQueryKeys from 'types/ReactQueryKeys'
import usePublishBlog from 'hooks/usePublishBlog'
import useUnpublishBlog from 'hooks/useUnpublishBlog'
import useDeleteBlog from 'hooks/useDeleteBlog'

export const BlogsListPage = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [textFilter, setTextFilter] = useState<string>('')
  const [previewTextFilter, setPreviewTextFilter] = useState<string>('')
  const [titleFilter, setTitleFilter] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [blogIdToDelete, setBlogIdToDelete] = useState<string>('')

  const blogsQuery = useBlogs({
    page: pageNumber,
    size: pageSize,
    title: titleFilter,
    text: textFilter,
    previewText: previewTextFilter,
  } as BlogsSearchRequest)

  const blogs = blogsQuery.data

  const createBlogMutation = useCreateBlog()
  const deleteBlogMutation = useDeleteBlog()
  const publishBlogMutation = usePublishBlog()
  const unpublishBlogMutation = useUnpublishBlog()

  const handleCreateBlog = () => createBlogMutation.mutate()
  const handleDeleteBlog = (blogId: string) => deleteBlogMutation.mutateAsync(blogId)
  const handlePublishBlog = (blogId: string) => publishBlogMutation.mutateAsync(blogId)
  const handleUnpublishBlog = (blogId: string) => unpublishBlogMutation.mutateAsync(blogId)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPageNumber(newPage)

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPageNumber(newPage - 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const clearFilters = () => {
    setTitleFilter('')
    setTextFilter('')
    setPreviewTextFilter('')
    setPageNumber(0)
  }

  const handleSearch = ({ title, text, previewText }) => {
    setTitleFilter(title || '')
    setTextFilter(text || '')
    setPreviewTextFilter(previewText || '')
    setPageNumber(0)
  }

  const filtersInitialValues = {
    title: '',
    previewText: '',
    text: '',
  }

  const tableContent = () => (
    <TableBody>
      {blogs?.content.map(({ id, title, previewImageUri, previewText, published, publishedAt }) => (
          <TableRow
            key={id}
            hover
            onClick={_ => history.push(`/blogs/${id}/edit`)}
            style={{ cursor: 'pointer' }}
          >
            <TableCell component="td" scope="row">
              {title}
            </TableCell>
            <TableCell component="td" scope="row">
              <Box sx={{ width: '100%', height: '50px' }}>
                {previewImageUri && <img
                    src={`${config.cdnBaseUri}${previewImageUri}`}
                    alt="Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />}
              </Box>
            </TableCell>
            <TableCell component="td" scope="row">
              {previewText}
            </TableCell>
            <TableCell component="td" scope="row" sx={{wordBreak: "break-word"}}>
              <BlogActions
                blogId={id}
                published={published}
                refetch={() => queryClient.invalidateQueries([ReactQueryKeys.BLOGS, pageNumber, pageSize, titleFilter, previewTextFilter, textFilter])}
                deleteConfirmDialogOpen={deleteConfirmDialogOpen}
                setDeleteConfirmDialogOpen={setDeleteConfirmDialogOpen}
                blogIdToDelete={blogIdToDelete}
                setBlogIdToDelete={setBlogIdToDelete}
                onPublish={handlePublishBlog}
                onUnpublish={handleUnpublishBlog}
                onDelete={handleDeleteBlog}
              />
            </TableCell>
          </TableRow>
        ),
      )}
    </TableBody>
  )

  return (
    <div>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Blogs</PageTitle>
        <SaveButton onClick={handleCreateBlog}>
          + New blog
        </SaveButton>
      </div>
      {blogsQuery.isLoading && <CircularProgress/>}
      {!blogsQuery.isLoading && (
        <Box>
          <Box
            sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
          >
            <Formik
              initialValues={filtersInitialValues}
              onSubmit={handleSearch}
            >{(({ setValues }) => (
              <Form className="u-flex-container">
                <span style={{ paddingTop: '28px', display: 'inline-block' }}>Filters:</span>
                <FormInput name="title" label="Title"/>
                <FormInput name="previewText" label="Preview text"/>
                <FormInput name="text" label="Text"/>
                <Box className="u-d-flex">
                  <SaveButton type='submit'>Find</SaveButton>
                  <SaveButton type='button' onClick={() => {
                    setValues(filtersInitialValues)
                    clearFilters()
                  }} className="u-wpx-165 u-ml-20">Clear filters</SaveButton>
                </Box>
              </Form>
            ))}
            </Formik>
          </Box>
          <br/>
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Preview image</TableCell>
                    <TableCell>Preview text</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                {tableContent()}
              </Table>
              <Grid container spacing={3}>
                <Grid item xs={6} display="flex" alignItems="center">
                  <Pagination
                    size={'medium'}
                    count={blogs?.totalPages}
                    onChange={handleChange}
                    page={pageNumber + 1}
                    boundaryCount={2}/>
                </Grid>
                <Grid item xs={6}>
                  <TablePagination
                    component="div"
                    count={blogs?.totalElements || -1}
                    page={pageNumber}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </TableContainer>
          </div>
        </Box>
      )}
    </div>
  )
}
