import React from 'react'
import { RouteComponentProps } from 'react-router'
import { isEmpty } from 'lodash'
import { isWeTest } from 'utils/constants'
import CourseEdit from './CourseEdit'
import useUpdateCourse from 'hooks/course/useUpdateCourse'
import useCourse from 'hooks/course/useCourse'
import useAreas from 'hooks/useAreas'
import useSelectItem from 'hooks/useSelectItem'

export const CourseEditPage = (props: RouteComponentProps<{ courseId?: string }>) => {
  const courseId: string = props.match.params.courseId || ''
  
  const course = useCourse(courseId).data || {} as CourseView
  const courseUpdateMutation = useUpdateCourse(courseId)
  
  const allAreas = useAreas().data || []
  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({ allItems: allAreas, initialSelectedItemsIds: course.areas })
  
  const handleSubmit = (data: CourseUpdateRequest) => {
    const formattedData = isWeTest() ? { areasIds: [...selectedItemIds].toString(), ...data } : data
    return courseUpdateMutation.mutate(formattedData)
  }

  return (
    <>
      {!isEmpty(course) &&
        <CourseEdit course={course}
                    allAreas={allAreas}
                    selectedAreaIds={selectedItemIds}
                    handleAreaChecked={handleItemChecked}
                    handleAllChecked={handleAllChecked}
                    onSubmit={handleSubmit}
        />}
    </>
  )
}
