import { Box, Button, Grid, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { UUID } from "utils/types";
import PageTitle from "components/layouts/dashboard/PageTitle";
import QuizForm from "./QuizForm";
import useCreatePackQuiz from "hooks/packSimulation/useCreatePackQuiz";
import { CreatePackQuiz } from "services/pack-quizzes-service";
import { ErrorTable } from "components/management/import/questions/ErrorTable";
import useTasksForImport from "hooks/useTasksForImport";
import useDeleteTasks from "hooks/useDeleteTasks";
import { useState } from "react";
import { isEmpty } from 'lodash'
import { ImportTask } from "components/management/import/questions/ImportTask";
import ReactQueryKeys from "types/ReactQueryKeys";
import { useQueryClient } from "react-query";

export const PackSimulationQuizCreateNew = () => {
  const queryClient = useQueryClient()
  const history = useHistory();
  const { packSimulationId } = useParams<{ packSimulationId: UUID }>();
  const tasks = useTasksForImport().data || []

  const deleteTasksMutation = useDeleteTasks()

  const handleDeleteTasks = () => deleteTasksMutation.mutate()

  const newQuizMutation = useCreatePackQuiz(packSimulationId);

  const refreshTask = () => queryClient.invalidateQueries(ReactQueryKeys.IMPORT_TASKS)

  const importSubmit = async (quiz: CreatePackQuiz) => {
    newQuizMutation.mutate(quiz, {
        async onSuccess() {
          await history.push(`/quizzes/pack-simulations/${packSimulationId}/quizzes`);
        },
        async onError() {
         refreshTask()
        }
      });
    }

  return (
    <div>
      <PageTitle>New Quiz</PageTitle>
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <QuizForm  onSubmit={importSubmit} />
      </Box>
      <Grid xs={10}>
      <Box sx={{ display: "flex", justifyContent: 'flex-end', mb: '5px' }}>
        <Button variant="contained" onClick={() => handleDeleteTasks()}>CLEAR TABLE</Button>
      </Box>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Created at
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Completed
                </TableCell>
                <TableCell>
                  Total questions
                </TableCell>
                <TableCell>
                  Questions imported
                </TableCell>
                <TableCell>
                  Errors
                </TableCell>
              </TableRow>
            </TableHead>
            <ErrorTable
              tasks={tasks}
            />
          </Table>
        </TableContainer>
      </div>
    </Grid>
    </div>
  );
};
