import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { isEmpty } from 'lodash'
import { Typography } from '@mui/material'
import { isWeTest } from 'utils/constants'
import QuestionForm from '../question-form/QuestionForm'
import QuestionFormWrap from 'components/layouts/dashboard/QuestionFormWrap'
import useQuestion from 'hooks/questions/useQuestion'
import useSubjectAreas from 'hooks/subjects/useSubjectAreas'
import useSaveQuestion from 'hooks/questions/useSaveQuestion'


export const CreateLinkedQuestionPage = (props: RouteComponentProps<{questionId: string, linkedQuestionId: string}>) => {
  const questionId: string = props.match.params.questionId || ''
  const linkedQuestionId: string = props.match.params.linkedQuestionId || ''
  const question = useQuestion(questionId).data || {} as QuestionResponse
  const questionSubjectId = question.subject?.id
  
  useEffect(() => {
    questionSubjectId && setSubjectId(questionSubjectId)
  }, [questionSubjectId])

  const [subjectId, setSubjectId] = useState<string>('')
  const subjectAreas = useSubjectAreas(subjectId).data

  const saveQuestionMutation = useSaveQuestion(`/questions`, linkedQuestionId)

  const handleSubmit = (data: QuestionUpsertModelRequest) => {
    const resultData = isWeTest() ? {...data, areas: question.areas} : data
    return saveQuestionMutation.mutate(resultData)
  }

  const defaultValue = {
    ...question,
    text: '',
    answerExplanation: '',
    options: [],
    subject: question.subject
  }

  const initialValue = () => isWeTest() ? defaultValue : {
        ...defaultValue,
        topic: question.topic,
        simulationTag: question.simulationTag,
        difficulty: question.difficulty
      } as QuestionResponse

  return (
    <>
      <Typography variant='h5'>Create Linked Question</Typography>
      {!isEmpty(question) && (
        <QuestionFormWrap className="u-mt-20">
          <QuestionForm
            question={initialValue()}
            subjectAreas={subjectAreas}
            selectedArea={question.areas}
            canSelect={false}
            onSubmit={handleSubmit}
          />
        </QuestionFormWrap>
      )}
    </>
  )
}
