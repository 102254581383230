import { UUID } from "../utils/types"
import { clientDataService } from './client-data-service'
import { TagSearchView } from '../types/TagSearchView'

export type SimulationTag = {
  name: string
  id: UUID
}

export type SimulationTagOne = {
  name: string
  id: UUID
  subjectId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
}

export type UpdateSimulationTag = {
  name: string
  subjectId: UUID
  simulationTagId: UUID
}

export type SimulationTagsParams = {
  simulationTagId: UUID
  subjectId: UUID
}

export const getSimulationTags = (subjectId: UUID): Promise<TagSearchView[]> =>
  clientDataService<TagSearchView[]>({
    method: 'GET',
    url: `/admin/simulation-tags?subjectId=${subjectId}`
  })

export const getTag = (tagId: string): Promise<UpdateTagView> =>
  clientDataService<UpdateTagView>({
    method: 'GET',
    url: `/admin/simulation-tags/${tagId}`
  })

export const addTag = (data: CreateSimulationTagRequest): Promise<UpdateTagView> =>
  clientDataService<UpdateTagView>({
    method: 'POST',
    url: '/admin/simulation-tags',
    data
  })

export const updateTag = (data: UpdateTagView): Promise<UpdateTagView> =>
  clientDataService<UpdateTagView>({
    method: 'PUT',
    url: `/admin/simulation-tags/${data.id}`,
    data
  })

export const deleteTag = (tagId: string): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/simulation-tags/${tagId}`,
  })
