import React, { FC } from 'react'
import ReportsList from './reportsList/ReportsList';
import PageTitle from '../layouts/dashboard/PageTitle';

const ReportsPage = () => {

  return (
        <div>
          <PageTitle>Reports</PageTitle>
          <ReportsList/>
        </div>
      )
}

export default ReportsPage