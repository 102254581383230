import { clientDataService } from './client-data-service'

export const getTopics = (subjectId: string): Promise<TopicSearchView[]> =>
  clientDataService<TopicSearchView[]>({
    method: 'GET',
    url: `/admin/topics?subjectId=${subjectId}`
  })

export const getTopic = (topicId: string): Promise<TopicSearchView> =>
  clientDataService<TopicSearchView>({
    method: 'GET',
    url: `/admin/topics/${topicId}`
  })

export const createTopic = (data: CreateTopicRequest): Promise<TopicSearchView> =>
  clientDataService<TopicSearchView>({
    method: 'POST',
    url: '/admin/topics',
    data
  })

export const updateTopic = (data: TopicSearchView): Promise<TopicSearchView> =>
  clientDataService<TopicSearchView>({
    method: 'PUT',
    url: `/admin/topics/${data.id}`,
    data
  })

export const deleteTopic = (topicId: string): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/topics/${topicId}`,
  })
