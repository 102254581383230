import useCreateSubscription from "hooks/subscriptions/useCreateSubscription";
import useClasses from "hooks/useClasses";
import SubscriptionForm from "./SubscriptionForm";

export interface initialValuesInterface {
  name: string;
  code: string;
  paid: boolean;
  hasClasses: boolean;
  hasCourses: boolean;
  randomQuizAccess: boolean;
  classId: string[];
  published: false;
  availableOnAndroid: boolean;
  availableOnIos: boolean;
}

const SubscriptionCreate = () => {
  const createMutation = useCreateSubscription();
  const allClasses = useClasses({
    page: 0,
    size: 100,
    name: "",
  }).data?.content;

  const handleSubmit = (initialValues: initialValuesInterface) => {
    createMutation.mutate({
      name: initialValues.name,
      code: initialValues.code,
      paid: initialValues.paid,
      hasClasses: initialValues.hasClasses,
      hasCourses: initialValues.hasCourses,
      randomQuizAccess: initialValues.randomQuizAccess,
      classIds: initialValues.classId ? initialValues.classId : [],
      published: false,
      availableOnAndroid: initialValues.availableOnAndroid,
      availableOnIos: initialValues.availableOnIos
    });
  };

  const getInitialState = () => {
    return {
      name: "",
      code: "",
      paid: true,
      hasClasses: true,
      hasCourses: true,
      randomQuizAccess: true,
      classId: "",
      availableOnAndroid: true,
      availableOnIos: true
    };
  };

  return (
    <>
      <SubscriptionForm
        initialValues={getInitialState()}
        onSubmit={handleSubmit}
        allClasses={allClasses}
      ></SubscriptionForm>
    </>
  );
};

export default SubscriptionCreate;
