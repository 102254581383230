import { useHistory } from 'react-router-dom'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { CreateSubscriptionRequest } from 'types/requests/CreateSubscriptionRequest'
import { SubscriptionView } from 'types/SubscriptionView'
import { createSubscription } from 'services/subscription-service'


const useCreateSubscription = ( options?: UseMutationOptions<SubscriptionView, AxiosError, CreateSubscriptionRequest>)
  : UseMutationResult<SubscriptionView, AxiosError, CreateSubscriptionRequest> => {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: CreateSubscriptionRequest) => createSubscription(data), {
      async onSuccess(_, data) {
        await queryClient.invalidateQueries(ReactQueryKeys.SUBSCRIPTIONS)
        await history.push(`/subscriptions`)
      },
      ...options,
    },
  )
}

export default useCreateSubscription
