import React from 'react';
import { Form, Formik } from 'formik'
import { DatePicker } from '@mui/lab'
import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import DateAdapter from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { OfficialNationalSimulationQuizUpsertModel } from 'services/official-national-quiz-service'
import { QuizSpecialty } from '../../../questions/constants'
import { StyledLabel, StyledInput } from '../../../layouts/dashboard/StyledInput'
import OfficialNationalQuizFormSchema from './OfficialNationalQuizFormSchema'

type OfficialNationalQuizFormProps = {
  quiz: OfficialNationalSimulationQuizUpsertModel
  onSubmit(question: OfficialNationalSimulationQuizUpsertModel): void
};

const OfficialNationalQuizForm = ({quiz, onSubmit}: OfficialNationalQuizFormProps) => (
    <Formik
      initialValues={{ ...quiz }}
      validationSchema={OfficialNationalQuizFormSchema}
      onSubmit={onSubmit}
    >{(props) => (
      <Form>
        <Box style={{width: "800px"}}>
          <div className="u-flex-container">
            <Typography>Specialty</Typography>
            <Select id="specialty"
                    name="specialty"
                    value={props.values.specialty}
                    onChange={props.handleChange}>
              <MenuItem value={QuizSpecialty.MEDICAL}>{QuizSpecialty.MEDICAL}</MenuItem>
              <MenuItem value={QuizSpecialty.VETERINARY}>{QuizSpecialty.VETERINARY}</MenuItem>
              <MenuItem value={QuizSpecialty.HEALTHCARE}>{QuizSpecialty.HEALTHCARE}</MenuItem>
            </Select>
              {props.errors.specialty && props.touched.specialty ?
              <div style={{color: "red"}}>{props.errors.specialty}</div> : null
            }
          </div>
          <br/>
          <div className="u-flex-container">
            <StyledLabel>Name</StyledLabel>
            <StyledInput type="text"
                          name="name"
                          placeholder="Fill name"
                          value={props.values.name}
                          onChange={props.handleChange}
            />
            {props.errors.name && props.touched.name ?
              <div style={{color: "red"}}>{props.errors.name}</div> : null
            } 
          </div>
          <div className="u-flex-container u-pt-30" >
            <Typography>Year</Typography>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                views={['year']}
                value={dayjs().set('year', props.values.year)}
                minDate={dayjs().set('year', 2000)}
                maxDate={dayjs()}
                onChange={(newValue: Dayjs | null) => { props.setFieldValue('year', newValue?.year()) }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
            {props.errors.year && props.touched.year ?
              <div style={{color: "red"}}>{props.errors.year}</div> : null
            }  
          </div>
        </Box>
        <br/>
        <Button type="submit" variant="contained">Save</Button>
      </Form>
    )}
    </Formik>
  )

export default OfficialNationalQuizForm;
