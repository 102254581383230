import jsonToFormData from '../utils/jsonToFormData'
import { clientDataService } from './client-data-service'
import { UUID } from '../utils/types'
import { QuestionSlimListPageable } from 'types/responses/QuestionSlimListPageable'
import {QuestionType, QuestionPurpose } from '../components/questions/constants'

export type GetQuestionsQueryParams = {
  size: number
  page: number
  text?: string | null
  answerExplanation?: string | null
}

export type QuestionCreateModel = {
  type: QuestionType
  purpose: QuestionPurpose
  linkId?: string
}

export const getQuestion = async (questionId: UUID): Promise<QuestionResponse> => {
  return await clientDataService<QuestionResponse>({
    method: 'get',
    url: `/admin/questions/${questionId}`
  })
}

export const getQuestions = async ({size, page, text, answerExplanation}: GetQuestionsQueryParams): Promise<QuestionSlimListPageable> =>
  await clientDataService<QuestionSlimListPageable>({
    method: 'get',
    url: `/admin/questions?sort=modifiedAt,DESC&size=${size}&page=${page}${text ? `&text=${text}` : ''}${answerExplanation ? `&answerExplanation=${answerExplanation}` : ''}`,
  })

export const addQuestion = async (question: QuestionCreateModel): Promise<QuestionResponse> =>
  await clientDataService<QuestionResponse>({
    method: 'post',
    url: '/admin/questions',
    data: question
  })

export const createMainQuestion = async (questionId: string): Promise<void> =>
  await clientDataService<void>({
    method: 'post',
    url: `/admin/linked-questions/${questionId}`,
  })

export const addLinkedQuestion = async (data: QuestionCreateModel): Promise<QuestionResponse> =>
  await clientDataService<QuestionResponse>({
    method: 'post',
    url: '/admin/linked-questions',
    data
  })

export const createQuestionImage = async ({questionId, ...data}: CreateQuestionImageData): Promise<CreateImageResponse> => {
  return await clientDataService<CreateImageResponse>({
    method: 'post',
    url: `/admin/questions/${questionId}/upload-image`,
    data: jsonToFormData(data),
  })
}

export const saveQuestion = async (questionId: string, question: QuestionUpsertModelRequest): Promise<QuestionResponse> => {
  return await clientDataService<QuestionResponse>({
    method: 'put',
    url: `/admin/questions/${questionId}`,
    data: question
  })
}

export const publishQuestion = async (questionId: string): Promise<void> => {
  return await clientDataService<void>({
    method: 'put',
    url: `/admin/questions/${questionId}/publish`,
  })
}

export const unpublishQuestion = async (questionId: string): Promise<void> => {
  return await clientDataService<void>({
    method: 'put',
    url: `/admin/questions/${questionId}/unpublish`,
  })
}

export const deleteQuestion = async (questionId: string): Promise<void> => {
  return await clientDataService<void>({
    method: 'delete',
    url: `/admin/questions/${questionId}`,
  })
}

export const deleteQuizQuestion = (quizId: UUID, questionId: UUID): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/quizzes/${quizId}/questions/${questionId}`,
  })
