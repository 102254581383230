import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getTopics } from '../services/topics-service'
import { isWeTest } from 'utils/constants'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useTopics = (subjectId: string, options?: UseQueryOptions<TopicSearchView[], AxiosError>)
  : UseQueryResult<TopicSearchView[]> => useQueryWithSnackbar<TopicSearchView[]>(
    [ReactQueryKeys.TOPICS, subjectId],
    () => getTopics(subjectId),
    {
      enabled: !!subjectId && !isWeTest(),
      ...options,
    },
  )

export default useTopics
