import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Button, Input } from '@mui/material'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { IProps } from '@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor'

import s from '../../courses/course-form/CourseForm.module.css'
import config from 'utils/config'
import useCreateLessonNotesImage from 'hooks/useCreateLessonNotesImage'
import blobToFile from 'utils/blobToFile'
import FormEditor from '../../common/ui/form/form-editor/form-editor'
import FormInput from '../../common/ui/form/form-input/form-input'

interface LessonFormProps {
  lesson?: LessonType
  onSubmit: (data: LessonUpdateRequest) => void
  withUploader?: boolean
}

export const validationSchema = yup.object({
  number: yup.number().required().min(0),
  name: yup.string().required(),
  lecturer: yup.string().required(),
  transcrypt: yup.string(),
  lessonText: yup.string(),
})

const LessonForm = ({lesson, onSubmit, withUploader}: LessonFormProps) => {
  const {courseId, lessonId}: any = useParams()
  const [imgPreview, setImgPreview] = useState<any>(null)
  const [error, setError] = useState(false)

  const previewImageUri = lesson?.previewImageUri

  useEffect(() => {
    if (previewImageUri) {
      setImgPreview(config.cdnBaseUri + previewImageUri)
    } else {
      setImgPreview(null)
    }
  }, [previewImageUri])


  const handleImageChange = (
    e: any,
    setFieldValue: FormikHelpers<unknown>['setFieldValue'],
  ) => {
    setError(false)
    const selected = e.target.files[0]
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"]
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader()
      reader.onloadend = () => {
        // @ts-ignore
        setImgPreview(reader.result)
      }
      reader.readAsDataURL(selected)
      setFieldValue("previewImage", selected)
    } else {
      setError(true)
    }
  }

  const createNotesImageMutation = useCreateLessonNotesImage()

  const handleLessonNotesImageUpload: IProps['init']['images_upload_handler'] = function (blobInfo, success) {
    const image = blobToFile(blobInfo.blob(), blobInfo.filename())

    createNotesImageMutation.mutate({
      courseId: courseId,
      lessonId: lessonId,
      image,
    } as CreateLessonNotesImageData, {
      onSuccess({imageUri}) {
        success(imageUri)
      },
    })
  }


  return (<Formik
      validateOnBlur
      validateOnChange={false}
      initialValues={{
        courseId: courseId,
        lessonId: lessonId,
        name: lesson?.name || "",
        lecturer: lesson?.lecturer || "",
        transcrypt: lesson?.transcrypt || "",
        lessonText: lesson?.text || "",
        number: lesson?.number || 0,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({handleSubmit, setFieldValue}) => (
        <form onSubmit={handleSubmit}>
          <div className="u-wpx-600 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary">
          <FormInput
            label="Number"
            name="number"
            type="number"
            fullWidth={true}
          />
          <br/>
          <FormInput
            label="Title"
            name="name"
            fullWidth={true}
          />
          <br/>
          <FormInput
            label="Lecturer"
            name="lecturer"
            fullWidth={true}
          />
          <br/>
          <FormEditor
            label="Lesson text"
            name="lessonText"
            imageUploadHandler={handleLessonNotesImageUpload}
          />
          <br/>
          <FormEditor
            label="Lesson transcrypt (lesson notes default text)"
            name="transcrypt"
            imageUploadHandler={handleLessonNotesImageUpload}
          />
          <br/>
          {withUploader && (
            <div className={s.previewWrapper}>
              <div className={s.container}>
                {error && <p className={s.errorMsg}>File not supported</p>}
                <div
                  className={s.imgPreview}
                  style={{
                    background: imgPreview
                      ? `url("${imgPreview}") no-repeat center/cover`
                      : "grey",
                  }}
                >
                  {imgPreview ?
                    (
                      <button className={s.removeButton}
                              type="button"
                              onClick={() => {
                                setImgPreview(null)
                                setFieldValue("deletePreviewImage", true)
                              }}
                      >
                        Remove image
                      </button>
                    )
                    :
                    (
                      <>
                        <p>Add an image</p>
                        <label htmlFor="fileUpload" className={s.customFileUpload}>
                          Choose file
                        </label>
                        <Input type="file" id="fileUpload" onChange={(e) => handleImageChange(e, setFieldValue)}/>
                        <span>(jpg, jpeg or png)</span>
                      </>
                    )}
                </div>
              </div>
            </div>
          )}
          <Button
            sx={{mt: 5}}
            variant={"contained"}
            type="submit"
          >
            Save
          </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

LessonForm.defaultProps = {withUploader: false}
export default LessonForm
