import { AxiosError } from "axios"
import useQueryWithSnackbar from "hooks/useQueryWithSnackbar"
import { UseQueryOptions, UseQueryResult } from "react-query"
import { getSubscriptions } from "services/subscription-service"
import ReactQueryKeys from "types/ReactQueryKeys"
import { SubscriptionResponse } from "types/responses/SubscriptionResponse"

const useSubscriptions = (options?: UseQueryOptions<SubscriptionResponse[], AxiosError>)
  : UseQueryResult<SubscriptionResponse[]> => useQueryWithSnackbar<SubscriptionResponse[]>(
    [ReactQueryKeys.SUBSCRIPTIONS],
    () => getSubscriptions(),
    options,
  )

export default useSubscriptions
