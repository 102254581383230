import { FC, ReactNode } from 'react'
import { isEmpty, isString } from 'lodash'
import { Box } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import cn from 'classnames'

import styles from '../User.module.scss'

interface UserAvatarProps {
  className?: string
  photo: ReactNode | string
  firstName?: string
  lastName?: string
  onClick?: () => void
  sx?: SxProps<Theme>
}

const UserAvatar: FC<UserAvatarProps> = ({
                                           className,
                                           firstName,
                                           lastName,
                                           photo,
                                           onClick,
                                           sx,
                                         }) => (
  <Box
    onClick={onClick}
    sx={{ width: { xs: '62px', sm: '90px' }, height: { xs: '62px', sm: '90px' }, ...sx }}
    className={cn(styles.userAvatar, className)}
  >
    {isString(photo)
      ? <img
        src={isEmpty(photo) ? `https://eu.ui-avatars.com/api/?name=${firstName}+${lastName}&background=4780D8&color=fff` : photo}
        alt={isEmpty(firstName) && isEmpty(lastName) ? 'User avatar' : `${firstName} ${lastName}`}/>
      : photo}
  </Box>
)

export default UserAvatar
