export enum UserRole {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  ADMIN = 'ADMIN'
}

export const userRoles = [
  UserRole.STUDENT,
  UserRole.TEACHER,
  UserRole.ADMIN,
]
