import { Box, Button, Grid, Modal } from "@mui/material";
import Selector from "components/shared/selector/Selector";

const EditUserPacksModal = ({
  isOpen,
  additionPacks,
  selectedPacksIds,
  handleAllChecked,
  handlePackChecked,
  onClose,
  handleClear,
  handleAddPackSimulation
}) => {
  const handleCancel = () => {
    onClose();
    handleClear();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        top: "35%",
        left: "36%",
        width: "600px",
        height: "fit-content",
        background: "gray",
      }}
    >
      <Box>
        {additionPacks && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "20px",
              maxHeight: "300px",
            }}
          >
            <Selector
              allItems={additionPacks}
              selectedItem={selectedPacksIds}
              onItemChecked={handlePackChecked}
              onAllChecked={handleAllChecked}
              title={'Add Pack'}
              sx={{ overflowY: "scroll", width: "100%", marginTop: 0 }}
            />
          </Grid>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleAddPackSimulation} variant="outlined" color="error">
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserPacksModal;
