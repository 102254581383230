import config from './config'

export enum HttpStatus {
  UNAUTHORIZED = 401
}

export const DEBOUNCE_TYPING_MILLI = 500
export const DEBOUNCE_AUTOSAVE_MILLI = 1000

export const TIME_FORMAT = 'H:mm:ss'
export const FULL_DATE_FORMAT = 'DD MMMM YYYY'
export const FULL_TIME_FORMAT = 'HH:mm:ss'

export const IMAGE_SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
]

export const isWeTest = () => config.platform === 'WE_TEST'
