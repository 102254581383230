import { useQueryClient } from 'react-query'
import { updateArea } from 'services/area-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useUpdateArea = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar((data: UpdateAreaRequest) => updateArea(data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.AREAS)
      },
    },
)}

export default useUpdateArea
