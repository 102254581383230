import { Box, Button, Grid, Paper, TextField } from '@mui/material'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { auth } from 'services/auth-service'
import authStore from 'auth/AuthStore'
import config from 'utils/config'


export const LoginPage = observer(() => {

  let [email,setEmail] = useState('');
  let [password,setPassword] = useState('');
  let history = useHistory()

  const goToDashboard = () => {
    authStore.setAuthenticated(true)
    localStorage.setItem('platform', config.platform || '')
    history.push('/')
  }

  const signIn = async () => {
    let data = {
      username : email,
      password: password
    }
    auth(data).then(goToDashboard)
  }

  return (
    <Box
      sx={{
        background: '#000000',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{maxWidth: {sm: '400px'}}}>
        <Box p={2}>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField id="username"
                           fullWidth
                           label="Email Address"
                           value={email}
                           autoComplete={"username"}
                           onChange={event => setEmail(event.target.value)}
                           name="username"/>
              </Grid>
              <Grid item xs={12}>
                <TextField id="password"
                           fullWidth
                           label="Password"
                           type="password"
                           value={password}
                           autoComplete={"current-password"}
                           onChange={event => setPassword(event.target.value)}
                           name="password"/>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={signIn}
                        fullWidth
                        variant="contained"
                        color='primary'
                >Sign In</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  )
})
