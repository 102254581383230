import { CreateSubscriptionRequest } from "types/requests/CreateSubscriptionRequest";
import { SubscriptionResponse } from "types/responses/SubscriptionResponse";
import { SubscriptionView } from "types/SubscriptionView";
import { clientDataService } from "./client-data-service";

export const getSubscriptions = (): Promise<SubscriptionResponse[]> =>
  clientDataService<SubscriptionResponse[]>({
    method: "GET",
    url: "/admin/subscriptions",
  });

export const deleteSubscription = (subscriptionId: string): Promise<void> =>
  clientDataService<void>({
    method: "DELETE",
    url: `/admin/subscriptions/${subscriptionId}`,
  });

  export const createSubscription = (data: CreateSubscriptionRequest): Promise<SubscriptionView> =>
    clientDataService<SubscriptionView>({
      method: 'POST',
      url: '/admin/subscriptions',
      data
  })

  export const getSubscription = (subscriptionId: string): Promise<SubscriptionView> =>
    clientDataService<SubscriptionView>({
      method: 'GET',
      url: `/admin/subscriptions/${subscriptionId}`
  })

  export const updateSubscriptions = (subscriptionId: string, data: CreateSubscriptionRequest): Promise<SubscriptionView> =>
    clientDataService<SubscriptionView>({
      method: 'PATCH',
      url: `/admin/subscriptions/${subscriptionId}`,
      data
  })

  export const publishSubscription = async (subscriptionId: string): Promise<void> =>
    await clientDataService<void>({
      method: 'PUT',
      url: `/admin/subscriptions/${subscriptionId}/publish`,
  })

  export const unpublishSubscription = async (subscriptionId: string): Promise<void> =>
    await clientDataService<void>({
      method: 'PUT',
      url: `/admin/subscriptions/${subscriptionId}/unpublish`,
  })
