import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getSubjectTopics } from 'services/subject-service'
import { isWeTest } from 'utils/constants'
import { UUID } from 'utils/types'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'

const useSubjectTopics = (subjectId: UUID, options?: UseQueryOptions<TopicType[], AxiosError>)
  : UseQueryResult<TopicType[]> => useQueryWithSnackbar<TopicType[]>(
    [ReactQueryKeys.SUBJECT_TOPICS, subjectId],
    () => getSubjectTopics(subjectId), {
    enabled: !!subjectId && !isWeTest(),
    ...options,
    }
  )

export default useSubjectTopics
