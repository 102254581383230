import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getQuestion } from 'services/question-service'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useQuestion = (questionId: string, options?: UseQueryOptions<QuestionResponse, AxiosError>)
  : UseQueryResult<QuestionResponse> => useQueryWithSnackbar<QuestionResponse>(
    [ReactQueryKeys.QUESTION, questionId],
    () => getQuestion(questionId),
    {
      enabled: !!questionId,
      ...options,
    },
  )

export default useQuestion
