import React from 'react'
import { Link, useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material'
import { isEmpty } from 'lodash'
import {
  SimulationTagsParams,
} from '../../services/simulationtags-service'
import PageTitle from '../layouts/dashboard/PageTitle'
import SaveButton from '../layouts/dashboard/SaveButton'
import DeleteButton from 'components/shared/delete-button/DeleteButton'
import useTags from 'hooks/useTags'
import useDeleteTag from 'hooks/useDeleteTag'

export const SimulationTagsPage = () => {
  const { subjectId }: SimulationTagsParams = useParams()
  const tagsQuery = useTags(subjectId)
  const tagsOption = tagsQuery.data || []

  const deleteTagMutation = useDeleteTag()
  const handleDeleteTag = (tagId: string) => deleteTagMutation.mutateAsync(tagId)

  const tableContent = () => (
      <TableBody>
        {tagsOption.map(tag => (
          <TableRow key={tag.id}>
            <TableCell component='th' scope='row'>{tag.name}</TableCell>
            <TableCell component='th' scope='row'>
              <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'flex-end'}}>
                <Link to={`/subjects/${subjectId}/tags/${tag.id}`}>
                  <Button color='primary' component='span'><EditIcon/></Button>
                </Link>
                <DeleteButton onDelete={() =>handleDeleteTag(tag.id)}/>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )

  return (
    <>
      <div className='u-flex-container u-mb-30'>
        <PageTitle>Simulation Tags</PageTitle>
        <Link to='/tags/new'>
          <SaveButton>
            Add simulation tag
          </SaveButton>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {!isEmpty(tagsOption) && tableContent()}
        </Table>
      </TableContainer>
    </>

  )
}
