import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import { SubscriptionView } from 'types/SubscriptionView'
import { getSubscription } from 'services/subscription-service'

const useSubscription = (subscriptionId: string, options?: UseQueryOptions<SubscriptionView, AxiosError>): UseQueryResult<SubscriptionView> =>
  useQueryWithSnackbar<SubscriptionView>(
    [ReactQueryKeys.SUBSCRIPTION, subscriptionId],
    () => getSubscription(subscriptionId), {
      enabled: !!subscriptionId,
      ...options,
    },
  )

export default useSubscription
