import { UseMutationOptions } from 'react-query'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { UUID } from 'utils/types'
import { QuizQuestion } from 'components/quizzes/quizzes'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { addStaticPracticeQuizQuestion, StaticPracticeQuizQuestionShort } from 'services/static-practice-simulation-quiz-service'

const useAddStaticPracticeQuizQuestion = (quizId: UUID, options?: UseMutationOptions<QuizQuestion, AxiosError, StaticPracticeQuizQuestionShort>)
  : UseMutationResult<QuizQuestion, AxiosError, StaticPracticeQuizQuestionShort> => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: StaticPracticeQuizQuestionShort) => addStaticPracticeQuizQuestion(quizId, data), {
    ...options,
  }
)}

export default useAddStaticPracticeQuizQuestion
