import { styled } from '@mui/material'

const PageTitle = styled('h2')(({theme}) => ({
    ...theme.mixins.toolbar,
    minHeight: '0px !important',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: 1.27,
    margin: '0px'
  }))

  export default PageTitle;