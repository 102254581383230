import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import {
  StyledInput,
  StyledLabel,
} from "components/layouts/dashboard/StyledInput";
import SubscriptionFormSchema from "./SubscriptionFormSchema";
import { isEmpty } from "lodash";

type StaticPracticeFormProps = {
  initialValues: any;
  onSubmit: (initialValues: any) => void;
  allClasses: ClassSearchView[] | undefined;
};

const SubscriptionForm = ({
  initialValues,
  onSubmit,
  allClasses,
}: StaticPracticeFormProps) => {
  let disabled = false;
  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={SubscriptionFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        if (props.values.hasClasses === true) {
          isEmpty(props.values.classId)
            ? (disabled = true)
            : (disabled = false);
        } else {
          disabled = false;
        }
        return (
          <Form>
            <Box style={{ width: "800px" }}>
              <div className="u-flex-container">
                <StyledLabel>Name</StyledLabel>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <StyledInput
                    type="text"
                    name="name"
                    placeholder="Fill name"
                    value={props.values.name}
                    onChange={props.handleChange}
                  />
                  {props.errors.name && props.touched.name ? (
                    <div style={{ color: "red" }}>{props.errors.name}</div>
                  ) : null}
                </Box>
              </div>
              <div className="u-flex-container">
                <StyledLabel>Code</StyledLabel>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <StyledInput
                    type="text"
                    name="code"
                    placeholder="Fill code"
                    value={props.values.code}
                    onChange={props.handleChange}
                  />
                  {props.errors.code && props.touched.code ? (
                    <div style={{ color: "red" }}>{props.errors.code}</div>
                  ) : null}
                </Box>
              </div>
              <Box pb={1} pt={1} className="u-flex-container">
                <StyledLabel>Paid</StyledLabel>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={props.values.paid}
                  onChange={(e) =>
                    props.setFieldValue("paid", !props.values.paid)
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disableTypography
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disableTypography
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box pb={1} pt={1} className="u-flex-container">
                <StyledLabel>Courses</StyledLabel>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={props.values.hasCourses}
                  onChange={(e) =>
                    props.setFieldValue("hasCourses", !props.values.hasCourses)
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disableTypography
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disableTypography
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box pb={1} pt={1} className="u-flex-container">
                <StyledLabel>Random Quiz Access</StyledLabel>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={props.values.randomQuizAccess}
                  onChange={(e) =>
                    props.setFieldValue(
                      "randomQuizAccess",
                      !props.values.randomQuizAccess
                    )
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disableTypography
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disableTypography
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box pb={1} pt={1} className="u-flex-container">
                <StyledLabel>Available on ios</StyledLabel>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={props.values.availableOnIos}
                  onChange={(e) =>
                    props.setFieldValue(
                      "availableOnIos",
                      !props.values.availableOnIos
                    )
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disableTypography
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disableTypography
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box pb={1} pt={1} className="u-flex-container">
                <StyledLabel>Available on android</StyledLabel>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={props.values.availableOnAndroid}
                  onChange={(e) =>
                    props.setFieldValue(
                      "availableOnAndroid",
                      !props.values.availableOnAndroid
                    )
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disableTypography
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disableTypography
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box pb={1} pt={1} className="u-flex-container">
                <StyledLabel>Classes</StyledLabel>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={props.values.hasClasses}
                  onChange={(e) =>
                    props.setFieldValue("hasClasses", !props.values.hasClasses)
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disableTypography
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disableTypography
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px 0",
                  alignItems: "center",
                }}
              >
                {props.values.hasClasses === true && (
                  <Select
                    value={props.values.classId}
                    size={"small"}
                    onChange={(e) =>
                      props.setFieldValue("classId", [e.target.value])
                    }
                    sx={{ width: "210px" }}
                  >
                    {allClasses &&
                      allClasses.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </Box>
            </Box>
            <br />
            <Button type="submit" variant="contained" disabled={disabled}>
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SubscriptionForm;
