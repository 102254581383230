export const resetPasswortStudent = {
  dialogTitle: 'Reset password confirmation',
  dialogContent: `After resetting password, a new generated password will shown only once. 
                    Do You confirm password reset?`,
  buttonText: 'Reset password'
}

export const confirmationSimulation = {
  dialogTitle: 'Confirmation',
  dialogContent: 'After confirm, quizzes will update. Do You confirm generation?',
  buttonText: 'Confirm'
}

export const deleteConfirmation = {
  dialogTitle: 'Confirmation',
  dialogContent: 'After confirmation this action You will not be able to restore the content. Are you sure?',
  buttonText: 'Confirm'
}

export const markDeletedConfirmation = {
  dialogTitle: 'Confirmation',
  dialogContent: 'After confirmation this action content wouldn\'t be available. Are you sure?',
  buttonText: 'Confirm'
}

export const resetPlanConfirmation = {
  dialogTitle: 'Confirmation',
  dialogContent: `Are you sure you want to drop down all subscription plans to Free Simulation?
  This action will affect all current simulations and could not be undone.`,
  buttonText: 'Confirm'
}
