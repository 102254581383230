import { clientDataService } from './client-data-service'
import { UUID } from '../utils/types'
import { QuizQuestion } from '../components/quizzes/quizzes'

export type StaticPracticeQuizQuestionShort = {
  number: number
  questionId: UUID
}

export const addStaticPracticeQuizQuestion = async (quizId: UUID, data: StaticPracticeQuizQuestionShort): Promise<QuizQuestion> =>
  await clientDataService<QuizQuestion>({
    method: 'POST',
    url: `/admin/quizzes/static-practice-simulations/${quizId}/questions`,
    data
})
