import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { unpublishLesson } from 'services/lesson-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUnpublishLesson = (options?: UseMutationOptions<void, AxiosError, PublishLessonRequest>)
  : UseMutationResult<void, AxiosError, PublishLessonRequest> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    unpublishLesson, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.LESSONS)
      }, 
      ...options,
    }

  )}

export default useUnpublishLesson
