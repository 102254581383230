import { useMutation, UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { useQueryClient } from 'react-query'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { deleteArea } from 'services/area-service'

const useDeleteArea = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()
  
  return useMutation<void, AxiosError, string>(
    deleteArea, {
    async onSuccess() {
      await queryClient.invalidateQueries(ReactQueryKeys.AREAS)
    },
    ...options,     
  }
  )
}

export default useDeleteArea
