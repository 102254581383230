import { clientDataService } from './client-data-service'
import jsonToFormData from 'utils/jsonToFormData'

export const getBlogs = ({ page, size, text, title, previewText}: BlogsSearchRequest): Promise<BlogsPageableType> =>
  clientDataService<BlogsPageableType>({
    method: 'GET',
    url: `/admin/blogs?sort=updatedAt,DESC&page=${page}&size=${size}&text=${text}&title=${title}&previewText=${previewText}`,
  })

export const getBlog = (blogId: string): Promise<BlogType> =>
  clientDataService<BlogType>({
    method: 'GET',
    url: `/admin/blogs/${blogId}`,
  })

export const createBlog = (): Promise<CreateResponse> =>
  clientDataService<CreateResponse>({
    method: 'POST',
    url: '/admin/blogs',
  })

export const patchBlog = (data: BlogUpdateRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PATCH',
    url: `/admin/blogs/${data.id}`,
    data: jsonToFormData(data),
  })

export const deleteBlog = (blogId: string): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/blogs/${blogId}`,
  })

export const publishBlog = (blogId: string): Promise<void> =>
  clientDataService<void>({
    method: 'PATCH',
    url: `/admin/blogs/${blogId}/publish`,
  })

export const unpublishBlog = (blogId: string): Promise<void> =>
  clientDataService<void>({
    method: 'PATCH',
    url: `/admin/blogs/${blogId}/unpublish`,
  })
