import { AxiosError } from 'axios'
import { UseQueryOptions, UseQueryResult } from 'react-query'
import { getReportsByParams, GetReportsQueryParams } from 'services/report-service'
import { ApiPageableResponse } from '../utils/api-responses'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useReports = (
  data: GetReportsQueryParams,
  options?: UseQueryOptions<ApiPageableResponse<ReportView>, AxiosError>
): UseQueryResult<ApiPageableResponse<ReportView>> => useQueryWithSnackbar<ApiPageableResponse<ReportView>>(
    [ReactQueryKeys.REPORTS, data.page, data.size, data.reportText, data.reportType, data.reportState],
    () => getReportsByParams(data),
    options,
)

export default useReports
