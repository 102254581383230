import React, { FC, useEffect, useState } from 'react'
import { Form, Formik, ErrorMessage } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Box, Button } from '@mui/material'
import { isEmpty } from 'lodash'

import s from 'components/courses/course-form/CourseForm.module.css'
import FormInput from 'components/common/ui/form/form-input/form-input'
import FormSelect from 'components/common/ui/form/form-select/FormSelect'
import UserAvatar from 'components/users/user-avatar/user-avatar'
import ClassFormSchema from './ClassValidationSchema'
import config from 'utils/config'
import { IMAGE_SUPPORTED_FORMATS } from 'utils/constants'

type ClassFormProps = {
  claz: ClassType
  onSubmit: (data: ClassUpdateRequest) => void
  users: UserSlimView[]
}

const ClassForm: FC<ClassFormProps> = ({
                                         claz,
                                         onSubmit,
                                         users,
                                       }) => {
  const { id, name, previewImage, users: selectedUsers } = claz

  const [imgPreview, setImgPreview] = useState<any>(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (previewImage) {
      setImgPreview(`${config.cdnBaseUri}${previewImage}`)
    } else {
      setImgPreview(null)
    }
  }, [previewImage])


  const handleImageChange = (
    e: any,
    setFieldValue: FormikHelpers<ClassUpdateRequest>['setFieldValue'],
  ) => {
    setError(false)
    const selected = e.target.files[0]
    if (selected && IMAGE_SUPPORTED_FORMATS.includes(selected.type)) {
      let reader = new FileReader()
      reader.onloadend = () => {
        setImgPreview(reader.result)
      }
      reader.readAsDataURL(selected)
      setFieldValue('previewImage', selected)
    } else {
      setError(true)
    }
  }

  const renderUserOption = ({ id, firstName, lastName, profileImage, username }: UserSlimView, selectedId, props) => (
    <Box
      key={id}
      sx={{
        padding: { xs: '10px 0', md: '10px 20px' },
        background: { md: id === selectedId ? '#3b3c3d !important' : '' },
        '&:hover': {
          cursor: 'pointer',
          background: '#262727',
        },
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UserAvatar
          photo={!isEmpty(profileImage) ? `${config.cdnBaseUri}${profileImage}` : ''}
          firstName={firstName}
          lastName={lastName}
          sx={{ width: '30px', height: '30px' }}
        />
        <Box
          sx={{
            display: 'flex',
            fontSize: {xs: '14px', md: '12px'},
            lineHeight: {xs: '16px', md: '14px'},
            pl: '13px',
          }}
        >
          <Box sx={{fontWeight: 500}}>{`${firstName} ${lastName}`}</Box>
          <Box sx={{px: '9px'}}>·</Box>
          <Box>{username}</Box>
        </Box>
      </Box>
    </Box>
  )

  const getUserOptionLabel = ({ firstName, lastName, username, nickname }: UserSlimView) =>
    `${firstName} ${lastName} · ${username || users.find(u => u.nickname === nickname)?.username}`

  return (
    <Formik
      initialValues={{
        id,
        name,
        usersIds: selectedUsers.map(u => u.userId).toString(),
        deletePreviewImage: false,
      }}
      validationSchema={ClassFormSchema}
      onSubmit={onSubmit}
    >{({ setFieldValue }) => (
      <Form>
        <Box
          className="u-m-0 u-bg-black-secondary"
          sx={{
            p: { xs: '20px', md: '40px' },
          }}
        >
          <FormInput name="name" label="Name"/>
          <Box sx={{ mt: '20px' }}>
            <label>Preview Image</label>

            <Box
              sx={{
                mt: '10px',
                p: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '460px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    background: imgPreview
                      ? `url("${imgPreview}") no-repeat center/cover`
                      : 'grey',
                  }}
                >
                  {imgPreview
                    ? (
                      <button
                        className={s.removeButton}
                        type="button"
                        onClick={() => {
                          setImgPreview(null)
                          setFieldValue('deletePreviewImage', true)
                        }}>
                        Remove image
                      </button>
                    )
                    : (
                      <>
                        <p>Add an image</p>
                        <label htmlFor="fileUpload" className={s.customFileUpload}>
                          Choose file
                        </label>
                        <input
                          type="file"
                          id="fileUpload"
                          hidden
                          onChange={(e) => handleImageChange(e, setFieldValue)}
                        />
                        <span>(jpeg or png)</span>
                      </>
                    )}
                  </Box>
                  <Box sx={{color: 'red', margin: '3px 0'}}>
                    {error && <p>File is not supported</p>}
                    <ErrorMessage name="previewImage"/>
                  </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: '20px' }}>
            <label>Participants</label>
            <FormSelect
              name="usersIds"
              options={users}
              multiple={true}
              selectedOptions={selectedUsers.map(u => ({ ...u, id: u.userId }))}
              renderOption={renderUserOption}
              label={{ text: getUserOptionLabel }}
              sx={{ width: '100%' }}
            />
          </Box>
        </Box>
        <Button type="submit" variant="contained" sx={{ mt: '20px' }}>Save</Button>
      </Form>
    )}
    </Formik>
  )
}

export default ClassForm
