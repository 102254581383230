import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Typography } from '@mui/material'
import { isWeTest } from 'utils/constants'
import { QuestionEdit } from '../../../questions/question-edit/QuestionEdit'
import useQuestion from 'hooks/questions/useQuestion'
import useSaveQuestion from 'hooks/questions/useSaveQuestion'
import useLessonAreas from 'hooks/lesson/useLessonAreas'

const LessonQuestionEditPage = () => {
  const {courseId, lessonId, quizId, questionId} = useParams<LessonQuestionPageParams>();

  const question = useQuestion(questionId).data || {} as QuestionResponse
  const lessonAreaQuery = useLessonAreas(courseId).data
  const areas = lessonAreaQuery?.map(area => area.id)
  const saveQuestionMutation = useSaveQuestion(`/courses/${courseId}/lessons/${lessonId}/quizzes/${quizId}`, questionId)


  const handleSubmit = (question: QuestionUpsertModelRequest) => {
    const resultData = isWeTest() ? {...question, areas} : question
    return saveQuestionMutation.mutate(resultData)
  }

  return (
    <div>
      <Typography variant="h5">Question</Typography>
      {!isEmpty(question) &&
        <QuestionEdit
          question={question}
          onSubmit={handleSubmit}
        />
      }
    </div>
  )
}

export default LessonQuestionEditPage
