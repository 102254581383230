import { clientDataService } from './client-data-service'
import jsonToFormData from 'utils/jsonToFormData'

export const getClasses = ({ page, size, name }: ClassesSearchRequest): Promise<ClassesPageableType> =>
  clientDataService<ClassesPageableType>({
    method: 'GET',
    url: `/admin/classes?sort=updatedAt,DESC&page=${page}&size=${size}&name=${name}`,
  })

export const getClass = (classId: string): Promise<ClassType> =>
  clientDataService<ClassType>({
    method: 'GET',
    url: `/admin/classes/${classId}`,
  })

export const createClass = (data: CreateClassRequest): Promise<CreateResponse> =>
  clientDataService<CreateResponse>({
    method: 'POST',
    url: '/admin/classes',
    data,
  })

export const patchClass = (data: ClassUpdateRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PATCH',
    url: `/admin/classes/${data.id}`,
    data: jsonToFormData(data),
  })

export const importParticipantsToClass = (data: ClassImportParticipantsRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PATCH',
    url: `/admin/classes/${data.id}/import-participants`,
    data: jsonToFormData(data),
  })

export const deleteClass = (classId: string): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/admin/classes/${classId}`,
  })
