import { AxiosError } from "axios"
import { UseMutationOptions, UseMutationResult, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { updateSubscriptions } from "services/subscription-service"
import ReactQueryKeys from "types/ReactQueryKeys"
import { CreateSubscriptionRequest } from "types/requests/CreateSubscriptionRequest"
import { SubscriptionView } from "types/SubscriptionView"
import { UUID } from "utils/types"
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useUpdateSubscription = (subscriptionId: UUID, options?: UseMutationOptions<SubscriptionView, AxiosError, CreateSubscriptionRequest>)
: UseMutationResult<SubscriptionView, AxiosError, CreateSubscriptionRequest> => {

  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data) => updateSubscriptions(subscriptionId,data),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.SUBSCRIPTION)
        await history.push(`/subscriptions`)
      },
      ...options
    }
)}

export default useUpdateSubscription
