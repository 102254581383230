import React, { FC } from 'react'
import NavBar from './NavBar'
import TopBar from './TopBar'
import { styled } from '@mui/material'
import PageWrapper from './PageWrapper'

const Wrapper = styled('div')({
  display: 'flex',
  height: '100vh',
  overflow: 'hidden'
})

const DashboardLayout: FC = ({children}: any) => {
  const [navCollapsed, setNavCollapsed] = React.useState(true)

  const toggleNav = () => {
    setNavCollapsed(!navCollapsed)
  }

  return (
    <Wrapper>
        <TopBar toggleNav={toggleNav} navCollapsed={navCollapsed} />
        <NavBar toggleNav={toggleNav} navCollapsed={navCollapsed} /> 
      <main style={{width: '100%'}}>
        <PageWrapper>
          {children}
        </PageWrapper>
      </main>
    </Wrapper>
  )
}

export default DashboardLayout
