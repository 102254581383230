import axios, { AxiosRequestConfig } from 'axios'
import config from '../utils/config'

export const instance = axios.create({
  baseURL: config.baseApiURI,
  withCredentials: true,
})

instance.interceptors.response.use(
  (response) => response.data.payload,
  async (error) => Promise.reject(error),
)

export const clientDataService = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return instance.request(config)
}
