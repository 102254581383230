import React from "react";
import {Link} from 'react-router-dom';
import {
  Button,
  styled,
} from "@mui/material";

const BasicHomeButton = styled(Button)(({theme}) => ({
  ...theme.mixins.toolbar,
  width: '160px',
  paddingLeft: '10px',
  paddingRight: '20px',
  

  '& .logoText': {
    marginLeft: '20px',
    
    fontStyle: 'normal',
    fontSize: '16px',
    color: 'white',
    textDecoration: 'none',
    textTransform: 'capitalize',
      '&:visited': {
        textDecoration: 'none',
      }
  }
}))

const HomeButton = () => {
  return (
    <BasicHomeButton style={{minHeight: "50px"}}>
      <Link to="/" style={{display: 'flex'}}>
          <img src="/logo.svg" alt="Logo"/>
          <span className="logoText">Admin</span>
      </Link>
     
    </BasicHomeButton>
  )
}

export default HomeButton;