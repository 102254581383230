import { clientDataService } from "./client-data-service";
import { PackQuizResponse } from "types/responses/PackQuizResponse";
import { CreatePackResponse } from "types/responses/CreatePackResponse";
import { CreatePackRequest } from "types/requests/CreatePackRequest";
import { UUID } from "utils/types";
import { StudentPackRequest } from "types/requests/StudentPackRequest";
import { StudentPackResponse } from "types/responses/StudentPackResponse";
import { AddStudentPackRequest } from "types/requests/AddStudentPackRequest";
import { DeleteStudentPackRequest } from "types/requests/DeleteStudentPackRequest";

const baseUrl = "/admin/pack-simulations";

export const getPackSimulationList = (): Promise<PackQuizResponse[]> =>
  clientDataService<PackQuizResponse[]>({
    method: "GET",
    url: baseUrl,
});

export const getPackSimulation = (packSimulationId: string): Promise<CreatePackResponse> =>
  clientDataService<CreatePackResponse>({
    method: 'GET',
    url: `${baseUrl}/${packSimulationId}`
})

export const createPackSimulation = async (data: CreatePackRequest): Promise<CreatePackResponse> =>
  await clientDataService<CreatePackResponse>({
    method: "POST",
    url: baseUrl,
    data,
});

export const editPackSimulation = (packSimulationId: UUID, data: EditPackRequest): Promise<CreatePackResponse> =>
  clientDataService<CreatePackResponse>({
    method: 'PATCH',
    url: `${baseUrl}/${packSimulationId}`,
    data
})

export const publishPackSimulation = async (packSimulationId: UUID): Promise<void> =>
  await clientDataService<void>({
    method: 'PATCH',
    url: `${baseUrl}/${packSimulationId}/publish`,
})

export const unpublishPackSimulation = async (packSimulationId: UUID): Promise<void> =>
  await clientDataService<void>({
    method: 'PATCH',
    url: `${baseUrl}/${packSimulationId}/unpublish`,
})

export const deletePackSimulation = async (packSimulationId: UUID): Promise<void> =>
  await clientDataService<void>({
    method: 'DELETE',
    url: `${baseUrl}/${packSimulationId}/delete`,
})

export const getStudentPackSimulationList = (data: StudentPackRequest): Promise<StudentPackResponse[]> =>
  clientDataService<StudentPackResponse[]>({
    method: "GET",
    url: `${baseUrl}/student-packs`,
    params: {
      studentId: data.studentId,
      availableForPurchase: data.availableForPurchase
    }
});

export const addStudentPackSimulation = async (data: AddStudentPackRequest): Promise<void> =>
  await clientDataService<void>({
    method: "POST",
    url: `${baseUrl}/add-student-pack-links`,
    data,
});

export const deleteStudentPackSimulation = async (data: DeleteStudentPackRequest): Promise<void> =>
  await clientDataService<void>({
    method: 'DELETE',
    url: `${baseUrl}/delete-student-pack-link`,
    data
})







