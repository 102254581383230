import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getImportTasks } from 'services/import-tasks-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { ImportTask } from '../components/management/import/questions/ImportTask'

const useTasksForImport = (options?: UseQueryOptions<ImportTask[], AxiosError>): UseQueryResult<ImportTask[]> =>
  useQueryWithSnackbar<ImportTask[]>(
    [ReactQueryKeys.IMPORT_TASKS],
    () => getImportTasks(),
    options,
  )

export default useTasksForImport
