import { UseMutationOptions, useQueryClient } from "react-query";
import { UseMutationResult } from "react-query/types/react/types";
import { AxiosError } from "axios";
import { restoreUser } from "services/users-service";
import useMutationWithSnackbar from "../useMutationWithSnackbar";
import ReactQueryKeys from "types/ReactQueryKeys";
import { useSnackbar } from "notistack";

const useRestoreUser = (
  options?: UseMutationOptions<void, AxiosError, string>
): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutationWithSnackbar(restoreUser, {
    async onSuccess() {
      await queryClient.invalidateQueries(ReactQueryKeys.USER);
      enqueueSnackbar("Successfully restored!", { variant: "success" });
    },
    ...options,
  });
};

export default useRestoreUser;
