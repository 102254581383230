//  general
import QuizGenerateRulesPage from '../components/quizzes/generate-rules/QuizGenerateRulesPage'
import QuizNewRulesPage from '../components/quizzes/generate-rules/QuizNewRulesPage'
import ResetPlanPage from 'components/plan/ResetPlanPage'
//  monthly
import MonthlySimulationsListPage from "components/quizzes/monthly-simulations/list-page/MonthlySimulationsListPage";
import MonthlySimulationsQuizPage from "components/quizzes/monthly-simulations/quiz-page/MonthlySimulationsQuizPage";
import MonthlySimulationQuestionEditPage
    from "components/quizzes/monthly-simulations/question-edit/MonthlySimulationQuestionEditPage";
//  official
import OfficialNationalSimulationsListPage
    from "components/quizzes/official-national/list-page/OfficialNationalSimulationsListPage";
import { OfficialNationalNew } from "components/quizzes/official-national/quiz-new/OfficialNationalNew";
import OfficialNationalQuizPage from "components/quizzes/official-national/quiz-page/OfficialNationalQuizPage"
import OfficialNationalQuestionEditPage
    from 'components/quizzes/official-national/question-edit/OfficialNationalQuestionEditPage';
//  pack Simulation
import PackSimulationsListPage
    from "components/quizzes/pack-simulation/PackSimulationsListPage";
//  question
import { QuestionsListPage } from '../components/questions/questions-list/QuestionsListPage'
import { QuestionEditPage } from '../components/questions/question-edit/QuestionEditPage'
import { CreateLinkedQuestionPage } from 'components/questions/linked-question/CreateLinkedQuestionPage'
//  subjects
import { SubjectsPage } from '../components/subjects/SubjectsPage'
import { EditSubjectPage } from '../components/subjects/subject-edit/EditSubjectPage'
import { NewSubjectPage } from '../components/subjects/subject-new/NewSubjectPage'
//  topics
import { TopicsPage } from '../components/subjecttopics/TopicsPage'
import { NewTopicPage } from '../components/subjecttopics/NewTopicPage'
import { TopicEditPage } from '../components/subjecttopics/TopicEditPage'
//  courses
import { CoursesPage } from '../components/courses/course-list/CoursesPage'
import { CourseNewPage } from '../components/courses/course-new/CourseNewPage'
import { CourseEditPage } from '../components/courses/course-edit/CourseEditPage'
//  lessons
import { LessonsListPage } from '../components/lessons/lessons-list/LessonsListPage'
import LessonEditPage from '../components/lessons/lesson-edit/LessonEditPage'
// quizzes lesson
import LessonQuizEditPage from '../components/quizzes/lesson/quiz-page/LessonQuizEditPage'
import LessonQuestionEditPage from '../components/quizzes/lesson/question-edit/LessonQuestionEditPage'
//  students
import { UsersListPage } from '../components/users/UsersListPage'
import { NewUserPage } from '../components/users/NewUserPage'
import { EditUserPage } from '../components/users/EditUserPage'
//
import { ImportQuestionsPage } from "../components/management/import/questions/ImportQuestionsPage"
import { VideoScaleTasksPage } from '../components/management/videoscaletasks/VideoScaleTasksPage'
import LessonVideoChaptersPage from '../components/lessons/lesson-video-chapters/LessonVideoChaptersPage'
import ReportsPage from '../components/reports'
import AreasPage from 'components/areas/areas-list'
import AreaNewPage from 'components/areas/area-new'
import { BlogsListPage } from '../components/blogs/blogs-list/BlogsListPage'
import { BlogEditPage } from '../components/blogs/blog-edit/BlogEditPage'
import { ClassesListPage } from '../components/classes/classes-list/ClassesListPage'
import { ClassEditPage } from '../components/classes/class-edit/ClassEditPage'

import { SimulationTagsPage } from '../components/subjectsimulationtags/SimulationTagsPage'
import { SimulationTagEditPage } from '../components/subjectsimulationtags/SimulationTagEditPage'
import { NewTagPage } from '../components/subjectsimulationtags/NewTagPage'

import QuizEditRulesPage from 'components/quizzes/generate-rules/QuizEditRulesPage';
import SubscriptionsListPage from 'components/subscriptions/SubscriptionsListPage';
import SubscriptionCreate from 'components/subscriptions/SubscriptionCreate';
import SubscriptionEdit from 'components/subscriptions/SubscriptionEdit';
import { PackSimulationNew } from 'components/quizzes/pack-simulation/pack-new/PackSimulationNew';
import { PackSimulationEdit } from 'components/quizzes/pack-simulation/pack-edit/PackSimulationEdit';
import PackSimulationQuizzesList from 'components/quizzes/pack-simulation/quizzes/PackSimulationQuizzesList';
import { PackSimulationQuizCreateNew } from 'components/quizzes/pack-simulation/quizzes/quiz-new/PackSimulationQuizCreateNew';
import { PackSimulationQuizEdit } from 'components/quizzes/pack-simulation/quizzes/PackSimulationQuizEdit';
import PackSimulationQuizPage from 'components/quizzes/pack-simulation/quizzes/PackSimulationQuizPage';
import PackSimulationQuestionEditPage from 'components/quizzes/pack-simulation/quizzes/PackSimulationQuestionEditPage';
import QuizRulesReportsPage from 'components/quizzes/generate-rules/QuizRulesReportsPage';

// need check export component

const pages = {
    QuizGenerateRulesPage,
    ResetPlanPage,
    StudentsListPage: UsersListPage,
    NewStudentPage: NewUserPage,
    EditStudentPage: EditUserPage,
    CoursesPage,
    CourseNewPage,
    CourseEditPage,
    TopicsPage,
    NewTopicPage,
    TopicEditPage,
    SimulationTagsPage,
    SimulationTagEditPage,
    NewTagPage,
    LessonQuizEditPage,
    LessonQuestionEditPage,
    LessonsListPage,
    LessonEditPage,
    LessonVideoChaptersPage,
    SubjectsPage,
    EditSubjectPage,
    NewSubjectPage,
    QuestionsListPage,
    QuestionEditPage,
    CreateLinkedQuestionPage,
    OfficialNationalSimulationsListPage,
    OfficialNationalNew,
    OfficialNationalQuizPage,
    OfficialNationalQuestionEditPage,
    MonthlySimulationsListPage,
    MonthlySimulationsQuizPage,
    MonthlySimulationQuestionEditPage,
    VideoScaleTasksPage,
    ImportQuestionsPage,
    ReportsPage,
    AreasPage,
    AreaNewPage,
    BlogsListPage,
    BlogEditPage,
    ClassesListPage,
    ClassEditPage,
    PackSimulationsListPage,
    PackSimulationNew,
    PackSimulationEdit,
    PackSimulationQuizzesList,
    PackSimulationQuizCreateNew,
    PackSimulationQuizEdit,
    PackSimulationQuizPage,
    PackSimulationQuestionEditPage,
    QuizNewRulesPage,
    QuizEditRulesPage,
    QuizRulesReportsPage,
    SubscriptionsListPage,
    SubscriptionCreate,
    SubscriptionEdit,
}

export default pages
