import {useEffect} from 'react'
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@mui/material'
import SimulationTagList from './SimulationTagList'
import useTags from 'hooks/useTags'
import {isEmpty} from "lodash";
import {FieldArrayRenderProps} from "formik";
import useEditSimulationTags from 'hooks/useEditSimulationTags';
import QuizTotal from 'components/layouts/dashboard/QuizTotal';
import { SectionSubjectType } from '../types/quizRule';


export type SubjectsListProps = {
  sectionSubject: SectionSubjectType,
  summaryPercents: number
  probabilityArrayHelper: FieldArrayRenderProps,
}

const SubjectsList = ({
  sectionSubject,
  summaryPercents,
  probabilityArrayHelper,
}: SubjectsListProps) => {

  const { subject, tagProbabilities } = sectionSubject
  const tagsList = useTags(subject.id).data

  const {
    isTagOpen,
    onTagClose,
    onTagSelect,
  } = useEditSimulationTags({
    ruleId: sectionSubject.subject.id
  });

  useEffect(() => {
    if (tagsList) {
      const mappedProbabilities = tagsList.map(tag =>  {
        const maybeTag = tagProbabilities.find(t => t.simulationTag.id === tag.id)
        return {
          id: maybeTag?.id || '',
          subjectId: subject.id,
          simulationTag: tag,
          probability: maybeTag?.probability || 0,
        }
      });
      probabilityArrayHelper.form.setFieldValue(probabilityArrayHelper.name, mappedProbabilities)
    }
  }, [tagsList, subject.id]);

return (
  <TableContainer component={Paper} key={1}>
    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems:'center', mt: '20px'}}>
      <QuizTotal>{subject.name} {summaryPercents}%</QuizTotal>
    </Box>
    <Table size="small" className="u-mt-10">
      <TableHead>
        <TableRow>
          <TableCell align="center" width="50">#</TableCell>
          <TableCell component="th" scope="row">Simulation tag</TableCell>
          <TableCell align="center" sx={{pl: '20px'}}>Probability</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <SimulationTagList
          probabilityArrayHelper={probabilityArrayHelper}
          simulationTagsProbability={sectionSubject.tagProbabilities}
          isTagOpen={isTagOpen}
          onSelect={onTagSelect}
          onClose={onTagClose}
        />
      </TableBody>
    </Table>
  </TableContainer>
)
}

export default SubjectsList
