import { styled } from '@mui/material'

const SaveButton = styled('button')(({theme}) => ({
    ...theme.mixins.toolbar,
    minHeight: '0px !important',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',

    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px', 
    
    padding: '10px 13px'
  }))

  export default SaveButton;
