import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteClass } from 'services/class-service'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useDeleteClass = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteClass,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.CLASSES)
      },
      ...options,
    },
  )
}

export default useDeleteClass
