import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteUser } from 'services/users-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useDeleteUser = (options?: UseMutationOptions<void, AxiosError, string>)
  : UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    deleteUser, {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.USERS)
      }, 
      ...options,
    }  
  )}

export default useDeleteUser
