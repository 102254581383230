import { Box, Button } from "@mui/material";
import FormInput from "components/common/ui/form/form-input/form-input";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useState } from "react";

type AmountFormProps = {
  initialValue: number;
  validateSchema?: any;
  onSave: (probability: number) => void;
  onClose: () => void;
};

export const AmountForm = ({
  initialValue,
  onSave,
  onClose,
}: AmountFormProps) => {

  const [value, setValue] = useState(initialValue);

  const handleSaveClick = () => {
    onSave(value);
    onClose();
  }

  return (
    <Box sx={{ m: 0, display: "flex", alignItems: "center" }}>
      <FormInput
        name="amount"
        type="number"
        value={value}
        variant="standard"
        sx={{ width: "100%", ml: "10px" }}
        onChange={e => setValue(Number(e.target.value))}
      />
      <Button
        type="button"
        sx={{ p: 0, ml: "15px" }}
        onClick={handleSaveClick}
      >
        <CheckOutlinedIcon />
      </Button>
      <Button
        type="button"
        sx={{ p: 0, ml: "5px" }}
        onClick={onClose}
      >
        <ClearOutlinedIcon />
      </Button>
    </Box>
  );
};
