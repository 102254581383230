import { UseMutationOptions } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { deleteQuizQuestion } from 'services/question-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'

const useDeleteQuizQuestion = (quizId: string)
  : UseMutationResult<void, AxiosError, string> => useMutationWithSnackbar(
    (questionId: string) => deleteQuizQuestion(quizId, questionId)
  )

export default useDeleteQuizQuestion
