import { makeAutoObservable } from "mobx";

class AuthStore {

  authenticated: boolean = localStorage.getItem("loggedIn") === "true"

  constructor() {
    makeAutoObservable(this)
  }

  isAuthenticated = () => this.authenticated
  setAuthenticated = (authenticated: boolean) => {
    this.authenticated = authenticated
  }
}

export default new AuthStore()
