import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FieldArray, FormikProps, FormikValues } from "formik";
import SubjectsList from "./SubjectsList";
import QuizTotal from "components/layouts/dashboard/QuizTotal";

type SectionsListProps = {
  selectedSectionIndex: number;
  onSelect: (section: any, index: number) => void;
  isDivide: boolean;
  formProps: FormikProps<FormikValues>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const SectionsList = ({
  selectedSectionIndex,
  onSelect,
  isDivide,
  formProps,
  setDisabled,
}: SectionsListProps) => {
  
  const calcRuleSubjectPercents = (tagProbabilities: any): number =>
    tagProbabilities.reduce(
      (sum, topic) => sum + topic.probability,
      0
    ) / 100;

  const calcRuleTotalPercents = (section: any): number => {
    return (
      section.subjects
        ?.map((item) => calcRuleSubjectPercents(item.tagProbabilities))
        ?.reduce((sum, subjectTotal) => sum + subjectTotal, 0) || 0
    );
  };

  formProps.values.sections[selectedSectionIndex] &&
  calcRuleTotalPercents(formProps.values.sections[selectedSectionIndex]) > 100
    ? setDisabled(true)
    : setDisabled(false);

  const handleDeleteSection = (sectionIndex) => {
    onSelect({}, -1);

    const updatedSections = formProps.values.sections;
    updatedSections.splice(sectionIndex, 1);
    formProps.setFieldValue("sections", updatedSections);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Questions</TableCell>
                {isDivide && (
                  <TableCell align="right">Section Time Limit</TableCell>
                )}
                <TableCell>Subjects</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {formProps.values.sections &&
                formProps.values.sections.map((section, index) => (
                  <TableRow
                    hover
                    key={index}
                    selected={index === selectedSectionIndex}
                    onClick={(_) => onSelect(section, index)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {section.name}
                    </TableCell>

                    <TableCell align="right">
                      <Typography>{section.questionsAmount}</Typography>
                    </TableCell>
                    {isDivide && (
                      <TableCell align="right">{section.timeLimit}</TableCell>
                    )}
                    <TableCell>
                      {" "}
                      <span>
                        {section.subjects.map((s) => s.subject.name).join(", ")}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteSection(index);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          {selectedSectionIndex >= 0 && (
            <>
              <QuizTotal
                className="u-pt-10"
                color={
                  calcRuleTotalPercents(
                    formProps.values.sections[selectedSectionIndex]
                  ) > 100
                    ? "red"
                    : "green"
                }
              >
                Section total percents{" "}
                {calcRuleTotalPercents(
                  formProps.values.sections[selectedSectionIndex]
                )}
                %
              </QuizTotal>
              {formProps.values.sections[selectedSectionIndex].subjects.map(
                (sectionSubject, subjectIndex) => (
                  <Box key={sectionSubject.subject.id}>
                    <FieldArray
                      name={`sections[${selectedSectionIndex}].subjects[${subjectIndex}].tagProbabilities`}
                      render={(probabilityArrayHelpers) => (
                        <SubjectsList
                          sectionSubject={sectionSubject}
                          summaryPercents={calcRuleSubjectPercents(
                            sectionSubject.tagProbabilities
                          )}
                          probabilityArrayHelper={probabilityArrayHelpers}
                        />
                      )}
                    />
                  </Box>
                )
              )}
            </>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SectionsList;
