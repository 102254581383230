import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { getSubjects } from 'services/subject-service'
import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useSubjects = (options?: UseQueryOptions<SubjectResponse[], AxiosError>)
  : UseQueryResult<SubjectResponse[]> => useQueryWithSnackbar<SubjectResponse[]>(
    [ReactQueryKeys.SUBJECTS],
    () => getSubjects(),
    options,
  )

export default useSubjects
