import { RouteComponentProps } from "react-router";

import PageTitle from "components/layouts/dashboard/PageTitle";
import useSelectItem from "hooks/useSelectItem";
import useSubscriptions from "hooks/subscriptions/useSubscriptions";
import usePackSimulation from "hooks/packSimulation/usePackSimulation";
import useEditPackSimulation from "hooks/packSimulation/useEditPackSimulation";
import PackSimulationForm from "../pack-form/PackSimulationForm";

export const PackSimulationEdit = (
  props: RouteComponentProps<{ packSimulationId: string }>
) => {
  const packSimulationId: string = props.match.params.packSimulationId;

  const allSubscriptions = useSubscriptions().data;
  const updatePackSimulationMutation = useEditPackSimulation(packSimulationId);

  const valuesPack = usePackSimulation(packSimulationId).data;
  const { handleItemChecked, handleAllChecked, selectedItemIds } =
    useSelectItem({
      allItems: allSubscriptions,
      initialSelectedItemsIds: valuesPack?.subscriptionIds,
    });

  const getInitialState = (
    title: string,
    code: string,
    availableFrom: number,
    purchaseLink: string
  ) => ({
    title: title,
    code: code,
    availableFrom: availableFrom,
    purchaseLink: purchaseLink,
  });

  const onEdit = (data) => {
    const formattedData = { subscriptionIds: selectedItemIds, ...data };
    return updatePackSimulationMutation.mutate(formattedData);
  };

  return (
    <div className="u-wpx-900 u-m-0 u-pt-30 u-pl-48 u-pr-48 u-pb-30 u-bg-black-secondary">
      <PageTitle>Edit Simulation Pack</PageTitle>
      {valuesPack && (
        <PackSimulationForm
          allSubscriptions={allSubscriptions}
          group={getInitialState(
            valuesPack.title,
            valuesPack.code,
            valuesPack.availableFrom,
            valuesPack.purchaseLink
          )}
          onSubmit={onEdit}
          handleSubscriptionChecked={handleItemChecked}
          handleAllChecked={handleAllChecked}
          selectedSubscriptionIds={selectedItemIds}
        />
      )}
    </div>
  );
};
