import { object, string, bool, mixed } from 'yup'

import config from 'utils/config'
import { IMAGE_SUPPORTED_FORMATS } from 'utils/constants'

const ClassFormSchema = object().shape({
  id: string().required(),
  name: string().required(),
  usersIds: string(),
  deletePreviewImage: bool().nullable(),
  previewImage: mixed()
    .test(
      'size',
      'Image size up to 10MB',
      value => !value || value && value.size <= config.image.maxFileSizeMb * 1024 * 1024,
    ).test(
      'type',
      'Supported image types: JPEG or PNG',
      value => !value || value && IMAGE_SUPPORTED_FORMATS.includes(value.type),
    ),
})

export default ClassFormSchema
