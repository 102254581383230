import { UseMutationOptions, useQueryClient } from 'react-query'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { setGeneratePlannedDate } from 'services/monthly-simulation-quiz-service'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUpdatePlannedDate = (options?: UseMutationOptions<void, AxiosError, number>)
  : UseMutationResult<void, AxiosError, number> => {
    const queryClient = useQueryClient()
  
    return useMutationWithSnackbar(
      (date: number) => setGeneratePlannedDate(date), {
        async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.PLANNED_DATE)
      },
      ...options,
      }
    )
}

export default useUpdatePlannedDate
