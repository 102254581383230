import dayjs, { Dayjs } from 'dayjs'
import { TIME_FORMAT } from './constants'

type DateType = Dayjs | number

export const getDurationMilli = (from: DateType, to?: DateType) => dayjs.duration(dayjs(to).diff(from)).asMilliseconds()

export const getDurationMilliFormatted = (dur: number, format: string = TIME_FORMAT) => dayjs.utc(dur).format(format)

export const getDurationSecondsFormatted = (dur: number, format: string = TIME_FORMAT) => getDurationMilliFormatted(dur * 1000, format)

export const getDurationFormatted = (from: DateType, to?: DateType) => {
  const dur = getDurationMilli(from, to)
  return dayjs.utc(dur).format(TIME_FORMAT)
}

export const HHMMSS_REQEX = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/

export const secondsToFullTimeFormat = (seconds: number) =>
  new Date(seconds * 1000).toISOString().substr(11, 8)

export const isHHmmSS = (formatted: string) => HHMMSS_REQEX.test(formatted)

export const hhMMssToSeconds = (formatted: string) => {
  if (isHHmmSS(formatted)) {
    const splitted = formatted.split(':')
    const hours = +splitted[0]
    const minutes = +splitted[1]
    const seconds = +splitted[2]
    return seconds + minutes * 60 + hours * 360
  } else return NaN
}
