import { useCallback, useState } from 'react'

interface UseFileUriResult {
  file: File | null
  change: (file: File) => void
  isLoading: boolean
  error: string | null
  fileUri: string | null
  fileName: string | null
}

interface UseFileUriOptions {
  maxFileSizeMb?: number
}

const useFileUri = ({maxFileSizeMb = 10}: UseFileUriOptions = {}): UseFileUriResult => {
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [fileUri, setFileUri] = useState<string | null>(null)
  const [fileName, setFileName] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const change = useCallback(
    (file: File | null) => {
      setError(null)
      setIsLoading(true)
      setFile(file)

      if (file) {
        const sizeMb = file.size * 0.000001
        if (sizeMb > maxFileSizeMb) {
          setError(`Max image size for upload ${maxFileSizeMb} Mb`)
          setFileUri(null)
          setIsLoading(false)
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
          setFileUri(`${event.target?.result}`)
          setIsLoading(false)
          setFileName(file.name)
        }
        reader.onerror = err => {
          setError('' + err)
          setFileUri(null)
          setIsLoading(false)
          setFileName(null)
        }
      } else {
        setFileUri(null)
        setFileName(null)
        setIsLoading(false)
      }
    },
    [maxFileSizeMb],
  )

  return {file, change, error, fileUri, isLoading, fileName}
}

export default useFileUri
