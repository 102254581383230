import axios from "axios";
import config from "../utils/config";
import { HttpStatus } from "../utils/constants";
import history from "../utils/history";
import { logout } from "./auth-service";

const serverApi = axios.create({
  baseURL: config.baseApiURI,
  withCredentials: true,
})

serverApi.interceptors.response.use(
  (successResponse) => successResponse.data,
  async (error) => {

    if (!error.response) {
      return Promise.reject(error)
    }

    const { status } = error.response
    switch (status) {
      case HttpStatus.UNAUTHORIZED:
        // TODO logout CDN
        history.push('/')
        await logout()
        break
    }
    return Promise.reject(error)
  }
)

export default serverApi
