import React from 'react'
import {
    AppBar as MuiAppBar,
    Button,
    Toolbar,
    styled,
    Theme,
    CSSObject,
    AppBarProps as MuiAppBarProps,
    Box,
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import authStore from 'auth/AuthStore'
import { logout } from 'services/auth-service'
import HomeButton from './HomeButton'
import history from 'utils/history'

interface AppBarProps extends MuiAppBarProps {
    navCollapsed: boolean;
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'navCollapsed'})<AppBarProps>
(({theme, navCollapsed}) => (
    {
        backgroundColor: 'black',
        overflow: "hidden",
        ...(!navCollapsed && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(navCollapsed && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }));


const openedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden'
});

const onLogout = () => {
    logout().then(() => {
        authStore.setAuthenticated(false)
        history.push("/")
    })
}

 type TopBarProps = {
     navCollapsed: boolean,
     toggleNav: () => void
 }

function TopBar ({navCollapsed, toggleNav}: TopBarProps) {

    const {t} = useTranslation()

    return (
        <AppBar
            position="fixed"
            elevation={0}
            navCollapsed={navCollapsed}
        >
         <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', height: 'inherit'}}>
                <Box sx={{display: 'flex', flexDirection: 'row' }}> 
                      <HomeButton />            
                </Box>
                <Toolbar sx={{justifyContent: 'flex-end', p: 0}}>
                    <Box my={"auto"} mr={1}>
                      <Button color="inherit" onClick={onLogout}>Logout</Button>
                    </Box>
                </Toolbar>
            </Box>
        </AppBar>
    )
}

export default TopBar