import React from 'react'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { isWeTest } from '../../utils/constants'
import PageTitle from '../layouts/dashboard/PageTitle'
import SaveButton from '../layouts/dashboard/SaveButton'
import DeleteButton from 'components/shared/delete-button/DeleteButton'
import EditIcon from '@mui/icons-material/Edit'
import useSubjects from 'hooks/subjects/useSubjects'
import useDeleteSubject from 'hooks/subjects/useDeleteSubject'


export const SubjectsPage = () => {

  const subjectsQuery = useSubjects()
  const subjects = subjectsQuery.data || []

  const deleteSubjectMutation = useDeleteSubject()
  const handleDeleteSubject = (subjectId: string) => deleteSubjectMutation.mutateAsync(subjectId)

  const tableContent = () => (
    <TableBody>
      {subjects.map(subject => (
        <TableRow key={subject.id} hover style={{ cursor: "pointer" }}>
          <TableCell component="th" scope="row">
            {subject.name}
          </TableCell>
          <TableCell component="th" scope="row" align="right">
            {!isWeTest() &&
              <>
                <Link to={`/subjects/${subject.id}/topics`} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" sx={{ ml: 3, width: 'auto' }}>
                    TOPICS
                  </Button>
                </Link>
                <Link to={`/subjects/${subject.id}/tags`} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" sx={{ ml: 3, width: 'auto' }}>
                    TAGS
                  </Button>
                </Link>
              </>
            }
            <Link to={`/subjects/${subject.id}/edit`}>
              <Button color="primary" component="span">
                <EditIcon />
              </Button>
            </Link>
            <DeleteButton onDelete={() => handleDeleteSubject(subject.id)} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

  return (
    <>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Subjects</PageTitle>
        <Link to="/subjects/new">
          <SaveButton>Add subject</SaveButton>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {!isEmpty(subjects) && tableContent()}
        </Table>
      </TableContainer>
    </>
  )
}
