import React, { useState } from 'react'
import {
  Box, CircularProgress,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, TextField,
} from '@mui/material'
import { debounce } from 'lodash'

import PageTitle from 'components/layouts/dashboard/PageTitle'
import SaveButton from 'components/layouts/dashboard/SaveButton'
import config from 'utils/config'
import useClasses from 'hooks/useClasses'
import useCreateClass from 'hooks/useCreateClass'
import useDeleteClass from 'hooks/useDeleteClass'
import { ClassActions } from './ClassActions'
import { DEBOUNCE_TYPING_MILLI } from 'utils/constants'

export const ClassesListPage = () => {
  const [nameFilter, setNameFilter] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [classIdToDelete, setClassIdToDelete] = useState<string>('')

  const classesQuery = useClasses({
    page: pageNumber,
    size: pageSize,
    name: nameFilter,
  } as ClassesSearchRequest)

  const classes = classesQuery.data

  const createClassMutation = useCreateClass()
  const deleteClassMutation = useDeleteClass()

  const handleCreateClass = () => createClassMutation.mutate({ className: 'New class' } as CreateClassRequest)
  const handleDeleteClass = (classId: string) => deleteClassMutation.mutateAsync(classId)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPageNumber(newPage)

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPageNumber(newPage - 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const setNameFilterDebounced = debounce(
    nextValue => setNameFilter(nextValue),
    DEBOUNCE_TYPING_MILLI,
  )

  const tableContent = () => (
    <TableBody>
      {classes?.content.map((item) => (
          <TableRow
            key={item.id}
            hover
            sx={{
              cursor: 'pointer',
              background: item.public ? '#4780D8' : 'unset',
            }}
          >
            <TableCell component="td" scope="row">
              {item.name}
            </TableCell>
            <TableCell component="td" scope="row">
              <Box sx={{ width: '100%', height: '50px' }}>
                {item.previewImageUri && <img
                    src={`${config.cdnBaseUri}${item.previewImageUri}`}
                    alt="Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />}
              </Box>
            </TableCell>
            <TableCell component="td" scope="row">
              {item.memberCount}
            </TableCell>
            <TableCell component="td" scope="row" sx={{ wordBreak: 'break-word' }}>
              <ClassActions
                classId={item.id}
                isPublic={item.public}
                deleteConfirmDialogOpen={deleteConfirmDialogOpen}
                setDeleteConfirmDialogOpen={setDeleteConfirmDialogOpen}
                classIdToDelete={classIdToDelete}
                setClassIdToDelete={setClassIdToDelete}
                onDelete={handleDeleteClass}
              />
            </TableCell>
          </TableRow>
        ),
      )}
    </TableBody>
  )

  return (
    <div>
      <div className="u-flex-container u-mb-30">
        <PageTitle>Classes</PageTitle>
        <SaveButton onClick={handleCreateClass}>
          + New class
        </SaveButton>
      </div>
      <Box
        sx={{ '& .MuiTextField-root': { m: 0, width: '25ch' } }}
      >
        <TextField
          label="Name"
          onChange={(e) => setNameFilterDebounced(e.target.value)}
        />
      </Box>
      <br/>
      {classesQuery.isLoading && <CircularProgress/>}
      {!classesQuery.isLoading && (
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Preview image</TableCell>
                  <TableCell>Members count</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              {tableContent()}
            </Table>
            <Grid container spacing={3}>
              <Grid item xs={6} display="flex" alignItems="center">
                <Pagination
                  size={'medium'}
                  count={classes?.totalPages}
                  onChange={handleChange}
                  page={pageNumber + 1}
                  boundaryCount={2}/>
              </Grid>
              <Grid item xs={6}>
                <TablePagination
                  component="div"
                  count={classes?.totalElements || -1}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </TableContainer>
        </Box>
      )}
    </div>
  )
}
