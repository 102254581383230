import React from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Grid } from '@mui/material'
import { isWeTest } from 'utils/constants'
import * as yup from 'yup'

import { StyledLabel, StyledInput } from '../../layouts/dashboard/StyledInput'
import PageTitle from '../../layouts/dashboard/PageTitle'
import SaveButton from '../../layouts/dashboard/SaveButton'
import useAreas from 'hooks/useAreas'
import useCreateCourse from 'hooks/course/useCreateCourse'
import Selector from 'components/shared/selector/Selector'
import useSelectItem from 'hooks/useSelectItem'

const validationSchema = yup.object({
    name: yup.string().required(),
    number: yup.number().required()
})

export const CourseNewPage = () => {

  const allAreas = useAreas().data || []
  const newCourseMutation = useCreateCourse()

  const { handleItemChecked, handleAllChecked, selectedItemIds } = useSelectItem({ allItems: allAreas })

  const handleCreateCourse = (data: CourseUpdateRequest) => {
    const formattedData = isWeTest() ? { areas: [...selectedItemIds], ...data } : data
    return newCourseMutation.mutate(formattedData)
  }

    const formik = useFormik({
        initialValues: {
            name: "",
            number: 0,
            
        },
        onSubmit: handleCreateCourse,
        validationSchema: validationSchema
    })

    return (
        <div>
            <PageTitle className="u-mb-30">New Course</PageTitle>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} className="u-wpx-600 u-m-0 u-bg-black-secondary">
                <Grid item xs={12} className="u-pl-48">
                  <StyledLabel>Number:</StyledLabel>
                    <StyledInput
                      name="number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      />
                </Grid>
                <Grid item xs={12} className="u-pl-48">
                  <StyledLabel>Title:</StyledLabel>
                  <br/>
                  <StyledInput
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                </Grid>
                {isWeTest() && !isEmpty(allAreas) &&
                  <Grid item xs={12} className="u-pl-48 u-pb-30"> 
                    <Selector
                      allItems={allAreas}
                      selectedItem={selectedItemIds}
                      onItemChecked={handleItemChecked}
                      onAllChecked={handleAllChecked}
                    />
                  </Grid>
                }
                <Grid item xs={12} className="u-pl-48 u-pb-30">
                  <SaveButton> Save </SaveButton>
                </Grid>
              </Grid>
            </form>

        </div>
    );
};


